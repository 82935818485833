import React from 'react';
import { Stack } from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import { useSelector } from 'react-redux';
import WbsIssueDocumentPaneCommandBar from '../commandBar/DocumentPaneCommandBar';
import WbsIssueDocumentPane from './WbsIssueDocumentPane';
import ProgressBar from '../../../shared/progressBar/progressBar';

const WbsIssueDocumentPaneWrapper = () => {

  const formData = useSelector((state: any) => state.wbsIssue.formData);
  const showWbsIssueProgressBar = useSelector((state: any) => state.wbsIssue.showWbsIssueProgressBar);

  return (
    <>
      <Stack>
        <Stack.Item>
          <TitlePane
            headerTitle={formData?.title}
            type={1}
            close={true}
            isFullScreen={true}
          />
        </Stack.Item>
        <Stack.Item>
          <Stack style={{ flexGrow: 1, borderLeft: '1px solid #ccc3c3' }}>
            <Stack
              className='project-list-header new-design'
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingRight: 0,
              }}
              styles={{ root: { width: '100%', paddingRight: 0 } }}
            >
              <ProgressBar show={showWbsIssueProgressBar} />
              <WbsIssueDocumentPaneCommandBar />
            </Stack>
            <Stack className={`main-layout-document-pane-stack`}>
              <div style={{ width: '100%', padding: 0 }}>
                <WbsIssueDocumentPane isNew={null} />
              </div>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default WbsIssueDocumentPaneWrapper;