import React from 'react';
import { connect } from 'react-redux';
import { LabourTeamMember, ProjectMolecule, Resource } from '../../../../types/projectMolecule';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import {
  createExtraWorkConsumable,
  createExtraWorkLabour,
  createExtraWorkMaterials,
  createExtraWorkTools,
  deleteConsumable,
  deleteLabours,
  deleteMaterials,
  deleteTools,
  readExtraTeamMembers,
  readPmolExtraWorkConsumableData,
  readPmolExtraWorkLaboursData,
  readPmolExtraWorkMaterialsData,
  readPmolExtraWorkResources,
  readPmolExtraWorkToolsData,
  setExtraWorkFetched,
} from '../../../../reducers/pmolReducer';
import DataGrid from '../../../../shared/dataGrid/component';
import { ResourceTypes } from '../../../../types/billOfResources';
import UprinceLogger from '../../../../shared/Logger/logger';
import { ActionButtonState } from '../../../../types/corporateProductCatalogue';
import { Selection } from '@fluentui/react/lib/DetailsList';
import ResourceModal from '../material/modal/component';
import client from '../../../../api';
import _ from 'lodash';
import { clearCpcData, getCpcById } from '../../../../reducers/billOfResourcesReducer';
import { CPC_PMOL_FILTER_EP } from '../../../../shared/endpoints';
import LabourModal from '../labour/modal/component';
import VisibilitySensor from 'react-visibility-sensor';


export interface State {
  ////PMOL////
  selectedResourceTypeId: string | null;
  selectedCpcEnvironment: string | null;

  materials: Resource[];
  material: Resource;
  isMaterialModalVisible: boolean;
  isMaterialEdit: boolean;
  materialActionButtonState: ActionButtonState;
  materialSelectionDetails: {} | string;
  materialDeleteItems: [] | string[];

  tools: Resource[];
  tool: Resource;
  isToolsModalVisible: boolean;
  isToolEdit: boolean;
  toolActionButtonState: ActionButtonState;
  toolSelectionDetails: {} | string;
  toolDeleteItems: [] | string[];

  consumables: Resource[];
  consumable: Resource;
  isConsumablesModalVisible: boolean;
  isConsumableEdit: boolean;
  consumableActionButtonState: ActionButtonState;
  consumableSelectionDetails: {} | string;
  consumableDeleteItems: [] | string[];

  labours: Resource[];
  labour: Resource;
  isLaboursModalVisible: boolean;
  isLabourEdit: boolean;
  labourActionButtonState: ActionButtonState;
  labourSelectionDetails: {} | string;
  labourDeleteItems: [] | string[];
  teamMembers: LabourTeamMember[];
  teamMember: LabourTeamMember | null;
  teamMemberIsEdit: boolean;
  /////
}

interface Props extends WithTranslation {
  formData: ProjectMolecule;
  clearCpcData: any;
  getCpcById: any;
  cpcData: any;

  createExtraWorkMaterials: any;
  deleteMaterials: any;
  materialListExtraWork: Resource[];
  createExtraWorkMaterialsStatus: boolean;
  readExtraWorkMaterialsStatus: boolean;

  createExtraWorkTools: any;
  deleteTools: any;
  toolsExtraWork: Resource[];
  createExtraWorkToolsStatus: boolean;
  readExtraWorkToolsStatus: boolean;

  createExtraWorkLabour: any;
  deleteLabours: any;
  laboursExtraWork: Resource[];
  createExtraWorkLabourStatus: boolean;
  readExtraWorkLabourStatus: boolean;

  createExtraWorkConsumable: any;
  deleteConsumable: any;
  consumablesExtraWork: Resource[];
  createExtraWorkConsumableStatus: boolean;
  readExtraWorkConsumableStatus: boolean;

  readPmolExtraWorkMaterialsData: (id: string) => void;
  readPmolExtraWorkToolsData: (id: string) => void;
  readPmolExtraWorkConsumableData: (id: string) => void;
  readPmolExtraWorkLaboursData: (id: string) => void;
  teamMembers: [];
  readExtraTeamMembers: any;

  readPmolExtraWorkResources: any;

  readOnly: boolean;
  readExtraWork: boolean;
  setExtraWorkFetched: () => void;
  extraWorkFetched: boolean
}

class ExtraWorkResources extends React.Component<Props, State> {
  private _materialsSelection: Selection;
  private _toolsSelection: Selection;
  private _labourSelection: Selection;
  private _consumableSelection: Selection;

  columns = [
    {
      key: 'column1',
      name: this.props.t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: this.props.t('plannedQuantity'),
      fieldName: 'required',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: this.props.t('consumedquantity'),
      fieldName: 'consumedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: this.props.t('unit'),
      fieldName: 'unit',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      materials: [],
      isMaterialModalVisible: false,
      materialSelectionDetails: {},
      materialActionButtonState: { add: false, edit: true, remove: true },
      material: new Resource(),
      isMaterialEdit: false,
      materialDeleteItems: [],

      tools: [],
      tool: new Resource(),
      isToolsModalVisible: false,
      isToolEdit: false,
      toolActionButtonState: { add: false, edit: true, remove: true },
      toolSelectionDetails: {},
      toolDeleteItems: [],

      consumables: [],
      consumable: new Resource(),
      isConsumablesModalVisible: false,
      isConsumableEdit: false,
      consumableActionButtonState: { add: false, edit: true, remove: true },
      consumableSelectionDetails: {},
      consumableDeleteItems: [],

      labours: [],
      labour: new Resource(),
      isLaboursModalVisible: false,
      isLabourEdit: false,
      labourActionButtonState: { add: false, edit: true, remove: true },
      labourSelectionDetails: {},
      labourDeleteItems: [],
      teamMembers: [],
      teamMember: null,
      teamMemberIsEdit: false,

      selectedCpcEnvironment: null,
      selectedResourceTypeId: null,
    };

    this._materialsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_materialsSelection',
          this._getMaterialSelectionDetails(),
        );
        this.setState({
          materialSelectionDetails: this._getMaterialSelectionDetails(),
          materialActionButtonState: this._getMaterialActionButtonState(),
          material: this._materialEditDetails(),
          materialDeleteItems: this._resourceDeleteDetails(
            this._materialsSelection,
          ),
        });
      },
    });

    this._toolsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_toolsSelection', this._getToolsSelectionDetails());
        this.setState({
          toolSelectionDetails: this._getToolsSelectionDetails(),
          toolActionButtonState: this._getToolsActionButtonState(),
          tool: this._toolsEditDetails(),
          toolDeleteItems: this._resourceDeleteDetails(this._toolsSelection),
        });
      },
    });

    this._labourSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_labourSelection',
          this._getLabourSelectionDetails(),
        );
        this.setState({
          labourSelectionDetails: this._getLabourSelectionDetails(),
          labourActionButtonState: this._getLabourActionButtonState(),
          labour: this._labourEditDetails(),
        });
      },
    });

    this._consumableSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_consumableSelection',
          this._getConsumableSelectionDetails(),
        );
        this.setState({
          consumableSelectionDetails: this._getConsumableSelectionDetails(),
          consumableActionButtonState: this._getConsumableActionButtonState(),
          consumable: this._consumableEditDetails(),
        });
      },
    });
  }

  componentDidMount() {
    if (this.props.formData.projectMoleculeId && this.props.formData.isSaved) {
      this.getResources();
    }
  }

  onVisibitiltyChangeExtraWork = (isVisible: boolean) => {
    // console.log('Element EXTRA WORK now %s', isVisible ? 'visible' : 'hidden');
    // if (this.props.formData.projectMoleculeId && isVisible && !this.props.extraWorkFetched ) {
    //   this.props.setExtraWorkFetched()
    //   messageService.sendMessage({isLoadingPMOLData:true})
    //   Promise.all([
    //     this.getResources()
    //   ]).then(()=>{
    //     setTimeout(() => {
    //       messageService.sendMessage({isLoadingPMOLData:false})
    //     }, 3000);

    //   })
    // }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.createExtraWorkMaterialsStatus !=
      this.props.createExtraWorkMaterialsStatus
    ) {
      this.getMaterisList();
    }
    if (
      prevProps.createExtraWorkToolsStatus !=
      this.props.createExtraWorkToolsStatus
    ) {
      this.getToolsList();
    }
    if (
      prevProps.createExtraWorkConsumableStatus !=
      this.props.createExtraWorkConsumableStatus
    ) {
      this.getConsumablesList();
    }
    if (
      prevProps.createExtraWorkLabourStatus !=
      this.props.createExtraWorkLabourStatus
    ) {
      this.getLabourList();
    }
    if (prevProps.formData != this.props.formData) {
      if (!_.isEmpty(this.props.formData)) {
        //this.getResources();
      }
    }

    if (prevProps.readExtraWork != this.props.readExtraWork) {
      if (this.props.readExtraWork) {
        this.getResources();
      }
    }
    if (prevProps.teamMembers != this.props.teamMembers) {
      this.setState({ teamMembers: this.props.teamMembers });
    }
  }

  render() {
    return (
      <div>
        <div className="proj-detail-block" id="14">
          <div id={'extra-work-id-4-2'}>
            <VisibilitySensor scrollCheck
                              onChange={this.onVisibitiltyChangeExtraWork}>
              <DataGrid
                dataList={
                  this.props.materialListExtraWork
                    ? this.props.materialListExtraWork
                    : []
                }
                editDataGrid={() => this.openMaterialModal()}
                openModel={() => {
                  this.openMaterialModal();
                }}
                selection={this._materialsSelection}
                actionButtonState={this.state.materialActionButtonState}
                title={'4.2 ' + this.props.t('material')}
                deleteDataGrid={() => {
                  this.handleMaterialsDelete();
                }}
                readOnly={this.props.readOnly}
                columns={this.columns}
                isLoaded={this.props.readExtraWorkMaterialsStatus}
              />
            </VisibilitySensor>
          </div>
        </div>

        <div className="proj-detail-block" id="15">
          <div id={'extra-work-id-4-3'}>
            <VisibilitySensor scrollCheck
                              onChange={this.onVisibitiltyChangeExtraWork}>
              <DataGrid
                dataList={
                  this.props.toolsExtraWork ? this.props.toolsExtraWork : []
                }
                editDataGrid={() => this.openToolsModal()}
                openModel={() => {
                  this.openToolsModal();
                }}
                selection={this._toolsSelection}
                actionButtonState={this.state.toolActionButtonState}
                title={'4.3 ' + this.props.t('tools')}
                deleteDataGrid={() => {
                  this.handleToolsDelete();
                }}
                readOnly={this.props.readOnly}
                columns={this.columns}
                isLoaded={this.props.readExtraWorkToolsStatus}
              />
            </VisibilitySensor>
          </div>
        </div>

        <div className="proj-detail-block" id="16">
          <div id={'extra-work-id-4-4'}>
            <VisibilitySensor scrollCheck
                              onChange={this.onVisibitiltyChangeExtraWork}>
              <DataGrid
                dataList={
                  this.props.consumablesExtraWork
                    ? this.props.consumablesExtraWork
                    : []
                }
                editDataGrid={() => this.openConsumableModal()}
                openModel={() => {
                  this.openConsumableModal();
                }}
                selection={this._consumableSelection}
                actionButtonState={this.state.consumableActionButtonState}
                title={'4.4 ' + this.props.t('consumables')}
                deleteDataGrid={() => {
                  this.handleConsumableDelete();
                }}
                readOnly={this.props.readOnly}
                columns={this.columns}
                isLoaded={this.props.readExtraWorkConsumableStatus}
              />
            </VisibilitySensor>
          </div>
        </div>

        <div className="proj-detail-block" id="17">
          <div id={'extra-work-id-4-5'}>
            <VisibilitySensor scrollCheck
                              onChange={this.onVisibitiltyChangeExtraWork}>
              <DataGrid
                dataList={
                  this.props.laboursExtraWork ? this.props.laboursExtraWork : []
                }
                editDataGrid={() => this.openLabourModal()}
                openModel={() => {
                  this.openLabourModal();
                }}
                selection={this._labourSelection}
                actionButtonState={this.state.labourActionButtonState}
                title={'4.5 ' + this.props.t('labour')}
                deleteDataGrid={() => {
                  this.handleLaboursDelete();
                }}
                readOnly={this.props.readOnly}
                columns={this.columns}
                isLoaded={this.props.readExtraWorkLabourStatus}
              />
            </VisibilitySensor>
          </div>
        </div>

        <ResourceModal
          isOpen={this.state.isMaterialModalVisible}
          openResourceModal={() => this.openMaterialModal()}
          isEdit={this.state.isMaterialEdit}
          handleResourceSave={(resource: Resource) => {
            this.props.createExtraWorkMaterials(resource);
          }}
          resource={this.state.material}
          title={
            this.state.isMaterialEdit
              ? this.props.t('editMaterial')
              : this.props.t('addMaterial')
          }
          pmolID={this.props.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
        />

        <ResourceModal
          isOpen={this.state.isToolsModalVisible}
          openResourceModal={() => this.openToolsModal()}
          isEdit={this.state.isToolEdit}
          handleResourceSave={(resource: Resource) => {
            this.props.createExtraWorkTools(resource);
          }}
          resource={this.state.tool}
          title={
            this.state.isToolEdit
              ? this.props.t('editTool')
              : this.props.t('addTool')
          }
          pmolID={this.props.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
        />
        <ResourceModal
          isOpen={this.state.isConsumablesModalVisible}
          openResourceModal={() => this.openConsumableModal()}
          isEdit={this.state.isConsumableEdit}
          handleResourceSave={(resource: Resource) => {
            this.props.createExtraWorkConsumable(resource);
          }}
          resource={this.state.consumable}
          title={
            this.state.isConsumableEdit
              ? this.props.t('editConsumable')
              : this.props.t('addConsumable')
          }
          pmolID={this.props.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
        />

        <LabourModal
          isOpen={this.state.isLaboursModalVisible}
          openResourceModal={() => this.openLabourModal()}
          isEdit={this.state.isLabourEdit}
          handleResourceSave={(resource: Resource) => {
            this.props.createExtraWorkLabour(resource);
          }}
          resource={this.state.labour}
          title={
            this.state.isLabourEdit
              ? this.props.t('editLabour')
              : this.props.t('addLabour')
          }
          pmolID={this.props.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
          teamMembers={this.state.teamMembers}
        />
      </div>
    );
  }

  getResources = () => {
    if (this.props.formData && this.props.formData.id && this.props.formData.projectMoleculeId) {
      this.props.readPmolExtraWorkResources(this.props.formData.id);
    }
  };

  getToolsList = () => {
    if (this.props.formData && this.props.formData.id && this.props.formData.projectMoleculeId) {
      this.props.readPmolExtraWorkToolsData(this.props.formData.id);
    }
  };

  getLabourList = () => {
    if (this.props.formData && this.props.formData.id && this.props.formData.projectMoleculeId) {
      this.props.readPmolExtraWorkLaboursData(this.props.formData.id);
    }
  };

  getMaterisList = () => {
    if (this.props.formData && this.props.formData.id && this.props.formData.projectMoleculeId) {
      this.props.readPmolExtraWorkMaterialsData(this.props.formData.id);
    }
  };

  getConsumablesList = () => {
    if (this.props.formData && this.props.formData.id && this.props.formData.projectMoleculeId) {
      this.props.readPmolExtraWorkConsumableData(this.props.formData.id);
    }
  };

  private openMaterialModal = (): void => {
    this.setState({
      isMaterialModalVisible: !this.state.isMaterialModalVisible,
      material: this._materialEditDetails(),
      selectedResourceTypeId: ResourceTypes.materials.id,
    });
  };

  private _materialEditDetails() {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let material = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isMaterialEdit: true,
      });

      let selectedMaterial = this._materialsSelection.getSelection()[0] as Resource;
      material = selectedMaterial;
      //assign
    } else {
      this.setState({
        isMaterialEdit: false,
      });
    }
    return material;
  }

  private _getMaterialSelectionDetails(): string | number | {} | [] {
    return this._materialsSelection.getSelection();
  }

  private _getMaterialActionButtonState(): ActionButtonState {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private _resourceDeleteDetails(selection: Selection) {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedRisk = selection.getSelection() as Resource[];
    savedDeleteList = selectedRisk.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: any) => {
      return item.id;
    });
    return deleteList;
  }

  private handleMaterialsDelete = () => {
    const selectedList = this._materialsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteMaterials(selectedList);
    }
    this._materialsSelection.setAllSelected(false);
  };

  private openToolsModal = (): void => {
    this.setState({
      isToolsModalVisible: !this.state.isToolsModalVisible,
      tool: this._toolsEditDetails(),
      selectedResourceTypeId: ResourceTypes.tools.id,
    });
  };

  private _toolsEditDetails() {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let tool = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isToolEdit: true,
      });

      let selectedTool = this._toolsSelection.getSelection()[0] as Resource;
      tool = selectedTool;
      //assign
    } else {
      this.setState({
        isToolEdit: false,
      });
    }
    return tool;
  }

  private _getToolsSelectionDetails(): string | number | {} | [] {
    return this._toolsSelection.getSelection();
  }

  private _getToolsActionButtonState(): ActionButtonState {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private handleToolsDelete = () => {
    const selectedList = this._toolsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteTools(selectedList);
    }
    this._toolsSelection.setAllSelected(false);
  };

  private openLabourModal = (): void => {
    this.setState(
      {
        isLaboursModalVisible: !this.state.isLaboursModalVisible,
        labour: this._labourEditDetails(),
        selectedResourceTypeId: ResourceTypes.labour.id,
      },
      this.loadLabourTeamList,
    );
  };

  private loadLabourTeamList = () => {
    if (this.state.isLaboursModalVisible && this.state.isLabourEdit) {
      this.props.readExtraTeamMembers(this.state.labour.id);
    } else {
      this.setState({
        teamMembers: [],
      });
    }
  };

  private _labourEditDetails() {
    const selectionCount = this._labourSelection.getSelectedCount();
    let labour = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isLabourEdit: true,
      });

      let selectedLabour = this._labourSelection.getSelection()[0] as Resource;
      labour = selectedLabour;
      //assign
    } else {
      this.setState({
        isLabourEdit: false,
      });
    }
    return labour;
  }

  private _getLabourSelectionDetails(): string | number | {} | [] {
    return this._labourSelection.getSelection();
  }

  private _getLabourActionButtonState(): ActionButtonState {
    const selectionCount = this._labourSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private handleLaboursDelete = () => {
    const selectedList = this._labourSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteLabours(selectedList);
    }
    this._labourSelection.setAllSelected(false);
  };

  private openConsumableModal = (): void => {
    this.setState({
      isConsumablesModalVisible: !this.state.isConsumablesModalVisible,
      consumable: this._consumableEditDetails(),
      selectedResourceTypeId: ResourceTypes.consumable.id,
    });
  };

  private _consumableEditDetails() {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let consumable = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isConsumableEdit: true,
      });

      let selectedConsumable = this._consumableSelection.getSelection()[0] as Resource;
      consumable = selectedConsumable;
      //assign
    } else {
      this.setState({
        isConsumableEdit: false,
      });
    }
    return consumable;
  }

  private _getConsumableSelectionDetails(): string | number | {} | [] {
    return this._consumableSelection.getSelection();
  }

  private _getConsumableActionButtonState(): ActionButtonState {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private handleConsumableDelete = () => {
    const selectedList = this._consumableSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteConsumable(selectedList);
    }
    this._consumableSelection.setAllSelected(false);
  };

  private cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(this.getCpcByName(inputValue));
      // }, 1000);
    });

  private getCpcByName = async (name: string) => {
    let filter = {
      resourceTypeId: this.state.selectedResourceTypeId,
      resourceFamilyId: null,
      resourceNumber: null,
      pmolId: this.props.formData.id,
      title: name,
      type: 'Extra',
      status: 1,
      sorter: {
        attribute: 'resourceNumber',
        order: 'asc',
      },
    };
    if (name.length >= 2) {
      let response = await client.post(
        CPC_PMOL_FILTER_EP,
        filter,
      );
      return this.formatCpcResponse(response);
    }
  };

  private formatCpcResponse = (response: any) => {
    let localOptions: { value: string; label: string }[] = [];
    let contractingUnitOptions: { value: string; label: string }[] = [];
    let centralOptions: { value: string; label: string }[] = [];
    let groupedOptions = [
      {
        label: this.props.t('project'),
        options: localOptions,
      },
      {
        label: this.props.t('contractingUnit'),
        options: contractingUnitOptions,
      },
      {
        label: this.props.t('Organization'),
        options: centralOptions,
      },
    ];
    if (response && response.data) {
      let localData = response.data.local;
      let contractingUnit = response.data.contractingUnit;
      let central = response.data.central;

      if (localData && _.isArray(localData)) {
        localOptions = localData.map((item: any) => {
          return {
            id: item.id,
            value: item.resourceNumber,
            label: item.title,
            environment: 'local',
          };
        });
      }
      if (contractingUnit && _.isArray(contractingUnit)) {
        contractingUnitOptions = contractingUnit.map((item: any) => {
          return {
            id: item.id,
            value: item.resourceNumber,
            label: item.title,
            environment: 'cu',
          };
        });
      }
      if (central && _.isArray(central)) {
        centralOptions = central.map((item: any) => {
          return {
            id: item.id,
            value: item.resourceNumber,
            label: item.title,
            environment: 'central',
          };
        });
      }
      groupedOptions = [
        {
          label: this.props.t('project'),
          options: localOptions,
        },
        {
          label: this.props.t('contractingUnit'),
          options: contractingUnitOptions,
        },
        {
          label: this.props.t('organization'),
          options: centralOptions,
        },
      ];
    } else {
    }

    return groupedOptions;
  };

  private getCpcDetails = (selectedCpc: any) => {
    if (selectedCpc && selectedCpc.value) {
      this.setState({
        selectedCpcEnvironment: selectedCpc.environment,
      });
      this.props.getCpcById(selectedCpc);
    }
  };

  private clearCpcData = () => {
    this.props.clearCpcData();
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    materialListExtraWork: state.pmol.materialsExtraWork,
    toolsExtraWork: state.pmol.toolsExtraWork,
    consumablesExtraWork: state.pmol.consumablesExtraWork,
    laboursExtraWork: state.pmol.laboursExtraWork,
    createExtraWorkMaterialsStatus: state.pmol.createExtraWorkMaterialsStatus,
    readExtraWorkMaterialsStatus: state.pmol.readExtraWorkMaterialsStatus,
    createExtraWorkToolsStatus: state.pmol.createExtraWorkToolsStatus,
    readExtraWorkToolsStatus: state.pmol.readExtraWorkToolsStatus,
    readExtraWorkConsumableStatus: state.pmol.readExtraWorkConsumableStatus,
    createExtraWorkConsumableStatus: state.pmol.createExtraWorkConsumableStatus,
    readExtraWorkLabourStatus: state.pmol.readExtraWorkLabourStatus,
    createExtraWorkLabourStatus: state.pmol.createExtraWorkLabourStatus,
    teamMembers: state.pmol.extraTeamMembers,
    extraWorkFetched: state.pmol.extraWorkFetched,
    cpcData: state.billOfResource.cpcData,
  };
};
const mapDispatchToProps = {
  readPmolExtraWorkMaterialsData,
  readPmolExtraWorkToolsData,
  readPmolExtraWorkConsumableData,
  readPmolExtraWorkLaboursData,
  readExtraTeamMembers,
  readPmolExtraWorkResources,

  createExtraWorkMaterials,
  createExtraWorkTools,
  createExtraWorkLabour,
  createExtraWorkConsumable,

  deleteMaterials,
  deleteTools,
  deleteLabours,
  deleteConsumable,

  getCpcById,
  clearCpcData,
  setExtraWorkFetched,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ExtraWorkResources)),
);
