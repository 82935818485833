import React from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { Depths } from '@fluentui/theme';
import { uPrinceTheme } from '../../../../theme';
import { messageService } from '../../../services/messageService';
import { PMOL_TYPE } from '../../../types/projectMolecule';

const BookMarkPaneComponent = (props: { divClass: string; currentActiveSection: string, list: any, toggleBookmark: () => void, toggleBookmarkOut: () => void, isEdit: boolean, pmolType: PMOL_TYPE, horizontalReSizer?: number }) => {
  const { t } = useTranslation();
  const collapseDocumentPane = () => {
    if (screen.width > 1440) {
      messageService.sendMessage({ collapseDocumentPane: true, documentPaneType: 1 });
    } else {
      props.toggleBookmarkOut();
    }
  };

  const BookMarkReg = () => {
    return (
      <div>
        <div>
          <Stack
            className={`${props.divClass} collapse`}
            verticalFill
            gap={30}
            styles={{ root: { selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } } } }}
          >
            <i
              title="Close"
              className="ms-Icon ms-Icon--ChromeClose"
              aria-hidden="true"
              onClick={collapseDocumentPane}
            ></i>
            <Text className="title">{t('bookmark')}</Text>
            <div style={{ height: props.horizontalReSizer ? props.horizontalReSizer - 100 : '', overflow: 'hidden' }}
                 className="inner-container inner-container-pmol">
              <Label className={`${props.currentActiveSection === '1' ? 'selected' : ''}`}>1. {t('pMOLHeader')}</Label>
              <Link className={`${props.currentActiveSection === '1' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '1' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#project-moleculer-header">1.{t('pMOLHeader')}</Link>
              <Label
                className={`${props.currentActiveSection === '2' || props.currentActiveSection === '3' || props.currentActiveSection === '4' || props.currentActiveSection === '5' || props.currentActiveSection == '6' ? 'selected' : ''}`}>2. {t('startHandshake')}</Label>
              <Link className={`${props.currentActiveSection === '3' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '3' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#start-handshake-id-2-1">2.1.{t('pMOLAddress')}</Link>
              <Link className={`${props.currentActiveSection === '4' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '4' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#start-handshake-id-2-2">2.2. {t('instructions')}</Link>
              <Link className={`${props.currentActiveSection === '5' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '5' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#start-handshake-id-2-3">2.3. {t('risks')}</Link>
              <Link className={`${props.currentActiveSection === '6' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '6' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#start-handshake-id-2-4">2.4. {t('quality')}</Link>
              <Label
                className={`${props.currentActiveSection === '7' || props.currentActiveSection === '8' || props.currentActiveSection === '9' || props.currentActiveSection === '10' ? 'selected' : ''}`}>3. {t('plannedwork')}</Label>
              <Link className={`${props.currentActiveSection === '8' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '8' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#planned-work-id-3-1">3.1. {t('material')}</Link>
              <Link className={`${props.currentActiveSection === '9' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '9' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#planned-work-id-3-2">3.2. {t('tools')}</Link>
              <Link className={`${props.currentActiveSection === '10' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '10' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#planned-work-id-3-3">3.3. {t('consumables')}</Link>
              <Link className={`${props.currentActiveSection === '11' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '11' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#planned-work-id-3-4">3.4. {t('labour')}</Link>
              <Label
                className={`${props.currentActiveSection === '12' || props.currentActiveSection === '13' || props.currentActiveSection === '14' || props.currentActiveSection === '15' ? 'selected' : ''}`}>4. {t('extraWork')}</Label>
              <Link className={`${props.currentActiveSection === '13' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '13' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#extra-work-id-4-1">4.1. {t('description')}</Link>
              <Link className={`${props.currentActiveSection === '14' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '14' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#extra-work-id-4-2">4.2. {t('material')}</Link>
              <Link className={`${props.currentActiveSection === '15' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '15' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#extra-work-id-4-3">4.3. {t('tools')}</Link>
              <Link className={`${props.currentActiveSection === '16' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '16' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#extra-work-id-4-4">4.4. {t('consumables')}</Link>
              <Link className={`${props.currentActiveSection === '17' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '17' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#extra-work-id-4-5">4.5. {t('labour')}</Link>
              <Label
                className={`${props.currentActiveSection === '18' ? 'selected' : ''}`}>5. {t('pMOLJournal')}</Label>
              <Link className={`${props.currentActiveSection === '19' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '19' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#journal-5-1">5.{t('pMOLJournal')}</Link>
              <Label
                className={`${props.currentActiveSection === '20' ? 'selected' : ''}`}>6. {t('stopHandshake')}</Label>
              <Link className={`${props.currentActiveSection === '21' ? 'selected' : ''}`}
                    style={{ borderLeft: props.currentActiveSection === '21' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                    href="#stop-handshake-6-1">6. {t('stopHandshake')}</Link>
              {props.isEdit &&
              <Label className={`${props.currentActiveSection === '22' ? 'selected' : ''}`}>7. {t('history')}</Label>}
              {props.isEdit && <Link className={`${props.currentActiveSection === '22' ? 'selected' : ''}`}
                                     style={{ borderLeft: props.currentActiveSection === '22' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                                     href="#history">7.{t('history')}</Link>}

            </div>
          </Stack>

          <Stack
            className="bookmark-panel-responsive"
            verticalFill
            onClick={props.toggleBookmark}
            gap={30}
            styles={{ root: { boxShadow: Depths.depth8 } }}
          >
            <TooltipHost
              content={t('bookmark')}
              id="bmark"
              calloutProps={{ gapSpace: 0 }}
              directionalHint={DirectionalHint.rightCenter}
            >
              <i
                className="ms-Icon ms-Icon--SearchBookmark"
                aria-labelledby="bmark"
                aria-hidden="true"
              ></i>
            </TooltipHost>
          </Stack>
        </div>
      </div>
    );
  };

  const BookMarkSub = () => {
    return (
      <div>
        <div>
          <Stack
            className={`${props.divClass} collapse`}
            verticalFill
            gap={30}
            styles={{ root: { selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } } } }}
          >
            <i
              title="Close"
              className="ms-Icon ms-Icon--ChromeClose"
              aria-hidden="true"
              onClick={collapseDocumentPane}
            ></i>
            <Text className="title">{t('bookmark')}</Text>
            <div className="inner-container">
              <Label className={`${props.currentActiveSection === '1' ? 'selected' : ''}`}>
                1. {t('pMOLHeader')}
              </Label>
              <Link
                className={`${props.currentActiveSection === '1' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '1' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#project-moleculer-header">1.{t('pMOLHeader')}
              </Link>
              <Label
                className={`${props.currentActiveSection === '2' || props.currentActiveSection === '3' || props.currentActiveSection === '4' || props.currentActiveSection === '5' || props.currentActiveSection === '6' ? 'selected' : ''}`}>
                2. {t('startHandshake')}
              </Label>
              <Link
                className={`${props.currentActiveSection === '3' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '3' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#start-handshake-id-2-1">
                2.1.{t('pMOLAddress')}
              </Link>
              <Link
                className={`${props.currentActiveSection === '4' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '4' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#start-handshake-id-2-2">
                2.2. {t('instructions')}
              </Link>
              <Link
                className={`${props.currentActiveSection === '5' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '5' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#start-handshake-id-2-3">
                2.3. {t('risks')}
              </Link>
              <Link
                className={`${props.currentActiveSection === '6' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '6' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#start-handshake-id-2-4">
                2.4. {t('quality')}
              </Link>
              <Label className={`${props.currentActiveSection === '30' ? 'selected' : ''}`}>
                4. {t('service')}
              </Label>
              <Link
                className={`${props.currentActiveSection === '8' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '8' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#service">
                4.{t('service')}
              </Link>
              <Label className={`${props.currentActiveSection === '18' ? 'selected' : ''}`}>
                5. {t('pMOLJournal')}
              </Label>
              <Link
                className={`${props.currentActiveSection === '19' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '19' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#journal-5-1">
                5.{t('pMOLJournal')}
              </Link>
              <Label className={`${props.currentActiveSection === '20' ? 'selected' : ''}`}>
                6. {t('stopHandshake')}
              </Label>
              <Link
                className={`${props.currentActiveSection === '21' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '21' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#stop-handshake-6-1">
                6. {t('stopHandshake')}
              </Link>
              {props.isEdit &&
              <Label className={`${props.currentActiveSection === '22' ? 'selected' : ''}`}>
                7. {t('history')}
              </Label>}
              {props.isEdit &&
              <Link
                className={`${props.currentActiveSection === '22' ? 'selected' : ''}`}
                style={{ borderLeft: props.currentActiveSection === '22' ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
                href="#history">
                7.{t('history')}
              </Link>}
            </div>
          </Stack>

          <Stack
            className="bookmark-panel-responsive"
            verticalFill
            onClick={props.toggleBookmark}
            gap={30}
            styles={{ root: { boxShadow: Depths.depth8 } }}
          >
            <TooltipHost
              content={t('bookmark')}
              id="bmark"
              calloutProps={{ gapSpace: 0 }}
              directionalHint={DirectionalHint.rightCenter}
            >
              <i
                className="ms-Icon ms-Icon--SearchBookmark"
                aria-labelledby="bmark"
                aria-hidden="true"
              ></i>
            </TooltipHost>
          </Stack>
        </div>
      </div>);
  };

  return (
    <div>
      {props.pmolType === PMOL_TYPE.REGULAR &&
      <BookMarkReg/>
      }
      {props.pmolType === PMOL_TYPE.SUB &&
      <BookMarkSub/>
      }

    </div>

  );
};

export default BookMarkPaneComponent;


