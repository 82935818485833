import * as React from 'react';
import { useEffect } from 'react';
import { Stack } from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { useSelector } from 'react-redux';
import WBSCreateCmdBarRight from '../commandBar/wbsCreateCmdBarRight/component';
import WbsCreateDocumentPane from './WbsCreateDocumentPane';
import WbsCreateProductDocumentPane from './WbsCreateProductDocumentPane';
import { ParamTypes } from '../../../types/uPrince';
import { useParams } from 'react-router';
import { messageService } from '../../../services/messageService';
import { WBS_TAXONOMY_LEVEL } from '../../../types/wbs';
import i18n from '../../../../i18n';
import DocumentPane from '../../wbsTask/documentPane/DocumentPane';

interface Props {
  isTemplate: boolean;
  template: any;
  wbsTaxonomyLevel: any
}

const DocumentPaneWrapper = (props: Props) => {
  const { featureId }: ParamTypes = useParams();

  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const formData = useSelector((state: any) => state.wbsCreate.wbsProductFormData);
  const taskData = useSelector((state: any) => state.wbsTask.formData);

  const [taskFormData, setTaskFormData] = React.useState<any>({});

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        // if (data.data.wbsTaxonomyLevel) {
        //   console.log('data.data.wbsTaxonomyLevel> ',data.data.wbsTaxonomyLevel);
        //   setWbsTaxonomyLevel(data.data.wbsTaxonomyLevel);
        // }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if(taskData?.id){
      // console.log('task formData >> ',formData);
      const loadTaskFormData = async () =>{
        setTaskFormData(taskData);
      }

      loadTaskFormData().then();
    }
  }, [taskData]);

  const renderTaxonomyDocumentPane = () => {
    // console.log(wbsTaxonomyLevel);
    switch (props?.wbsTaxonomyLevel) {
      case WBS_TAXONOMY_LEVEL.PRODUCT:
        return <WbsCreateProductDocumentPane />;
      case WBS_TAXONOMY_LEVEL.WBS:
        return <WbsCreateDocumentPane featureId={featureId} />;
      case WBS_TAXONOMY_LEVEL.TASK:
        return <DocumentPane />;
      default:
        return <></>;
    }
  };

  const renderDocumentPaneTitle = () => {
    // console.log(wbsTaxonomyLevel);
    switch (props?.wbsTaxonomyLevel) {
      case WBS_TAXONOMY_LEVEL.PRODUCT:
        return formData ? `${formData?.productId} - ${formData?.name}` : '';
      case WBS_TAXONOMY_LEVEL.WBS:
        return props?.template?.title;
      case WBS_TAXONOMY_LEVEL.TASK:
        return `${taskFormData?.name}`;
      default:
        return i18n.t('title');
    }
  };

  return (
    <>
      <Stack>
        <Stack.Item>
          {/* not modified cuz changes in future */}
          <TitlePane
            headerTitle={props?.isTemplate ? renderDocumentPaneTitle() : props?.template?.title}
            type={1}
            close={true}
            isFullScreen={true}
          />
        </Stack.Item>
        <Stack.Item>
          <Stack className={'grid-righter'}>
            <Stack
              className='project-list-header new-design'
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingRight: 0,
              }}
              styles={{ root: { width: '100%', paddingRight: 0 } }}
            >
              <WBSCreateCmdBarRight isTemplate={props.isTemplate} wbsTaxonomyLevel={props?.wbsTaxonomyLevel} />
              <ProgressBar show={showWbsProgressBar} />
            </Stack>
            <Stack className={`main-layout-document-pane-stack`}>
              <div style={{ width: '100%', padding: 0 }}>
                {props.isTemplate ? renderTaxonomyDocumentPane() : <WbsCreateDocumentPane featureId={featureId} />}
              </div>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );

};

export default DocumentPaneWrapper;