import * as React from 'react';
import { DatePicker as FDatePicker } from '@fluentui/react-datepicker-compat';
import { Field, FluentProvider, makeStyles, shorthands, teamsLightTheme } from '@fluentui/react-components';
import { formatDateTimeToISOString } from '../../util';
import moment from 'moment/moment';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: 'repeat(1fr)',
    justifyItems: 'start',
    ...shorthands.gap('2px'),
    maxWidth: '400px',
    // width:'100%'
  },
});

const DatePickerF9 = (
  {
    selectedDate,
    onChange,
    label,
    placeholder,
    errMsg,
    ...rest
  }: any) => {
  const styles = useStyles();
  const setSelectedDate = (date: Date | null | undefined) => {
    onChange(date ? formatDateTimeToISOString(date) : undefined);
  };

  const setDate = () => {
    return selectedDate ? moment(selectedDate).toDate() : null;
  };

  return (
    <div className={styles.root}>
      <Field
        label={label} size='medium'
        style={{ width: '100%', fontWeight: 500 }}
        validationMessage={errMsg ?? null}
      >
        <FluentProvider theme={teamsLightTheme}>
          <FDatePicker
            value={setDate()}
            onSelectDate={setSelectedDate}
            placeholder={placeholder}
            style={{ width: '100%' }}
          />
        </FluentProvider>
      </Field>
    </div>
  );

};

export default DatePickerF9;
