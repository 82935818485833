export class HumanResourceListPaneFilter {
  [x: string]: any;

  title: string | null = null;
  sorter: Sorter = new Sorter();
}

export interface HumanResourceShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class HumanResourceListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface HumanResourceDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface HumanResource {
  id: string | null;
  title: string;
  sequenceId: string
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',
};

export interface WorkSchedule {
  id: string;
  day: string;
  startTime: string;
  endTime: string;
  displayOrder: string;
  personId: string | null;
}

export default interface Absence {
  type: string,
  value: string,
}

export type LabourPersonDTO = {
  Organisation: string | null;
  cabPersonId: string | null;
  email: string | null;
  key: string | null;
  mobile: string | null;
  secondaryText: string | null;
  text: string | null;
};

export type AbsenceModalDTO = {
  id: string | null;
  person: string | undefined;
  leaveType: [];
  allDay: boolean;
  startDate: string | null;
  endDate: string | null;
  startTime: string | null;
  endTime: string | null;
};

export type ContractListModelDTO = {
  id: string | null;
  hrId: string | null;
  startDate: string | null;
  endDate: string | null;
  contractTypeId: string | undefined;
  url: string | undefined;

};

export const PERSON_LEVEL_ID = 'we10e768-3e06-po02-b337-ee367a82adwe';
export const PERSON_ROLE_WORKER_ID = '335ab9fe-po57-4088-82a9-d27008688tgg';
