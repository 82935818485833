import { themeRulesStandardCreator } from "@fluentui/react";
import moment from "moment";
import {walk} from "react-sortable-tree";
import { formatDateTimeToISOString } from '../../../util';

export const getClonedNode = (node:any, index:number, newIndex?:any) => {
    console.log("getClonedNode", node)
    const currentDate = new Date();
    return {
        id:node?.pbsSequenceId,
        title:node?.title,
        name:node?.title,
        endDate:node?.endDate,
        startDate: node?.startDate,
        treeIndex:node?.treeIndex,
        parentId: node?.parentId,
        pbsProductStatusId: node?.pbsProductStatusId,
        pbsSequenceId: node?.pbsSequenceId,
        uid:node?.id,
        isSelected:true,
        isDisabled:true,
        isHidden:false,
        expanded:false,
        progress:25,
        start: node.startDate ? new Date(Date.parse(node.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end: node.endDate ? moment(node.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        endDateTitle: node.endDate ? new Date(Date.parse(node.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        cu: node?.cu,
        project: node?.projectSequenceId,
    }
}


export const getNewBorNode = (node:any, index:number) => {
    console.log("getNewBorNode", node)
    const currentDate = new Date();
    return {
        id:node?.id,
        borSequenceId:node?.itemId,
        title:node?.borTitle,
        name:node?.borTitle,
        parentId: node?.product?.id,
        pbsSequenceId:node?.product?.productId,
        type: 'BOR',
        borStatus:  node?.borStatusId,
        start: node?.startDate ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end: node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
    }
}

export const getPBSNode = (node:any, index:number, comNode:any, isDocumentPaneOpen:boolean) => {
    console.log("getPBSNode", node)
    const currentDate = new Date();
    return {
        id: node?.productId,
        title: node?.title,
        children: comNode?.node?.children,
        expanded: comNode?.node?.expanded,
        parentId: node?.parentId,
        pbsSequenceId: node?.productId,
        // startDate: node?.startDate ? new Date(node?.startDate): new Date(),
        startDate: node?.startDate ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        endDate: node?.endDate ? new Date(node?.endDate): new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        createdDate: null,
        treeIndex: 0,
        pbsProductStatusId: node?.pbsProductStatusId, // this works in product taxonomy list pane pie chart
        pbsStatus: node?.pbsProductStatusId, // newly added to work in week planning
        name: node?.title,
        uid: node?.id,
        // start: new Date(node?.startDate),
        start: node.startDate ? new Date(Date.parse(node.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end:  node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        endDateTitle: new Date(node?.endDate),
        progress: 25,
        isHidden: false,
        isDisabled: true,
        isSelected: isDocumentPaneOpen ? true : false,
        type: 'PBS',
        project: comNode?.node?.project,
        consolidatedDuration: node?.consolidatedDuration,
}
}

export const getPBSNodeMyEnv = (node:any, index:number, comNode:any, isDocumentPaneOpen:boolean) => {
    console.log("getPBSNodeMyEnv", node)
    const currentDate = new Date();
    return {
        id: node?.productId,
        title: node?.title,
        // children: comNode?.node?.children,
        expanded: comNode?.node?.expanded,
        parentId: node?.parentId,
        pbsSequenceId: node?.productId,
        // startDate: node?.startDate ? new Date(node?.startDate): new Date(),
        startDate: node?.startDate ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        endDate: node?.endDate ? new Date(node?.endDate): new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        createdDate: null,
        treeIndex:index+1,
        pbsProductStatusId: node?.pbsProductStatusId, // this works in product taxonomy list pane pie chart
        pbsStatus: node?.pbsProductStatusId, // newly added to work in week planning
        name: node?.title,
        uid: node?.id,
        // start: new Date(node?.startDate),
        start: node.startDate ? new Date(Date.parse(node.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end:  node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        endDateTitle: new Date(node?.endDate),
        progress: 25,
        isHidden: false,
        isDisabled: true,
        isSelected: isDocumentPaneOpen ? true : false,
        type: 'PBS',
    }
}

export const getProjectNode = (node: any, index: number, comNode: any, isDocumentPaneOpen: boolean, updatedProjectFromPP: any) => {
    console.log("getProjectNode", node)
    const currentDate = new Date();
    return {
        ...comNode?.node,
        projectScopeStatusId: updatedProjectFromPP?.projectScopeStatusId,
        title: updatedProjectFromPP?.title,
        // rowInfo uses start and end
        start: node?.startDate ? new Date(moment.utc(updatedProjectFromPP?.projectTime?.startDate).format('YYYY-MM-DD')) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end: node?.endDate ? new Date(moment.utc(updatedProjectFromPP?.projectTime?.endDate).format('YYYY-MM-DD')) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
    };
};

export const getCPCNode = (node: any, parentNode: any) => {
    console.log("getCPCNode", node)
    return {
        id: node?.cpcKey,
        title: node?.cpcText,
        parentId: parentNode?.id,
        pbsSequenceId: null,
        cpcId: node?.cpcKey,
        startDate: new Date(), // TODO: Start date is not available in response
        endDate: new Date(), // TODO: End date is not available in response
        createdDate: null,
        treeIndex: 0,
        pbsProductStatusId: null,
        nodeType: null,
        type: 'PbsLabour',
        projectSequenceId: null,
        cu: parentNode?.cu,
        project: parentNode?.project,
        projectStatus: null,
        projectScopeStatusId: null,
        sequenceId: null,
        displayOrder: 32,
        isFilter: false,
        pbsId: null,
        cabPersonId: null,
        projectTitle: null,
        wbs: null,
        wbsTaxonomyLevelId: null,
        templateId: null,
    };
};

export const getPBSTaxonomyNode = (node:any, index:number, comNode:any) => {
    console.log("getPBSTaxonomyNode", node)
    const currentDate = new Date();
    return {
        id: node?.productId,
        title: node?.title,
        children: comNode?.node?.children,
        expanded: comNode?.node?.expanded,
        parentId: node?.parentId,
        pbsSequenceId: node?.productId,
        startDate: node?.startDate ? new Date(node?.startDate): new Date(),
        endDate: node?.endDate ? new Date(node?.endDate): new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        createdDate: null,
        treeIndex: 0,
        pbsProductStatusId: node?.pbsProductStatusId,
        name: node?.title,
        uid: node?.id,
        start: new Date(node?.startDate),
        end:  node?.endDate ? new Date(node?.endDate) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        endDateTitle: new Date(node?.endDate),
        progress: 25,
        isHidden: false,
        isDisabled: true,
        isSelected: false,
        type: 'PBS',
}
}

export const getBorNode = (node:any, index:number, comNode:any) => {
    console.log("getBorNode", node)
    const currentDate = new Date();
    return {
        id:node?.id,
        borSequenceId:node?.itemId,
        title:node?.borTitle,
        name:node?.borTitle,
        children: comNode?.node?.children,
        expanded: comNode?.node?.expanded,
        parentId: comNode?.node?.parentId,
        type: 'BOR',
        // borStatus:  node?.borStatus, - This had a issue of pie chart not updating in PP list pane after changing BOR status
        borStatus:  node?.borStatusId,
        start: node?.startDate ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end: node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        isWarfAvailable: comNode?.node?.isWarfAvailable,
        orderStatus: comNode?.node?.orderStatus,
    }
}

export const getNewPMOLNode = (node:any, index:number,comNode:any, filterType:number) => {
    console.log("getNewPMOLNode", node)
    const currentDate = new Date();
    return  {
        id: node?.header?.id,
        parentId: filterType === 6 ? comNode?.node?.parentId : node?.header?.bor?.id,
        type: "PMOL",
        // title: node?.header?.title, - This had a issue of title not updating in PP list pane after approving PMOL
        title: node?.header?.title ? node?.header?.title : node?.title,
        children: comNode?.node?.children,
        pbsSequenceId: node?.header?.projectMoleculeId,
        borSequenceId: node?.header?.bor?.itemId,
        expanded: comNode?.node?.expanded,
        pmolSequenceId: node?.projectMoleculeId,
        start: node?.startDate ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end: node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        startDate: node?.startDate ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        endDate: node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        cpcId: null,
        milestoneSequenceId: null,
        cabPersonId: null,
        isUsed: false,
        pbsStatus: null,
        borStatus: null,
        // pmolStatus: node?.header?.statusId, - This had a issue of pie chart not updating in PP list pane after approving PMOL
        pmolStatus: node?.header?.statusId ? node?.header?.statusId : node?.statusId,
        orderStatus: null,
        isWarfAvailable: false,
        executionDate: formatDateTimeToISOString(node?.header?.executionDate),
        isSelected: true,
      }
}


export const getNewPMOLNodeForGeneratePMOlFromPBS = (node:any, index:number, pabsNode:any, children:any) => {
    console.log("getNewPMOLNodeForGeneratePMOlFromPBS", node)

    const currentDate = new Date();
    const nodex = {
        id: node?.id,
        parentId: node?.productId,
        type: "PMOL",
        title: node?.title,
        pbsSequenceId: node?.projectMoleculeId,
        borSequenceId: null,
        pmolSequenceId: node?.projectMoleculeId,
        start: node?.executionDate ? new Date(Date.parse(node?.executionDate)) : (node?.startDateTime ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear()), currentDate.getMonth(), currentDate.getDate()),
        end: node?.executionDate ? new Date(Date.parse(node?.executionDate))  : node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        startDate: node?.startDate ? new Date(Date.parse(node?.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        endDate: node?.endDate ? moment(node?.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        cpcId: null,
        milestoneSequenceId: null,
        cabPersonId: null,
        isUsed: false,
        pbsStatus: null,
        borStatus: null,
        pmolStatus: node?.statusId,
        orderStatus: null,
        isWarfAvailable: false,
        children: children,
        isSelected:true
      }
      return nodex
}

export const getPMOLResourceChildrens = (response:any, pmol:any) => {
    let children = []
    const currentDate = new Date()
    const {labour, tools, consumable, material} = response?.data?.result.plannedResource
    if (response?.data?.result && response?.data?.result.plannedResource) {
        if (Array.isArray(labour) && labour.length > 0) {
            children = labour?.map((item:any) => {
            return  {
                id: item?.id,
                title: item.title,
                parentId: pmol.projectMoleculeId,
                type: "LabourItem",
                pbsSequenceId: "",
                borSequenceId: '',
                pmolSequenceId: "",
                start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                cpcId: null,
                milestoneSequenceId: null,
                cabPersonId: null,
                isUsed: false,
                pbsStatus: null,
                borStatus: null,
                pmolStatus: "d60aad0b-2e84-482b-ad25-618d80d49477",
                orderStatus: null,
                isWarfAvailable: null,
                treeIndex: 0
            }
            
            })
        } else if (Array.isArray(tools) && tools.length > 0) {
            children = tools?.map((item:any) => {
                return  {
                    id: item?.id,
                    title: item.title,
                    parentId: pmol.projectMoleculeId,
                    type: "ToolItem",
                    pbsSequenceId: "",
                    borSequenceId: '',
                    pmolSequenceId: "",
                    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    cpcId: null,
                    milestoneSequenceId: null,
                    cabPersonId: null,
                    isUsed: false,
                    pbsStatus: null,
                    borStatus: null,
                    pmolStatus: "d60aad0b-2e84-482b-ad25-618d80d49477",
                    orderStatus: null,
                    isWarfAvailable: null,
                    treeIndex: 0
                }})
        } else if (Array.isArray(consumable) && consumable.length > 0) {
            children = consumable?.map((item:any) => {
                return  {
                    id: item?.id,
                    title: item.title,
                    parentId: pmol.projectMoleculeId,
                    type: "consumableItem",
                    pbsSequenceId: "",
                    borSequenceId: '',
                    pmolSequenceId: "",
                    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    cpcId: null,
                    milestoneSequenceId: null,
                    cabPersonId: null,
                    isUsed: false,
                    pbsStatus: null,
                    borStatus: null,
                    pmolStatus: "d60aad0b-2e84-482b-ad25-618d80d49477",
                    orderStatus: null,
                    isWarfAvailable: null,
                    treeIndex: 0
                }})
        } else if(Array.isArray(material) && material.length > 0) {
            children = consumable?.map((item:any) => {
                return  {
                    id: item?.id,
                    title: item.title,
                    parentId: pmol.projectMoleculeId,
                    type: "materialItem",
                    pbsSequenceId: "",
                    borSequenceId: '',
                    pmolSequenceId: "",
                    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                    cpcId: null,
                    milestoneSequenceId: null,
                    cabPersonId: null,
                    isUsed: false,
                    pbsStatus: null,
                    borStatus: null,
                    pmolStatus: "d60aad0b-2e84-482b-ad25-618d80d49477",
                    orderStatus: null,
                    isWarfAvailable: null,
                    treeIndex: 0
                }})
        }
      }
      console.log("childrenchildrenchildrenchildrenchildren", children)
      return children;
}

export const getPbsAssignedLabourNode = (_node: any, pbsAssignedLabourNodes: any, comNode: any, index: number) => {
    if (comNode?.node?.children) {
        return {
            ...comNode?.node,
            children: pbsAssignedLabourNodes?.filter((item: any) => !comNode?.node?.children?.some((child: any) => (child.uid === item.personId || child.personId === item.personId))).concat(comNode?.node?.children),
            expanded: true,
        };
    } else {
        return {
            ...comNode?.node,
            children: pbsAssignedLabourNodes,
            expanded: true,
        };
    }
};

export const getEmptyRows = () => {
    const currentDate = new Date();
    const emptyrows:any = Array(100).fill('').map((_, i) => {
        return {
            id:1000+i,
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            isHidden: true,
            parentId:null,
            isDisabled: true
        }
    })
    return emptyrows
}

const getNodeKey = ({ treeIndex }: any) => treeIndex;

export const getNewPBSNodepath = (treeData: any, id:number) => {
    let newPath:any = [];
    const callback = ({ node, treeIndex, parentNode, path, lowerSiblingCounts }: any) => {
        if (node.pbsSequenceId === id ){
          newPath = path
        }
    };
    walk({
        treeData,
        getNodeKey,
        callback,
        ignoreCollapsed: false
    });
    console.log("Expand path", newPath)
    return newPath;
};

export const getNewBorNodepath = (treeData: any, id:number) => {
    let newPath:any = [];
    const callback = ({ node, treeIndex, parentNode, path, lowerSiblingCounts }: any) => {
        if (node.id === id ){
          newPath = path
        }
    };
    walk({
        treeData,
        getNodeKey,
        callback,
        ignoreCollapsed: true
    });
    return newPath;
};

export const getNewPMOLNodepath = (treeData: any, id:number) => {
    let newPath:any = [];
    const callback = ({ node, treeIndex, parentNode, path, lowerSiblingCounts }: any) => {
        if (node.id === id ){
          newPath = path
        }
    };
    walk({
        treeData,
        getNodeKey,
        callback,
        ignoreCollapsed: true
    });
    return newPath;
};