import * as React from 'react';
import { Card, Field, Input, InputProps, makeStyles, shorthands } from '@fluentui/react-components';
import i18n from '../../../../../i18n';
import { Search24Regular } from '@fluentui/react-icons';
import Dropdown from '../../../../shared/fluentUI9/Dropdown/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { readWbsIssueList, saveWbsIssueStateAttr } from '../../../../reducers/wbsIssueReducer';

const useStyles = makeStyles({
  card: {
    ...shorthands.margin('auto'),
    width: '98%',
    maxWidth: '100%',
    marginTop: '10px',
  },
});

interface Props {
  isCollapsed: boolean;
}

const WbsIssueFilters = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  // const { state }: any = useLocation();
  // const { featureId }: ParamTypes = useParams();

  const wbsIssueFilter = useSelector((state: any) => state.wbsIssue.wbsIssueFilter);
  const wbsIssueDropdownData = useSelector((state: any) => state.wbsIssue.wbsIssueDropdownData);

  const onChangeTitle: InputProps['onChange'] = (ev, data) => {
    // console.log({ data });
    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
      ...wbsIssueFilter,
      title: data?.value != '' ? data?.value : null,
    }));
  };

  const onChangeProduct: InputProps['onChange'] = (ev, data) => {
    // console.log({ data });
    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
      ...wbsIssueFilter,
      productId: data?.value != '' ? data?.value : null,
    }));
  };

  const onFilter = (event: any) => {
    if (event?.keyCode === 13) {
      dispatch(readWbsIssueList(wbsIssueFilter));
    }
  };

  const addAllItem = (options: any) => {
    const allItem = [{ key: null, id: null, text: i18n.t('all') }];
    return [...allItem, ...options];
  };

  return (
    <>
      <Card className={styles.card}>
        {props?.isCollapsed ?
          <div style={{ display: 'flex', justifyContent: 'start', gap: 5 }}>

            {/* Title */}
            <Field>
              <Input
                value={wbsIssueFilter?.title}
                onChange={onChangeTitle}
                onKeyDown={onFilter}
                placeholder={i18n.t('title')}
                contentBefore={<Search24Regular />}
              />
            </Field>

            {/* Product */}
            <Field>
              <Input
                value={wbsIssueFilter?.productId}
                onChange={onChangeProduct}
                onKeyDown={onFilter}
                placeholder={i18n.t('product')}
                contentBefore={<Search24Regular />}
              />
            </Field>

            {/* Status */}
            <div>
              <Dropdown
                placeholder={i18n.t('status')}
                options={wbsIssueDropdownData?.status ? addAllItem(wbsIssueDropdownData?.status) : []}
                value={wbsIssueFilter?.status}
                onChange={(value: any) => {
                  dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                    ...wbsIssueFilter,
                    status: value?.key,
                  }));
                }}
              />
            </div>

            {/* Priority */}
            <div>
              <Dropdown
                placeholder={i18n.t('priority')}
                options={wbsIssueDropdownData?.priority ? addAllItem(wbsIssueDropdownData?.priority) : []}
                value={wbsIssueFilter?.priority}
                onChange={(value: any) => {
                  dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                    ...wbsIssueFilter,
                    priority: value?.key,
                  }));
                }}
              />
            </div>

            {/* Severity */}
            <div>
              <Dropdown
                placeholder={i18n.t('severity')}
                options={wbsIssueDropdownData?.severity ? addAllItem(wbsIssueDropdownData?.severity) : []}
                value={wbsIssueFilter?.severity}
                onChange={(value: any) => {
                  dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                    ...wbsIssueFilter,
                    severity: value?.key,
                  }));
                }}
              />
            </div>
          </div> :
          <div style={{ display: 'flex', justifyContent: 'start', gap: 5 }}>

            {/* Title */}
            <Field style={{ width: '100%' }}>
              <Input
                value={wbsIssueFilter?.title}
                onChange={onChangeTitle}
                onKeyDown={onFilter}
                placeholder={i18n.t('title')}
                contentBefore={<Search24Regular />}
              />
            </Field>
          </div>
        }
      </Card>
    </>
  );

};

export default WbsIssueFilters;