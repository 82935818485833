import React, {useEffect, useState} from 'react';
import {
  Calendar,
  DayOfWeek,
  defaultCalendarStrings,
  DirectionalHint,
  Label,
  Link,
  mergeStyleSets,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getContractingUnit, getTimeZone, getTomorrow} from '../../../shared/util';
import history from '../../../history';
import moment from 'moment';
import {getAllTimeClocks} from '../../../reducers/timeClockReducer';
import {store} from '../../../../index';
import {saveCIAWStateAttr} from '../../../reducers/ciawReducer';

const CIAW_STATUS_PENDING = '4010e768-3e06-4702-ciaws-ee367a82addb'
const CIAW_STATUS_COMPLETED = '7bcb4e8d-8e8c-487d-ciaws-6b91c89fAcce'

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative'
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important'
  }
});

const ShortCutPaneComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [divClass, setDivClass] = useState<any>('filter-panel-left');
  // const [currentActiveSection, setCurrentActiveSection] = useState<any>(null);

  const filterItems: any = [
    {
      key: 'all',
      id: null,
      type: 'timeClock',
      name: t('all'),
      value: null,
      subItem: false
    },
    {
      key: 'today',
      id: 'Today',
      type: 'timeClock',
      name: t('today'),
      value: null,
      subItem: false
    },
    {
      key: 'yesterday',
      id: 'Yesterday',
      type: 'timeClock',
      name: t('yesterday'),
      value: null,
      subItem: false
    },
    {
      key: 'thisWeek',
      id: 'ThisWeek',
      type: 'timeClock',
      name: t('thisWeek'),
      value: null,
      subItem: false
    },
    {
      key: 'lastWeek',
      id: 'LastWeek',
      type: 'timeClock',
      name: t('LastWeek'),
      value: null,
      subItem: false
    },
    {
      key: 'timeRegistration',
      id: 'timeRegistration',
      type: 'tr',
      name: t('timeRegistration'),
      value: null,
      subItem: false
    },
    {
      key: 'general',
      id: 'general',
      type: 'tr',
      name: t('general'),
      value: null,
      subItem: true
    },
    {
      key: 'hrView',
      id: 'hrView',
      type: 'tr',
      name: t('hrView'),
      value: null,
      subItem: true
    },
    {
      key: 'workers',
      id: 'workers',
      type: 'hr',
      name: t('workers'),
      value: null,
      subItem: false
    },
    {
      key: 'ciaw',
      id: 'ciaw',
      type: 'ciaw',
      name: t('ciawStatus'),
      value: null,
      subItem: false
    },
    {
      key: 'pending',
      id: 'pending',
      type: 'ciaw',
      name: t('pending'),
      value: null,
      subItem: true
    },
    {
      key: 'completed',
      id: 'completed',
      type: 'ciaw',
      name: t('completed'),
      value: null,
      subItem: true
    }
  ];

  const { collapseBookmarkPane } = useSelector(
    (state: any) => state.timeClock
  );

  const { timeClockSelectedDate, currentActiveSectionTC } = useSelector(
    (state: any) => state.ciaw
  );

  useEffect(() => {

    return () => {
      // dispatch(saveCIAWStateAttr('currentActiveSectionTC', null));
    }
  }, []);

  useEffect(() => {
    if (collapseBookmarkPane) {
      setDivClass('filter-panel-left panel-move');
    } else {
      setDivClass('filter-panel-left');
    }
  }, [collapseBookmarkPane]);

  useEffect(() => {
    console.log(currentActiveSectionTC);
  }, [currentActiveSectionTC])

  const resetFilter = () => {
    dispatch(saveCIAWStateAttr('currentActiveSectionTC', null));
    // setCurrentActiveSection(null);
  };

  const handelShortCutPaneFilter = (selectedItem: any) => {
    // setCurrentActiveSection(selectedItem?.id);
    dispatch(saveCIAWStateAttr('currentActiveSectionTC', selectedItem?.id))

    if (selectedItem?.type === 'timeClock') {
      handleTimeClockFilter(selectedItem);
    } else if (selectedItem?.type === 'hr') {
      handleCuHrFilter(selectedItem);
    } else if (selectedItem?.type === 'ciaw') {
      handleCiawFilter(selectedItem);
    } else if (selectedItem?.type === 'tr') {
      // handleTimeRegistrationFilter(selectedItem);
    }
  };

  const setFilterOption = (selectedItem: any) => {
    if (selectedItem.type === 'all') {
      resetFilter();
    }
    handelShortCutPaneFilter(selectedItem);
  };

  const filterTimeClock = (filterShiftDay: any) => {
    let filterData = {
      startDate: null,
      endDate: null,
      userName: null,
      statusId: null,
      startDateTime: filterShiftDay,
      // this.state.filterStartDateTime || this.state.filterEndDateTime
      //   ? null
      //   : this.state.filterShiftDay,
      userId: null,
      projectId: null,
      date: null,
      localDate: moment().toISOString(),
      offSet: getTimeZone(),
      sorter: {
        attribute: null,
        order: null,
      },
    };

    dispatch(getAllTimeClocks(filterData));
  };

  const handleTimeClockFilter = (filter: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/time_clock`,
      state: { timeClockFilter: filter?.value }
    });

    if (filter?.key == 'today') {
      filterTimeClock(0);
    } else if (filter?.key == 'yesterday') {
      filterTimeClock(-1);
    } else if (filter?.key == 'thisWeek') {
      filterTimeClock(-7);
    } else if (filter?.key == 'lastWeek') {
      filterTimeClock(-14);
    } else {
      filterTimeClock(null);
    }
  };

  const handleCuHrFilter = (filter: any) => {
    console.log("🚀 ~ file: component.tsx ~ line 229 ~ handleCuHrFilter ~ filter", filter)
    history.push({
      pathname: `/CU/${getContractingUnit()}/human-resource`,
      state: { timeClockFilter: filter?.value }
    });
  };

  const handleCiawFilter = (filter: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/ciaw`,
      state: {timeClockFilter: filter?.value}
    });

    if (filter.id === 'completed') {
      dispatch(saveCIAWStateAttr('ciawFilterData', {
        ...store.getState().ciaw?.ciawFilterData,
        ciawStatus: CIAW_STATUS_COMPLETED
      }))
    } else if (filter.id === 'pending') {
      dispatch(saveCIAWStateAttr('ciawFilterData', {
        ...store.getState().ciaw?.ciawFilterData,
        ciawStatus: CIAW_STATUS_PENDING
      }))
    } else {
      dispatch(saveCIAWStateAttr('ciawFilterData', {
        ...store.getState().ciaw?.ciawFilterData,
        ciawStatus: null,
        date: null
      }))
    }
  };

  const handleTimeRegistrationFilter = (filter: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/human-resource`,
      state: { timeClockFilter: filter?.value }
    });
  };

  const renderFilterItems = (filters: any) => {
    if (filters) {
      return filters.map((item: any, index: number) => {
        let divider = false;
        if (index > 0) {
          const previousItem: any = filters[index - 1];
          if (previousItem.type !== item.type) {
            divider = true;
          } else {
            divider = false;
          }
        } else {
          divider = true;
        }

        return (
          <div key={item.id + 'div'} style={{ marginLeft: item?.subItem ? divClass === 'filter-panel-left' ? 20 : 0 : 0 }}>
            {divider && (
              <hr
                key={item.id + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={item.id + 'link'}
              className={`${currentActiveSectionTC === item.id ? classNames.selected : ''
                }`}
              href="#"
              onClick={() => {
                setFilterOption(item);
              }}
            >
              <TooltipHost
                key={item.id + 't'}
                content={item.name}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={item.id + 'i'}
                  className="ms-Icon ms-Icon--GotoToday"
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                ></i>
              </TooltipHost>
              <i
                key={item.id + 'i2'}
                className="filter-icon ms-Icon ms-Icon--GotoToday filter-icon"
                aria-hidden="true"
              ></i>
              <Text key={item.id}>{item.name} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };

  const toggleOverlay = () => {
    setDivClass('filter-panel-left panel-move');
  };

  const toggleOverlayFwd = () => {
    setDivClass('filter-panel-left');
  };

  const checkDateIsValid = (date: any) => {
    if (typeof date === "string") {
      return new Date(date);
    } else {
      return date;
    }
  };

  return (
    <div className={`timeclock ${divClass} ${classNames.wrapper}`}>
      <div className="inner-container">
        <i
          onClick={toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data">
          <Label>{`${t('timeClock')}`}</Label>
        </div>

        <div>
          {window.location.href.indexOf("ciaw") > -1 &&
            <Calendar
            onSelectDate={(date: Date, selectedDateRangeArray: Date[] | undefined) => {
              dispatch(saveCIAWStateAttr('timeClockSelectedDate', date));
              // dispatch(saveCIAWStateAttr('ciawFilterData', { ...store.getState().ciaw?.ciawFilterData, date: date ? moment(date).format('YYYY-MM-DD') : undefined }))
            }}
            value={timeClockSelectedDate ? checkDateIsValid(timeClockSelectedDate) : getTomorrow()}
            firstDayOfWeek={DayOfWeek.Monday}
            // Calendar uses English strings by default. For localized apps, you must override this prop.
            strings={defaultCalendarStrings}
            showWeekNumbers
            showMonthPickerAsOverlay
            highlightSelectedMonth
            showGoToToday
          />}
        </div>

        <div className="filter-option-list" style={{}}>
          <Stack
            gap={10}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center'
              }
            }}
          >
            {renderFilterItems(filterItems)}
          </Stack>
        </div>
      </div>
    </div>
  );


};

export default ShortCutPaneComponent;