import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';
import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';

import { formData, RiskRegister } from '../../../types/riskRegister';
import { createRisk, readByRiskRegisterId } from '../../../reducers/riskRegisterReducer'; //saveFormData removed from this import as that function changed to createRisk
import { v4 as uuidv4 } from 'uuid';
import { savePbsRisk } from '../../../reducers/projectBreakdownReducer';
import { PbsRisk } from '../../../types/projectBreakdownStructure';

{/* for saving risk data */
}


export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;

}


interface Props {

  rrDropDowns: any;
  saveFormData: (data: RiskRegister) => void;
  getAllProjectDropdowns: () => void;
  test: string;
  formData: any;

  /* for saving risk data */
  createRisk: any;
  createRiskStatus: any;
  savePbsRisk: (risk: PbsRisk) => void;
  saveRiskStatus: boolean;
  readByRiskRegisterId: (id: string) => void
  rrId: any;

}


class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: any) {

    super(props);
    this.state = {

      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,

    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    } else {
    }

    if (prevProps.rrId !== this.props.rrId) {
      if (this.props.rrId) {
        this.props.readByRiskRegisterId(this.props.rrId);
        // console.log("hola", this.props.rrId);
      }
    }


    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData = this.props.formData;
          this.setState({ formData: formData, isClear: false });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({ formData: formData, isClear: false });
        }
      }
    }

    /* for saving risk data */
    if (prevProps.createRiskStatus != this.props.createRiskStatus) {
      //this.setState({ isQualityModalVisible: false });
      if (this.props.createRiskStatus) {
        this.savePbsRisk();
        //this.props.filterQualityList(this.state.formData.id!!);
      }
    }

    if (prevProps.saveRiskStatus != this.props.saveRiskStatus) {
      if (this.props.saveRiskStatus) {
        //this.getPbsQualityList();
        this.props.filterRiskList(this.state.formData.id!!);
      }
    }

    // if(prevProps.formData != this.props.formData){
    //     console.log('check',formData)
    // }

  }

  savePbsRisk = () => {
    if (!_.isEmpty(this.state.data) && this.props.newRiskId) {
      let risk: PbsRisk = {
        pbsProductId: this.state.data.id,
        riskId: this.props.newRiskId,
      };
      this.props.savePbsRisk(risk);
    }
  };


  componentDidMount() {
    // get document pane dropdowns
    this.props.getAllProjectDropdowns();
    this.setState({ uid: uuidv4(), isClear: this.props.isNewRR });
    this.subscription = messageService.getMessage().subscribe((data: any) => {

      if (data) {
        if (data.data.isNewRR) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }
          if (this.form) {
            //Commented to prevent error occurs when clicking again on new button

            this.form.reset();
            this.form.resetFieldState('name');
            // this.form.resetFieldState('header.cpcResourceTitle');
          }
        }
        if (data.data.saveRRData) {
          // this.saveFormData();
          this.submitExternal();
        }
        if (data.data.rrFilter) {
          this.setState({ filter: data.data.rr });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }
      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
    if (this.props.formData?.id) {
    }
  }


  componentWillUnmount() {
    this.subscription.unsubscribe();
  }


  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });

  };


  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };
  setForm = (form: any) => {
    this.form = form;
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}

        />

        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={this.props.dropDowns}

          /* for saving risk data */
          createRisk={(risk: RiskRegister) => {
            this.props.createRisk(risk);
          }}

          //saveFormData={this.props.createRisk}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingRiskRegister}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}

        />


      </div>

    );

  }


}

// private openRiskModal = (): void => {
//     this.setState({
//       isRiskModalVisible: !this.state.isRiskModalVisible,
//       risk: this._riskEditDetails(),
//     });
//   };

// private createRisk = (risk: Risk) => {
//     this.props.createRisk(risk);
//   };

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.rr.isEdit,
    isNew: state.rr.isNew,
    uid: state.rr.uid,
    createRiskStatus: state.rr.createRiskStatus, /* for saving risk data */
    projectHeader: state.project.projectHeader,
    showProgressBar: state.rr.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.rr.rrDropDowns,
    rrId: state.rr.rrId,

  };
};


const mapDispatchToProps = {

  createRisk, /* for saving risk data */
  savePbsRisk,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readByRiskRegisterId,

};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane));
