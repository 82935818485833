import React from 'react';
import './project.css';
import { AnimationStyles, css, mergeStyles, Panel, PanelType, Stack } from '@fluentui/react';
import OverlayLightExample from './filterPanel';
import ProjectList from './projectList';
import CommandBarRight from './commandBarRight';
import ProjectForm from './projectForm';
import { connect } from 'react-redux';
import {
  deleteTeamMembers,
  getAllProjectDropdowns,
  getAllProjects,
  getFilterProjectList,
  getProjectCurrency,
  getProjectDefinition,
  getProjectManagementLevel,
  getProjectState,
  getProjectTemplate,
  getProjectToleranceState,
  getProjectType,
  getUserRoles,
  saveProjectDefinition,
  updateIsDetailsLoadedState,
  updateProjectDefinition,
} from '../../reducers/projectReducer';
import { addUserProject, getContractingUnits, getLastSeenProjects } from '../../reducers/cuProjectReducer';
import { Redirect, withRouter } from 'react-router';
import CustomAlert from '../../shared/alert/customAlert';
import client from '../../api';
import CommandBarLeft from './commandBarLeft';
import history from '../../history';
import ConfirmDialog from './dialog';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getContractingUnit, getProject, getUserProjectscodes } from '../../shared/util';
import { messageService } from '../../services/messageService';
import ProgressBar from '../../shared/progressBar/progressBar';
import api from './../../api';
import AuthService from '../../services/auth.service';
import { saveStateAttr } from './../../reducers/uprinceReducer';
import { getAddressBookList } from './../../reducers/addressBookReducer';
import _ from 'lodash';
import TitlePane from '../../shared/titlePane/titlePane';
import i18n from '../../../i18n';
import { CABFilter } from '../../types/addressBookItem';

i18n;

export interface State {
  showProgressBar: boolean;
  showDocumentPane: boolean;
  display: string;
  dialogHidden: boolean;
  projectFormChange: boolean;
  nextProjectId: any;
  resetForm: boolean;
  redirect: boolean;
  selectedProject: [];
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  headerFilter: boolean;
  selectedProjectId: any;
  loadProject: any;
  showProjectDetails: any;
  projectManagementLevel: any;
  projectCurrency: any;
  showMsg: boolean;
  alertMsg: string;
  alertMsgType: string;
  sortingAttribute: any;
  sortingOrder: any;
  filterTitle: any;
  filterProjectTypeId: any;
  filterManagementLevelId: any;
  filterToleranceStateId: any;
  filterTemplateId: any;
  filterProjectManagerId: any;
  filterToleranceState: any;
  resetFilter: boolean;
  activeProject: boolean;
  submitProject: boolean;
  saveBtnDisabled: boolean;
  userRoles: any[];
  title: string;
  filter: number,
  collapseBookmarkPane: boolean;
  //titleClick:boolean
  isCabPerson: boolean;
  filterIsHistory: boolean
}

interface Props extends WithTranslation {
  updateIsDetailsLoadedState: any;
  getAllProjectDropdowns: any;
  getFilterProjectList: any;
  getProjectManagementLevel: any;
  getProjectCurrency: any;
  projectManagementLevel: any;
  getProjectToleranceState: any;
  projectToleranceState: any;
  projectScopeStatus: any;
  projectFinanceStatus: any;
  projectCurrency: any;
  getProjectState: any;
  projectState: any;
  getProjectType: any;
  projectType: any;
  getProjectTemplate: any;
  projectTemplate: any;
  getAllProjects: any;
  allProjects: any;
  getProjectDefinition: any,
  selectedProject: any;
  lastInsertProjectId: any;
  saveProjectDefinition: any;
  updateProjectDefinition: any;
  updateProjectList: boolean;
  saveMsgType: string;
  saveMsg: string;
  loadMsg: string;
  alertMsg: string,
  alertMsgType: string,
  resetForm: boolean,
  isDataLoaded?: boolean;
  isDetailsLoaded?: boolean;
  getUserRoles: any;
  userRoles: any[];
  deleteTeamMembers: any;
  addUserProject: any;
  getContractingUnits: any;
  contractingUnits: any;
  titleClick: boolean;
  saveStateAttr: any;
  ledgerDropDowns: any[];
  getAddressBookList: any;
  addressBook: any
}

class CUProjectMainLayout extends React.Component<Props, State> {
  titleClick: boolean;
  subscription: any;
  private authService: AuthService;

  constructor(props: Props) {
    super(props);
    this.titleClick = false;
    this.authService = new AuthService();
    this.state = {
      display: 'none',
      showProgressBar: false,
      showDocumentPane: false,
      dialogHidden: true,
      nextProjectId: '',
      projectFormChange: false,
      resetForm: false,
      activeProject: true,
      resetFilter: false,
      redirect: false,
      loadProject: false,
      selectedProjectId: 0,
      projectManagementLevel: '',
      projectCurrency: '',
      loading: false,
      selectedProject: [],
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      showProjectDetails: false,
      showMsg: false,
      alertMsgType: '',
      alertMsg: '',
      sortingAttribute: null,
      sortingOrder: null,
      filterTitle: null,
      filterProjectTypeId: null,
      filterTemplateId: null,
      filterProjectManagerId: null,
      filterManagementLevelId: null,
      filterToleranceStateId: null,
      filterToleranceState: null,
      submitProject: false,
      saveBtnDisabled: false,
      userRoles: [],
      title: '',
      filter: 0,
      collapseBookmarkPane: false,
      //titleClick:false
      isCabPerson: false,
      filterIsHistory: false,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this.props.getFilterProjectList({
      title: null,
      projectTypeId: null,
      managementLevelId: null,
      toleranceStateId: null,
      toleranceState: null,
      projectManager: null,
      sorter: {
        attribute: null,
        order: null,
      },
    });
    this.props.getAllProjectDropdowns();
    this.props.getContractingUnits();
    this.props.getUserRoles();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.titleClick) {
          this.titleClick = data.data.titleClick;
        }
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }
        if (data.data.disableSave) {
          this.setState({ saveBtnDisabled: true }, () => {
            setTimeout(() => {
              this.setState({ saveBtnDisabled: false });
            }, 3000);
          });
        }
        if (data.data.collapseDocumentPane) {
          this.setState({
            collapseBookmarkPane: (!this.state.collapseBookmarkPane),
          });
        }
        if (data.data.isNewProject) {
          this.checkIsCabPerson();
        }
      }
    });
  }

  private hideDocumentPane = () => {
    if (getContractingUnit()) {
      history.push(`/CU/${getContractingUnit()}`);
      this.handelShowDocumentPane(false);
    }
  };

  private checkIsCabPerson = () => {
    let user: any = this.authService.getUser();
    let cabFilter = new CABFilter();
    if (user) {
      cabFilter.email = user.username;
      this.props.getAddressBookList(cabFilter).then((response: any) => {
        if (response.payload && response.payload.data && response.payload.data.result) {
          this.setState({
            saveBtnDisabled: false,
            isCabPerson: true,
            showMsg: false,
            alertMsg: '',
            alertMsgType: '',
          });
        } else {
          this.setState({
            saveBtnDisabled: true,
            isCabPerson: false,
            showMsg: true,
            alertMsg: this.props.t('cabPersonNotFound'),
            alertMsgType: 'error',

          });
        }
      });
    } else {
      this.setState({
        saveBtnDisabled: true,
      });
    }
  };

  static getDerivedStateFromProps(nextProps: any, prevState: any) {

    const id = nextProps.match.params.id;
    const prevId = prevState.selectedProjectId;
    const showDocumentPane = (id) ? true : prevState.showDocumentPane;
    const display = (showDocumentPane) ? 'block' : 'none';
    if (id && (prevId != id)) {
      return {
        selectedProjectId: nextProps.match.params.id,
        loadProject: true,
        display: display,
      };
    } else {
      return {
        selectedProjectId: nextProps.match.params.id,
        loadProject: false,
        display: display,
      };
    }

  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.userRoles !== this.props.userRoles) {
      this.setState({ userRoles: this.props.userRoles });
    }

    if (this.props.selectedProject !== prevProps.selectedProject) {
      if (this.props.selectedProject && this.props.selectedProject.id !== prevProps.selectedProject.id && this.props.selectedProject.id !== '0') {
        this.props.addUserProject(this.props.selectedProject.id).then((res: any) => {
          messageService.sendMessage({ waypointRefresh: true });
        });
      }
    }
  }

  async checkIsLogged() {
    if (this.authService.checkIsLogged()) {
      //window.location.reload();
      const loginResponse = await api.post('User/login');

      this.props.saveStateAttr('currentUser', loginResponse.data.result);

    }
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
    });
    if (this.state.width > 1100) {
      if (this.state.selectedProjectId) {
        this.setState({ showPanel: false, showProjectDetails: true });
      } else {
        this.setState({ showPanel: false, showProjectDetails: false });
      }

    } else {
      if (this.state.selectedProjectId) {
        this.setState({ showPanel: true, showProjectDetails: false });
      } else {
        this.setState({ showPanel: false, showProjectDetails: false });
      }
    }

    this.setState({ headerFilter: false });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', this.updateDimensions);
  }

  private handleProjectLoad = (id: {}): void => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true, showProjectDetails: false });
    } else {
      this.setState({
        showProjectDetails: true,
        showPanel: false,
      });
    }
    // this.props.getProjectDefinition(id).then(() => {

    // });
  };

  private handelShowDocumentPane = (showDocumentPane: boolean): void => {

    if (showDocumentPane) {
      this.props.updateIsDetailsLoadedState();
      if (this.state.width > 1100) {
        this.setState({
          display: 'block',
          showDocumentPane: showDocumentPane,
          resetForm: true,
          selectedProjectId: 0,
          showPanel: false,
          showProjectDetails: true,
        });
      } else {

        this.setState({
          display: 'block',
          showDocumentPane: showDocumentPane,
          resetForm: true,
          selectedProjectId: 0,
          showPanel: true,
          showProjectDetails: false,
        });
      }

    } else {
      this.setState({
        display: 'none',
        showPanel: false,
        showDocumentPane: showDocumentPane,
        showProjectDetails: showDocumentPane,
      });

    }
    history.push(`/CU/${getContractingUnit()}`);

  };

  private handleProjectUpdateOpen = (projectId: any, value: string, title: string): void => {
    if (!this.titleClick) {
      this._showPanel();
      // this.props.addUserProject(value)
      if (this.state.projectFormChange && projectId != this.state.selectedProjectId) {
        this.setState({
          dialogHidden: false,
          nextProjectId: projectId,
        });
      } else {
        this.setState({
          title: title,
        });
        history.push(`/CU/${window.location.pathname.split('/')[2]}/project/${projectId}`);
      }
    }
    this.titleClick = false;
  };

  private handleProjectFromChange = (): void => {
    this.setState({
      projectFormChange: true,
    });
  };

  private handleResetForm = (resetForm: boolean): void => {
    this.setState({
      resetForm: resetForm,
    });
  };

  private handleActionBarSave = (save: boolean): void => {
    this.setState({
      submitProject: save,
    });
  };

  private handleProjectFilter = (): void => {
    this.props.getFilterProjectList({
      title: this.state.filterTitle,
      projectTypeId: this.state.filterProjectTypeId,
      managementLevelId: this.state.filterManagementLevelId,
      toleranceStateId: this.state.filterToleranceStateId,
      templateId: this.state.filterTemplateId,
      toleranceState: this.state.filterToleranceState,
      projectManager: this.state.filterProjectManagerId,
      history: this.state.filterIsHistory,
      sorter: {
        attribute: this.state.sortingAttribute,
        order: this.state.sortingOrder,
      },
    });
  };

  private handleProjectListUpdate = (): void => {
    this.props.getFilterProjectList({
      title: this.state.filterTitle,
      projectTypeId: this.state.filterProjectTypeId,
      managementLevelId: this.state.filterManagementLevelId,
      toleranceStateId: this.state.filterToleranceStateId,
      toleranceState: this.state.filterToleranceState,
      templateId: this.state.filterTemplateId,
      projectManager: this.state.filterProjectManagerId,
      history: this.state.filterIsHistory,
      sorter: {
        attribute: this.state.sortingAttribute,
        order: this.state.sortingOrder,
      },
    }).then((res: any) => {
      let projects = getUserProjectscodes(res?.payload?.data?.result);
      if (projects && projects.length !== 0 && getProject()!! && projects.indexOf(getProject()!!) === -1) {
        this.setState({ showPanel: false, showProjectDetails: false });
        history.push(`/CU/${window.location.pathname.split('/')[2]}`);
      }
    });
    if (this.state.selectedProjectId) {
      this.props.getProjectDefinition(this.state.selectedProjectId);
    }
  };

  private handleProjectListSorting = (attribute: string, order: string): void => {
    this.setState({
      sortingAttribute: attribute,
      sortingOrder: order,
    }, this.handleProjectFilter);
  };

  private resetToleranceState = () => {
    this.setState({
      filterToleranceStateId: null,
    });
  };

  private handleProjectListFiltering = (data: {
    filterTitle: null,
    filterProjectTypeId: null,
    filterManagementLevelId: null,
    filterToleranceStateId: null,
    filterTemplateId: null,
    filterToleranceState: null,
    filterProjectManagerId: null,
  }): void => {
    this.setState({
      filterTitle: data.filterTitle,
      filterProjectTypeId: data.filterProjectTypeId,
      filterManagementLevelId: data.filterManagementLevelId,
      filterToleranceStateId: data.filterToleranceStateId,
      filterToleranceState: data.filterToleranceState,
      filterTemplateId: data.filterTemplateId,
      filterProjectManagerId: data.filterProjectManagerId,
    }, this.handleProjectFilter);
  };

  private handleProjectFormSubmit = (data: {}): void => {
    this.setState({
      saveBtnDisabled: true,
      showProgressBar: true,
    });
    let self = this;
    client
      .post('/ProjectDefinition/Create', data)
      .then(
        (response: any) => {
          if (response.status === 200) {
            this.checkIsLogged().then(() => {
              this.handleProjectListUpdate();
            });

            this.setState({
              alertMsgType: 'success',
              alertMsg: response.data.message,
              dialogHidden: true,
              projectFormChange: false,
              resetForm: true,
              submitProject: false,
              saveBtnDisabled: false,
              showProgressBar: false,
              showMsg: true,
            });
            setTimeout(function() {
              self.setState({
                showMsg: false,
              });
            }, 3000);
          }
        },
        (error: any) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.setState({
              alertMsgType: 'error',
              alertMsg: error.response.data.message,
              resetForm: false,
              saveBtnDisabled: false,
              showMsg: true,
            });
            setTimeout(function() {
              self.setState({
                showMsg: false,
              });
            }, 3000);
          }
        },
      );
  };

  private handleProjectUpdateFormSubmit = (data: {}): void => {
    this.setState({
      saveBtnDisabled: true,
      showProgressBar: true,
    });
    let self = this;
    client
      .put('/ProjectDefinition/Update', data)
      .then(
        (response: any) => {
          if (response.status === 200) {

            this.handleProjectListUpdate();
            this.handleProjectLoad(this.state.selectedProjectId);
            this.setState({
              alertMsgType: 'success',
              alertMsg: response.data.message,
              resetForm: false,
              showMsg: true,
              dialogHidden: true,
              projectFormChange: false,
              submitProject: false,
              saveBtnDisabled: false,
              showProgressBar: false,
            });
            setTimeout(function() {
              self.setState({
                showMsg: false,
              });
            }, 3000);
          }
        },
        (error: any) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.setState({
              alertMsgType: 'error',
              alertMsg: error.response.data.message,
              showMsg: true,
              saveBtnDisabled: false,
            });
            setTimeout(function() {
              self.setState({
                showMsg: false,
              });
            }, 3000);
          }
        },
      );
  };

  private handleResetFilter = (resetFilter: boolean): void => {
    this.setState({
      resetFilter: resetFilter,
    });
  };

  private handleFilterShortcutPane = (filterToleranceStateId: any, activeProject: boolean, filter: number, isHistory: boolean): void => {
    this.setState({
      showProjectDetails: false,
      showPanel: false,
      resetFilter: true,
      filterTitle: null,
      filterManagementLevelId: null,
      filterProjectTypeId: null,
      filterTemplateId: null,
      filterProjectManagerId: null,
      filterToleranceStateId: filterToleranceStateId,
      activeProject: activeProject,
      filter: filter,
      filterIsHistory: isHistory,
    }, this.handleProjectFilter);

    if (getContractingUnit()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}`,
        state: { isRoot: true },
      });
    } else {
      history.push({
        pathname: '/project',
        state: { isRoot: true },
      });
    }
  };

  private handleCustomAlert(showMsg: boolean) {
    this.setState({
      showMsg: showMsg,
    });
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    } else {
      this.setState({ showProjectDetails: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false });
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({ activeClass: 'grid-righter column-filter-activated', headerFilter: true });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }

  onClickConfirmDialog = () => {
    this.setState({ dialogHidden: true, projectFormChange: false });
    if (getContractingUnit()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/project/${this.state.nextProjectId}`,
        state: { isRoot: true },
      });
    } else {
      history.push({
        pathname: `/project/${this.state.nextProjectId}`,
        state: { isRoot: true },
      });
    }
  };

  onClickCancelDialog = () => {
    this.setState({ dialogHidden: true });
  };

  getHeaderTitle = () => {
    let title;
    switch (this.state.filter) {
      case 1:
        title = i18n.t('activeProject');
        break;
      case 2:
        title = i18n.t('toleranceLimit');
        break;
      case 3:
        title = i18n.t('outOfTolerance');
        break;
      default:
        title = i18n.t('activeProject');
        break;
    }
    return title;
  };

  renderListPane = () => {
    return (
      <div>
        <Stack horizontal>
          <OverlayLightExample
            handleFilterShortcutPane={(filterToleranceStateId: any, activeProject: boolean, filter: number, isHistory: boolean) => this.handleFilterShortcutPane(filterToleranceStateId, activeProject, filter, isHistory)}/>

          <div className={this.state.activeClass}>
            <Stack className="project-list-header right-panel"
                   style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}>

              <TitlePane
                headerTitle={
                  this.getHeaderTitle()
                }
              />
              <CommandBarLeft
                handelShowDocumentPane={(showDocumentPane: any) => this.handelShowDocumentPane(showDocumentPane)}
              />
            </Stack>

            <Stack className="project-list-grid">
              <ProjectList
                projectFormChange={this.state.projectFormChange}
                resetFilter={this.state.resetFilter}
                filterToleranceStateId={this.state.filterToleranceStateId}
                allProjects={this.props.allProjects}
                isDataLoaded={this.props.isDataLoaded}
                loadMsg={this.props.loadMsg}
                updateProjectList={this.props.updateProjectList}
                resetToleranceState={() => this.resetToleranceState()}
                handleProjectListUpdate={() => this.handleProjectListUpdate()}
                handleProjectListSorting={(attribute: string, order: string) => this.handleProjectListSorting(attribute, order)}
                handleProjectListFiltering={(data: any) => this.handleProjectListFiltering(data)}
                handleResetFilter={(resetFilter: boolean) => this.handleResetFilter(resetFilter)}
                handleProjectUpdateOpen={(projectId: any, value: string, title: string) => this.handleProjectUpdateOpen(projectId, value, title)}
              />
              <ConfirmDialog
                show={this.state.dialogHidden}
                onClickCancelDialog={this.onClickCancelDialog}
                onClickConfirmDialog={this.onClickConfirmDialog}
              />
            </Stack>
          </div>
        </Stack>
      </div>
    );
  };

  renderDocumentPane = () => {
    return (
      <div style={{ transition: 'all 0.9s linear' }}>
        <Stack className="project-list-header right-panel" style={{ backgroundColor: 'rgb(255, 255, 255)' }}
               styles={{ root: { width: '100%' } }}>
          <TitlePane
            close={true}
            headerTitle={
              this.state.title ? this.state.title : ''
            }


          />
          <CommandBarRight
            handelShowDocumentPane={(showDocumentPane: any) => this.handelShowDocumentPane(showDocumentPane)}
            handleActionBarSave={(save: boolean) => this.handleActionBarSave(save)}
            saveBtnDisabled={this.state.saveBtnDisabled}
            disableProjectDefinition={this.state.selectedProjectId ? false : true}
          />

          <ProgressBar show={this.state.showProgressBar}/>
        </Stack>
        <div style={{ width: '80%' }}>
          <CustomAlert
            showMsg={this.state.showMsg}
            msg={this.state.alertMsg}
            msgType={this.state.alertMsgType}
            handleCustomAlert={(showMsg: boolean) => this.handleCustomAlert(showMsg)}
          />
        </div>
        <Stack className="project-detail-list inner-container cpc">
          <ProjectForm
            saveBtnDisabled={this.state.saveBtnDisabled}
            isDetailsLoaded={this.props.isDetailsLoaded}//
            submitProject={this.state.submitProject}
            resetForm={this.state.resetForm}
            selectedProject={this.props.selectedProject}//
            selectedProjectId={this.state.selectedProjectId}
            saveMsgType={this.props.saveMsgType}
            saveMsg={this.props.saveMsg}
            projectCurrency={this.props.projectCurrency}
            projectManagementLevel={this.props.projectManagementLevel}
            projectToleranceState={this.props.projectToleranceState}
            projectScopeStatus={this.props.projectScopeStatus}
            projectState={this.props.projectState}
            projectType={this.props.projectType}
            projectTemplate={this.props.projectTemplate}
            lastInsertProjectId={this.props.lastInsertProjectId}
            projectFromSubmit={(data: {}) => this.handleProjectFormSubmit(data)}
            handleProjectUpdateFormSubmit={(data: {}) => this.handleProjectUpdateFormSubmit(data)}
            handleProjectLoad={(id: {}) => this.handleProjectLoad(id)}
            handleProjectFromChange={() => this.handleProjectFromChange()}
            handleResetForm={(resetForm: boolean) => this.handleResetForm(resetForm)}
            handleActionBarSave={(save: boolean) => this.handleActionBarSave(save)}
            userRoles={this.state.userRoles}
            contractingUnits={this.getContractingUnitDetails()}
            deleteTeam={(data: any[]) => this.props.deleteTeamMembers(data)}
            hideProgress={this.state.showMsg}
            ledgerDropDowns={this.props.ledgerDropDowns}
            handleProjectFilter={this.handleProjectFilter}
          />
        </Stack>
      </div>
    );
  };

  getContractingUnitDetails = () => {
    if (_.isArray(this.props.contractingUnits) && !_.isEmpty(this.props.contractingUnits)) {
      let selectCu = getContractingUnit();
      let contractingUnits = this.props.contractingUnits.filter((item: any) => {
        return item.sequenceCode === selectCu;
      });
      return contractingUnits;
    }
    return [];
  };

  render() {
    const animation = mergeStyles(
      this.state.showProjectDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    if (this.state.redirect) {
      return <Redirect to="/project"/>;
    }
    const { t } = this.props;
    return (
      <div className={'ms-Grid'} dir="ltr">
        <div className={'ms-Grid'} style={{ width: '100%' }}>
          <div className="ms-Grid-row">
            <div className={css('ms-Grid')} style={{ width: '100%' }}>
              <div className="ms-Grid-row">
                <div className={
                  this.state.showProjectDetails
                    ? 'ms-Grid-col ms-sm6 ms-md8 ms-lg6 padding-0 '
                    : 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 padding-0 '
                }>
                  {this.renderListPane()}
                </div>
                <div

                  className={`ms-Grid-col ms-sm6 ms-md8 ms-lg6 padding-left-0 ${animation}`}
                  style={{
                    display: this.state.showProjectDetails ? 'block' : 'none',
                    transition: 'all 0.9s linear',
                  }}
                >
                  {this.state.showProjectDetails ? this.renderDocumentPane() : false}
                </div>
              </div>
            </div>
          </div>


          <Panel
            isOpen={this.state.showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={this._hidePanel}
            isLightDismiss={true}
            headerText={(this.state.selectedProjectId && this.props.selectedProject) ? this.props.selectedProject.title : t('Project Create')}
            className="custom-detail-panel"
            closeButtonAriaLabel="Close"
          >
            {this.renderDocumentPane()}
          </Panel>
        </div>

      </div>
    );
  }

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    projectManagementLevel: state.project.projectManagementLevel,
    projectToleranceState: state.project.projectToleranceState,
    projectScopeStatus: state.project.projectScopeStatus,
    projectFinanceStatus: state.project.projectFinanceStatus,
    projectType: state.project.projectType,
    projectCurrency: state.project.projectCurrency,
    projectState: state.project.projectState,
    projectTemplate: state.project.projectTemplate,
    selectedProject: state.project.selectedProject,
    allProjects: state.project.allProjects,
    lastInsertProjectId: state.project.lastInsertProjectId,
    updateProjectList: state.project.updateProjectList,
    saveMsgType: state.project.saveMsgType,
    saveMsg: state.project.saveMsg,
    loadMsg: state.project.loadMsg,
    alertMsgType: state.project.alertMsgType,
    alertMsg: state.project.alertMsg,
    resetForm: state.project.resetForm,
    isDataLoaded: state.project.isDataLoaded,
    isDetailsLoaded: state.project.isDetailsLoaded,
    userRoles: state.project.userRoles,
    contractingUnits: state.cuProject.contractingUnits,
    ledgerDropDowns: state.project.ledgerDropDowns,
    addressBook: state.addressBook.addressBook,
  };
};

const mapDispatchToProps = {
  getProjectManagementLevel,
  getProjectToleranceState,
  getProjectCurrency,
  getProjectState,
  getProjectType,
  getProjectTemplate,
  getAllProjects,
  getProjectDefinition,
  saveProjectDefinition,
  updateProjectDefinition,
  getAllProjectDropdowns,
  getFilterProjectList,
  updateIsDetailsLoadedState,
  getUserRoles,
  deleteTeamMembers,
  addUserProject,
  getLastSeenProjects,
  getContractingUnits,
  saveStateAttr,
  getAddressBookList,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(CUProjectMainLayout)),
);

