import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { formData, MileStones } from '../../../types/mileStones';
import {
  createMSTaxonomyNode,
  readByMileStonesId,
  readMileStonesDropDownData,
  saveFormData,
} from '../../../reducers/mileStonesReducer';
import { v4 as uuidv4 } from 'uuid';
import { createTaxonomyNode, readTaxonomyData, readTaxonomyLevel } from '../../../reducers/projectBreakdownReducer';
import client from '../../../api';
import { ITag } from '@fluentui/react';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
  selectedNodeLevel: any;
}

interface Props {
  msId: any;
  msDropDowns: any;
  saveFormData: (data: MileStones) => void;
  getAllProjectDropdowns: () => void;
  test: string;
  formData: any;
  readByMileStonesId: (id: string) => void;
  readMileStonesDropDownData: () => void;

  locationTaxonomy: [];
  utilityTaxonomy: [];
  machineTaxonomy: [];
  utilityTaxonomyLevel: [],
  locationTaxonomyLevel: [];
  machineTaxonomyLevel: [];
  readTaxonomyData: () => void;
  readTaxonomyLevel: () => void;
}

class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
      selectedNodeLevel: null,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    } else {
    }

    if (prevProps.msId !== this.props.msId) {
      if (this.props.msId) {
        this.props.readByMileStonesId(this.props.msId);
      }
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          this.props.readTaxonomyData();
          const formData = this.props.formData;
          this.setState({ formData, isClear: false });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({ formData: formData, isClear: false });
        }
      }
    }

    if (prevProps.formData?.sequenceId !== this.props.formData?.sequenceId) {
      // this.props.readMachineTaxonomy({wareHouseId:this.props.formData.id});   //API for read machine taxonomy data
    }

    if (prevProps.createNewNode != this.props.createNewNode) {
      if (this.props.createNewNode) {
        // this.props.readTaxonomyData();
      }
    }
  }

  componentDidMount() {
    this.props.readMileStonesDropDownData();
    this.props.readTaxonomyData();
    this.props.readTaxonomyLevel();
    // get document pane dropdowns
    this.props.getAllProjectDropdowns();
    this.setState({ uid: uuidv4(), isClear: this.props.isNewMSTONE });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {

        if (data.data.isNewMSTONE) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }

          if (this.form) {
            this.form.reset();
          }
        }
        if (data.data.saveMSTONEData) {
          //this.props.saveFormData();
          this.submitExternal();
        }
        if (data.data.msFilter) {
          this.setState({ filter: data.data.ms });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }
        if (data.data.createNewNode) {
          this.createNewNode(data.data.createNewNode);
        }
        if (data.data.selectedNodeLevel) {
          this.setState({
            selectedNodeLevel: data.data.selectedNodeLevel,
          });
        }
        if (data.data.createNewMSNode) {
          this.createNewMSNode(data.data.createNewMSNode);
        }
        //   if (data.data.selectedMSNodeLevel) {
        //     this.setState({
        //       selectedMSNodeLevel: data.data.selectedMSNodeLevel,
        //     });
        //   }
        if (data.data.closeTaxonomyModal) {
          this.props.readTaxonomyData();
        }
      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
    if (this.props.formData?.id) {

    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };

  createNewNode = (newNode: any) => {
    if (newNode) {
      this.props.createTaxonomyNode(newNode);
    }
  };

  createNewMSNode = (newNode: any) => {
    if (newNode) {
      this.props.createMSTaxonomyNode(newNode); //API for create a new mcTaxonomy node
    }
  };

  private onFilterTaxonomyNodeChanged = (
    filterText: string,
    currentPersonas: ITag[],
    limitResults?: number,
  ): ITag[] | Promise<ITag[]> => {
    if (filterText) {
      return this.getTaxonomyByName(filterText);
    } else {
      return [{ key: '', name: filterText }];
    }
  };

  private getTaxonomyByName = async (name: string) => {
    let filter = {
      title: name,
      pbsTaxonomyLevelId: (this.state.selectedNodeLevel) ? this.state.selectedNodeLevel.id : null,
    };
    let response = await client.post(`Pbs/FilterProductByTaxonomyLevel`, filter);
    return this.formatTaxonomyResponse(response, name);
  };

  formatTaxonomyResponse = (response: any, name: string) => {
    let data: ITag[] | any = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          name: item.text,
          key: item.key,
          isNewItem: false,
        };
      });
      let obj = data.find((o: any) => o.name.toLowerCase() === name.toLowerCase());
      if (!obj) {
        data.unshift({ key: '', name: name, isNewItem: true });
      }
    } else {
      return [{ key: '', name: name, isNewItem: true }];
    }
    return data;
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={this.props.dropDowns}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingMileStones}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          dropDownOptions={{
            locationTaxonomy: this.props.locationTaxonomy,
            utilityTaxonomy: this.props.utilityTaxonomy,
            machineTaxonomy: this.props.machineTaxonomy,
            locationTaxonomyLevel: this.props.locationTaxonomyLevel,
            utilityTaxonomyLevel: this.props.utilityTaxonomyLevel,
            machineTaxonomyLevel: this.props.machineTaxonomyLevel,
          }}
          onFilterTaxonomyNodeChanged={this.onFilterTaxonomyNodeChanged}
        />

      </div>
    );
  }

}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ms.isEdit,
    isNew: state.ms.isNew,
    uid: state.ms.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.ms.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.ms.msDropDowns,
    msId: state.ms.msId,
    createNewMSNode: state.ms.createNewMSNode,

    createNewNode: state.projectBreakdown.createNewNode,
    locationTaxonomy: state.projectBreakdown.locationTaxonomy,
    utilityTaxonomy: state.projectBreakdown.utilityTaxonomy,
    machineTaxonomy: state.projectBreakdown.machineTaxonomy,
    utilityTaxonomyLevel: state.projectBreakdown.utilityTaxonomyLevel,
    locationTaxonomyLevel: state.projectBreakdown.locationTaxonomyLevel,
    machineTaxonomyLevel: state.projectBreakdown.machineTaxonomyLevel,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readByMileStonesId,
  readTaxonomyData,
  readTaxonomyLevel,
  readMileStonesDropDownData,
  createTaxonomyNode,
  createMSTaxonomyNode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPane);
