import * as React from 'react';
import { useEffect, useState } from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import WbsTaskListPane from './listPane/WbsTaskListPane';
import TitlePane from '../../shared/titlePane/titlePane';
import WbsShortcutPane from '../wbs/shortcutPane/component';
import { Stack } from '@fluentui/react';
import ProgressBar from '../../shared/progressBar/progressBar';
import { useParams } from 'react-router';
import DocumentPaneWrapper from './documentPane/DocumentPaneWrapper';
import { messageService } from '../../services/messageService';
import { getWbsTaskDropdown } from '../../reducers/wbsTaskReducer';
import { useDispatch } from 'react-redux';
import ListPaneCommandBar from './commandBar/ListPaneCommandBar';
import './wbs-task-style-sheet.css';
import WbsTaskFilterCard from './listPane/component/WbsTaskFilterCard';
import { useLocation } from 'react-router-dom';
import { ParamTypes } from '../../types/uPrince';

const WbsTaskMainLayout = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { state }: any = useLocation();
  const { featureId }: ParamTypes = params;

  const [reSizer, setReSizer] = useState<number>(screen.width);
  const [showDocumentPane, setShowDocumentPane] = useState<boolean>(false);

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          setReSizer(screen.width);
          setShowDocumentPane(false);
          // getProject() && history.push(`/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`);
        }
      }
    });
    dispatch(getWbsTaskDropdown());

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (featureId) {
      setReSizer(screen.width / 2);
      setShowDocumentPane(true);
    } else {
      setReSizer(screen.width);
      setShowDocumentPane(false);
    }
  }, [params]);


  const renderShortCutPane = () => {
    return <WbsShortcutPane />;
  };

  const getListPaneView = () => {
    return <>
      <TitlePane
        headerTitle={'All'}
        type={1}
        isFullScreen={false}
      />
      <div style={{
        height: 'calc(100vh - 134px)',
        overflow: 'overlay',
      }}>
        <Stack horizontal>
          {renderShortCutPane()}
          <div className={'grid-righter full-height'}>
            <Stack
              className='project-list-header new-design'
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingRight: 0,
              }}
              styles={{ root: { width: '100%', paddingRight: 0 } }}
            >
              <ProgressBar show={false} />
              <ListPaneCommandBar />
            </Stack>
            <Stack>
              <WbsTaskFilterCard isCollapsed={!showDocumentPane} />
            </Stack>
            <Stack style={{ height: 'calc(100vh - 249px)', alignSelf: 'center' }}>
              <WbsTaskListPane />
            </Stack>
          </div>
        </Stack>

      </div>
    </>;
  };

  const getDocumentPaneView = () => {
    return <>
      <DocumentPaneWrapper />
    </>;
  };

  return (
    <div>
      <SplitPane
        className={'SplitPane'}
        minSize={(screen.width / 3)}
        maxSize={(screen.width / 3) * 2}
        defaultSize={screen.width}
        split='vertical'
        onChange={(size: any) => {
          setReSizer(size);
        }}
        size={reSizer}
        style={{ height: '85.5%' }}
      >
        <Pane className={''}
              style={{
                width: reSizer,
                height: '100%',
              }}>{getListPaneView()} </Pane>
        <Pane className={''}
              style={{
                marginLeft: 6,
                width: screen.width - reSizer,
                height: '100%',
              }}> {showDocumentPane && getDocumentPaneView()}</Pane>
      </SplitPane>
    </div>
  );

};

export default WbsTaskMainLayout;
