import moment from 'moment';
import {getTheme, ITheme, Panel, PanelType, registerIcons, Stack,} from '@fluentui/react';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import SplitPane, {Pane} from 'react-split-pane';
import history from '../../history';
import {getById as borGetById, resetBorFormDate, saveBORStateAttr,} from '../../reducers/billOfResourcesReducer';
import {
  clearFormData as clearMStoneData,
  filterMileStonesListPaneData,
  readByMileStonesId,
  readMileStonesDropDownData,
} from '../../reducers/mileStonesReducer';
import {clearFormData as clearPMOLData, readPMOLByID, savePMOLStateAttr,} from '../../reducers/pmolReducer';
import {getDropdownData, getProductCatalogueById,} from '../../reducers/productCatalogueReducer';
import {getById, readDropdownData,} from '../../reducers/projectBreakdownReducer';
import {readByPurchaseOrderId, readPurchaseOrderDropDownData,} from '../../reducers/purchaseOrderReducer';
import {
  clearFormData,
  filterVisualPlanListPaneData,
  readByVisualPlanId,
  readCpcResourceFamilyforVp,
  readCUHRbyPersonId,
  readMilestoneShortCutPaneData,
  readMTPOrganizationTaxanomyData,
  readProjectVPData,
  readProjectVPPMOLPersonData,
  readVisualPlanDropDownData,
  readVisualPlanShortCutPaneData,
  readVPOrganizationTaxanomyData,
  readVPPBSLocationTaxonomyData,
  readVPPBSTaxonomyData,
  readVPPBSUtilityTaxonomyData,
  readVPProjectResources,
  readVPWareHouseTaxanomyData,
  saveVPStateAttr,
} from '../../reducers/visualPlanReducer';
import {messageService} from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import DocumentPaneSection from '../../shared/mainUI/documentPaneSection/documentPaneSection';
import DocumentPaneSectionVp from '../../shared/mainUI/documentPaneSection/documentPaneSectionVp';
import withMainUI from '../../shared/mainUI/withMainUI';
import ProgressBar from '../../shared/progressBar/progressBar';
import TitlePane from '../../shared/titlePane/titlePane';
import {
  formatDateTimeToISOString,
  getContractingUnit,
  getSelectedFilterDates,
  isPPOpenFromPlanboard,
} from '../../shared/util';
import {MileStonesListPaneFilter} from '../../types/mileStones';
import {OrganisationSettingListPaneFilter} from '../../types/organisationSetting';
import {ProjectBreakdown} from '../../types/projectBreakdownStructure';
import {BillOfResource, pmolFormData, ProjectMolecule,} from '../../types/projectMolecule';
import {BookMarkItem} from '../../types/template';
import {VisualPlanListItem, VisualPlanListPaneFilter, VPPaneType, VPShortCutPaneItem,} from '../../types/visualPlan';
import CpcDocumentPane from '../dayPlanning/documentPane/cpcContainer';
import '../style/style.css';
import CommandBarLeft from './commandBar/commandBarLeft';
import CommandBarRight from './commandBar/commandBarRight';
import ListPane from './listPane/container';
import MilestoneListPane from './milestoneListPane/container';
import OrgListPane from './orgListPane/container';
import ProductTreeListPaneComponent from './productTaxonomyListPane/component';
import ShortCutPane from './shortCutPane/container';
import ShortCutPaneTaxonomy from './shortCutPaneTaxonomy/container';
import PurchaseAndDeliveryComponent from './purchaseAndDeliveryBor/component';
import PurchaseAndDeliveryPRComponent from './purchaseAndDeliveryPR/component';
import MachineTaxonomyListPane from './machinetaxonomyListPane/component';
import {SvgIcons} from '../../shared/svgIcons/svgIcons';
import MatrixTable from './resourceTable/component';
import {getProjectDefinition} from '../../reducers/projectReducer';
import OrgTaxonomyPane from './orgTaxonomyPane/container';
import MidTermTreeListPaneComponent from "./midTermPlanningTaxonomyListPane/component";
import MidtermCapacityTable from './midtermCapacityTable/component';
import ProjectTeamCapacityTable from './projectTeamCapacityTable/component';

// const SUBCONTRACTOR_PRODUCT_ID = '48a7dd9c-55ac-4e7c-a2f3-653811c0eb14';
const INTERNAL_PRODUCT_ID = 'aa0c8e3c-f716-4f92-afee-851d485164da';

registerIcons(SvgIcons);

export interface State {
  selectedShortCutItem: VPShortCutPaneItem;
  filter: VisualPlanListPaneFilter;
  formData: ProjectMolecule;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  showDetails: boolean;
  autoSave: boolean;
  isEdit: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  horizontalReSizer: number;
  projectId: null | string;
  topReSizer: number;
  bottomReSizer: number;
  isMinimize: boolean;
  collapseBottomBookmarkPane: boolean;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  showProgressBar: boolean;
  shortCutPaneFilters: VPShortCutPaneItem[];
  formData: any;
  setUid: () => void;
  clearUid: () => void;
  resetIsChange: () => void;
  uid: string | null;
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  loading: boolean;
  handleShowDocumentPane: () => void;
  reSizer: number;
  horizontalReSizer: number;
  showDetails: boolean;
  showPanel: boolean;
  handleScroll: () => void;
  currentActiveSection: string;
  animation: any;
  readVisualPlanShortCutPaneData: () => void;
  filterVisualPlanListPaneData: (filter: VisualPlanListPaneFilter) => void;
  readVPWareHouseTaxanomyData: (data: { wareHouseId: null }) => void;
  readVisualPlanDropDownData: () => void;
  readByVisualPlanId: (id: string) => void;
  clearFormData: () => void;
  shortcutPaneData: VPShortCutPaneItem[];
  readPurchaseOrderDropDownData: any;
  readMileStonesDropDownData: any;
    readVPOrganizationTaxanomyData: (data: any) => void;
  readMTPOrganizationTaxanomyData: (data: any) => void;

  vpList: VisualPlanListItem[];
  vpOrgTaxonomyList: any[];
  isVPListLoaded: boolean;
  vpDropDowns: any;
  reloadListPane: boolean;
  purchaseOrderDropDowns: any;
  readByPurchaseOrderId: (id: string) => void;
  getDropdownData: any;
  reloadRequired: boolean;
  poSelectedResources: any;
  showToolsOrLabourDocumentPane: boolean;
  showPODocumentPane: boolean;
  selectedResourceTypeOrg: string;
  saveVPStateAttr: any;
  selectedLabour: string;
  cuhrFormData: any;
  selectedTool: string;
  getProductCatalogueById: any;
  selectedPO: string;
  productCatalogue: any;
  poSelectedTypes: any;
  poFilterFromDate: string;
  poFilterToDate: string;
  readCUHRbyPersonId: any;
  vpProjectList: any[];
  readProjectVPData: any;
  readVPProjectResources: any;
  readMilestoneShortCutPaneData: any;
  milestoneShortCut: any;
  vpProjectSelectedShortcutpaneType: string;
  showvpProjectDocumentPane: string;
  selectedVpProjectBarType: string;
  selectedPBS: string;
  getById: any;
  selectedPMOL: string;
  readPMOLByID: any;
  selectedBOR: string;
  borGetById: any;
  filterMileStonesListPaneData: any;
  selectedMileStone: any;
  readByMileStonesId: any;
  milestoneFormDate: any;
  purchaseOrderFormData: any;
  msDropDowns: any;
  projectLevelReloadRequired: boolean;
  clearPMOLData: any;
  resetBorFormDate: any;
  clearMStoneData: any;
  topReSizer: number;
  bottomReSizer: number;
  isMSEdit: boolean;
  isBorEdit: boolean;
  isPmolEdit: boolean;
  isPBSEdit: boolean;
  selectedStartDateProjectItem: any;
  selectedEndDateProjectItem: any;
  selectedProjectItemType: string;
  pbsFormData: ProjectBreakdown;
  borFormData: BillOfResource;
  pmolFormData: ProjectMolecule;
  readProjectVPPMOLPersonData: any;
  readVPPBSUtilityTaxonomyData: any;
  readVPPBSLocationTaxonomyData: any;
  vpProducTaxonomyList: any[];
  showPBSProgressbar: boolean;
  showBORProgressBar: boolean;
  showPMOLProgressBar: boolean;
  savePMOLStateAttr: any;
  saveBORStateAttr: any;
  readDropdownData: any;
  readVPPBSTaxonomyData: any;
  selectedVpResourceBarType: any;
  selectedProject: any;
  projectFormData: any;
  getProjectDefinition: any;
  showMidTermTableProgressBar: boolean;

  readCpcResourceFamilyforVp: (data: { wareHouseId: null }) => void;
}

class CuVisualPlanMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  theme: ITheme = getTheme();
  subscription: any;
  bookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'po-header',
      label: this.props.t('purchaseOrderHeader'),
    },
    {
      id: 2,
      link: 'pbs-involved-parties',
      label: this.props.t('involvedParties'),
    },
    { id: 3, link: 'po-resources', label: this.props.t('resources') },
    {
      id: 4,
      link: 'po-external-products',
      label: this.props.t('externalProducts'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: new VisualPlanListPaneFilter(),
      selectedShortCutItem: {
        id: null,
        key: null,
        name: null,
        type: null,
        value: null,
      },
      isEdit: false,
      autoSave: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel-vp parent',
      showDetails: false,
      formData: pmolFormData,
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: screen.width,
      horizontalReSizer: screen.height - 340,
      projectId: null,
      topReSizer: screen.width,
      bottomReSizer: screen.width,
      isMinimize: true,
      collapseBottomBookmarkPane: false,
    };
  }

  getNewTaskType = (type: any) => {
    if (type.toString() === 'pbs' || type.toString() === 'sub-pbs') {
      return 'bor';
    }
    if (type.toString() === 'bor') {
      return 'pmol';
    }
  };

  initialFilter = {
    type: isPPOpenFromPlanboard() ? ['4'] : ['0'],
    startDate: isPPOpenFromPlanboard()
      ? moment().startOf('week').format('YYYY-MM-DD hh:mm')
      : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
    endDate: isPPOpenFromPlanboard()
      ? moment().endOf('week').format('YYYY-MM-DD hh:mm')
      : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
  };

  componentDidMount() {
    this.handleUrlChange();
    this.props.readMileStonesDropDownData();
    this.props.getDropdownData();
    this.props
      .readProjectVPData({
        ...this.initialFilter,
      })
      .then(() => {
        this.setGanttChartHeight();
      });
    // this.props.readVPOrganizationTaxanomyData(
    //   new OrganisationSettingListPaneFilter()
    // );
    this.props.readVPWareHouseTaxanomyData({ wareHouseId: null });
    this.props.readVPProjectResources();
    this.props.readMilestoneShortCutPaneData();
    this.props.readDropdownData();
    this.props.savePMOLStateAttr('formData', {});
    this.props.saveVPStateAttr('selectedPBS', null);
    this.props.saveVPStateAttr('selectedPMOL', null);
    this.props.saveVPStateAttr('selectedBOR', null);
    this.props.saveVPStateAttr('selectedPR', null);
    this.props.saveVPStateAttr('selectedProject', null);
    this.props.saveVPStateAttr('showvpProjectDocumentPane', false);
    this.props.saveVPStateAttr('selectedVpProjectBarType', null);
    this.props.saveVPStateAttr('isLabourTeamAssignedProject', false);
    this.props.saveVPStateAttr('isToolPoolAssignedProject', false);
    this.props.saveVPStateAttr('selectedBuInCuProjectPlanning', null);
    this.props.saveVPStateAttr(
      'selectedStartDateProjectItem',
      moment().startOf('month').format('YYYY-MM-DD hh:mm')
    );
    this.props.saveVPStateAttr(
      'selectedEndDateProjectItem',
      moment().endOf('month').format('YYYY-MM-DD hh:mm')
    );
    this.props.saveVPStateAttr('vpProjectSelectedShortcutpaneType', ['1']);
    this.props.savePMOLStateAttr('isPMOLOpenFromVP', true);
    this.props.saveBORStateAttr('isBOROpenFromVP', true);
    this.props.readCpcResourceFamilyforVp({ wareHouseId: null });

    if (
      location.pathname.split('/').pop() === 'new' &&
      this.state.formData &&
      this.state.formData.id === null
    ) {
      this.createNew();
    }

    this.setState(
      {
        formData: this.props.formData,
      },
      this.props.handleShowDocumentPane
    );

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewMSTONE) {
          this.props.saveVPStateAttr('showvpProjectDocumentPane', true);
          this.props.saveVPStateAttr('selectedVpProjectBarType', 'milestone');
          this.props.clearMStoneData();
        }
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane(data.data.documentPaneType);
        }

        if (data.data.isNewVP) {
          this.createNew();
        }

        if (
          data.data.pmolCreatedFromVP ||
          data.data.borCreatedFromVP ||
          data.data.pbsCreatedFromVP
        ) {
          //   this.props.readProjectVPData({
          //     type:[this.props.selectedProjectItemType],
          //     startDate: formatDateTimeToISOString(this.props.selectedStartDateProjectItem),
          //     endDate: formatDateTimeToISOString(this.props.selectedEndDateProjectItem),
          // }).then(()=>{
          //       this.setGanttChartHeight()
          //   });;
        }

        if (data.data.rightClickTask) {
          if (
            data.data.rightClickTask.type === 'pbs' ||
            data.data.rightClickTask.type === 'sub-pbs' ||
            data.data.rightClickTask.type === 'bor'
          ) {
            this.props.clearPMOLData();
            this.props.resetBorFormDate();
            this.props.saveVPStateAttr('showvpProjectDocumentPane', true);
            messageService.sendMessage({ vpBorPbsReset: true });

            this.props.saveVPStateAttr(
              'selectedVpProjectBarType',
              this.getNewTaskType(data.data.rightClickTask.type)
            );
            if (data.data.rightClickTask.type.toString() === 'pbs') {
              this.props.saveVPStateAttr(
                'selectedPBS',
                data.data.rightClickTask.pbsSequenceId
              );
              this.props.saveVPStateAttr(
                'slectedPBSForBor',
                data.data.rightClickTask.pbsSequenceId
              );
              this.props.saveVPStateAttr(
                'borInheritedPBSTitle',
                data.data.rightClickTask.name.slice(
                  10,
                  data.data.rightClickTask.name?.length
                )
              );
            }

            if (data.data.rightClickTask.type.toString() === 'bor') {
              this.props.saveVPStateAttr(
                'selectedBORforPMOL',
                data.data.rightClickTask.borSequenceId
              );
              this.props.saveVPStateAttr(
                'slectedPBSForPMOL',
                data.data.rightClickTask.pbsSequenceId
              );
              this.props.saveVPStateAttr(
                'pmolInheritedBORTitle',
                data.data.rightClickTask.name.slice(
                  11,
                  data.data.rightClickTask.name?.length
                )
              );
            }
          }
        }

        if (data.data.collapseDocumentPane) {
          this.setState({
            isDrag: !this.state.isDrag,
            collapseBookmarkPane:
              data.data.documentPaneType === VPPaneType.TOP
                ? !this.state.collapseBookmarkPane
                : this.state.collapseBookmarkPane,
            collapseBottomBookmarkPane:
              data.data.documentPaneType === VPPaneType.BOTTOM
                ? !this.state.collapseBottomBookmarkPane
                : this.state.collapseBottomBookmarkPane,
            resetLayout: !this.state.resetLayout,
            topReSizer:
              data.data.documentPaneType === VPPaneType.TOP
                ? this.state.topReSizer === 300
                  ? screen.width / 2
                  : 300
                : this.state.topReSizer,
            bottomReSizer:
              data.data.documentPaneType === VPPaneType.BOTTOM
                ? this.state.bottomReSizer === 300
                  ? screen.width / 2
                  : 300
                : this.state.bottomReSizer,
          });
        }

        if (data.data.minimizeBottomPane) {
          this.setState({
            horizontalReSizer: data.data.isMinimize
              ? screen.height - 330
              : screen.height / 2 - 140,
            isMinimize: data.data.isMinimize,
          });
        }

        if (
          data &&
          data.data &&
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: true });
        } else if (
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          !data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: false });
        }
      }
    });

    // this.props.readMTPOrganizationTaxanomyData(new OrganisationSettingListPaneFilter());
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.savePMOLStateAttr('isPMOLOpenFromVP', true);
      // this.props.readVPPBSTaxonomyData({
      //   fromDate: null,
      //   toDate: null,
      // });
    }

    if (
      this.props.reloadListPane !== prevProps.reloadListPane &&
      this.props.reloadListPane
    ) {
      this.reloadListPaneItem();
    }
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange();
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
        topReSizer: this.props.showvpProjectDocumentPane
          ? screen.width / 2
          : screen.width,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.filter != this.props.location.state.poFilter
    ) {
      if (this.props.location.state.poFilter) {
        this.setState(
          {
            filter: this.props.location.state.poFilter,
          },
          this.reloadListPaneItem
        );
      }
    }

    if (this.props.projectLevelReloadRequired) {
      //   this.props.readProjectVPData({
      //     type:[this.props.selectedProjectItemType],
      //     startDate: formatDateTimeToISOString(this.props.selectedStartDateProjectItem),
      //     endDate: formatDateTimeToISOString(this.props.selectedEndDateProjectItem),
      // }).then(()=>{
      //       this.setGanttChartHeight()
      //   });
      // this.props.readVPProjectResources();
      this.props.readCpcResourceFamilyforVp({ wareHouseId: null });
    }

    if (
      this.props.selectedLabour &&
      this.props.selectedLabour !== prevProps.selectedLabour
    ) {
      this.props.readCUHRbyPersonId(this.props.selectedLabour);
    }

    if (
      this.props.selectedTool &&
      this.props.selectedTool !== prevProps.selectedTool
    ) {
      this.props.getProductCatalogueById(this.props.selectedTool);
    }

    if (
      this.props.selectedPBS &&
      this.props.selectedPBS !== prevProps.selectedPBS
    ) {
      this.props.getById(this.props.selectedPBS, this.props.selectedProject);
    }

    if (
      this.props.selectedProject &&
      this.props.selectedProject !== prevProps.selectedProject
    ) {
      this.props.getProjectDefinition(this.props.selectedProject);
    }

    if (
      this.props.selectedPMOL &&
      this.props.selectedPMOL !== prevProps.selectedPMOL
    ) {
      this.props.readPMOLByID(this.props.selectedPMOL);
    }

    if (
      this.props.selectedBOR &&
      this.props.selectedBOR !== prevProps.selectedBOR
    ) {
      this.props.borGetById(this.props.selectedBOR);
    }

    if (
      this.props.selectedMileStone &&
      this.props.selectedMileStone !== prevProps.selectedMileStone
    ) {
      this.props.readByMileStonesId(this.props.selectedMileStone);
    }

    if (
      this.props.vpProjectSelectedShortcutpaneType !==
        prevProps.vpProjectSelectedShortcutpaneType &&
      this.props.vpProjectSelectedShortcutpaneType[0] === '2'
    ) {
      this.props
        .filterMileStonesListPaneData(new MileStonesListPaneFilter())
        .then(() => {
          this.setGanttChartHeight();
        });
    }

    if (
      this.props.showvpProjectDocumentPane !==
      prevProps.showvpProjectDocumentPane
    ) {
      this.setState({
        topReSizer: this.props.showvpProjectDocumentPane
          ? screen.width / 2
          : screen.width,
      });
    }
    if (
      this.props.showToolsOrLabourDocumentPane !==
      prevProps.showToolsOrLabourDocumentPane
    ) {
      //alert(JSON.stringify(this.props.showToolsOrLabourDocumentPane))
      this.setState({
        bottomReSizer: this.props.showToolsOrLabourDocumentPane
          ? screen.width / 2
          : screen.width,
      });
    }
    if (this.props.cuhrFormData !== prevProps.cuhrFormData) {
      this.setState({
        bottomReSizer: this.props.showToolsOrLabourDocumentPane
          ? screen.width / 2
          : screen.width,
      });
    }

    if (this.state.horizontalReSizer !== prevState.horizontalReSizer) {
      this.setGanttChartHeight();
    }
    if (this.props.vpProducTaxonomyList !== prevProps.vpProducTaxonomyList) {
      setTimeout(() => {
        this.setGanttChartHeight();
      }, 1000);
    }
  }

  handleUrlChange() {
    let id = this.props.match.params.featureId
      ? this.props.match.params.featureId
      : null;

    if (id) {
      this.props.readByPurchaseOrderId(id);
    }

    this.setState(
      {
        isEdit: !!this.props.match.params.featureId,
        isDisableNewButton: !!id,
      }
      //this.props.handleShowDocumentPane
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.props.saveVPStateAttr('siblingCount', 0);
  }

  // 2. clear states in this method and save to state
  // 3. update redirect url
  createNew = (): void => {
    this.props.clearFormData();
    //this.props.setUid();
    let formData: any = {
      title: null,
      // ....
    };
    this.setState({
      formData: formData,
    });
    history.push(`/CU/${getContractingUnit()}/visual-plan/new`);
  };

  reloadListPaneItem = () => {};

  renderPOShortCutPane = () => {
    return (
      <ShortCutPane
        projectId={this.state.projectId}
        shortCutPaneFilters={this.props.milestoneShortCut}
        filter={this.state.filter}
        handelShortCutPaneFilter={(selectedItem: VPShortCutPaneItem) =>
          this.handelPOShortCutPaneFilter(selectedItem)
        }
        toggleOverlay={this.state.collapseBookmarkPane}
        resetLayout={this.state.resetLayout}
      />
    );
  };

  renderBottomShortCutPane = () => {
    return (
      <ShortCutPaneTaxonomy
        projectId={this.state.projectId}
        shortCutPaneFilters={[]}
        filter={this.state.filter}
        handelShortCutPaneFilter={(selectedItem: VPShortCutPaneItem) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        toggleOverlay={this.state.collapseBottomBookmarkPane}
        resetLayout={this.state.resetLayout}
      />
    );
  };

  private getMileStoneListPaneView = (isFirst?: boolean) => {
    return (
      <div>
        <TitlePane headerTitle={this.props.t('milestones')} />
        <div
          style={{
            height: isFirst
              ? this.state.horizontalReSizer - 45
              : screen.height - (this.state.horizontalReSizer - 45),
            //backgroundColor:'#0f561a',
            overflow: 'auto',
          }}
        >
          <Stack horizontal>
            {this.renderPOShortCutPane()}
            <div
              className={
                this.state.activeClass +
                (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')
              }
            >
              <Stack
                className="project-list-header right-panel"
                style={{
                  backgroundColor: 'rgb(255, 255, 255)',
                  paddingRight: 0,
                }}
                styles={{ root: { width: '100%', paddingRight: 0 } }}
              >
                <CommandBarLeft disable={false} />
                <ProgressBar show={this.props.showProgressBar} />
              </Stack>

              <Stack style={{ alignSelf: 'center' }}>
                <MilestoneListPane
                  projectId={this.state.projectId}
                  loadMsg={this.props.loadMsg}
                  reloadListPaneItem={() => this.reloadListPaneItem()}
                  filter={this.state.filter}
                  listPaneItems={this.props.vpProjectList}
                  isDataLoaded={this.props.isVPListLoaded}
                  isChange={this.props.isChange}
                  formData={this.state.formData}
                  handelFilter={(filter: VisualPlanListPaneFilter) => {
                    console.log('filter', filter);
                  }}
                  handleSelectedListPaneItem={(
                    selectedListItemIds: [] | string[]
                  ) => {
                    console.log('selectedListItemIds', selectedListItemIds);
                  }}
                  reSizer={this.state.reSizer}
                />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getProductListPaneView = (isFirst?: boolean) => {
    // console.log("get getProductListPaneView")
    return (
      <div>
        <TitlePane headerTitle={this.props.t('productTaxonomy')} />
        <div
          style={{
            height: isFirst
              ? this.state.horizontalReSizer - 45
              : screen.height - (this.state.horizontalReSizer - 45),
            overflow: 'auto',
          }}
        >
          <Stack horizontal>
            {this.renderPOShortCutPane()}
            <div
              className={
                this.state.activeClass +
                (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')
              }
            >
              <Stack style={{ alignSelf: 'center' }}>
                {this.props.selectedProjectItemType.toString() === '7'
                    ?  <MidTermTreeListPaneComponent/>
                    : <ProductTreeListPaneComponent/>
                }
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getPurchaseAndDeliveryListPaneView = (isFirst?: boolean) => {
    return (
      <div>
        <TitlePane headerTitle={this.props.t('purchaseAndDelivery')} />
        <div
          style={{
            height: isFirst
              ? this.state.horizontalReSizer - 45
              : screen.height - (this.state.horizontalReSizer - 45),
            overflow: 'auto',
          }}
        >
          <Stack horizontal>
            {this.renderPOShortCutPane()}
            <div
              className={
                this.state.activeClass +
                (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')
              }
            >
              <Stack style={{ alignSelf: 'center' }}>
                <PurchaseAndDeliveryComponent />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getPurchaseAndDeliveryPRListPaneView = (isFirst?: boolean) => {
    return (
      <div>
        <TitlePane headerTitle={this.props.t('purchaseAndDelivery')} />
        <div
          style={{
            height: isFirst
              ? this.state.horizontalReSizer - 45
              : screen.height - (this.state.horizontalReSizer - 45),
            overflow: 'auto',
          }}
        >
          <Stack horizontal>
            {this.renderPOShortCutPane()}
            <div
              className={
                this.state.activeClass +
                (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')
              }
            >
              <Stack style={{ alignSelf: 'center' }}>
                <PurchaseAndDeliveryPRComponent />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getMachinetaxonomyListPaneView = (isFirst?: boolean) => {
    return (
      <div>
        <TitlePane headerTitle={this.props.t('machinetaxonomy')} />
        <div
          style={{
            height: isFirst
              ? this.state.horizontalReSizer - 45
              : screen.height - (this.state.horizontalReSizer - 45),
            overflow: 'auto',
          }}
        >
          <Stack horizontal>
            {this.renderPOShortCutPane()}
            <div
              className={
                this.state.activeClass +
                (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')
              }
            >
              <Stack style={{ alignSelf: 'center' }}>
                <MachineTaxonomyListPane />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getListPaneView = (isFirst?: boolean) => {
    // console.log("called getListPaneView")
    return (
      <div>
        <TitlePane headerTitle={this.props.t('projects')} />
        <div
          style={{
            height: isFirst
              ? this.state.horizontalReSizer - 45
              : screen.height - (this.state.horizontalReSizer - 45),
            overflow: 'auto',
          }}
        >
          <Stack horizontal>
            {this.renderPOShortCutPane()}
            <div
              className={
                this.state.activeClass +
                (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')
              }
            >
              <Stack
                style={{
                  backgroundColor: 'rgb(255, 255, 255)',
                  paddingRight: 0,
                }}
                styles={{
                  root: { width: '100%', paddingRight: 0, marginTop: 2 },
                }}
              >
                <ProgressBar
                  show={
                    this.props.showPBSProgressbar ||
                    this.props.showBORProgressBar ||
                    this.props.showPMOLProgressBar
                  }
                />
              </Stack>

              <Stack style={{ alignSelf: 'center' }}>
                <ListPane
                  projectId={this.state.projectId}
                  loadMsg={this.props.loadMsg}
                  reloadListPaneItem={() => this.reloadListPaneItem()}
                  filter={this.state.filter}
                  listPaneItems={this.props.vpProjectList}
                  isDataLoaded={this.props.isVPListLoaded}
                  isChange={this.props.isChange}
                  formData={this.state.formData}
                  handelFilter={(filter: VisualPlanListPaneFilter) => {
                    //this.handleFilter(filter)
                  }}
                  handleSelectedListPaneItem={(
                    selectedListItemIds: [] | string[]
                  ) => {
                    //this.handleSelectedListPaneItem(selectedListItemIds);
                  }}
                  reSizer={this.state.topReSizer}
                  msDropDowns={this.props.msDropDowns}
                />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getBottomListPane = (typeId: string) => {
    switch (typeId) {
      case '0': //All
        return <div
            className={
                this.state.activeClass +
                (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')
            }
        >
          <ProgressBar show={this.props.showProgressBar}/>

          <Stack style={{alignSelf: 'center'}}>
            <OrgListPane
                projectId={this.state.projectId}
                loadMsg={this.props.loadMsg}
                reloadListPaneItem={() => this.reloadListPaneItem()}
                filter={this.state.filter}
                listPaneItems={this.props.vpOrgTaxonomyList}
                isDataLoaded={this.props.isVPListLoaded}
                isChange={this.props.isChange}
                formData={this.state.formData}
                handelFilter={(filter: VisualPlanListPaneFilter) => {
                }}
                handleSelectedListPaneItem={(
                    selectedListItemIds: [] | string[]
                ) => {
                }}
                reSizer={this.state.topReSizer}
            />
          </Stack>
        </div>
      case '100': //Resource matrix
        return <div>
          <ProgressBar show={this.props.showProgressBar}/>
          <Stack style={{alignSelf: 'center'}}>
            <MatrixTable matrixType={this.props.selectedVpResourceBarType} height={this.getListPaneHeight()}/>
          </Stack>
        </div>
      case '200': //Week matrix
        return <div>
          <ProgressBar show={this.props.showProgressBar}/>
          <Stack style={{alignSelf: 'center'}}>
            <MatrixTable matrixType={this.props.selectedVpResourceBarType} height={this.getListPaneHeight()}/>
          </Stack>
        </div>
      case '300': //Organisation settings
        return <div className={this.state.activeClass + ' vp'} style={{height: 'inherit'}}>
          {/*<ProgressBar show={this.props.showProgressBar}/>*/}
          <Stack style={{ alignSelf: 'center', height: 'inherit' }}>
            <OrgTaxonomyPane
                projectId={this.state.projectId}
                loadMsg={this.props.loadMsg}
                reloadListPaneItem={() => this.reloadListPaneItem()}
                filter={this.state.filter}
                listPaneItems={this.props.vpOrgTaxonomyList}
                isDataLoaded={this.props.isVPListLoaded}
                isChange={this.props.isChange}
                formData={this.state.formData}
                handelFilter={(filter: VisualPlanListPaneFilter) => {
                  //this.handleFilter(filter)
                }}
                handleSelectedListPaneItem={(
                    selectedListItemIds: [] | string[],
                ) => {
                  //this.handleSelectedListPaneItem(selectedListItemIds);
                }}
                reSizer={this.state.topReSizer}
            />
          </Stack>
        </div>
      case '400': // Midterm Capacity
        return <div>
          <ProgressBar show={this.props.showProgressBar} />
          <Stack style={{ alignSelf: 'center' }}>
            <MidtermCapacityTable matrixType={this.props.selectedVpResourceBarType} height={this.getListPaneHeight()} />
          </Stack>
        </div>;
      case '500': // Project Team Capacity
        return <div>
          <ProgressBar show={this.props.showProgressBar} />
          <Stack style={{ alignSelf: 'center' }}>
            <ProjectTeamCapacityTable matrixType={this.props.selectedVpResourceBarType} height={this.getListPaneHeight()} />
          </Stack>
        </div>;

    }
  }

  private bottomListPaneView = () => {
    return (
        <div>
          <TitlePane
              headerTitle={null}
              minimize={true}
              isMinimize={this.state.isMinimize}
          />
          <ProgressBar show={this.props.showMidTermTableProgressBar} />
          <div style={{ height: (this.getListPaneHeight()), overflow: 'hidden' }}>
          <Stack horizontal styles={{root:{height:'100%'}}}>
              {this.renderBottomShortCutPane()}
              {this.getBottomListPane(this.props.selectedVpResourceBarType)}
            </Stack>
          </div>
        </div>
    );
  };

  private getPBSDocumentPaneView = () => {
    const VPPBSDocumentPane = require('./documentPane/pbsDocumentPane').default;
    let bookmarkList = [
      {
        id: 1,
        link: 'pbs-product-header',
        label: this.props.t('productHeader'),
      },
      {
        id: 2,
        link: 'pbs-product-detail',
        label: this.props.t('productDetails'),
      },
      { id: 3, link: 'dynamic-attributes', label: this.props.t('dynamicAttributes')},
      { id: 4, link: 'pbs-competency', label: this.props.t('competencies') },
      this.props.pbsFormData.pbsProductItemTypeId === INTERNAL_PRODUCT_ID
        ? { id: 5, link: 'pbs-resources', label: this.props.t('resources') }
        : { id: 5, link: 'pbs-resources', label: this.props.t('service') },
      { id: 6, link: 'pbs-instructions', label: this.props.t('instructions') },
      { id: 7, link: 'pbs-risks', label: this.props.t('risks') },
      { id: 8, link: 'pbs-quality', label: this.props.t('quality') },
      {
        id: 9,
        link: 'history',
        label: this.props.t('history'),
      },
    ];
    return (
      <div style={{ width: '100%' }}>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          showProgress={this.props.showPBSProgressbar}
          handleScroll={this.props.handleScroll}
          isEdit={this.props.isPBSEdit}
          formData={this.props.pbsFormData}
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => (
            <CommandBarRight
              disable={true}
              type={'pbs'}
              vpProjectSelectedShortcutpaneType={
                this.props.vpProjectSelectedShortcutpaneType[0]
              }
              documentPaneType={VPPaneType.TOP}
            />
          )}
          type={VPPaneType.TOP}
        >
          <div
            style={{
              height: this.state.horizontalReSizer - 90,
              //backgroundColor:'#0f561a',
              overflow: 'overlay',
              width: '100%',
            }}
          >
            <VPPBSDocumentPane />
          </div>
        </DocumentPaneSectionVp>
      </div>
    );
  };

  private getCPCDocumentPaneView = (isFirst?: boolean) => {
    // const CPCDocumentPane = require('../productCatalogue/documentPane/container').default;
    let bookmarkList = [
      {
        id: 1,
        link: 'corporate-product-header',
        label: this.props.t('corporateProductHeader'),
      },
      { id: 2, link: 'vendor', label: this.props.t('vendor') },
      {
        id: 3,
        link: 'history',
        label: this.props.t('history'),
      },
    ];
    return (
      <div>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBottomBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={this.props.isEdit}
          formData={this.state.formData}
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => <CommandBarRight type={''} disable={false} />}
          type={VPPaneType.BOTTOM}
        >
          <div
            style={{
              height: isFirst
                ? this.state.horizontalReSizer - 90
                : `${this.getListPaneHeight() - 100}px`,
              //backgroundColor:'#0f561a',
              overflow: 'overlay',
              width: '100%',
            }}
          >
            {/*document pane*/}
            <CpcDocumentPane cpcId={this.props.selectedTool} />
          </div>
        </DocumentPaneSectionVp>
      </div>
      // </div>
    );
  };

  private getCUHRDocumentPaneView = (isFirst?: boolean) => {
    const CUHRDocumentPane =
      require('../humanResource/documentPane/container').default;
    let bookmarkList = [
      {
        id: 1,
        link: 'labour',
        label: this.props.t('labour'),
      },
      {
        id: 2,
        link: 'competencies',
        label: this.props.t('competencies'),
      },
      {
        id: 3,
        link: 'certifications',
        label: this.props.t('certifications'),
      },
      {
        id: 4,
        link: 'labourHistory',
        label: this.props.t('labourHistory'),
      },
      {
        id: 5,
        link: 'workSchedule',
        label: this.props.t('workSchedule'),
      },
      {
        id: 6,
        link: 'history',
        label: this.props.t('history'),
      },
    ];
    return (
      <div>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBottomBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={this.props.isEdit}
          formData={this.props.cuhrFormData ? this.props.cuhrFormData : {}}
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => <CommandBarRight type={''} disable={false} />}
          type={VPPaneType.BOTTOM}
        >
          <div
            style={{
              height: isFirst
                ? this.state.horizontalReSizer - 90
                : `${this.getListPaneHeight() - 100}px`,
              //backgroundColor:'#0f561a',
              overflow: 'overlay',
              width: '100%',
            }}
          >
            <CUHRDocumentPane
              formData={this.props.cuhrFormData}
              vpDropDowns={[]}
              isExpand={false}
              isNewVP={false}
              isLoadingVisualPlan={false}
            />
          </div>
        </DocumentPaneSectionVp>
      </div>
    );
  };

  private getBORDocumentPaneView = () => {
    const VPBORDocumentPane = require('./documentPane/borDocumentPane').default;
    let bookmarkList = [
      { id: 1, link: 'bor-detail', label: this.props.t('bORHeader') },
      { id: 2, link: 'bor-product', label: this.props.t('product') },
      { id: 3, link: 'bor-material', label: this.props.t('material') },
      { id: 4, link: 'bor-tools', label: this.props.t('tools') },
      { id: 5, link: 'bor-consumables', label: this.props.t('consumables') },
      { id: 6, link: 'bor-labour', label: this.props.t('labour') },
      {
        id: 7,
        link: 'history',
        label: this.props.t('history'),
      },
    ];
    return (
      <div>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          showProgress={this.props.showBORProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={this.props.isBorEdit}
          formData={{
            ...this.props?.borFormData,
            title: this.props?.borFormData?.headerTitle,
          }}
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => (
            <CommandBarRight disable={false} type={'bor'} />
          )}
          type={VPPaneType.TOP}
        >
          <div
            style={{
              height: this.state.horizontalReSizer - 90,
              //backgroundColor:'#0f561a',
              overflow: 'overlay',
              width: '100%',
            }}
          >
            <VPBORDocumentPane />
          </div>
        </DocumentPaneSectionVp>
      </div>
    );
  };

  private getPMOLDocumentPaneView = (isFirst?: boolean) => {
    const VPPMOLDocumentPane =
      require('./documentPane/pmolDocumentPane').default;
    let bookmarkList = [
      {
        id: 1,
        link: 'pbs-product-header',
        label: this.props.t('productHeader'),
      },
      {
        id: 2,
        link: 'pbs-product-detail',
        label: this.props.t('productDetails'),
      },
      { id: 3, link: 'pbs-competency', label: this.props.t('competencies') },
      { id: 4, link: 'pbs-resources', label: this.props.t('resources') },
      { id: 5, link: 'pbs-instructions', label: this.props.t('instructions') },
      { id: 6, link: 'pbs-risks', label: this.props.t('risks') },
      { id: 7, link: 'pbs-quality', label: this.props.t('quality') },
      {
        id: 8,
        link: 'history',
        label: this.props.t('history'),
      },
    ];
    return (
      <div>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          showProgress={this.props.showPMOLProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={this.props.isPmolEdit}
          formData={{
            ...this.props?.pmolFormData,
            title: this.props?.pmolFormData?.headerTitle,
          }}
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => (
            <CommandBarRight type={'pmol'} disable={false} />
          )}
          type={VPPaneType.TOP}
          isPMOL={true}
        >
          <div
            style={{
              height: isFirst
                ? this.state.horizontalReSizer - 90
                : `${screen.height}px`,
              //backgroundColor:'#0f561a',
              overflow: 'overlay',
              width: '100%',
            }}
          >
            <VPPMOLDocumentPane
              horizontalReSizer={this.state.horizontalReSizer}
            />
          </div>
        </DocumentPaneSectionVp>
      </div>
    );
  };

  private getMSDocumentPaneView = (isFirst?: boolean) => {
    const MSDocumentPane =
      require('../mileStones/documentPane/container').default;
    let bookmarkList = [
      {
        id: 1,
        link: 'milestones-header',
        label: this.props.t('milestonesHeader'),
      },
      {
        id: 2,
        link: 'milestones-taxonomy',
        label: this.props.t('milestonesTaxonomy'),
      },
      {
        id: 3,
        link: 'involved-parties',
        label: this.props.t('involvedParties'),
      },
      // { id: 4, link: 'delivery-details', label: this.props.t('deliveryDetails'), },
      { id: 4, link: 'finance', label: this.props.t('finance') },
      { id: 5, link: 'comments', label: this.props.t('comments') },
      { id: 6, link: 'documents', label: this.props.t('documents') },
      { id: 7, link: 'history', label: this.props.t('history') },
    ];
    return (
      <div>
        <DocumentPaneSection
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={this.props.isMSEdit}
          formData={
            this.props.milestoneFormDate ? this.props.milestoneFormDate : {}
          }
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => (
            <CommandBarRight type={'milestone'} disable={false} />
          )}
          type={VPPaneType.TOP}
        >
          <div
            style={{
              height: isFirst
                ? this.state.horizontalReSizer - 45
                : `${screen.height}px`,
              //backgroundColor:'#0f561a',
              overflow: 'overlay',
              width: '100%',
            }}
          >
            <MSDocumentPane
              formData={this.props.milestoneFormDate}
              vpDropDowns={[]}
              isExpand={false}
              isNewVP={false}
              isLoadingVisualPlan={false}
            />
          </div>
        </DocumentPaneSection>
      </div>
    );
  };

  private getProjectDocumentPaneView = (isFirst?:boolean) => {
    const ProjectDocumentPane = require('../project/documentPane/container').default
    let bookmarkList = [
      { id: 1, link: 'header', label: this.props.t('projectDefinition'), },
      { id: 2, link: 'project-classification', label: this.props.t('projectClassification'), },
      { id: 3, link: 'project-address', label: this.props.t('address') },
      { id: 4, link: 'project-team', label: this.props.t('team') },
      { id: 5, link: 'project-finance', label: this.props.t('finance') },
      { id: 6, link: 'project-time', label: this.props.t('Date') },
      { id: 7, link: 'history', label: this.props.t('history') },
    ];

    return (
      <div>
        <DocumentPaneSection
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={true}
          formData={this.props.projectFormData ?? {}}
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => <CommandBarRight type={'project'} disable={false}/>}
          type={VPPaneType.TOP}
        >
          <div style={{height:isFirst?this.state.horizontalReSizer-45:`${(screen.height)}px`,
            //backgroundColor:'#0f561a',
            overflow:'overlay',width:'100%'}}>
            <ProjectDocumentPane
              formData={this.props.milestoneFormDate}
              vpDropDowns={[]}
              isExpand={false}
              isNewVP={false}
              isLoadingVisualPlan={false}
            />
          </div>
        </DocumentPaneSection>

      </div>
    );
  };

  private getPRDocumentPaneView = (isFirst?: boolean) => {
    const PRDocumentPane =
      require('../purchaseOrder/documentPane/container').default;
    let bookmarkList = [
      { id: 1, link: 'po-header', label: this.props.t('purchaseOrderHeader') },
      {
        id: 2,
        link: 'po-delivery-details',
        label: this.props.t('deliveryDetails'),
      },
      {
        id: 3,
        link: 'po-involved-parties',
        label: this.props.t('involvedParties'),
      },
      { id: 4, link: 'po-resources', label: this.props.t('resources') },
      { id: 5, link: 'history', label: this.props.t('history') },
    ];
    return (
      <div style={{ height: '100%', overflow: 'auto', position: 'relative' }}>
        <DocumentPaneSection
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={true}
          formData={
            this.props.purchaseOrderFormData
              ? this.props.purchaseOrderFormData
              : {}
          }
          bookmarkList={bookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => (
            <CommandBarRight type={'pr'} disable={false} />
          )}
          type={VPPaneType.TOP}
        >
          <div
            style={{
              height: isFirst
                ? this.state.horizontalReSizer - 45
                : `${screen.height}px`,
              //backgroundColor:'#0f561a',
              overflow: 'overlay',
              width: '100%',
            }}
          >
            <PRDocumentPane
              formData={this.props.purchaseOrderFormData}
              vpDropDowns={[]}
              isExpand={false}
              isNewVP={false}
              isLoadingVisualPlan={false}
            />
          </div>
        </DocumentPaneSection>
      </div>
    );
  };

  handelPOShortCutPaneFilter = (selectedItem: VPShortCutPaneItem) => {
    if (
      selectedItem.value.toString() !== '4' &&
      selectedItem.value.toString() !== '5' &&
      selectedItem.value.toString() !== '6' &&
      selectedItem.value.toString() !== '7' &&
      selectedItem.value.toString() !== '8' &&
      selectedItem.value.toString() !== '9' &&
      selectedItem.value.toString() !== '10' &&
      selectedItem.value.toString() !== '11' &&
      selectedItem.value.toString() !== '12'
    ) {
      this.props
        .readProjectVPData({
          type: [selectedItem.value.toString()],
          startDate: null,
          endDate: null,
          // startDate: (selectedItem.value.toString() === '0') ? null : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
          // endDate: (selectedItem.value.toString() === '0') ? null : moment().endOf('month').format('YYYY-MM-DD hh:mm')
        })
        .then(() => {
          this.setGanttChartHeight();
        });
    }

    if (selectedItem.value.toString() === '6') {
      this.props
        .readProjectVPData({
          type: ['4'],
          startDate: formatDateTimeToISOString(
            getSelectedFilterDates(['2'])[0]
          ),
          endDate: formatDateTimeToISOString(getSelectedFilterDates(['2'])[1]),
        })
        .then(() => {
          this.setGanttChartHeight();
        });
    }

    if (selectedItem.value.toString() === '7') {
      this.props
        .readVPPBSUtilityTaxonomyData({
          type: ['7'],
          startDate: null,
          endDate: null,
        })
        .then(() => {
          this.setGanttChartHeight();
        });
    }

    if (selectedItem.value.toString() === '8') {
      this.props
        .readVPPBSLocationTaxonomyData({
          type: ['8'],
          startDate: null,
          endDate: null,
        })
        .then(() => {
          this.setGanttChartHeight();
        });
    }
  };

  handelShortCutPaneFilter = (selectedItem: VPShortCutPaneItem) => {};

  getVPPRojectDocumentPane = () => {
    switch (this.props.selectedVpProjectBarType) {
      case 'pbs':
        return this.getPBSDocumentPaneView();
      case 'sub-pbs':
        return this.getPBSDocumentPaneView();
      case 'bor':
        return this.getBORDocumentPaneView();
      case 'pmol':
        return this.getPMOLDocumentPaneView(true);
      case 'milestone':
        return this.getMSDocumentPaneView(true);
      case 'pr':
        return this.getPRDocumentPaneView(true);
      case 'project':
        return this.getProjectDocumentPaneView(true);
      default:
        return null;
    }
  };

  getVPProjectListPane = () => {
    switch (this.props.vpProjectSelectedShortcutpaneType[0]) {
      case '0':
        if (this.props.selectedProjectItemType.toString() === '9') {
          return this.getMachinetaxonomyListPaneView(true);
        } else {
          return this.getListPaneView(true);    // long term planning Week Plan List Pane
        }
      case '1':
        return isPPOpenFromPlanboard()
          ? this.getListPaneView(true)
          : this.getProductListPaneView(true);    // long term planning Product Taxonomy List Pane & Midterm planning List Pane
      case '3':
        if (
          this.props.selectedProjectItemType.toString() === '10' ||
          this.props.selectedProjectItemType.toString() === '11'
        ) {
          return this.getPurchaseAndDeliveryListPaneView(true);
        } else if (this.props.selectedProjectItemType.toString() === '12') {
          return this.getPurchaseAndDeliveryPRListPaneView(true);
        } else {
          return this.getPurchaseAndDeliveryListPaneView(true);
        }

      case '2':
        return this.getMileStoneListPaneView(true);
    }
  };

  getListPaneHeight = (): number => {
    if (screen.width <= 1920) {
      return screen.height - (this.state.horizontalReSizer + 285);
    } else if (screen.width > 1920 && screen.width <= 2048) {
      return screen.height - (this.state.horizontalReSizer + 150);
    } else if (screen.width > 2048) {
      return screen.height - (this.state.horizontalReSizer + 200);
    } else {
      return screen.height - (this.state.horizontalReSizer + 285);
    }
  };

  setGanttChartHeight = () => {
    // const nodeContents:any = Array.from(document.getElementsByClassName('gantt-module_horizontalContainer_1Ns26'));
    // const shortCutnodeContents:any = Array.from(document.getElementsByClassName('filter-panel-left'));
    // if(nodeContents && nodeContents[0]){
    //   nodeContents[0].style.setProperty('height', `${this.state.horizontalReSizer-30}px`, 'important');
    // }
    // if(nodeContents && nodeContents[1]){
    //   nodeContents[1].style.setProperty('height', `${this.state.horizontalReSizer-30}px`, 'important');
    // }
    // if(nodeContents && nodeContents[2]){
    //   nodeContents[2].style.setProperty('height', `${screen.height-(this.state.horizontalReSizer-30)}px`, 'important');
    // }
    // if(nodeContents && nodeContents[3]){
    //   nodeContents[3].style.setProperty('height', `${screen.height-(this.state.horizontalReSizer-30)}px`, 'important');
    // }
    // if(shortCutnodeContents && shortCutnodeContents[0]){
    //     shortCutnodeContents[0].style.setProperty('min-height', `${this.state.horizontalReSizer}px`, 'important');
    // }
    // nodeContents.forEach((element:any) => {
    // });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        {/* <span>{this.props.selectedVpProjectBarType}</span> */}
        <SplitPane
          defaultSize={screen.height - 340}
          onChange={(size: any) => {
            this.setState({ horizontalReSizer: size });
            // console.log('size', size);
          }}
          maxSize={screen.height - 310}
          minSize={40}
          style={{ position: 'inherit' }}
          split="horizontal"
          size={this.state.horizontalReSizer}
        >
          <div style={{ width: '100%', height: '100%' }} className={'ms-Grid'}>
            <SplitPane
              minSize={screen.width / 3}
              maxSize={(screen.width / 3) * 2}
              defaultSize={
                this.props.showvpProjectDocumentPane
                  ? screen.width / 2
                  : screen.width
              }
              split="vertical"
              onChange={(size: any) => {
                this.setState({ topReSizer: size });
              }}
              size={this.state.topReSizer}
            >
              <Pane
                style={{
                  width: '100%',
                  height: `${this.state.horizontalReSizer - 10}px`,
                }}
              >
                {this.getVPProjectListPane()}
              </Pane>

              <Pane style={{ width: '100%', height: '100%' }}>
                {' '}
                {this.getVPPRojectDocumentPane()}
              </Pane>
            </SplitPane>
            <div></div>
          </div>
          <div style={{ width: '100%', height: '100%' }} className={'ms-Grid'}>
            <div id={'bottomPane'} style={{ height: this.getListPaneHeight() }}>
              {this.bottomListPaneView()}
              {/*<SplitPane*/}
              {/*  minSize={screen.width / 3}*/}
              {/*  maxSize={(screen.width / 3) * 2}*/}
              {/*  defaultSize={*/}
              {/*    this.props.showToolsOrLabourDocumentPane*/}
              {/*      ? screen.width / 2*/}
              {/*      : screen.width*/}
              {/*  }*/}
              {/*  split="vertical"*/}
              {/*  onChange={(size: any) => {*/}
              {/*    this.setState({ bottomReSizer: size });*/}
              {/*  }}*/}
              {/*  size={this.state.bottomReSizer}*/}
              {/*>*/}
              {/*  <Pane style={{ width: '100%', height: '100%' }}>*/}
              {/*    {this.bottomListPaneView()}*/}
              {/*  </Pane>*/}
              {/*  <Pane style={{ width: '100%', height: '100%' }}>*/}
              {/*    {' '}*/}
              {/*    {this.props.showToolsOrLabourDocumentPane*/}
              {/*      ? this.props.selectedResourceTypeOrg ===*/}
              {/*        RESOURCE_TYPE_IDS.TOOLS*/}
              {/*        ? this.getCPCDocumentPaneView()*/}
              {/*        : this.getCUHRDocumentPaneView()*/}
              {/*      : null}*/}
              {/*  </Pane>*/}
              {/*</SplitPane>*/}
            </div>
          </div>
        </SplitPane>

        <Panel
          isOpen={this.props.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('corporateProductCatalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getVPPRojectDocumentPane()}
        </Panel>

        <ConfirmationDialog
          hidden={true}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={() => {}}
          onClickCancelDialog={() => {}}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    history.push(`/projects/project/${this.state.projectId}/product/pbs`);
  };

  private hideDocumentPane = (type: number) => {
    if (type === 1) {
      this.props.saveVPStateAttr('showvpProjectDocumentPane', false);
      this.setState({ topReSizer: screen.width });
    } else if (type === 2) {
      this.setState({ bottomReSizer: screen.width });
      this.props.saveVPStateAttr('showToolsOrLabourDocumentPane', false);
    }
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortcutPaneData: state.vp?.vpShortCutPaneData,
    isVPListLoaded: state.vp.isVPListLoaded,
    vpList: state.vp.vpList,
    vpDropDowns: state.vp.vpDropDowns,
    reloadListPane: state.vp.reloadListPane,
    formData: state.po.formData,
    purchaseOrderDropDowns: state.po.purchaseOrderDropDowns,
    vpOrgTaxonomyList: state.vp.vpOrgTaxonomyList,
    reloadRequired: state.vp.reloadRequired,
    poSelectedResources: state.vp.poSelectedResources,
    showToolsOrLabourDocumentPane: state.vp.showToolsOrLabourDocumentPane,
    showPODocumentPane: state.vp.showPODocumentPane,
    selectedResourceTypeOrg: state.vp.selectedResourceTypeOrgProject,
    cuhrFormData: state.vp.cuhrFormData,
    selectedLabour: state.vp.selectedLabour,
    selectedTool: state.vp.selectedTool,
    productCatalogue: state.productCatalogue.productCatalogue,
    selectedPO: state.vp.selectedPO,
    poSelectedTypes: state.vp.poSelectedTypes,
    poFilterToDate: state.vp.poFilterToDate,
    poFilterFromDate: state.vp.poFilterFromDate,
    vpProjectList: state.vp.vpProjectList,
    milestoneShortCut: state.vp.milestoneShortCut,
    vpProjectSelectedShortcutpaneType:
      state.vp.vpProjectSelectedShortcutpaneType,
    showvpProjectDocumentPane: state.vp.showvpProjectDocumentPane,
    selectedVpProjectBarType: state.vp.selectedVpProjectBarType,
    selectedVpResourceBarType: state.vp.selectedVpResourceBarType,
    selectedPBS: state.vp.selectedPBS,
    selectedPMOL: state.vp.selectedPMOL,
    selectedBOR: state.vp.selectedBOR,
    selectedMileStone: state.vp.selectedMileStone,
    msDropDowns: state.ms.msDropDowns,
    isMSEdit: state.ms.isEdit,
    isPmolEdit: state.pmol.isEdit,
    isBorEdit: state.billOfResource.isEdit,
    isPBSEdit: state.projectBreakdown.isEdit,
    projectLevelReloadRequired: state.vp.projectLevelReloadRequired,
    milestoneFormDate: state.ms.formData,
    selectedStartDateProjectItem: state.vp.selectedStartDateProjectItem,
    selectedEndDateProjectItem: state.vp.selectedEndDateProjectItem,
    selectedProjectItemType: state.vp.selectedProjectItemType,
    pbsFormData: state.projectBreakdown.formData,
    borFormData: state.billOfResource.formData,
    pmolFormData: state.pmol.formData,
    vpProducTaxonomyList: state.vp.vpProducTaxonomyList,
    showPBSProgressbar: state.projectBreakdown.showProgressBar,
    showBORProgressBar: state.billOfResource.showProgressBar,
    showPMOLProgressBar: state.pmol.showProgressBar,
    showMidTermTableProgressBar: state.vp.showProgressBar,
    purchaseOrderFormData: state.po.formData,
    selectedProject: state.vp.selectedProject,
    projectFormData: state.project.selectedProject,
  };
};

const mapDispatchToProps = {
  readVisualPlanShortCutPaneData,
  filterVisualPlanListPaneData,
  readVisualPlanDropDownData,
  readByVisualPlanId,
  clearFormData,
  readPurchaseOrderDropDownData,
  readMileStonesDropDownData,
  readByPurchaseOrderId,
    readVPOrganizationTaxanomyData,
  readMTPOrganizationTaxanomyData,
  getDropdownData,
  readVPWareHouseTaxanomyData,
  saveVPStateAttr,
  getProductCatalogueById,
  readCUHRbyPersonId,
  readProjectVPData,
  readVPProjectResources,
  readMilestoneShortCutPaneData,
  getById,
  readPMOLByID,
  borGetById,
  filterMileStonesListPaneData,
  readByMileStonesId,
  clearPMOLData,
  resetBorFormDate,
  clearMStoneData,
  readProjectVPPMOLPersonData,
  readVPPBSUtilityTaxonomyData,
  readVPPBSLocationTaxonomyData,
  savePMOLStateAttr,
  saveBORStateAttr,
  readDropdownData,
  readVPPBSTaxonomyData,
  getProjectDefinition,
  readCpcResourceFamilyforVp,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withMainUI(CuVisualPlanMainLayout)))
);
