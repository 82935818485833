import * as React from 'react';
import { Button } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import DefaultCustomModal from '../../../../shared/customeModal/defaultCustomModal';
import { Field, Form, FormSpy } from 'react-final-form';
import { DropDownAdaptater, TextFieldFF } from '../../../../shared/fluentFinalForm';
import { OnBlur, OnFocus } from 'react-final-form-listeners';
import { UPLOAD_URL } from '../../../../types/contract';
import { v4 as uuid4 } from 'uuid';
import { saveInstructionStateAttr, uploadInstructionDoc } from '../../../../reducers/instructionRegisterReducer';
import { FontWeights, getTheme, IIconProps, mergeStyleSets, PrimaryButton } from '@fluentui/react';
import { getFileType } from '../../../../shared/util';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { messageService } from '../../../../services/messageService';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end'
    }
  ],
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark }
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles
};
const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles
};

const AddDocument = (props:{onChange:any}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const ref: any = useRef(null);
  const inputFile: any = useRef(null);
  const classNames = mergeStyleSets({
    fullWidth: { width: '100%',marginTop:20 },
    halfWidth: { width: '49%' },
  });

  const { uploadedInstructionLink, showProgressBar }: any = useSelector(
    (state: any) => state.ir
  );

  const onSubmit = async (values: any) => {
    console.log({values});
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    if (!values.id) {
      values.id=uuid4();
    }
    values.value=values.link;
    props.onChange(values);
    setIsOpen(false)
  };

  const onRenderFooter=()=>{

  }

  useEffect(() => {
    if (uploadedInstructionLink && ref?.current) {
      ref?.current.mutators.setDocumentLink(uploadedInstructionLink);
      dispatch(saveInstructionStateAttr('uploadedInstructionLink', null));
    }
  }, [uploadedInstructionLink]);

  const getInitialValues = () => {
      return {
        id: null,
        title: null,
        // type: null,
        link: null
      };
  };

 return (
  <>
    <DefaultCustomModal
      onRenderFooter={onRenderFooter}
      isModalOpen={isOpen}
      title={t('document')}
      handleClose={() => setIsOpen(false)}>
      <>
        <div style={{width:"100%"}}>
          <Form
            //form={formRef.current}
            onSubmit={onSubmit}
            initialValues={getInitialValues()}
            mutators={{
              // setTypeName: (args, state, utils) => {
              //     const field = state.fields.typeName;
              //     field.change(args[0]);
              // },
              setDocumentLink: (args, state, utils) => {
                const field = state.fields.link;
                field.change(args[0]);
              },
              setDocumentType: (args, state, utils) => {
                const field = state.fields.type;
                field.change(args[0]);
              }
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values?.link) {
                errors.link = t('linkRequired');
              }
              return errors;
            }}

            render={({ handleSubmit, form, submitting, pristine, values }) => {

              ref.current = form;
              return (<div>
                  <FormSpy

                    onChange={(spyProps: any) => { }}
                  />

                  <div style={{display:"flex",flexDirection:"column",minWidth:"30vw",padding:10}}>
                    <div style={{display:"flex",gap:5}}>
                      {/*<div className={`${classNames.halfWidth}`}>*/}
                      {/*  <Field*/}
                      {/*    name="title"*/}
                      {/*    component={TextFieldFF}*/}
                      {/*    lable={t('title')}*/}
                      {/*    required={true}*/}
                      {/*  />*/}
                      {/*</div>*/}
                      {/*<div className={`${classNames.halfWidth}`}>*/}
                      {/*  <Field*/}
                      {/*    name="type"*/}
                      {/*    component={DropDownAdaptater}*/}
                      {/*    options={[*/}
                      {/*      { key: 'PDF', text: 'PDF' },*/}
                      {/*      { key: 'FileImage', text: 'Image' },*/}
                      {/*      { key: 'Website', text: 'URL' },*/}
                      {/*      { key: 'WordDocument', text: 'Word Document' },*/}
                      {/*      { key: 'ExcelDocument', text: 'Excel Document' },*/}
                      {/*      { key: 'PowerPointDocument', text: 'Power Point Document' },*/}
                      {/*    ]}*/}
                      {/*    lable={t('type')}*/}
                      {/*    placeholder={t('type')}*/}
                      {/*    required={true}*/}
                      {/*    disabled={true}*/}
                      {/*  />*/}
                      {/*</div>*/}
                    </div>
                    <div style={{display:"flex"}}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="link"
                          component={TextFieldFF}
                          lable={t('link')}
                          disabled={false}
                          placeholder={t('copyURLhere')}
                          required={true}
                        />
                        <OnFocus name="link">
                          {() => {
                            const sub = values?.link?.substring(0, 30)
                            if (sub && sub !== UPLOAD_URL) {
                              form.mutators.setDocumentType(getFileType(''));
                            }
                          }}
                        </OnFocus>
                        <OnBlur name="link">
                          {() => {
                            const sub = values?.link?.substring(0, 30)
                            if (sub && sub !== UPLOAD_URL) {
                              form.mutators.setDocumentType(getFileType(''));
                            }
                          }}
                        </OnBlur>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    hidden
                    onChange={(event: any) => {
                      const formData = new FormData();
                      if (event?.target?.files[0]) {
                        formData.append('file', event.target.files[0]);
                        formData.append('id', uuid4());
                        dispatch(uploadInstructionDoc(formData));
                        // console.log("TARGET", event.target.files[0].type);
                        // form.mutators.setDocumentType(getFileType(event.target.files[0].type));

                      }
                    }}
                  />
                  <PrimaryButton
                    iconProps={uploadIconWhite}
                    text={t('upload')}
                    disabled={false}
                    style={{ marginTop: 32, marginLeft: 10 }}
                    onClick={() => {
                      inputFile?.current?.click();
                    }}
                  />

                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      iconProps={addIconWhite}
                      text={t('save')}
                      disabled={submitting || pristine}
                      style={{ marginTop: 38, marginBottom: 10 }}
                      onClick={() => {

                        form.submit();
                      }}
                    />
                  </div>
                  {/*<div style={{width:500,height:600,overflow:'auto'}}>*/}
                  {/*  <pre>  {JSON.stringify(values,null,2)}</pre>*/}
                  {/*</div>*/}
                </div>
              )
            }}
          />
        </div>
      </>
    </DefaultCustomModal>
    <Button onClick={() => {
      messageService.sendMessage({ saveWbsTask: true });
      setIsOpen(true);
    }} size='small'>{t('document')}</Button>
  </>
 );

};

export default AddDocument
