import * as React from 'react';
import {
  css, PrimaryButton,
  Calendar,
  FocusTrapZone,
  Callout,
  DirectionalHint,
  defaultCalendarStrings,
  DefaultButton, FontIcon,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedGridType } from '../../reducers/dayPlanningReducer';
import { SelectedGridType } from '../../types/dayPlanning';
import { GridView } from '../../types/myDayPlanning';
import { messageService } from '../../services/messageService';
import { useBoolean } from '@fluentui/react-hooks';
import moment from 'moment';
import { saveMyDayPlanningStateAttr } from '../../reducers/myDayPlanningReducer';

export interface ICustomTooolbarProps {
  view: string;
  views: string[];
  label: any;
  localizer: any;
  onNavigate: (action: any) => void;
  onView: (view: any) => void;
  onViewChange: (view: any) => void;
  messages: any;
}

export const navigateContants = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

export const views = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
};

const CustomToolbar = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const [selectedDate, setSelectedDate]:any = React.useState(props?.selectedDate?props?.selectedDate:new Date());
  const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] = useBoolean(false);

  const selectedGrid: SelectedGridType = useSelector(
    (state: any) => state.dplan.selectedGridType,
  );

  const onSelectDate = React.useCallback(
    (date: Date, dateRangeArray?: Date[]): void => {
      console.log({date});
      setSelectedDate(date);
      // navigate.bind(null, date)
      hideCalendar();
      if(props?.onSelectDateFromToolBar){
        props?.onSelectDateFromToolBar(date);
      }

    },
    [hideCalendar],
  );

  function navigate(action: any) {
    props.onNavigate(action);
  }

  function viewItem(view: any) {
    dispatch(setSelectedGridType(view));
    props.onView(view);
  }

  function viewNamesGroup(messages: any) {
    const viewNames = props.views;
    const view = props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name: any) => (
        <button
          type="button"
          key={name}
          className={css({ 'rbc-active': view === name })}
          onClick={viewItem.bind(null, name)}>
          {t(name)}
        </button>
      ));
    }
  }

  {/*{messages[name]}*/
  }
  return (
    <div className="rbc-toolbar">



      <span className="rbc-toolbar-label">
         {props?.view != GridView.WEEK ? <span className="rbc-btn-group">
           {props?.selectedDate ? <>
             <div className={'ms-style'} style={{display:"flex"}}>
               <button type="button" onClick={navigate.bind(null, navigateContants.PREVIOUS)}>
                 <FontIcon aria-label="Compass" iconName="ChevronLeftSmall" />
               </button>

               <button type="button" onClick={navigate.bind(null, navigateContants.NEXT)}>
                 <FontIcon aria-label="Compass" iconName="ChevronRightSmall" />
               </button>
               {/*<button type="button" onClick={navigate.bind(null, navigateContants.TODAY)}>*/}
               {/* {props.label}*/}
               {/* </button>*/}
               <div ref={buttonContainerRef}>
                 {selectedDate && <DefaultButton
                   onClick={toggleShowCalendar}
                   text={`${moment(selectedDate).format('dddd MMM DD')}`}
                 />}
               </div>
             </div>
           </> :<>
             <button type="button" onClick={navigate.bind(null, navigateContants.PREVIOUS)}>
               {/*<FontIcon aria-label="Compass" iconName="ChevronLeftSmall" />*/}
               {`<`}
             </button>
             <button type="button" onClick={navigate.bind(null, navigateContants.TODAY)}>
               {props.label}
             </button>
             <button type="button" onClick={navigate.bind(null, navigateContants.NEXT)}>
               {/*<FontIcon aria-label="Compass" iconName="ChevronRightSmall" />*/}
               {`>`}
             </button>
           </>}
            </span>:props.label}
            <span style={{float:'right'}}>
            {props?.showAllButton && <PrimaryButton
              text={t('showAll')}
              onClick={() => {
                console.log("showAllInScreen")
                messageService.sendMessage({ showAllInScreen: true });
              }}
            />}
          </span>
      </span>
      <span style={{ paddingRight: selectedGrid === SelectedGridType.WEEK ? 0 : 20 }}
            className="rbc-btn-group">{viewNamesGroup(props.messages)}</span>


      {showCalendar && (
        <Callout
          isBeakVisible={false}
          gapSpace={0}
          doNotLayer={false}
          target={buttonContainerRef}
          directionalHint={DirectionalHint.bottomLeftEdge}
          onDismiss={hideCalendar}
          setInitialFocus
        >
          <FocusTrapZone isClickableOutsideFocusTrap>
            <Calendar
              onSelectDate={onSelectDate}
              onDismiss={hideCalendar}
              isMonthPickerVisible
              value={selectedDate ? new Date(selectedDate) : undefined}
              highlightCurrentMonth
              isDayPickerVisible
              showGoToToday
              // Calendar uses English strings by default. For localized apps, you must override this prop.
              strings={defaultCalendarStrings}
            />
          </FocusTrapZone>
        </Callout>
      )}
    </div>
  );
};

export default CustomToolbar;
