export const SAVE_WBS_CREATE_STATE_ATTR = 'uprice/SAVE_WBS_CREATE_STATE_ATTR';

export const READ_WBS_CREATE_LIST_DATA = 'uprice/READ_WBS_CREATE_LIST_DATA';
export const READ_WBS_CREATE_LIST_DATA_SUCCESS = 'uprice/READ_WBS_CREATE_LIST_DATA_SUCCESS';
export const READ_WBS_CREATE_LIST_DATA_FAIL = 'uprice/READ_WBS_CREATE_LIST_DATA_FAIL';

export const GET_WBS_CREATE_LEVEL_DATA = 'uprice/GET_WBS_CREATE_LEVEL_DATA';
export const GET_WBS_CREATE_LEVEL_DATA_SUCCESS = 'uprice/GET_WBS_CREATE_LEVEL_DATA_SUCCESS';
export const GET_WBS_CREATE_LEVEL_DATA_FAIL = 'uprice/GET_WBS_CREATE_LEVEL_DATA_FAIL';

export const CREATE_WBS_NODE = 'uprice/CREATE_WBS_NODE';
export const CREATE_WBS_NODE_SUCCESS = 'uprice/CREATE_WBS_NODE_SUCCESS';
export const CREATE_WBS_NODE_FAIL = 'uprice/CREATE_WBS_NODE_FAIL';

export const READ_WBS_TAXONOMY_LIST = 'uprice/READ_WBS_TAXONOMY_LIST';
export const READ_WBS_TAXONOMY_LIST_SUCCESS = 'uprice/READ_WBS_TAXONOMY_LIST_SUCCESS';
export const READ_WBS_TAXONOMY_LIST_FAIL = 'uprice/READ_WBS_TAXONOMY_LIST_FAIL';

export const CREATE_WBS_TEMPLATE = 'uprice/CREATE_WBS_TEMPLATE';
export const CREATE_WBS_TEMPLATE_SUCCESS = 'uprice/CREATE_WBS_TEMPLATE_SUCCESS';
export const CREATE_WBS_TEMPLATE_FAIL = 'uprice/CREATE_WBS_TEMPLATE_FAIL';

export const GET_WBS_TAXONOMY_BY_TEMPLATE_ID = 'uprice/GET_WBS_TAXONOMY_BY_TEMPLATE_ID';
export const GET_WBS_TAXONOMY_BY_TEMPLATE_ID_SUCCESS = 'uprice/GET_WBS_TAXONOMY_BY_TEMPLATE_ID_SUCCESS';
export const GET_WBS_TAXONOMY_BY_TEMPLATE_ID_FAIL = 'uprice/GET_WBS_TAXONOMY_BY_TEMPLATE_ID_FAIL';

export const GET_WBS_PRODUCT_BY_ID = 'uprice/GET_WBS_PRODUCT_BY_ID';
export const GET_WBS_PRODUCT_BY_ID_SUCCESS = 'uprice/GET_WBS_PRODUCT_BY_ID_SUCCESS';
export const GET_WBS_PRODUCT_BY_ID_FAIL = 'uprice/GET_WBS_PRODUCT_BY_ID_FAIL';

export const CREATE_WBS_PRODUCT = 'uprice/CREATE_WBS_PRODUCT';
export const CREATE_WBS_PRODUCT_SUCCESS = 'uprice/CREATE_WBS_PRODUCT_SUCCESS';
export const CREATE_WBS_PRODUCT_FAIL = 'uprice/CREATE_WBS_PRODUCT_FAIL';

export const RENAME_WBS_TEMPLATE = 'uprice/RENAME_WBS_TEMPLATE';
export const RENAME_WBS_TEMPLATE_SUCCESS = 'uprice/RENAME_WBS_TEMPLATE_SUCCESS';
export const RENAME_WBS_TEMPLATE_FAIL = 'uprice/RENAME_WBS_TEMPLATE_FAIL';

export const UPDATE_WBS_TAXONOMY_PARENT_NODE = 'uprice/UPDATE_WBS_TAXONOMY_PARENT_NODE';
export const UPDATE_WBS_TAXONOMY_PARENT_NODE_SUCCESS = 'uprice/UPDATE_WBS_TAXONOMY_PARENT_NODE_SUCCESS';
export const UPDATE_WBS_TAXONOMY_PARENT_NODE_FAIL = 'uprice/UPDATE_WBS_TAXONOMY_PARENT_NODE_FAIL';
