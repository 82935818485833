import {
  ADD_MID_TERM_LABOUR_EP,
  ADD_PO_LABOUR_TEAM_EP,
  ADD_PO_TOOLS_POOL_EP,
  CREATE_PBS_CLONE_EP,
  CREATE_PR_FROM_BOR_EP,
  DELETE_MID_TERM_LABOUR_EP,
  DELETE_VP_SHIFT_RESOURCE_ITEM_EP,
  GET_ALL_PBS_LABOUR_EP,
  GET_ALL_PMOL_LABOUR_EP,
  GET_MIDTERM_CAPACITY_DATA,
  GET_PROJECT_TEAM_CAPACITY_DATA,
  GET_RESOURCE_MATRIX,
  GET_VP_FILTER_DROPDOWN_DATA_EP,
  READ_CPC_RESOURCE_FAMILY_FOR_VP_EP,
  READ_CUHR_BY_PERSON_ID_EP,
  READ_MID_TERM_ORG_TAXONOMY_EP,
  READ_MID_TERM_PLANNING_EP,
  READ_PBS_LOCATION_TAXONOMY_VP,
  READ_PBS_MACHINE_TAXONOMY_VP,
  READ_PBS_UTILITY_TAXONOMY_VP,
  READ_RESOURCE_ITEMS_VP,
  READ_VISUAL_PLAN_BY_ID_EP,
  READ_VISUAL_PLAN_DROPDOWN_EP,
  READ_VISUAL_PLAN_LIST_EP,
  READ_VISUAL_PLAN_SHORTCUT_PANE_EP,
  READ_VISUAL_PLAN_TOOLS_LIST_EP,
  READ_VP_ORG_SETTING_EP,
  READ_VP_PMOL_PERSON_DATA,
  READ_VP_PRODUCT_TAXONOMY_DATA,
  READ_VP_PROJECT_DATA,
  READ_VP_PROJECT_RESOURCE,
  READ_VP_PROJECT_SHORTCUT,
  READ_VP_PURCHASE_PR_TAXONOMY_DATA,
  READ_VP_PURCHASE_TAXONOMY_CU_DATA,
  READ_VP_PURCHASE_TAXONOMY_DATA,
  UPDATE_PBS_TAXONOMY_PARENT,
  UPDATE_PERSON_DATES_EP,
  UPDATE_PMOL_VP_LABOUR_EP,
  UPDATE_PMOL_VP_TOOL_EP,
  UPDATE_VP_ITEM_DATE,
  UPDATE_VP_PO_EP,
  UPDATE_WH_TAXONOMY_DATE,
  VISUAL_PLAN_SAVE_EP,
  VP_MY_ENV_PBS_DISPLAY_ORDER,
  VP_UPDATE_NEW_SIBLING_TREE_INDEX,
  VP_UPDATE_TREE_INDEX,
} from '../shared/endpoints';
import {
  ADD_MID_TERM_LABOUR,
  ADD_MID_TERM_LABOUR_FAIL,
  ADD_MID_TERM_LABOUR_SUCCESS,
  ADD_PO_LABOUR_TEAM,
  ADD_PO_LABOUR_TEAM_FAIL,
  ADD_PO_LABOUR_TEAM_SUCCESS,
  ADD_TOOLS_POOLS,
  ADD_TOOLS_POOLS_FAIL,
  ADD_TOOLS_POOLS_SUCCESS,
  CLEAR_VISUAL_PLAN_FORM_DATA,
  CLEAR_VISUAL_PLANUID,
  CREATE_PBS_CLONE,
  CREATE_PBS_CLONE_FAIL,
  CREATE_PBS_CLONE_SUCCESS,
  CREATE_PR_FROM_BOR,
  CREATE_PR_FROM_BOR_FAIL,
  CREATE_PR_FROM_BOR_SUCCESS,
  DELETE_MID_TERM_LABOUR,
  DELETE_MID_TERM_LABOUR_FAIL,
  DELETE_MID_TERM_LABOUR_SUCCESS,
  DELETE_VP_SHIFT_RESOURCE_ITEM,
  DELETE_VP_SHIFT_RESOURCE_ITEM_FAIL,
  DELETE_VP_SHIFT_RESOURCE_ITEM_SUCCESS,
  GET_VP_FILTER_DROPDOWN_DATA,
  GET_VP_FILTER_DROPDOWN_DATA_FAIL,
  GET_VP_FILTER_DROPDOWN_DATA_SUCCESS,
  LOAD_CPC_RESOURCE_FAMILY_FOR_VP,
  LOAD_CPC_RESOURCE_FAMILY_FOR_VP_FAIL,
  LOAD_CPC_RESOURCE_FAMILY_FOR_VP_SUCCESS,
  LOAD_VISUAL_PLAN_BY_ID,
  LOAD_VISUAL_PLAN_BY_ID_FAIL,
  LOAD_VISUAL_PLAN_BY_ID_SUCCESS,
  LOAD_VISUAL_PLAN_DROPDOWN,
  LOAD_VISUAL_PLAN_DROPDOWN_FAIL,
  LOAD_VISUAL_PLAN_DROPDOWN_SUCCESS,
  LOAD_VISUAL_PLAN_LSITPANE,
  LOAD_VISUAL_PLAN_LSITPANE_FAIL,
  LOAD_VISUAL_PLAN_LSITPANE_SUCCESS,
  LOAD_VISUAL_PLAN_SHORTCUTPANE,
  LOAD_VISUAL_PLAN_SHORTCUTPANE_FAIL,
  LOAD_VISUAL_PLAN_SHORTCUTPANE_SUCCESS,
  LOAD_VP_CUHR,
  LOAD_VP_CUHR_FAIL,
  LOAD_VP_CUHR_SUCCESS,
  LOAD_VP_ORG_SETTING_BY_ID,
  LOAD_VP_ORG_SETTING_BY_ID_FAIL,
  LOAD_VP_ORG_SETTING_BY_ID_SUCCESS,
  LOAD_WAREHOUSE_TOOLS,
  LOAD_WAREHOUSE_TOOLS_FAIL,
  LOAD_WAREHOUSE_TOOLS_SUCCESS,
  READ_ALL_PBS_LABOUR,
  READ_ALL_PBS_LABOUR_FAIL,
  READ_ALL_PBS_LABOUR_SUCCESS,
  READ_ALL_PMOL_LABOUR,
  READ_ALL_PMOL_LABOUR_FAIL,
  READ_ALL_PMOL_LABOUR_SUCCESS,
  READ_MID_TERM_ORG_TAXONOMY,
  READ_MID_TERM_ORG_TAXONOMY_FAIL,
  READ_MID_TERM_ORG_TAXONOMY_SUCCESS,
  READ_MID_TERM_PLANNING,
  READ_MID_TERM_PLANNING_FAIL,
  READ_MID_TERM_PLANNING_SUCCESS,
  READ_MIDTERM_CAPACITY_DATA,
  READ_MIDTERM_CAPACITY_DATA_FAIL,
  READ_MIDTERM_CAPACITY_DATA_SUCCESS,
  READ_MILESTONE_SHORTCUT,
  READ_MILESTONE_SHORTCUT_FAIL,
  READ_MILESTONE_SHORTCUT_SUCCESS,
  READ_PROJECT_TEAM_CAPACITY_DATA,
  READ_PROJECT_TEAM_CAPACITY_DATA_FAIL,
  READ_PROJECT_TEAM_CAPACITY_DATA_SUCCESS,
  READ_RESOURCE_MATRIX,
  READ_RESOURCE_MATRIX_FAIL,
  READ_RESOURCE_MATRIX_SUCCESS,
  READ_VP_MACHINE_TAXONOMY,
  READ_VP_MACHINE_TAXONOMY_FAIL,
  READ_VP_MACHINE_TAXONOMY_SUCCESS,
  READ_VP_PRODUCT_TAXONOMY,
  READ_VP_PRODUCT_TAXONOMY_FAIL,
  READ_VP_PRODUCT_TAXONOMY_REFRESH,
  READ_VP_PRODUCT_TAXONOMY_REFRESH_FAIL,
  READ_VP_PRODUCT_TAXONOMY_REFRESH_SUCCESS,
  READ_VP_PRODUCT_TAXONOMY_SUCCESS,
  READ_VP_PROJECT,
  READ_VP_PROJECT_FAIL,
  READ_VP_PROJECT_RESOURCE_FAIL,
  READ_VP_PROJECT_RESOURCE_SUCCESS,
  READ_VP_PROJECT_SUCCESS,
  READ_VP_PROJECT_WITHOUT_REFRESH,
  READ_VP_PROJECT_WITHOUT_REFRESH_FAIL,
  READ_VP_PROJECT_WITHOUT_REFRESH_SUCCESS,
  READ_VP_PURCHASE_CU_TAXONOMY,
  READ_VP_PURCHASE_CU_TAXONOMY_FAIL,
  READ_VP_PURCHASE_CU_TAXONOMY_SUCCESS,
  READ_VP_PURCHASE_PR_TAXONOMY,
  READ_VP_PURCHASE_PR_TAXONOMY_FAIL,
  READ_VP_PURCHASE_PR_TAXONOMY_SUCCESS,
  READ_VP_PURCHASE_TAXONOMY,
  READ_VP_PURCHASE_TAXONOMY_FAIL,
  READ_VP_PURCHASE_TAXONOMY_SUCCESS,
  RESET_VISUAL_PLAN_IS_CHANGE,
  SAVE_VISUAL_PLAN,
  SAVE_VISUAL_PLAN_FAIL,
  SAVE_VISUAL_PLAN_SUCCESS,
  SAVE_VP_STATE_ATTR,
  SET_VISUAL_PLAN_UID,
  UPDATE_PBS_PARENT,
  UPDATE_PBS_PARENT_FAIL,
  UPDATE_PBS_PARENT_SUCCESS,
  UPDATE_PERSON_DATES,
  UPDATE_PERSON_DATES_FAIL,
  UPDATE_PERSON_DATES_SUCCESS,
  UPDATE_PMOL_LABOUR_TEAM,
  UPDATE_PMOL_LABOUR_TEAM_FAIL,
  UPDATE_PMOL_LABOUR_TEAM_SUCCESS,
  UPDATE_PMOL_TOOL_TEAM,
  UPDATE_PMOL_TOOL_TEAM_FAIL,
  UPDATE_PMOL_TOOL_TEAM_SUCCESS,
  UPDATE_PROJECT_ITEMS_DATE,
  UPDATE_PROJECT_ITEMS_DATE_FAIL,
  UPDATE_PROJECT_ITEMS_DATE_SUCCESS,
  UPDATE_TREE_INDEX_OF_NEW_SIBLING,
  UPDATE_TREE_INDEX_OF_NEW_SIBLING_FAIL,
  UPDATE_TREE_INDEX_OF_NEW_SIBLING_SUCCESS,
  UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER,
  UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_FAIL,
  UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_SUCCESS,
  UPDATE_VP_PO,
  UPDATE_VP_PO_FAIL,
  UPDATE_VP_PO_SUCCESS,
  UPDATE_VP_PT_TREE_INDEX,
  UPDATE_VP_PT_TREE_INDEX_FAIL,
  UPDATE_VP_PT_TREE_INDEX_SUCCESS,
} from '../actionTypes/visualPlanActionTypes';

import i18n from '../../i18n';
import { v4 as uuidv4 } from 'uuid';
import { PurchaseOrder } from '../types/purchaseOrder';
import { getFlatDataFromTree, getTreeFromFlatData, walk } from '@nosferatu500/react-sortable-tree';
import { OrganisationSettingListPaneFilter } from '../types/organisationSetting';
import groupArray from 'group-array';
import {
  formatwithoutTime,
  getBlackShades,
  getBlueShades,
  getBrownShades,
  getCeyanShades,
  getGreenShades,
  getOrangeShades,
  getPinkShades,
  getPurpleShades,
  getRedShades,
  isCuProjectPlanning,
  isMyEnvironment,
  PRIMARY_COLOR,
  RESOURCE_TYPE_IDS,
} from '../shared/util';
import _ from 'lodash';
import moment from 'moment';
import { getBuGroups } from '../types/myDayPlanning';
import { getSelectedBuFromCuProjectPlanning, MidTermLabourAdd } from '../types/visualPlan';
import {
  formatMidTermTaxonomyData,
  formatMtpOrgTaxonomyData,
  formatMtpOrgTaxonomyListData,
  removeUnimportantData,
} from '../components/cuProjectVisualPlan/API/DataFormatting';

import data from '../shared/projectData/p0056.json';

const p0056 = data;

const defaultState: any = {
    vpList: [],
    isVPListLoaded: false,
    isNew: false,
    isChange: false,
    isEdit: false,
    showProgressBar: false,
    vpDropDowns: {},
    formData: {},
    uid: null,
    loadMsg: '',
    vpShortCutPaneData: [],
    reloadListPane: false,
    borResources: {},
    isLoadingVisualPlan: false,
    vpBarList: [],
    vpInteractBarList: [],
    vpOrgTaxonomyList: [],
    vpOrgBarList: [],
    vpInteractOrgBarList: [],
    isLabourTeamAssigned: false,
    isLabourTeamAssignedProject: false,
    isToolPoolAssigned: false,
    isToolPoolAssignedProject: false,
    selectedResourceTypeOrg: RESOURCE_TYPE_IDS.LABOURS,
    selectedResourceTypeOrgProject: RESOURCE_TYPE_IDS.LABOURS,
    whToolsListLoaded: false,
    whTaxonomyList: [],
    whBarList: [],
    whInteractBarList: [],
    selectedPOVPshortcutpaneItems: [],
    isPOUpdated: false,
    reloadRequired: false,
    poSelectedResources: [],
    poSelectedTypes: [],
    showToolsOrLabourDocumentPane: false,
    showPODocumentPane: false,
    selectedLabour: null,
    selectedTool: null,
    selectedPO: null,
    poFilterFromDate: null,
    poFilterToDate: null,
    cuhrFormData: [],
    vpProjectData: [],
    vpProjectList: [],
    vpProjectBarList: [],
    vpProjectInteractBarList: [],
    vpProjectDataLoaded: false,
    milestoneShortCut: [],

    vpProjectLabourTaxonomyList: [],
    vpProjectLabourBarList: [],
    vpProjectLabourInteractBarList: [],
    vpProjectToolsTaxonomyList: [],
    vpProjectToolsBarList: [],
    vpProjectToolsInteractBarList: [],
    vpProjectLabourDataLoaded: false,
    vpProjectSelectedShortcutpaneType: ['1'],
    vpProjectSelectedShortcutpaneId: '1',
    vpProjectSelectedShortcutpaneItems: [],
    selectedVpProjectBarType: null,
    showvpProjectDocumentPane: false,
    selectedPBS: null,
    selectedBOR: null,
    selectedPMOL: null,
    selectedMileStone: null,
    projectLevelReloadRequired: false,
    slectedPBSForBor: null,
    slectedPBSForPMOL: null,
    selectedBORforPMOL: null,
    selectedProjectItemType: 5,
    selectedStartDateProjectItem: null,
    selectedEndDateProjectItem: null,
    selectedStartDateCu: null,
    selectedEndDateCu: null,
    selectedPOResourceTypes: [],
    selectedPOProject: null,
    vpFilterDropdown: [],
    pbsTaxonomyTreeHasChanged:false,

    vpProducTaxonomyList: [],
    vpProducTaxonomyBarList: [],
    vpProducTaxonomyInteractBarList: [],
    prodTaxanomyDataLoaded: false,
    productTaxonomyReloadRequired: false,
    producttaxonomySearchText: null,
    scrollPosDate: null,
    vpPBSCloneSuccess: false,
    vpClonedPBSID: null,
    pbsTaxonomyStructureUpdated: false,
    pbsTaxonomyReloaded:false,

    vpPurchaseTaxonomyList: [],
    vpPurchaseTaxonomyBarList: [],
    vpPurchaseTaxonomyInteractBarList: [],
    purchaseTaxanomyDataLoaded: false,
    purchaseTaxonomyReloadRequired: false,
    purchaseScrollPosDate: null,

    vpPurchaseCUTaxonomyList: [],
    vpPurchaseCUTaxonomyBarList: [],
    vpPurchaseCUTaxonomyInteractBarList: [],
    purchaseCUTaxanomyDataLoaded: false,
    purchaseCUTaxonomyReloadRequired: false,
    purchaseCUScrollPosDate: null,
    vpCUSelectedShortcutpaneType:1,

    vpPurchasePRTaxonomyList: [],
    vpPurchasePRTaxonomyBarList: [],
    vpPurchasePRTaxonomyInteractBarList: [],
    purchasePRTaxanomyDataLoaded: false,
    purchasePRTaxonomyReloadRequired: false,
    purchasePRScrollPosDate: null,

    vpMachineTaxonomyList: [],
    vpMachineTaxonomyBarList: [],
    vpMachineTaxonomyInteractBarList: [],
    machineTaxanomyDataLoaded: false,
    machineTaxonomyReloadRequired: false,
    machineScrollPosDate: null,

    pbsTaxSerarchString:[''],
    expandPath:null,
    siblingIndex:0,
    prCreatefromBor:false,
    prCreatedBorId: null,
    borInheritedPBSTitle: null,
    pmolInheritedBORTitle:null,
    isPBSDirty:false,
    createdPrId:null,
    vpLTPpath:null,
    childCount:0,

    toolsTaxonomyList: [],
    newToolsListLoaded: false,
    toolsInteractBarList: [],
    expandNode: null,
    siblingCount:0,
    allPbsLaboursList: [],
    allPmolLaboursList: [],
    allPmolLaboursListLoading: false,
    personUpdatedDateVP:null,
    resourcePaths: new Map(),
    labourPersonId:null,
    selectedVpResourceBarType:'100',
    matrixData: null,
    savePPDisplayOrderSuccess: false,
    createdNewProductFromMyEnv: false,
    selectedBuInCuProjectPlanning: null,
    selectedProject: null,
    midtermCapacityData: null,
    projectTeamCapacityData: null,
    isOrgTaxonomyModalOpen: false,
    cpcNodeInfo: null,
    pbsAssignedLabourNodes: [],
    deleteMidTermLabourSuccess: false,
    deletingMidTermLabourData: null,
};

export default function visualPlanReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {
        case LOAD_VISUAL_PLAN_SHORTCUTPANE:
            return { ...state };
        case LOAD_VISUAL_PLAN_SHORTCUTPANE_SUCCESS:
            return {
                ...state,
                vpShortCutPaneData: action.payload.data.result
            };
        case LOAD_VISUAL_PLAN_SHORTCUTPANE_FAIL:
            return { ...state, vpShortCutPaneData: [] };
        case LOAD_VISUAL_PLAN_LSITPANE:
            return { ...state, isVPListLoaded: false, vpList: [], reloadRequired: false };
        case LOAD_VISUAL_PLAN_LSITPANE_SUCCESS:
            const data = formatVpResourceData(action.payload.data.result);
            return {
                ...state,
                isVPListLoaded: true,
                reloadRequired: false,
                vpList: data,
                vpBarList: [ ...formatVpResourceBarData(data)[0] ],
                vpInteractBarList: [ ...formatVpResourceBarData(data)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case LOAD_VISUAL_PLAN_LSITPANE_FAIL:
            return {
                ...state,
                isVPListLoaded: true,
                vpList: [],
                loadMsg: action.error.response.data.message
            };
        case RESET_VISUAL_PLAN_IS_CHANGE:
            return {
                ...state,
                isChange: false
            };

        case LOAD_VISUAL_PLAN_DROPDOWN:
            return { ...state, vpDropDowns: {} };
        case LOAD_VISUAL_PLAN_DROPDOWN_SUCCESS:
            return { ...state, vpDropDowns: action.payload.data.result };
        case LOAD_VISUAL_PLAN_DROPDOWN_FAIL:
            return {
                ...state,
                vpDropDowns: {}
            };
        case LOAD_VISUAL_PLAN_BY_ID:
            return { ...state, formData: {}, showProgressBar: true, isLoadingVisualPlan: true };
        case LOAD_VISUAL_PLAN_BY_ID_SUCCESS:
            return {
                ...state,
                isEdit: true,
                formData: action.payload.data.result,
                showProgressBar: false,
                isLoadingVisualPlan: false
            };
        case LOAD_VISUAL_PLAN_BY_ID_FAIL:
            return {
                ...state,
                formData: {},
                showProgressBar: false,
                isLoadingVisualPlan: false
            };

        case SAVE_VISUAL_PLAN:
            return { ...state, showProgressBar: true, reloadListPane: false };
        case SAVE_VISUAL_PLAN_SUCCESS:
            return {
                ...state,
                isChange: true,
                isNew: false,
                showProgressBar: false,
                reloadListPane: true
            };
        case SAVE_VISUAL_PLAN_FAIL:
            return {
                ...state,
                showProgressBar: false,
                reloadListPane: false
            };

        case SET_VISUAL_PLAN_UID:
            return {
                ...state,
                isEdit: false,
                uid: uuidv4()
            };
        case CLEAR_VISUAL_PLANUID:
            return {
                ...state,
                uid: null,
                isChange: false
            };
        case CLEAR_VISUAL_PLAN_FORM_DATA:
            return {
                ...state,
                uid: null,
                isNew: true,
                isChange: false,
                formData: {}
            };
        // eslint-disable-next-line no-case-declarations
        case SAVE_VP_STATE_ATTR:
            const attr = Object.keys(action)[1];
            const value = Object.values(action)[1];
            return {
                ...state,
                [attr]: value
            };
        case LOAD_VP_ORG_SETTING_BY_ID:
            return { ...state };
        // eslint-disable-next-line no-case-declarations
        case LOAD_VP_ORG_SETTING_BY_ID_SUCCESS:
            const data2 = formatVpOrgTaxonomyData(action.payload.data.result);
            return {
                ...state,
                reloadRequired: false,
                isVPOrgListLoaded: true,
                vpOrgTaxonomyList: formatVpOrgTaxonomyListData(action.payload.data.result),
                vpOrgBarList: [ ...formatVpOrgBarData(data2)[0] ],
                vpOrgInteractBarList: [ ...formatVpOrgBarData(data2)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case LOAD_VP_ORG_SETTING_BY_ID_FAIL:
            return {
                ...state,
                vpOrgTaxonomyList: []
            };
        case ADD_PO_LABOUR_TEAM:
            return { ...state, isLabourTeamAssigned: false };
        case ADD_PO_LABOUR_TEAM_SUCCESS:
            return { ...state, isLabourTeamAssigned: true, reloadRequired: true };
        case ADD_PO_LABOUR_TEAM_FAIL:

            return { ...state, isLabourTeamAssigned: false };
        case UPDATE_PMOL_TOOL_TEAM:
            return { ...state, isToolPoolAssignedProject: false };
        case UPDATE_PMOL_TOOL_TEAM_SUCCESS:
            return { ...state, isToolPoolAssignedProject: true, projectLevelReloadRequired: true };
        case UPDATE_PMOL_TOOL_TEAM_FAIL:

            return { ...state, isToolPoolAssignedProject: false };

        case LOAD_WAREHOUSE_TOOLS:
            return { ...state, whToolsListLoaded: false, whTaxonomyList: [], whInteractBarList: [], whBarList: [] };
        // eslint-disable-next-line no-case-declarations
        case LOAD_WAREHOUSE_TOOLS_SUCCESS:
            const dataTools = formatVpToolsData(action.payload.data.result);
            return {
                ...state,
                whToolsListLoaded: true,
                whTaxonomyList: formatVpWhTaxonomyListData(action.payload.data.result),
                whBarList: [ ...formatVpToolsBarData(dataTools)[0] ],
                whInteractBarList: [ ...formatVpToolsBarData(dataTools)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case LOAD_WAREHOUSE_TOOLS_FAIL:
            return { ...state, whToolsListLoaded: false };
        case UPDATE_VP_PO:
            return { ...state };
        case UPDATE_VP_PO_SUCCESS:
            return { ...state };
        case UPDATE_VP_PO_FAIL:
            return { ...state };
        case UPDATE_PERSON_DATES:
            return { ...state };
        case UPDATE_PERSON_DATES_SUCCESS:
            return { ...state, reloadRequired: false, personUpdatedDateVP: action.payload.data.result };
        case UPDATE_PERSON_DATES_FAIL:
            return { ...state };
        case ADD_TOOLS_POOLS:
            return { ...state };
        case ADD_TOOLS_POOLS_SUCCESS:
            return { ...state, reloadRequired: true };
        case ADD_TOOLS_POOLS_FAIL:
            return { ...state };
        case LOAD_VP_CUHR:
            return { ...state };
        case LOAD_VP_CUHR_SUCCESS:
            return { ...state, cuhrFormData: action.payload.data.result };
        case LOAD_VP_CUHR_FAIL:
            return { ...state };
        case READ_VP_PROJECT:
            return { ...state, vpProjectDataLoaded: false };
        case READ_VP_PROJECT_FAIL:
            return { ...state, vpProjectDataLoaded: false };
        case READ_VP_PROJECT_SUCCESS:
            const dataproject = formatVpProductTaxonomyData(action.payload.data.result);
            return {
                ...state,
                vpProjectDataLoaded: true,
                projectLevelReloadRequired: false,
                productTaxonomyReloadRequired: false,
                vpProjectList: dataproject,
                vpProjectBarList: [ ...formatVpProductBarData(dataproject)[0] ],
                vpProjectInteractBarList: [ ...formatVpProductBarData(dataproject)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };

        case READ_MILESTONE_SHORTCUT:
            return { ...state };
        case READ_MILESTONE_SHORTCUT_SUCCESS:
            return { ...state, milestoneShortCut: action.payload.data.result };
        case READ_MILESTONE_SHORTCUT_FAIL:
            return { ...state };
        case READ_VP_PROJECT_RESOURCE:
            return { ...state, vpProjectLabourDataLoaded: false };
        case READ_VP_PROJECT_RESOURCE_SUCCESS:
            const dataResLabourPro = formatVpProjectLabourData(action.payload.data.result.labourTeam);
            const dataResToolPro = formatVpProjectToolsData(action.payload.data.result.tools);
            return {
                ...state,
                vpProjectLabourTaxonomyList: dataResLabourPro,
                vpProjectLabourBarList: [ ...formatVpProjectLabourBarData(dataResLabourPro)[0] ],
                vpProjectLabourInteractBarList: [ ...formatVpProjectLabourBarData(dataResLabourPro)[1] ],
                projectLevelReloadRequired: false,

                vpProjectToolsTaxonomyList: dataResToolPro,
                vpProjectToolsBarList: [ ...formatVpProjectToolsBarData(dataResToolPro)[0] ],
                vpProjectToolsInteractBarList: [ ...formatVpProjectToolsBarData(dataResToolPro)[1] ],
                vpProjectLabourDataLoaded: true
            };
        case READ_VP_PROJECT_RESOURCE_FAIL:
            return { ...state, vpProjectLabourDataLoaded: false };
        case UPDATE_PMOL_LABOUR_TEAM:
            return { ...state, isLabourTeamAssignedProject: false };
        case UPDATE_PMOL_LABOUR_TEAM_SUCCESS:
            return { ...state, projectLevelReloadRequired: true, isLabourTeamAssignedProject: true };
        case UPDATE_PMOL_LABOUR_TEAM_FAIL:
            return { ...state, isLabourTeamAssignedProject: false };

        case GET_VP_FILTER_DROPDOWN_DATA:
            return { ...state, vpFilterDropdown: [] };
        case GET_VP_FILTER_DROPDOWN_DATA_SUCCESS:
            return { ...state, vpFilterDropdown: action.payload.data.result };
        case GET_VP_FILTER_DROPDOWN_DATA_FAIL:
            return { ...state, vpFilterDropdown: [] };

        case UPDATE_PROJECT_ITEMS_DATE:
            return { ...state, projectLevelReloadRequired: false };
        case UPDATE_PROJECT_ITEMS_DATE_SUCCESS:
            return { ...state, projectLevelReloadRequired: true };
        case UPDATE_PROJECT_ITEMS_DATE_FAIL:
            return { ...state, projectLevelReloadRequired: false };
        case READ_VP_PRODUCT_TAXONOMY:
            return { ...state, prodTaxanomyDataLoaded: false };
        case READ_VP_PRODUCT_TAXONOMY_SUCCESS:
            const dataProduct = formatVProductTaxonomyData(action.payload.data.result.productTaxonomy);
            return {
                ...state,
                prodTaxanomyDataLoaded: true,
                reloadRequired: false,
                vpProducTaxonomyList: dataProduct,
                // vpProducTaxonomyBarList: [ ...formatVProductTaxonomyBarData(dataProduct)[0] ],
                // vpProducTaxonomyInteractBarList: [ ...formatVProductTaxonomyBarData(dataProduct)[1] ],
                vpProducTaxonomyBarList: [ ...formatVPurchaseTaxonomyBarDataWithoutRefresh(dataProduct)[0] ],
                vpProducTaxonomyInteractBarList: [ ...formatVPurchaseTaxonomyBarDataWithoutRefresh(dataProduct)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case READ_VP_PRODUCT_TAXONOMY_FAIL:
            return { ...state };

        case UPDATE_PBS_PARENT:
            return { ...state, productTaxonomyReloadRequired: false };
        case UPDATE_PBS_PARENT_SUCCESS:
            return { ...state, productTaxonomyReloadRequired: true };
        case UPDATE_PBS_PARENT_FAIL:
            return { ...state, productTaxonomyReloadRequired: false };
        case CREATE_PBS_CLONE:
            return { ...state, vpPBSCloneSuccess: false };
        case CREATE_PBS_CLONE_SUCCESS:
            return { ...state, vpPBSCloneSuccess: true, vpClonedPBSID: action.payload.data.result};
        case CREATE_PBS_CLONE_FAIL:
            return { ...state, vpPBSCloneSuccess: false };
        case READ_VP_PROJECT_WITHOUT_REFRESH:
            return {...state}
        case READ_VP_PROJECT_WITHOUT_REFRESH_SUCCESS:
            const dataprojectRef = formatVpProductTaxonomyData(action.payload.data.result);
            return {
                ...state,
                vpProjectDataLoaded: true,
                projectLevelReloadRequired: false,
                productTaxonomyReloadRequired: false,
                vpProjectList: dataprojectRef,
                vpProjectBarList: [ ...formatVpProductBarData(dataprojectRef)[0] ],
                vpProjectInteractBarList: [ ...formatVpProductBarData(dataprojectRef)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case READ_VP_PROJECT_WITHOUT_REFRESH_FAIL:
                return {...state,pbsTaxonomyReloaded:false}

        case READ_VP_PRODUCT_TAXONOMY_REFRESH:
            return { ...state,pbsTaxonomyReloaded:false };
        case READ_VP_PRODUCT_TAXONOMY_REFRESH_SUCCESS:
            const dataProductTax = formatVProductTaxonomyData(action.payload.data?.result?.productTaxonomy);
            return {
                ...state,
                prodTaxanomyDataLoaded: true,
                reloadRequired: false,
                pbsTaxonomyReloaded:true,
                vpProducTaxonomyList: dataProductTax,
                // vpProducTaxonomyBarList: [ ...formatVProductTaxonomyBarData(dataProductTax)[0] ],
                // vpProducTaxonomyInteractBarList: [ ...formatVProductTaxonomyBarData(dataProductTax)[1] ],
                vpProducTaxonomyBarList: [ ...formatVPurchaseTaxonomyBarDataWithoutRefresh(dataProductTax)[0] ],
                vpProducTaxonomyInteractBarList: [ ...formatVPurchaseTaxonomyBarDataWithoutRefresh(dataProductTax)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case READ_VP_PURCHASE_TAXONOMY_SUCCESS:
            const dataPuchaseTax = formatVPurchaseTaxonomyData(action.payload.data.result);
            return {
                ...state,
                purchaseTaxanomyDataLoaded: true,
                reloadRequired: false,
                vpPurchaseTaxonomyList: dataPuchaseTax,
                vpPurchaseTaxonomyBarList: [ ...formatVPurchaseTaxonomyBarData(dataPuchaseTax)[0] ],
                vpPurchaseTaxonomyInteractBarList: [ ...formatVPurchaseTaxonomyBarData(dataPuchaseTax)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case READ_VP_PURCHASE_CU_TAXONOMY:
            return {
                ...state,
                purchaseCUTaxanomyDataLoaded:false
            };
        case READ_VP_PURCHASE_CU_TAXONOMY_SUCCESS:
            const dataPuchaseTaxCU = formatVPurchaseTaxonomyData(action.payload.data.result);
            return {
                ...state,
                purchaseCUTaxanomyDataLoaded: true,
                reloadRequired: false,
                vpPurchaseCUTaxonomyList: dataPuchaseTaxCU,
                vpPurchaseCUTaxonomyBarList: [ ...formatVPurchaseTaxonomyBarData(dataPuchaseTaxCU)[0] ],
                vpPurchaseCUTaxonomyInteractBarList: [ ...formatVPurchaseTaxonomyBarData(dataPuchaseTaxCU)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };
        case READ_VP_PURCHASE_PR_TAXONOMY_SUCCESS:
            const dataPuchaseTaxPR = formatVPurchaseTaxonomyData(action.payload.data.result);
            return {
                ...state,
                purchasePRTaxanomyDataLoaded: true,
                reloadRequired: false,
                vpPurchasePRTaxonomyList: dataPuchaseTaxPR,
                vpPurchasePRTaxonomyBarList: [ ...formatVPurchaseTaxonomyBarData(dataPuchaseTaxPR)[0] ],
                vpPurchasePRTaxonomyInteractBarList: [ ...formatVPurchaseTaxonomyBarData(dataPuchaseTaxPR)[1] ],
                loadMsg: !action.payload.data.status
                    ? action.payload.data.message
                    : ''
            };

        case READ_VP_MACHINE_TAXONOMY_SUCCESS:
                const dataMachineTaxonomy = formatVPurchaseTaxonomyData(action.payload.data.result);
                return {
                    ...state,
                    machineTaxanomyDataLoaded: true,
                    reloadRequired: false,
                    vpMachineTaxonomyList: dataMachineTaxonomy,
                    vpMachineTaxonomyBarList: [ ...formatVPurchaseTaxonomyBarData(dataMachineTaxonomy)[0] ],
                    vpMachineTaxonomyInteractBarList: [ ...formatVPurchaseTaxonomyBarData(dataMachineTaxonomy)[1] ],
                    loadMsg: !action.payload.data.status
                        ? action.payload.data.message
                        : ''
                };
        case READ_VP_PRODUCT_TAXONOMY_REFRESH_FAIL:
            return { ...state };
        case CREATE_PR_FROM_BOR_SUCCESS:
            return {
                ...state,
                prCreatefromBor: true,
                createdPrId: action.payload.data.result
            };

        case LOAD_CPC_RESOURCE_FAMILY_FOR_VP:
          return {
            ...state,
            newToolsListLoaded: false,
            toolsTaxonomyList: [],
            toolsInteractBarList: [],
            toolsBarList: [],
          };
        // eslint-disable-next-line no-case-declarations
        case LOAD_CPC_RESOURCE_FAMILY_FOR_VP_SUCCESS:
          const cpcTools = formatVpToolsData(action.payload.data.result);
          return {
            ...state,
            newToolsListLoaded: true,
            toolsTaxonomyList: action.payload.data.result,
            toolsBarList: [...formatCpcToolBarData(cpcTools)[0]],
            toolsInteractBarList: [...formatCpcToolBarData(cpcTools)[1]],
            loadMsg: !action.payload.data.status
              ? action.payload.data.message
              : ''
          };
        case LOAD_CPC_RESOURCE_FAMILY_FOR_VP_FAIL:
          return {
            ...state,
            newToolsListLoaded: false
          };

        case READ_ALL_PBS_LABOUR:
        return {
            ...state
        };
        case READ_ALL_PBS_LABOUR_SUCCESS:
        return {
            ...state,
            allPbsLaboursList: action.payload.data.result,
        };
        case READ_ALL_PBS_LABOUR_FAIL:
        return {
            ...state,
            allPbsLaboursList: [],
        };

        case READ_ALL_PMOL_LABOUR:
            return {
                ...state,
                allPmolLaboursList: [],
                allPmolLaboursListLoading: true,
            };
        case READ_ALL_PMOL_LABOUR_SUCCESS:
            return {
                ...state,
                allPmolLaboursList: action.payload.data.result,
                allPmolLaboursListLoading: false,
            };
        case READ_ALL_PMOL_LABOUR_FAIL:
            return {
                ...state,
                allPmolLaboursList: [],
                allPmolLaboursListLoading: false,
            };

        case READ_RESOURCE_MATRIX:
            return {
                ...state,
                matrixData: null,
            };
        case READ_RESOURCE_MATRIX_SUCCESS:
            return {
                ...state,
                matrixData: action.payload.data.result,
            };
        case READ_RESOURCE_MATRIX_FAIL:
            return {
                ...state,
                matrixData: null,
            };
        case READ_MIDTERM_CAPACITY_DATA:
            return {
                ...state,
                midtermCapacityData: null,
                showProgressBar: true,
            };
        case READ_MIDTERM_CAPACITY_DATA_SUCCESS:
            return {
                ...state,
                midtermCapacityData: action.payload.data.result,
                showProgressBar: false,
            };
        case READ_MIDTERM_CAPACITY_DATA_FAIL:
            return {
                ...state,
                midtermCapacityData: null,
                showProgressBar: false,
            };
        case READ_PROJECT_TEAM_CAPACITY_DATA:
            return {
                ...state,
                projectTeamCapacityData: null,
                showProgressBar: true,
            };
        case READ_PROJECT_TEAM_CAPACITY_DATA_SUCCESS:
            return {
                ...state,
                projectTeamCapacityData: action.payload.data.result,
                showProgressBar: false,
            };
        case READ_PROJECT_TEAM_CAPACITY_DATA_FAIL:
            return {
                ...state,
                projectTeamCapacityData: null,
                showProgressBar: false,
            };
          case UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER:
            return {
              ...state,
              savePPDisplayOrderSuccess: false,
            }
          case UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_SUCCESS:
            return {
              ...state,
              savePPDisplayOrderSuccess: true,

            }
          case UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_FAIL:
            return {
              ...state,
              savePPDisplayOrderSuccess: false
            }

            case READ_MID_TERM_PLANNING:
                return {
                    ...state,
                    pbsTaxonomyReloaded:false,
                }
            case READ_MID_TERM_PLANNING_SUCCESS:
                const midTermDataTaxonomy = formatMidTermTaxonomyData(action.payload.data.result.productTaxonomy);
                return {
                    ...state,
                    prodTaxanomyDataLoaded: true,
                    reloadRequired: false,
                    pbsTaxonomyReloaded:true,
                    midTermPlanningTaxonomyList: midTermDataTaxonomy,
                    midTermTaxonomyBarList: [...formatVPurchaseTaxonomyBarDataWithoutRefresh(midTermDataTaxonomy)[0]],
                    midTermTaxonomyInteractBarList: [...formatVPurchaseTaxonomyBarDataWithoutRefresh(midTermDataTaxonomy)[1]],
                    loadMsg: !action.payload.data.status
                        ? action.payload.data.message
                        : ''
                }
            case READ_MID_TERM_PLANNING_FAIL:
                return {
                    ...state,
                }

            case ADD_MID_TERM_LABOUR:
                return {...state, isLabourTeamAssigned: false};
            case ADD_MID_TERM_LABOUR_SUCCESS:
                return {...state, isLabourTeamAssigned: true, reloadRequired: true};
            case ADD_MID_TERM_LABOUR_FAIL:
                return {...state, isLabourTeamAssigned: false};

            case DELETE_MID_TERM_LABOUR:
                return {
                    ...state,
                    deleteMidTermLabourSuccess: false
                }
            case DELETE_MID_TERM_LABOUR_SUCCESS:
                return {
                    ...state,
                    deleteMidTermLabourSuccess: true
                }
            case DELETE_MID_TERM_LABOUR_FAIL:
                return {
                    ...state,
                    deleteMidTermLabourSuccess: false
                }

            case READ_MID_TERM_ORG_TAXONOMY:
                return {
                    ...state,
                    mtpOrgInteractBarList:[],
                    isMTPOrgListLoaded: false,
                };
            case READ_MID_TERM_ORG_TAXONOMY_SUCCESS:
                const midTermOrgTaxonomyData = formatMtpOrgTaxonomyData(removeUnimportantData(action?.payload?.data?.result));
                return {
                    ...state,
                    reloadRequired: false,
                    isMTPOrgListLoaded: true,
                    mtpOrgTaxonomyList: formatMtpOrgTaxonomyListData(midTermOrgTaxonomyData), //vpOrgTaxonomyList
                    mtpOrgBarList: [...formatVpOrgBarData(midTermOrgTaxonomyData)[0]],   //vpOrgBarList
                    mtpOrgInteractBarList: [...formatVpOrgBarData(midTermOrgTaxonomyData)[1]],   //vpOrgInteractBarList
                    loadMsg: !action.payload.data.status
                        ? action.payload.data.message
                        : ''
                };
            case READ_MID_TERM_ORG_TAXONOMY_FAIL:
                return {
                    ...state,
                    mtpOrgInteractBarList: [],
                    isMTPOrgListLoaded: false,
                };

        default:
            return state;
        }
    }
}


export const readVisualPlanShortCutPaneData = () => {
  return {
      types: [
          LOAD_VISUAL_PLAN_SHORTCUTPANE,
          LOAD_VISUAL_PLAN_SHORTCUTPANE_SUCCESS,
          LOAD_VISUAL_PLAN_SHORTCUTPANE_FAIL
      ],
      payload: { request: { url: READ_VISUAL_PLAN_SHORTCUT_PANE_EP } }
  };
};

export const readVisualPlanDropDownData = () => {
  return {
      types: [
          LOAD_VISUAL_PLAN_DROPDOWN,
          LOAD_VISUAL_PLAN_DROPDOWN_SUCCESS,
          LOAD_VISUAL_PLAN_DROPDOWN_FAIL
      ],
      payload: { request: { method: 'GET', url: READ_VISUAL_PLAN_DROPDOWN_EP } }
  };
};


export const filterVisualPlanListPaneData = (data:any) => {
    return {
        types: [
            LOAD_VISUAL_PLAN_LSITPANE,
            LOAD_VISUAL_PLAN_LSITPANE_SUCCESS,
            LOAD_VISUAL_PLAN_LSITPANE_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_VISUAL_PLAN_LIST_EP,
                data: {
                    ...data,
                    resourceType: data.resourceType ? data.resourceType : [],
                    requestType: data.requestType ? data.requestType : []
                }
            }
        }
    };
};

// read labour and tools assign to PO
export const readVPProjectResources = () => {
    return {
        types: [
            READ_VP_PROJECT_RESOURCE,
            READ_VP_PROJECT_RESOURCE_SUCCESS,
            READ_VP_PROJECT_RESOURCE_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_VP_PROJECT_RESOURCE,
                data: {
                    fromDate: null,
                    toDate: null
                }
            }
        }
    };
};


export const readByVisualPlanId = (id: string) => {
    return {
        types: [
            LOAD_VISUAL_PLAN_BY_ID,
            LOAD_VISUAL_PLAN_BY_ID_SUCCESS,
            LOAD_VISUAL_PLAN_BY_ID_FAIL ],
        payload: { request: { url: READ_VISUAL_PLAN_BY_ID_EP + id } }
    };
};

export const GetVPFilterDropdownData = () => {
    return {
        types: [
            GET_VP_FILTER_DROPDOWN_DATA,
            GET_VP_FILTER_DROPDOWN_DATA_SUCCESS,
            GET_VP_FILTER_DROPDOWN_DATA_FAIL ],
        payload: { request: { url: GET_VP_FILTER_DROPDOWN_DATA_EP } }
    };
};

export const addPOLabourTeam = (data:any) => {
    return {
        types: [
            ADD_PO_LABOUR_TEAM,
            ADD_PO_LABOUR_TEAM_SUCCESS,
            ADD_PO_LABOUR_TEAM_FAIL ],
        payload: {
            request: {
                method: 'POST',
                url: ADD_PO_LABOUR_TEAM_EP,
                data: data
            }
        }
    };
};

export const readVPOrganizationTaxanomyData = (filter: OrganisationSettingListPaneFilter) => {
    return {
        types: [
            LOAD_VP_ORG_SETTING_BY_ID,
            LOAD_VP_ORG_SETTING_BY_ID_SUCCESS,
            LOAD_VP_ORG_SETTING_BY_ID_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_VP_ORG_SETTING_EP,
                data: filter
            }
        }
    };
};


export const readVPWareHouseTaxanomyData = (filter: {wareHouseId:string|null}) => {
    return {
        types: [
            LOAD_WAREHOUSE_TOOLS,
            LOAD_WAREHOUSE_TOOLS_SUCCESS,
            LOAD_WAREHOUSE_TOOLS_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_VISUAL_PLAN_TOOLS_LIST_EP,
                data: filter
            }
        }
    };
};

export const readCUHRbyPersonId = (id:string) => {
    return {
        types: [
            LOAD_VP_CUHR,
            LOAD_VP_CUHR_SUCCESS,
            LOAD_VP_CUHR_FAIL
        ],
        payload: {
            request: {
                method: 'GET',
                url: READ_CUHR_BY_PERSON_ID_EP + id
            }
        }
    };
};

export const readMilestoneShortCutPaneData = () => {
    return {
        types: [
            READ_MILESTONE_SHORTCUT,
            READ_MILESTONE_SHORTCUT_SUCCESS,
            READ_MILESTONE_SHORTCUT_FAIL
        ],
        payload: {
            request: {
                method: 'GET',
                url: READ_VP_PROJECT_SHORTCUT,
                headers: isCuProjectPlanning() ? { 'isMyEnv': true } : undefined
            }
        }
    };

};


export const updateVpPO = (data: any) => {
    return {
        types: [
            UPDATE_VP_PO,
            UPDATE_VP_PO_SUCCESS,
            UPDATE_VP_PO_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: UPDATE_VP_PO_EP,
                data: data
            }
        }
    };
};

export const updateVpPersonDate = (data: any) => {
    return {
        types: [
            UPDATE_PERSON_DATES,
            UPDATE_PERSON_DATES_SUCCESS,
            UPDATE_PERSON_DATES_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: UPDATE_PERSON_DATES_EP,
                data: data
            }
        }
    };
};

export const updatePMOLLabourTeam = (data: any) => {
    return {
        types: [
            UPDATE_PMOL_LABOUR_TEAM,
            UPDATE_PMOL_LABOUR_TEAM_SUCCESS,
            UPDATE_PMOL_LABOUR_TEAM_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: UPDATE_PMOL_VP_LABOUR_EP,
                data: data
            }
        }
    };
};

export const updatePMOLToolsPool = (data: any) => {
    return {
        types: [
            UPDATE_PMOL_TOOL_TEAM,
            UPDATE_PMOL_TOOL_TEAM_SUCCESS,
            UPDATE_PMOL_TOOL_TEAM_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: UPDATE_PMOL_VP_TOOL_EP,
                data: data
            }
        }
    };
};

export const updateHWTaxonomyDateDate = (data: any) => {
    return {
        types: [
            UPDATE_PERSON_DATES,
            UPDATE_PERSON_DATES_SUCCESS,
            UPDATE_PERSON_DATES_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: UPDATE_WH_TAXONOMY_DATE,
                data: data
            }
        }
    };
};

export const addPOToolsPool = (data: any) => {
    return {
        types: [
            ADD_TOOLS_POOLS,
            ADD_TOOLS_POOLS_SUCCESS,
            ADD_TOOLS_POOLS_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ADD_PO_TOOLS_POOL_EP,
                data: data
            }
        }
    };

};

export const resetIsChange = () => {
    return { type: RESET_VISUAL_PLAN_IS_CHANGE };
};

export const saveFormData = (formData: PurchaseOrder) => {
    return {
        types: [
            SAVE_VISUAL_PLAN,
            SAVE_VISUAL_PLAN_SUCCESS,
            SAVE_VISUAL_PLAN_FAIL ],
        payload: {
            request: {
                url: VISUAL_PLAN_SAVE_EP,
                method: 'POST',
                data: formData
            }
        }
    };
};

export const updateProjectItemsDate = (payload: any) => {
    return {
        types: [
            UPDATE_PROJECT_ITEMS_DATE,
            UPDATE_PROJECT_ITEMS_DATE_SUCCESS,
            UPDATE_PROJECT_ITEMS_DATE_FAIL ],
        payload: {
            request: {
                url: UPDATE_VP_ITEM_DATE,
                method: 'POST',
                data: payload,
            }
        }
    };
};

export const readPurchaseAndDeliveryTaxonomyData = (data:any) => {
    return {
        types: [
            READ_VP_PURCHASE_TAXONOMY,
            READ_VP_PURCHASE_TAXONOMY_SUCCESS,
            READ_VP_PURCHASE_TAXONOMY_FAIL ],
        payload: {
            request: {
                url: READ_VP_PURCHASE_TAXONOMY_DATA,
                method: 'POST',
                data: data
            }
        }
    };
};

export const readPurchaseAndDeliveryTaxonomyDataCU = (data:any) => {
    return {
        types: [
            READ_VP_PURCHASE_CU_TAXONOMY,
            READ_VP_PURCHASE_CU_TAXONOMY_SUCCESS,
            READ_VP_PURCHASE_CU_TAXONOMY_FAIL ],
        payload: {
            request: {
                url: READ_VP_PURCHASE_TAXONOMY_CU_DATA,
                method: 'POST',
                data: data
            }
        }
    };
};


export const readPurchaseAndDeliveryPRTaxonomyData = (data:any) => {
    return {
        types: [
            READ_VP_PURCHASE_PR_TAXONOMY,
            READ_VP_PURCHASE_PR_TAXONOMY_SUCCESS,
            READ_VP_PURCHASE_PR_TAXONOMY_FAIL ],
        payload: {
            request: {
                url: READ_VP_PURCHASE_PR_TAXONOMY_DATA,
                method: 'POST',
                data: data
            }
        }
    };
};


export const readVPMachineTaxonomyData = (data:any) => {
    return {
        types: [
            READ_VP_MACHINE_TAXONOMY,
            READ_VP_MACHINE_TAXONOMY_SUCCESS,
            READ_VP_MACHINE_TAXONOMY_FAIL ],
        payload: {
            request: {
                url: READ_PBS_MACHINE_TAXONOMY_VP,
                method: 'POST',
                data: data
            }
        }

    };
};

export const readVPPBSTaxonomyData = (data:any) => {
    return {
        types: [
            READ_VP_PRODUCT_TAXONOMY,
            READ_VP_PRODUCT_TAXONOMY_SUCCESS,
            READ_VP_PRODUCT_TAXONOMY_FAIL ],
        payload: {
            request: {
                url: READ_VP_PRODUCT_TAXONOMY_DATA,
                method: 'POST',
                data: {...data, BusinessUnit: isCuProjectPlanning() ? getSelectedBuFromCuProjectPlanning() : getBuGroups() },

            }
        }
    };
};

export const readVPPBSTaxonomyDataWithoutRefreshing = (data:any) => {
    return {
        types: [
            READ_VP_PRODUCT_TAXONOMY_REFRESH,
            READ_VP_PRODUCT_TAXONOMY_REFRESH_SUCCESS,
            READ_VP_PRODUCT_TAXONOMY_REFRESH_FAIL ],
        payload: {
            request: {
                url: READ_VP_PRODUCT_TAXONOMY_DATA,
                method: 'POST',
                data: {...data, BusinessUnit:getBuGroups() },
            }
        }
    };
};

export const createPBSCloneOrVirginChildOrSibling = (data:any, project: any) => {
    return {
        types: [
            CREATE_PBS_CLONE,
            CREATE_PBS_CLONE_SUCCESS,
            CREATE_PBS_CLONE_FAIL ],
        payload: {
            request: {
                url: CREATE_PBS_CLONE_EP,
                method: 'POST',
                data: data,
            }
        }
    };
};

export const addMidTermLabourTeam = (data:MidTermLabourAdd) => {
    return {
        types: [
            ADD_MID_TERM_LABOUR,
            ADD_MID_TERM_LABOUR_SUCCESS,
            ADD_MID_TERM_LABOUR_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ADD_MID_TERM_LABOUR_EP,
                data: data
            }
        }
    };
};


export const deleteMidTermAssignedLabour = (data: any) => {
    return {
        types: [
            DELETE_MID_TERM_LABOUR,
            DELETE_MID_TERM_LABOUR_SUCCESS,
            DELETE_MID_TERM_LABOUR_FAIL,
        ],
        payload: {
            request: {
                method: 'POST',
                url: DELETE_MID_TERM_LABOUR_EP,
                data: data,
            },
        },
    };
};

export const readMTPOrganizationTaxanomyData = (filter: OrganisationSettingListPaneFilter) => {
    return {
        types: [
            READ_MID_TERM_ORG_TAXONOMY,
            READ_MID_TERM_ORG_TAXONOMY_SUCCESS,
            READ_MID_TERM_ORG_TAXONOMY_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_MID_TERM_ORG_TAXONOMY_EP,
                data: filter
            }
        }
    };
};

export function saveVPStateAttr(attr:string, value:any) {
    return {
        type: SAVE_VP_STATE_ATTR,
        [attr]: value
    };
}

export const setUid = () => {
    return { type: SET_VISUAL_PLAN_UID };
};

export const clearFormData = () => {
    return { type: CLEAR_VISUAL_PLAN_FORM_DATA };
};


const getVpPoStartDate = (item:any) => {
    const currentDate = new Date();
    if (item.type === 'Project'){
        if (item.startDate) {
            return new Date(Date.parse(item.startDate))
        } else {
            return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        }
    } else {
        if (item.createdDate) {
            return new Date(Date.parse(item.createdDate))
        } else {
            return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        }
    }
}

const getVpPoEndDate = (item:any) => {
    const currentDate = new Date();
    if (item.type === 'Project'){
        if (item.endDate) {
            return [new Date(Date.parse(item.endDate)), false]
        } else {
            return [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1), true]
        }
    } else {
        if (item.deliveryDate) {
            return [new Date(Date.parse(item.deliveryDate)), false]
        } else {
            return [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1), true]
        }
    }
}


const getVpPoRequestedDate = (item:any) => {
    const currentDate = new Date();
    if (item.type === 'Project'){
        if (item.endDate) {
            return new Date(Date.parse(item.endDate))
        } else {
            return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        }
    } else {
        if (item.deliveryDate) {
            return new Date(Date.parse(item.deliveryDate))
        } else {
            return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        }
    }
}

const getVpPoExpectedDate = (item:any) => {
    const currentDate = new Date();
    if (item.type === 'Project'){
        if (item.endDate) {
            return [new Date(Date.parse(item.endDate)), false]
        } else {
            return [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1), true]
        }
    } else {
        if (item.expectedDate) {
            return [new Date(Date.parse(item.expectedDate)), false]
        } else {
            return [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1), true]
        }
    }

}


export const formatVpResourceData = (data:any[]) => {
    const currentDate = new Date();
    const newData = data.map((item: any) => {
        return {
            ...item,
            type: item.type.toLowerCase(),
            name: item.title,
            // start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            // end: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            requestedDate: getVpPoRequestedDate(item),
            expectedDate: getVpPoExpectedDate(item)[0],
            start: getVpPoStartDate(item),
            // end:getVpPoEndDate(item)[0],
            deliveryDate: getVpPoEndDate(item)[0],
            end: getVpPoExpectedDate(item)[0],
            endDateAdded: getVpPoEndDate(item)[1],
            expectedDatedAdded: getVpPoExpectedDate(item)[1],
            progress: 25,
            linkId: item.linkId,
            poId: item.poId
        };
    });

    const emptyrows:any = Array(100).fill('').map((_, i) => {
        return {
            id:1000+i,
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            isHidden: true,
            parentId:null,
            isDisabled: true
        }
          })

    if (newData?.length > 0) {
        newData.push(...emptyrows)
    }
    return newData;
};

export const formatVpToolsData = (data:any[]) => {
    const currentDate = new Date();
    const currentStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 10);
    const currentSEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
    const getId = (item:any) => {
        if (item.id) {
            return item.id;
        }
        return null;
    };
    const newData = data.map((item: any) => {
        return {
            ...item,
            cpcId: item.cpcId,
            id: item.id ? item.id : (item.cpcId + item.parentId),
            name: item.title,
            displayOrder: item.displayOrder,
            parentId: item.parentId,
            resourceTypeId: item.resourceTypeId,
            wareHouseId: item.wareHouseId,
            whTaxonomyLevelId: item.whTaxonomyLevelId,
            // start: new Date(Date.parse(item.startDate)),
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            // end: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            // end: new Date(Date.parse(item.endDate)),
            progress: 0,
            isHidden: item.cpcId === null? true : false
        };
    });
    return newData;
};

export const formatVpOrgTaxonomyData = (data:any[]) => {
    const currentDate = new Date();
    let regativeRemovedList = data.filter((item)=> {
      if (item.startDate) {
          let sd = moment(item.startDate)
          let ed = moment(item.endDate)
          let diff = ed.diff(sd, 'days')
          return diff >= 0
      } else {
          return true;
      }

  })
    const newData = regativeRemovedList.map((item: any, i:any) => {
        return {
            ...item,
            name: '',
            // start: new Date(Date.parse(item.startDate)),
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            // end: new Date(Date.parse(item.endDate)),
            progress: 0,
            isHidden: item.personId ? false : true,
            isDisabled: true,
            key:item.id+i
        };
    });
    return newData;
};

const getDateRangeTitle = (start:string, end:string) => {
    const starts = formatwithoutTime(start);
    const ends = formatwithoutTime(end);
    return starts + '-' + ends;
};

export const formatVpOrgTaxonomyListData = (data:any[]) => {
    const currentDate = new Date();
    const newData = data.map((item: any, i:any) => {
        return {
            ...item,
            key:item.id+i,
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            title: item.organizationTaxonomyLevelId ? item.title : getDateRangeTitle(item.startDate, item.endDate)
        };
    });
    return newData;
};

export const formatVpWhTaxonomyListData = (data:any[]) => {
    const currentDate = new Date();
    const newData = data.map((item: any) => {
        return {
            ...item,
            title: item.whTaxonomyLevelId ? item.title : getDateRangeTitle(item.startDate, item.endDateTitle)
        };
    });
    return newData;
};


const getResourceBarStyle = (node:any) => {
    const materiaslStyle = { progressColor: '#ffbb54', backgroundColor: '#6C3483' };
    const consumableStyle = { progressColor: '#ffbb54', backgroundColor: '#D68910' };
    const labourStyle = { progressColor: '#ffbb54', backgroundColor: '#B0185B' };
    const toolsStyle = { progressColor: '#ffbb54', backgroundColor: '#6DC20E' };
    const taskStyle = { progressColor: '#ffbb54', backgroundColor: '#CCD1D1' };
    const projectStyle = { progressColor: '#ffbb54', backgroundColor: '#B7950B' };
    const poStyle = { progressColor: '#ffbb54', backgroundColor: '#EC7063' };
    if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('materials').toLowerCase()) {
        return materiaslStyle;
    } else if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('consumables').toLowerCase()) {
        return consumableStyle;
    } else if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('labour').toLowerCase()) {
        return labourStyle;
    } else if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('tools').toLowerCase()) {
        return toolsStyle;
    } else if (node.type === 'po') {
        return projectStyle;
    } else if (node.type === 'project') {
        return poStyle;
    }

    return taskStyle;
};

const getPBSPMOLBarStyle = (node:any) => {
    const materiaslStyle = { progressColor: '#ffbb54', backgroundColor: '#6C3483' };
    const consumableStyle = { progressColor: '#ffbb54', backgroundColor: '#D68910' };
    const labourStyle = { progressColor: '#ffbb54', backgroundColor: '#B0185B' };
    const toolsStyle = { progressColor: '#ffbb54', backgroundColor: '#6DC20E' };
    const taskStyle = { progressColor: '#ffbb54', backgroundColor: '#CCD1D1' };
    const projectStyle = { progressColor: '#ffbb54', backgroundColor: '#B7950B' };
    const poStyle = { progressColor: '#ffbb54', backgroundColor: '#F2D7D5' };
    const labourItemStyle = { progressColor: '#ffbb54', backgroundColor: '#5499C7' };
    const toolsItemStyle = { progressColor: '#ffbb54', backgroundColor: '#78281F' };
    const milestoneStyle = { progressColor: '#ffbb54', backgroundColor: '#DBC236' };
    if (node.type === 'Sub - PBS') {
        return projectStyle;
    } else if (node.type === 'PBS') {
        return consumableStyle;
    } else if (node.type === 'BOR') {
        return labourStyle;
    } else if (node.type === 'PMOL') {
        return toolsStyle;
    } else if (node.type === 'LabourItem') {
        return poStyle;
    } else if (node.type === 'Labour') {
        return labourItemStyle;
    } else if (node.type === 'Tool') {
        return toolsItemStyle;
    } else if (node.type === 'Tools') {
        return toolsStyle;
    } else if (node.type === 'Milestone') {
        return milestoneStyle;
    }

    return taskStyle;
};


const getCapacityDemandBarStyle = (node:any) => {
    const cap1Style = { progressColor: '#ffbb54', backgroundColor: '#EC6981' };
    const cap2Style = { progressColor: '#ffbb54', backgroundColor: '#A1DAA4' };
    const cap3Style = { progressColor: '#ffbb54', backgroundColor: '#EDCF28' };
    const cap4Style = { progressColor: '#ffbb54', backgroundColor: '#EC6981' };
    if (node.percentage >= 0 && node.percentage <= 80 ) {

        return cap1Style;
    } else if (node.percentage >= 81 && node.percentage <= 110 ) {
        return cap2Style;
    } else if (node.percentage >= 111 && node.percentage <= 120 ) {
        return cap3Style;
    } else if (node.percentage >= 121 ) {
        return cap4Style;
    }
    return cap1Style;

};


const formatVpOrgBarData = (data: any[]) => {
    const tData = getTreeFromFlatData({
        flatData: data,
        getKey: (node: any) => node.id,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null
    });

    const treeTotal1 = sumNodeValues(tData);
    const fData = getFlatDataFromTree({
        treeData: tData,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false

    });

    const finalresult = fData.map((item:any) => {
        return {
            start: item.node.start,
            end: item.node.end,
            name: item.node.organizationTaxonomyLevelId ? item.node.title : getDateRangeTitle(item.node.startDate, item.node.endDateTitle),
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            hideChildren: false,
            parentId: item.node.parentId,
            isHidden: item.node.isHidden,
            personId: item.node.personId,
            styles: item.node.organizationTaxonomyLevelId ? getCapacityDemandBarStyle(item.node) : null,
            isDisabled: true,
            projectTitle: item.node.projectTitle,
            removeTitleFromChart: item.node.removeTitleFromChart,
            // styles: getResourceBarStyle(item.node)
        };
    });
    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    return [ finalresult, newTasks ];

};

const getResourceFiledQuantity = (teams:any[], toolspool:any[], cpcId:string, resourceType: string) => {
    if (teams && !_.isEmpty(teams) && resourceType === 'Labour') {
        const groupTeamArray:any = groupArray(teams, 'cpcId');
        if (groupTeamArray.hasOwnProperty(cpcId)) {
            return groupTeamArray[cpcId].length;
        }
    } else if (toolspool && !_.isEmpty(toolspool) && resourceType === 'Tools') {
        const groupToolsArray:any = groupArray(toolspool, 'requestedCPCId');
        if (groupToolsArray.hasOwnProperty(cpcId)) {
            return groupToolsArray[cpcId].length;
        }
    }
    return 0;
};

export const formatVpToolsBarData = (data:any[]) => {
    const tData = getTreeFromFlatData({
        flatData: data,
        getKey: (node: any) => node.id,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null
    });
    const treeTotal1 = sumNodeValues(tData);
    const fData = getFlatDataFromTree({
        treeData: tData,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false
    });

    const finalresult = fData.map((item:any) => {
        return {
            start: item.node.start,
            end: item.node.end,
            name: item.node.whTaxonomyLevelId ? item.node.name : getDateRangeTitle(item.node.start, item.node.endDateTitle),
            title: item.node.whTaxonomyLevelId ? item.node.name : getDateRangeTitle(item.node.start, item.node.endDateTitle),
            id: item.node.id,
            progress: 0,
            hideChildren: false,
            parentId: item.node.parentId,
            isHidden: item.node.isHidden,
            displayOrder: item.node.displayOrder,
            wareHouseId: item.node.wareHouseId,
            whTaxonomyLevelId: item.node.whTaxonomyLevelId,
            cpcId: item.node.cpcId,
            isDisabled: true,
            styles: item.node.whTaxonomyLevelId ? getCapacityDemandBarStyle(item.node) : null
            // styles: getResourceBarStyle(item.node)
        };
    });
    // const ids = finalresult.map((item) => {
    //     return item.id;
    // });
    // const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index);
    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    return [ finalresult, newTasks ];

};

export const formatVpResourceBarData = (data:any[]) => {
    const tData = getTreeFromFlatData({
        flatData: data,
        getKey: (node: any) => node.id,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null
    });

    const fData = getFlatDataFromTree({
        treeData: tData,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false
    });

    const treeTotal1 = sumNodeValues(tData);
    const finalresult = fData.map((item:any) => {
        return {
            start: item.node.start,
            // end: item.node.end,
            end: new Date(new Date(Date.parse(item.node.end)).getFullYear(), new Date(Date.parse(item.node.end)).getMonth(), new Date(Date.parse(item.node.end)).getDate() + 1),
            // endDateTitle: new Date(Date.parse(item.endDate)),
            name: item.node.title,
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            type: item?.node?.type?.toLowerCase(),
            hideChildren: false,
            parentId: item.node.parentId,
            linkId: item.node.linkId,
            deliveryDate: item.node?.deliveryDate,
            poId: item.node.poId,
            requiredQuantity: item.node.quantity,
            filedQuantity: getResourceFiledQuantity(item.node.teams, item.node.toolsPool, item.node.ccpcId, item.node.resourceType),
            resourceType: item.node.resourceType,
            styles: getResourceBarStyle(item.node),
            teams: item.node.teams,
            toolsPool: item.node.toolsPool,
            cpcId: item.node.ccpcId,
            project: item.node.project,
            isDisabled: true
        };
    });

    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    const currentDate = new Date();
    const emptyrows:any = Array(100).fill('').map((_, i) => {
        return {
            id:1000+i,
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            isHidden: true,
            parentId:null,
            isDisabled: true
        }})

    if (newTasks?.length > 0) {
        newTasks.push(...emptyrows)
    }
    return [ finalresult, newTasks ];
};


// /////////////===================== PROJECT PLAN

export const readProjectVPData = (data:any) => {
    return {
        types: [
            READ_VP_PROJECT,
            READ_VP_PROJECT_SUCCESS,
            READ_VP_PROJECT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_VP_PROJECT_DATA,
                data: {...data, BusinessUnit: isCuProjectPlanning() ? getSelectedBuFromCuProjectPlanning() : getBuGroups() },
            }
        }
    };
};

export const readProjectVPDataWithOutRefreshing = (data:any) => {
    return {
        types: [
            READ_VP_PROJECT_WITHOUT_REFRESH,
            READ_VP_PROJECT_WITHOUT_REFRESH_SUCCESS,
            READ_VP_PROJECT_WITHOUT_REFRESH_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_VP_PROJECT_DATA,
                data: {...data, BusinessUnit: isCuProjectPlanning() ? getSelectedBuFromCuProjectPlanning() : getBuGroups() },
            }
        }
    };
};

export const readProjectVPPMOLPersonData = () => {
    return {
        types: [
            READ_VP_PROJECT,
            READ_VP_PROJECT_SUCCESS,
            READ_VP_PROJECT_FAIL
        ],
        payload: {
            request: {
                method: 'GET',
                url: READ_VP_PMOL_PERSON_DATA
            }
        }
    };
};

export const readVPPBSUtilityTaxonomyData = (data:any) => {
    return {
        types: [
            READ_VP_PROJECT,
            READ_VP_PROJECT_SUCCESS,
            READ_VP_PROJECT_FAIL ],
        payload: {
            request: {
                method: 'POST',
                url: READ_PBS_UTILITY_TAXONOMY_VP,
                data:data
            }
        }
    };
};

export const readVPPBSLocationTaxonomyData = (data:any) => {
    return {
        types: [
            READ_VP_PROJECT,
            READ_VP_PROJECT_SUCCESS,
            READ_VP_PROJECT_FAIL ],
        payload: {
            request: {
                method: 'POST',
                url: READ_PBS_LOCATION_TAXONOMY_VP,
                data:data
            }
        }
    };
};
export const readResourceTaxonmyDataVP = (data:any) => {
    return {
        types: [
            READ_VP_PURCHASE_CU_TAXONOMY,
            READ_VP_PURCHASE_CU_TAXONOMY_SUCCESS,
            READ_VP_PURCHASE_CU_TAXONOMY_FAIL ],
        payload: {
            request: {
                method: 'POST',
                url: READ_RESOURCE_ITEMS_VP,
                data:data
            }
        }
    };
};

export const deleteVpShiftItem = (id: string) => {
    return {
        types: [
            DELETE_VP_SHIFT_RESOURCE_ITEM,
            DELETE_VP_SHIFT_RESOURCE_ITEM_SUCCESS,
            DELETE_VP_SHIFT_RESOURCE_ITEM_FAIL ],
        payload: {
            request: {
                url: DELETE_VP_SHIFT_RESOURCE_ITEM_EP + id,
                method: 'DELETE',
            },
        },
    };
};

export const updateProductTaxonomyTreeIndex = (data:any) => {
    return {
        types: [
            UPDATE_VP_PT_TREE_INDEX,
            UPDATE_VP_PT_TREE_INDEX_SUCCESS,
            UPDATE_VP_PT_TREE_INDEX_FAIL ],
            payload: {
                request: {
                    method: 'POST',
                    url: VP_UPDATE_TREE_INDEX,
                    data: data
                }
            }
    };
};

export const updateTreeIndexOfNewSibling = (data:any) => {
  return {
    types: [
      UPDATE_TREE_INDEX_OF_NEW_SIBLING,
      UPDATE_TREE_INDEX_OF_NEW_SIBLING_SUCCESS,
      UPDATE_TREE_INDEX_OF_NEW_SIBLING_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: VP_UPDATE_NEW_SIBLING_TREE_INDEX,
        data: data
      }
    }
  };
};

export const updateMyEnvPbsDisplayOrder = (data:any) => {
    return {
        types: [
            UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER,
            UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_SUCCESS,
            UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_FAIL ],
            payload: {
                request: {
                    method: 'POST',
                    url: VP_MY_ENV_PBS_DISPLAY_ORDER,
                    data: data
                }
            }
    };
}

export const updateProductTaxonomyParent = (data:any) => {
    return {
        types: [
            UPDATE_PBS_PARENT,
            UPDATE_PBS_PARENT_SUCCESS,
            UPDATE_PBS_PARENT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: UPDATE_PBS_TAXONOMY_PARENT,
                data: isCuProjectPlanning() ? data?.value : data,
                headers: isCuProjectPlanning() ? { 'project': data?.project } : undefined,
            }
        }
    };
};

export const createPRFromBor = (data:any) => {
    return {
        types: [
            CREATE_PR_FROM_BOR,
            CREATE_PR_FROM_BOR_SUCCESS,
            CREATE_PR_FROM_BOR_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: CREATE_PR_FROM_BOR_EP,
                data: data
            }
        }
    };
};

export const readAllPbsLabour = () => {
    return {
        types: [
          READ_ALL_PBS_LABOUR,
          READ_ALL_PBS_LABOUR_SUCCESS,
          READ_ALL_PBS_LABOUR_FAIL
        ],
        payload: {
            request: {
                method: 'GET',
                url: GET_ALL_PBS_LABOUR_EP
            }
        }
    };
};

export const readAllPmolLabour = (data: any) => {
    return {
        types: [
          READ_ALL_PMOL_LABOUR,
          READ_ALL_PMOL_LABOUR_SUCCESS,
          READ_ALL_PMOL_LABOUR_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: GET_ALL_PMOL_LABOUR_EP,
                data: data
            }
        }
    };
};

export const readCpcResourceFamilyforVp = (filter: { wareHouseId: string | null }) => {
    return {
        types: [
            LOAD_CPC_RESOURCE_FAMILY_FOR_VP,
            LOAD_CPC_RESOURCE_FAMILY_FOR_VP_SUCCESS,
            LOAD_CPC_RESOURCE_FAMILY_FOR_VP_FAIL
        ],
        payload: {
            request: {
                method: 'GET',
                url: READ_CPC_RESOURCE_FAMILY_FOR_VP_EP,
                // data: filter
            }
        }
    };
};

export const getResourceMatrixData = (data:any) => {
    return {
        types: [
            READ_RESOURCE_MATRIX,
            READ_RESOURCE_MATRIX_SUCCESS,
            READ_RESOURCE_MATRIX_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: GET_RESOURCE_MATRIX,
                data: {...data, BusinessUnit: isCuProjectPlanning() ? getSelectedBuFromCuProjectPlanning() : getBuGroups() },
            }
        }
    };
};

export const getMidtermCapacityData = (data:any) => {
    return {
        types: [
            READ_MIDTERM_CAPACITY_DATA,
            READ_MIDTERM_CAPACITY_DATA_SUCCESS,
            READ_MIDTERM_CAPACITY_DATA_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: GET_MIDTERM_CAPACITY_DATA,
                data: {...data, BusinessUnit: isCuProjectPlanning() ? getSelectedBuFromCuProjectPlanning() : getBuGroups() },
            }
        }
    };
};

export const getProjectTeamCapacityData = (data:any) => {
    return {
        types: [
            READ_PROJECT_TEAM_CAPACITY_DATA,
            READ_PROJECT_TEAM_CAPACITY_DATA_SUCCESS,
            READ_PROJECT_TEAM_CAPACITY_DATA_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: GET_PROJECT_TEAM_CAPACITY_DATA,
                data: {...data, BusinessUnit: isCuProjectPlanning() ? getSelectedBuFromCuProjectPlanning() : getBuGroups() },
            }
        }
    };
};

export const readMidTermPlanning = (data: any) => {
    return {
        types: [
            READ_MID_TERM_PLANNING,
            READ_MID_TERM_PLANNING_SUCCESS,
            READ_MID_TERM_PLANNING_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_MID_TERM_PLANNING_EP,
                data: {
                    ...data,
                    BusinessUnit: isCuProjectPlanning() ? getSelectedBuFromCuProjectPlanning() : getBuGroups()
                },
            }
        }
    };
};

export const formatVpProductTaxonomyData = (data:any[]) => {
    const currentDate = new Date();
    const newData = data.map((item: any, index:number) => {
        return {
            ...item,
            id: item.id,
            name: item.title,
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : item.milestoneSequenceId ? moment(item.startDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            progress: 25,
            isHidden: item.type === null ? true : false,
            sortValue: item?.type === 'BOR' ? 0: 1,
            endDate: item.milestoneSequenceId ? item.startDate : item.endDate,
        };
    });
    newData.sort((a, b) => (a.sortValue > b.sortValue) ? 1 : -1)

    const emptyrows:any = Array(100).fill('').map((_, i) => {
    return {
        id:1000+i,
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        isHidden: true,
        parentId:null,
        isDisabled: true
    }
        })

    if (newData?.length > 0) {
        newData.push(...emptyrows)
    }

    return newData;
};

const getNodeKey = ({ treeIndex }:any) => treeIndex;

const sumNodeValues = (treeData: any) => {
    let indexss = '';
    const callback = ({ node }: any) => {
        indexss += ',' + node.id;
    };
    walk({
        treeData,
        getNodeKey,
        callback,
        ignoreCollapsed: true
    });
    const v = stringToArr(indexss);
    return indexss;
};

const stringToArr = (string1: any) => {
    if (string1) {
        const x = string1.trim().split(',');
        x.shift();
        return x;
    }
    return [];

};


export const formatVpProductBarData = (data:any[]) => {
    const tData = getTreeFromFlatData({
        flatData: data,
        getKey: (node: any) => node.id,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null
    });

  const fData = getFlatDataFromTree({
      treeData: tData,
      getNodeKey: (node: any) => node.id,
      ignoreCollapsed: false
  });

  const treeTotal1 = sumNodeValues(tData);
  const finalresult = fData.map((item:any) => {
      const currentDate = new Date();
      return {
          start: item.node.start, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
          end: item.node.end, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
          name: item.node.title,
          title: item.node.title,
          id: item.node.id,
          progress: 0,
          type: item.node.type ? item.node.type.toLowerCase() : null,
          hideChildren: false,
          parentId: item.node.parentId,
          linkId: item.node.linkId,
          poId: item.node.poId,
          requiredQuantity: item.node.quantity,
          filedQuantity: getResourceFiledQuantity(item.node.teams, item.node.toolsPool, item.node.ccpcId, item.node.resourceType),
          styles: getPBSPMOLBarStyle(item.node),
          teams: item.node.teams,
          toolsPool: item.node.toolsPool,
          cpcId: item.node.ccpcId,
          project: item.node.project,
          borSequenceId: item.node.borSequenceId,
          pbsSequenceId: item.node.pbsSequenceId,
          pmolSequenceId: item.node.pmolSequenceId,
          milestoneSequanceId: item.node.milestoneSequenceId,
          isHidden: item.node.isHidden
      };
  });
  const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
  return [ finalresult, newTasks ];
};


export const formatVpProjectLabourData = (data:any[]) => {
    const currentDate = new Date();
    const newData = data.map((item: any, index:number) => {
        return {
            ...item,
            name: item.title,
            id: item.id,
            // start: new Date(Date.parse(item.startDate)),
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            // end: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            // end: new Date(Date.parse(item.endDate)),
            progress: 25,
            isHidden: item.personId ? false : true
        };
    });
    return newData;
};

export const formatVpProjectToolsData = (data:any[]) => {
    const currentDate = new Date();
    const newData = data.map((item: any, index:number) => {
        return {
            ...item,
            name: item.title,
            id: item.id,
            // start: new Date(Date.parse(item.startDate)),
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            // end: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            // end: new Date(Date.parse(item.endDate)),
            progress: 25,
            isHidden: false
        };
    });
    return newData;
};

export const formatVpProjectLabourBarData = (data:any[]) => {
    const tData = getTreeFromFlatData({
        flatData: data,
        getKey: (node: any) => node.id,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null
    });
    const treeTotal1 = sumNodeValues(tData);

    const fData = getFlatDataFromTree({
        treeData: tData,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false
    });

    const finalresult = fData.map((item:any) => {
        return {
            start: item.node.start,
            end: item.node.end,
            name: item.node.type === 'labour' ? item.node.title : getDateRangeTitle(item.node.start, item.node.endDateTitle),
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            hideChildren: false,
            parentId: item.node.parentId,
            isHidden: item.node.isHidden,
            personId: item.node.personId,
            isDisabled: true
        };
    });
    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    return [ finalresult, newTasks ];

};

// Purchase
export const formatVPurchaseTaxonomyData = (data:any[]) => {
  const currentDate = new Date();
  let regativeRemovedList = data.filter((item)=> {
      if (item.startDate) {
          let sd = moment(item.startDate)
          let ed = moment(item.endDate)
          let diff = ed.diff(sd, 'days')
          return diff >= 0
      } else {
          return true;
      }

  })
  const newData = regativeRemovedList.map((item: any, index:number) => {
      return {
          ...item,
          name: item.title,
          id: item.id,
          uid: item.id,
          start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
          end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
          endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
          progress: 25,
          isHidden: false,
          isDisabled: true
      };

  });

  return newData;
};


export const formatVPurchaseTaxonomyBarData = (data:any[]) => {
  const tData = getTreeFromFlatData({
      flatData: data,
      getKey: (node: any) => node.id,
      getParentKey: (node: any) => node.parentId,
      // @ts-ignore
      rootKey: null
  });
  const fData = getFlatDataFromTree({
      treeData: tData,
      getNodeKey: (node: any) => node.id,
      ignoreCollapsed: false
  });
  const treeTotal1 = sumNodeValues(tData);
  const finalresult = fData.map((item:any) => {
      const currentDate = new Date();
      return {
          start: item.node.start, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
          end: item.node.end, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
          name: item.node.title,
          title: item.node.title,
          id: item.node.id,
          progress: 0,
          hideChildren: false,
          parentId: item.node.parentId,
          pbsSequenceId: item.node.pbsSequenceId,
          type: item.node.hasOwnProperty('children') ?'parentProduct':'product'
      };
  });

  const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
  return [ finalresult, newTasks ];
};


export const formatVPurchaseTaxonomyBarDataWithoutRefresh = (data:any[]) => {
    const fData = getFlatDataFromTree({
        treeData: data,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false
    });
    const treeTotal1 = sumNodeValues(data);
    const finalresult = fData.map((item:any) => {
        const currentDate = new Date();
        return {
            start: item.node.start, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
            end: item.node.end, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            name: item.node.title,
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            hideChildren: false,
            parentId: item.node.parentId,
            pbsSequenceId: item.node.pbsSequenceId,
            type: item.node.hasOwnProperty('children') ?'parentProduct':'product',
            cu: item.node.cu,
            project: item.node.project,
            isFilter: item.node.isFilter
        };
    });
    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    return [ finalresult, newTasks ];
};


export const formatVpProjectToolsBarData = (data:any[]) => {
    const tData = getTreeFromFlatData({
        flatData: data,
        getKey: (node: any) => node.id,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null
    });
    const treeTotal1 = sumNodeValues(tData);
    const fData = getFlatDataFromTree({
        treeData: tData,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false
    });


    const finalresult = fData.map((item:any) => {
        return {
            start: item.node.start,
            end: item.node.end,
            name: item.node.type === 'Tools' ? item.node.title : getDateRangeTitle(item.node.start, item.node.endDateTitle),
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            hideChildren: false,
            parentId: item.node.parentId,
            isHidden: item.node.isHidden,
            personId: item.node.personId,
            isDisabled: true
        };
    });
   const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    return [ finalresult, newTasks ];
};

export const formatVProductTaxonomyData = (data:any[]) => {
    const currentDate = new Date();
    let regativeRemovedList = data.filter((item)=> {
        if (item.startDate) {
            let sd = moment(item.startDate)
            let ed = moment(item.endDate)
            let diff = ed.diff(sd, 'days')
            return diff >= 0
        } else {
            return true;
        }
    })

    const newData = regativeRemovedList.map((item: any, index:number) => {
        return {
            ...item,
            name: item.title,
            // id: isMyEnvironment() ? (item.type === 'Pbs' ? item.pbsSequenceId : item.id) : isCuProjectPlanning() ? item.id : item.pbsSequenceId,
            id: (isMyEnvironment() || isCuProjectPlanning()) ? (item.type === 'Pbs' ? item.pbsSequenceId : item?.id) : item.pbsSequenceId,
            uid: item.id,
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            progress: 25,
            isHidden: false,
            isDisabled: true,
            cu: item.cu,
            project: item.project,
        };
    });

    const emptyrows:any = Array(100).fill('').map((_, i) => {
    return {
        id:1000+i,
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        isHidden: true,
        parentId:null,
        isDisabled: true
    }
      })
    newData.push(...emptyrows)
    return newData;

};

const updatePBSStyle = (treeData: any) => {
    treeData = treeData.map((item:any, i:number) => {
        return {
            ...item,
            styles: getProductBarStyle(i)
        }
    })
    let indexss = 0
    const callback = (mainNode:any) => {
    const {node, treeIndex, parentNode, path, lowerSiblingCounts} = mainNode

    if (node?.parentId !== null) {
      indexss++
      if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.PINK) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getPinkShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.PINK  }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.GREEN) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getGreenShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.GREEN }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.ORANGE) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getOrangeShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.ORANGE }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.RED) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getRedShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.RED }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.PURPLE) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getPurpleShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.PURPLE }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.CEYAN) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getCeyanShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.CEYAN }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.BLUE) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getBlueShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.BLUE }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.BROWN) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getBrownShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.BROWN }
      } else if (parentNode && parentNode?.styles?.primaryColor === PRIMARY_COLOR.BLACK) {
        node.styles = { progressColor: '#ffbb54', backgroundColor: getBlackShades(lowerSiblingCounts.length), primaryColor:PRIMARY_COLOR.BLACK }
      }
       else {
        node.styles = 'ss'
      }

    }
  };

  walk({
        treeData,
        getNodeKey,
        callback,
        ignoreCollapsed: false
  });
  return treeData;
};

export const formatVProductTaxonomyBarData = (data:any[]) => {
    const tData = getTreeFromFlatData({
        flatData: data,
        getKey: (node: any) => node.pbsSequenceId,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null
    });
    const fData = getFlatDataFromTree({
        treeData: tData,
        getNodeKey: (node: any) => node.pbsSequenceId,
        ignoreCollapsed: false
    });
    const treeTotal1 = sumNodeValues(tData);
    const finalresult = fData.map((item:any, i:number) => {
        const currentDate = new Date();
        return {
            start: item.node.start, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
            end: item.node.end, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            name: item.node.title,
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            hideChildren: false,
            parentId: item.node.parentId,
            pbsSequenceId: item.node.pbsSequenceId,
            styles:item?.node?.styles,
            type: item.node.hasOwnProperty('children') ? 'parentProduct':'product'
        };
    });
    //console.log("RRRRRRRR", finalresult)
    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    return [ finalresult, newTasks ];
};

const getProductBarStyle = (index:number) => {
    let taskStyle = null;
    const firstStyle = { progressColor: '#ffbb54', backgroundColor: '#c580b9', primaryColor: PRIMARY_COLOR.PINK };
    const secondStyle = { progressColor: '#ffbb54', backgroundColor: '#33ab5f' , primaryColor: PRIMARY_COLOR.GREEN };
    const thirdStyle = { progressColor: '#ffbb54', backgroundColor: '#f8973e', primaryColor: PRIMARY_COLOR.ORANGE };
    const fourthStyle = { progressColor: '#ffbb54', backgroundColor: '#fe4e4e', primaryColor: PRIMARY_COLOR.RED };
    const fifthStyle = { progressColor: '#ffbb54', backgroundColor: '#634490', primaryColor: PRIMARY_COLOR.PURPLE };
    const sixStyle = { progressColor: '#ffbb54', backgroundColor: '#00c4d4', primaryColor: PRIMARY_COLOR.CEYAN };
    const sevenStyle = { progressColor: '#ffbb54', backgroundColor: '#3466a5', primaryColor: PRIMARY_COLOR.BLUE };
    const eightStyle = { progressColor: '#ffbb54', backgroundColor: '#aa3a39', primaryColor: PRIMARY_COLOR.BROWN };
    const nightStyle = { progressColor: '#ffbb54', backgroundColor: '#303030', primaryColor: PRIMARY_COLOR.BLACK };

    switch(index % 9){
        case 0:
            taskStyle = firstStyle
            break;
        case 1:
            taskStyle = secondStyle
            break;
        case 2:
            taskStyle = thirdStyle
            break;
        case 3:
            taskStyle = fourthStyle
            break;
        case 4:
            taskStyle = fifthStyle
            break;
        case 5:
            taskStyle = sixStyle
            break;
        case 6:
            taskStyle = sevenStyle
            break;
        case 7:
            taskStyle = eightStyle
            break;
        case 8:
            taskStyle = nightStyle
            break;
        default:{
            taskStyle = firstStyle
        }
    }


    return taskStyle;
};

export const formatVProductTaxonomyBarDataWithoutRefresh = (data:any[]) => {
    const newTreeData = updatePBSStyle(data)
    const currentDate = new Date();
    const fData = getFlatDataFromTree({
        treeData: newTreeData,
        getNodeKey: (node: any) => node.pbsSequenceId,
        ignoreCollapsed: false
    });

    const treeTotal1 = sumNodeValues(data);

    const finalresult = fData.map((item:any, i:number) => {
        const currentDate = new Date();

        return {
            start: item.node.start, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
            end: item.node.end, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            name: item.node.title,
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            hideChildren: false,
            parentId: item.node.parentId,
            pbsSequenceId: item.node.pbsSequenceId,
            styles: item?.node?.styles,
            type: item.node.hasOwnProperty('children') && item?.node?.children?.length > 0 ?'parentProduct':'product',
            cu: item?.node?.cu,
            project: item?.node?.project,
        };
    });
    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
    const emptyrows:any = Array(100).fill('').map((_, i) => {
        return {
            id:1000+i,
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            isHidden: true,
            parentId:null,
            isDisabled: true
        }})
    newTasks.push(...emptyrows)
    return [ finalresult, newTasks ];
};


export const formatVpProductBarDataWithoutRefresh = (data:any[]) => {
    const currentDate = new Date();
    const fData = getFlatDataFromTree({
        treeData: data,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false
    });

    const treeTotal1 = sumNodeValues(data);
    const finalresult = fData.map((item:any) => {
        const currentDate = new Date();
        return {
            start: item.node.start, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
            end: item.node.end, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
            name: item.node.title,
            title: item.node.title,
            id: item.node.id,
            progress: 0,
            //type: item.node.type ? item.node.type.toLowerCase() : null,
            hideChildren: false,
            parentId: item.node.parentId,
            linkId: item.node.linkId,
            poId: item.node.poId,
            requiredQuantity: item.node.quantity,
            filedQuantity: getResourceFiledQuantity(item.node.teams, item.node.toolsPool, item.node.ccpcId, item.node.resourceType),
            styles: getPBSPMOLBarStyle(item.node),
            teams: item.node.teams,
            toolsPool: item.node.toolsPool,
            cpcId: item.node.hasOwnProperty('ccpcId') ? item?.node?.ccpcId : item.node?.cpcId,
            project: item.node.project,
            borSequenceId: item.node.borSequenceId,
            pbsSequenceId: item.node.pbsSequenceId,
            pmolSequenceId: item.node.pmolSequenceId,
            milestoneSequanceId: item.node.milestoneSequenceId,
            isHidden: item.node.isHidden,
            isSelected: item.node.isSelected,
            type: item.node.hasOwnProperty('children') && item?.node?.children?.length > 0?'parentProduct':'product',
            cu: item.node.cu,
        };
    });


    const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));

    const emptyrows:any = Array(100).fill('').map((_, i) => {
        return {
            id:1000+i,
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            isHidden: true,
            parentId:null,
            isDisabled: true
        }})

    if (newTasks?.length > 0) {
        newTasks.push(...emptyrows)
    }

    return [ finalresult, newTasks ];
};

export const formatCpcToolBarData = (data: any[]) => {
  const tData = getTreeFromFlatData({
    flatData: data,
    getKey: (node: any) => node.id,
    getParentKey: (node: any) => node.parentId,
    // @ts-ignore
    rootKey: null
  });
  const treeTotal1 = sumNodeValues(tData);
  const fData = getFlatDataFromTree({
    treeData: tData,
    getNodeKey: (node: any) => node.id,
    ignoreCollapsed: false
  });

  const finalresult = fData.map((item: any) => {
    return {
      start: item.node.start,
      end: item.node.end,
      name: item.node.name.replace(/00:00:00.0000000/g, ""),
      title: item.node.name.replace(/00:00:00.0000000/g, ""),
      id: item.node.id,
      progress: 0,
      hideChildren: false,
      parentId: item.node.parentId,
      isHidden: item.node.isHidden,
      displayOrder: item.node.displayOrder,
      wareHouseId: item.node.wareHouseId,
      whTaxonomyLevelId: item.node.whTaxonomyLevelId,
      cpcId: item.node.cpcId,
      isDisabled: true,
      styles: item.node.whTaxonomyLevelId ? getCapacityDemandBarStyle(item.node) : null
      // styles: getResourceBarStyle(item.node)
    };
  });
  // const ids = finalresult.map((item) => {
  //     return item.id;
  // });
  // const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index);
  const newTasks = finalresult.filter(item => stringToArr(treeTotal1).includes(item.id));
  return [finalresult, newTasks];
};
