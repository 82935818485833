import * as React from 'react';
import { useEffect, useState } from 'react';
import Tree from '../../../shared/tree/Tree';
import { Box20Regular, Clover20Regular, Timer20Regular } from '@fluentui/react-icons';
import { registerIcons } from '@fluentui/react';
import { SvgIcons } from '../../../shared/svgIcons/svgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import history from '../../../history';
import { deepCloneArray, getContractingUnit, getPerentFromTreeData } from '../../../shared/util';
import { useParams } from 'react-router';
import { ParamTypes } from '../../../types/uPrince';
import { saveWbsCreateStateAttr } from '../../../reducers/wbsCreateReducer';
import _ from 'lodash';
import { WBS_TAXONOMY_LEVEL } from '../../../types/wbs';
import { messageService } from '../../../services/messageService';
import { saveWbsStateAttr } from '../../../reducers/wbsReducer';
import { Label, Link } from '@fluentui/react-components';
import i18n from 'i18next';
import { store } from '../../../../index';
// import { Timer20Regular } from '@fluentui/react-icons/lib/fonts';

registerIcons(SvgIcons);

const WbsCreateListPane = ({showDocumentPane}: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state }: any = location;
  const { featureId, templateId }: ParamTypes = useParams();


  const wbsTemplateTaxonomyData = useSelector((state: any) => state.wbsCreate.wbsTemplateTaxonomyData);
  const wbsListData = useSelector((state: any) => state.wbsCreate.wbsListData);
  const showTempMsg = useSelector((state: any) => state.wbsCreate.showTempMsg);
  const defaultOpenItems= useSelector((state: any) => state.wbs.defaultOpenItems);
  const [isLoading, setIsLoading] = useState(false);

  const getParentTemplate = wbsListData.filter((item: any) => item?.id === featureId);

  useEffect(() => {

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isWbsExpand) {
          wbsTaxonomyExpand(data.data.isWbsExpand?.status);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
      dispatch(saveWbsCreateStateAttr('wbsTemplateTaxonomyData', []));
      dispatch(saveWbsStateAttr('projectWbsTemplateId', null));
      dispatch(saveWbsStateAttr('defaultOpenItems', []));
      console.log('cleaned up');
    };
  }, []);


  const wbsTaxonomyExpand=(isWbsExpand:boolean)=>{
    setIsLoading(true);
    if(isWbsExpand){
      let Ids=formatTreeData(store?.getState()?.wbsCreate?.wbsTemplateTaxonomyData)?.map((item:any)=>{
        return item?.id;
      })

      dispatch(saveWbsStateAttr('defaultOpenItems', Ids));
    }else{
      dispatch(saveWbsStateAttr('defaultOpenItems', []));
    }
    setTimeout(()=>{
      setIsLoading(false);
    },100);
  }

  const formatTreeData = (data: any) => {
    let formattedData: any[] = deepCloneArray(data);

    if (formattedData && !_.isEmpty(formattedData)) {
      if (featureId) {
        dispatch(saveWbsStateAttr('projectWbsTemplateId', getParentTemplate[0]?.id));

        formattedData.unshift(getParentTemplate[0]);

        if (getParentTemplate && !_.isEmpty(getParentTemplate)) {
          formattedData.map((item: any) => {
            if (item?.parentId === null) {
              return item.parentId = getParentTemplate[0].id;
            }
          });
        }
      }
    }

    return formattedData;
  };

  const onClickTreeItem = (item: any) => {
  };

  const setDefaultOpenItems=(item:any)=>{
    let selectedParentIds=getPerentFromTreeData(formatTreeData(wbsTemplateTaxonomyData), item?.id);
    selectedParentIds.push(item?.id)
    dispatch(saveWbsStateAttr('defaultOpenItems', selectedParentIds));
  }


  const onClickTitle = (item: any) => {
    console.log({ item });
    setDefaultOpenItems(item)
    const levelId = item.wbsTaxonomyLevelId;
    switch (levelId) {
      case WBS_TAXONOMY_LEVEL.PRODUCT:
        history.push({
          pathname: `/CU/${getContractingUnit()}/wbs-create/${featureId}/template/${item.id}`,
        });
        messageService.sendMessage({
          showWbsTaxonomyCreateDocumentPane: true,
          wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.PRODUCT,
        });
        break;
      case WBS_TAXONOMY_LEVEL.WBS:
        messageService.sendMessage({
          showWbsTaxonomyCreateDocumentPane: true,
          wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.WBS,
        });
        break;
      case WBS_TAXONOMY_LEVEL.TASK:
        history.push({
          pathname: `/CU/${getContractingUnit()}/wbs-create/${featureId}/template/${item.id}`,
        });
        messageService.sendMessage({
          showWbsTaxonomyCreateDocumentPane: true,
          wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.TASK,
        });
        break;
      default:
        messageService.sendMessage({
          showWbsTaxonomyCreateDocumentPane: true,
          wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.WBS,
        });
        break;

    }
  };

  const renderIconBefore = (props: any) => {
    switch (props.wbsTaxonomyLevelId) {
      case WBS_TAXONOMY_LEVEL.WBS:
        return <Clover20Regular />;

      case WBS_TAXONOMY_LEVEL.PRODUCT:
        return <Box20Regular />;

      case WBS_TAXONOMY_LEVEL.TASK:
        return <Timer20Regular />;

      default:
        return <></>;
    }
  };

  return (
    <>
      {showTempMsg ? <div style={{ padding: 0 }}>
        {wbsTemplateTaxonomyData && !_.isEmpty(wbsTemplateTaxonomyData) ?
          <>{!isLoading && <Tree
            flatTreeItems={formatTreeData(wbsTemplateTaxonomyData)}
            iconBefore={renderIconBefore}
            stopPropagation={true}
            onClickTreeItem={onClickTreeItem}
            onClickTitle={onClickTitle}
            defaultOpenItems={defaultOpenItems}
          />} </>:
          <div>
            {/**
             * keep the space inside the <Label> component
             * createTempMsg = Please create a node for the template*/}
            <Label>{i18n.t('createTempMsg')} </Label>
            <Link
              onClick={() => history.push(`/CU/${getContractingUnit()}/wbs-create/${getParentTemplate[0]?.id}`)}>
              {getParentTemplate[0]?.title}
            </Link>
          </div>
        }
        {/*<div>{JSON.stringify(getParentTemplate[0], null, 2)}</div>*/}
      </div> : <span>{i18n.t('loading')}...</span>}
    </>
  );

};

export default WbsCreateListPane;
