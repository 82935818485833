import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import DefaultCustomModal from '../../../../shared/customeModal/defaultCustomModal';
import { Field, Form } from 'react-final-form';
import { createForm } from 'final-form';
import { getWbsTaskById, getWbsTaskInstructionList } from '../../../../reducers/wbsTaskReducer';
import { Dropdown, getTheme, Icon, IIconProps, Label, mergeStyleSets, PrimaryButton } from '@fluentui/react';
import TextEditor from '../../../../shared/textEditor/textEditor';
import { instructionTypeIds } from '../../../../types/wbs';
import { readInstructionDropDown } from '../../../../reducers/billOfResourcesReducer';
import { useDispatch, useSelector } from 'react-redux';
import DataGridWithModal, { ModalGridContext } from '../../../../shared/dataGridModal/component';
import { pbsInstructionLink } from '../../../../types/instructionRegister';
import { uPrinceTheme } from '../../../../../theme';
import AddLink from './AddLink';
import CustomDropdown from '../../../../shared/customDropdown/customDropdown';
import {
  readByInstructionRegisterId,
  saveInstructionStateAttr,
} from '../../../../reducers/instructionRegisterReducer';
import { getProject } from '../../../../shared/util';
import client from '../../../../api';
import _ from 'lodash';
import {
  createTechInstruction,
  savePbsInstruction,
  savePbsStateAttr,
} from '../../../../reducers/projectBreakdownReducer';
import { v4 as uuidv4 } from 'uuid';
import { messageService } from '../../../../services/messageService';

const theme = getTheme();

// Styles for the "Save" button icon
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};

// CSS class names for styling

const classNames = mergeStyleSets({
  fullWidth: { width: '100%', marginTop: 20 },
  halfWidth: { width: '49%' },
});

const AddInstruction = (props: { taskId: any, pbsId: any, productId: any, isEdit: boolean | undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // State variables
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [linkList, setLinkList] :any= useState([]);
  const [selectedLink, setSelectedLink]: any = useState(null);

  // Redux state variables
  const { instructionDropDowns } = useSelector((state: any) => state.billOfResource);
  const { formData } = useSelector((state: any) => state.ir);
  const saveInstructionStatus = useSelector((state: any) => state.projectBreakdown.saveInstructionStatus);

  // Fetch instruction dropdown data when the component mounts
  useEffect(() => {
    dispatch(readInstructionDropDown());
  }, []);

  // Handle changes in the `saveInstructionStatus` to trigger actions
  useEffect(() => {
    if (saveInstructionStatus) {
      dispatch(savePbsStateAttr('saveInstructionStatus', false));
      Promise.all([dispatch(getWbsTaskInstructionList({
        taskId: props?.taskId,
        productId: props?.pbsId,
      }))]).then(() => {
        dispatch(getWbsTaskById({ id: props?.taskId }));
      });
    }
  }, [saveInstructionStatus]);

  // Function to handle form submission
  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    dispatch(createTechInstruction(values));
    await sleep(300);
    let pbsInstruction = {
      id: uuidv4(),
      pbsProductId: props?.pbsId,
      instructionsId: values.id,
    };
    dispatch(savePbsInstruction(pbsInstruction));
    dispatch(saveInstructionStateAttr('formData', {}));
    await sleep(300);
    formRef?.current?.reset({
      id: null,
      name: null,
      pbsInstructionFamilyId: null,
      instructionType: null,
      instructionsDetails: null,
      pbsInstructionLink: [],
    });
    setIsOpen(false);
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  const onRenderFooter = () => {
    return <>
      <PrimaryButton
        iconProps={addIconWhite}
        text={t('save')}
        disabled={formRef.current.getState().submitting}
        style={{ marginTop: 38, marginBottom: 10 }}
        onClick={() => {

          formRef.current.submit();
        }}
      />
    </>;
  };


  const getInitialFormValues = () => {
    if (formData && formRef?.current?.getState().values?.title) {
      return {
        ...formData,
        id: formData?.id,
        title: formRef?.current?.getState().values?.title,
        name: formData?.name,
        pbsInstructionFamilyId: formData?.pbsInstructionFamilyId,
        instructionType: formRef?.current?.getState().values?.instructionType,
        instructionsDetails: formData?.instructionsDetails,
        pbsInstructionLink: formData?.pbsInstructionLink ? formData?.pbsInstructionLink : [],
      };
    } else {
      return {
        id: null,
        title: null,
        name: null,
        pbsInstructionFamilyId: null,
        instructionType: null,
        instructionsDetails: null,
        pbsInstructionLink: [],
      };
    }

  };

  const getInstructionFamily = () => {
    if (instructionDropDowns) {
      if (formRef?.current?.getState().values?.instructionType === instructionTypeIds.technical) {
        return instructionDropDowns.filter((item: any) => {
          return item?.type === 'technical';
        });
      } else {
        return instructionDropDowns.filter((item: any) => {
          return item?.type != 'technical';
        });
      }
    } else {
      return [];
    }
  };

  const getFileType = (type: any) => {
    let insLinkType = null;
    switch (type) {
      case 'FileImage':
        insLinkType = 'Image';
        break;
      case 'PDF':
        insLinkType = 'PDF';
        break;
      case 'WordDocument':
        insLinkType = 'Word Document';
        break;
      case 'ExcelDocument':
        insLinkType = 'Excel Document';
        break;
      case 'PowerPointDocument':
        insLinkType = 'Power Point Document';
        break;
      case 'Website':
        insLinkType = 'URL';
        break;
    }
    return insLinkType;
  };
  const getIconName = (type: any) => {
    switch (type) {
      case 'PDF':
        return 'PDF';
      case 'Image':
        return 'FileImage';
      case 'URL':
        return 'Website';
      case 'Word Document':
        return 'WordDocument';
      case 'Excel Document':
        return 'ExcelDocument';
      case 'Power Point Document':
        return 'PowerPointDocument';
      default:
        return 'Website';
    }
  };

  const instructionLinkColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: pbsInstructionLink) => {
        return (
          <div>
            <span>{getFileType(item.type)}</span>
          </div>
        );
      },
    },
    {
      key: 'column3',
      name: t('links'),
      fieldName: 'link',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: pbsInstructionLink) => {
        // console.log("ITEM", getIconName(getFileType(item.type)));
        return (
          <div>
            <Icon
              style={{
                cursor: 'pointer',
                color: uPrinceTheme.palette.themePrimary,
              }}
              iconName={getIconName(getFileType(item.type))}
              onClick={(event) => {
                event.preventDefault();
                window.open(item.link!!);
              }}
            />
          </div>
        );
      },
    },
  ];

  const addOrUpdateItem = (item: any) => {
    let linkList = formRef?.current?.getState().values?.pbsInstructionLink;
    const idx = linkList.findIndex((listItem: any) => listItem?.id === item?.id);
    console.log({ idx });
    if (idx >= 0) {
      const updatedItem = { ...item };

      const nextList = [...linkList];
      nextList.splice(idx, 1, updatedItem);
      console.log({ nextList });
      // setLinkList(nextList)
      return nextList;
    } else {
      let currentList = [...linkList];
      let newList = [...currentList, { ...item }];
      console.log({ newList });
      // setLinkList(newList)
      return newList;
    }
  };

  const instructionOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getinstructionByName(inputValue));
      // }, 1000);
    });

  const getinstructionByName = async (name: string) => {
    const filter = {
      title: name,
      pbsProductId: null,
      instructionType: formRef?.current?.getState().values?.instructionType,
      pbsInstructionFamilyId: null,
      sorter: {
        attribute: 'title',
        order: 'asc',
      },
    };
    const header = getProject() ? { headers: { project: getProject() } } : undefined;
    if (formRef?.current?.getState().values?.instructionType) {
      const response = await client.post(
        'PbsInstruction/InstructionsFilter',
        filter, header,
      );
      return formatInstructionResponse(response);
    }
  };
  const formatInstructionResponse = (response: any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
        options = data.map((item: any) => {
          return {
            value: item.sequenceCode,
            label: item.title,
          };
        });
      }


      return options;
    }
  };

  // Function to close the modal and reset the form
  const handelClose = () => {
    dispatch(saveInstructionStateAttr('formData', {}));

    formRef?.current?.reset({
      id: null,
      name: null,
      pbsInstructionFamilyId: null,
      instructionType: null,
      instructionsDetails: null,
      pbsInstructionLink: [],
    });
    setIsOpen(false);
  };

  return (
    <>

      <DefaultCustomModal
        onRenderFooter={onRenderFooter}
        isModalOpen={isOpen}
        title={t('addInstructions')}
        handleClose={() => handelClose()}>
        <>
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialFormValues()}
            mutators={{
              setSequenceCode: (args, state, utils) => {
                const field = state.fields.sequenceCode;
                field.change(args[0]);
              },
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values?.instructionType) {
                errors.instructionType = t('instructionTypeRequired');
              }
              if (!values?.title) {
                errors.title = t('instructionNameRequired');
              }
              return errors;
            }}
            render={({
                       handleSubmit,
                       form,
                       submitting,
                       pristine,
                       values,
                       errors,
                     }) => {
              formRef.current = form;
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <div style={{ display: 'flex', flexDirection: 'column', minWidth: '50vw' }}>
                    <div style={{ display: 'flex', gap: 5 }}>
                      <Field
                        name='sequenceCode'
                        component={'input'}
                        type={'text'}
                        disabled={true}
                        hidden
                      />

                      <div className={`${classNames.halfWidth}`}>
                        <Field name='instructionType'>
                          {({ input, meta }) => (
                            <Dropdown
                              errorMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                              placeholder={t('instructionType')}
                              label={t('instructionType')}
                              selectedKey={values.instructionType}
                              options={[{ key: instructionTypeIds.technical, text: t('technical') },
                                { key: instructionTypeIds.environment, text: t('environment') },
                                { key: instructionTypeIds.safety, text: t('safety') },
                                { key: instructionTypeIds.health, text: t('health') }]}
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: any,
                              ) => {
                                input.onChange(item.key);
                              }}
                              required={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`${classNames.halfWidth}`}>
                        <Field name='title'>
                          {({ input, meta }) => (
                            <>
                              <Label required={true}>{t('instructionName')}</Label>
                              <CustomDropdown
                                // Changing the {id} re-renders the dropdown
                                id={values?.instructionType + 'instructionName'}
                                reference={''}
                                onChange={(value: any, label: string) => {
                                  if (value) {
                                    //call ins get by id  >>
                                    input.onChange(label);
                                    form.mutators.setSequenceCode(value);
                                    dispatch(readByInstructionRegisterId(value));

                                  } else {
                                    input.onChange(null);
                                    form.mutators.setSequenceCode(null);
                                  }
                                }}
                                selectedOption={(values && values?.sequenceCode ? {
                                  value: values?.sequenceCode,
                                  label: values?.title,
                                } : null)}
                                promiseOptions={instructionOptions}
                                validationMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                                // validationMessage={instructionValidationMessages.nameValidationMsg}
                                required={true}
                                disabled={!values?.instructionType}
                                noHeight={true}
                              />

                              {/*<TextField*/}
                              {/*  label={t('instructionName')}*/}
                              {/*  value={values?.name}*/}
                              {/*  onChange={(event, value) => {*/}
                              {/*    input.onChange(value)*/}
                              {/*  }}*/}
                              {/*/>*/}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div style={{ display: 'flex', gap: 5, paddingTop: 10 }}>
                      <div className={`${classNames.halfWidth}`}>
                        <Field name='pbsInstructionFamilyId'>
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('instructionFamily')}
                              label={t('instructionFamily')}
                              selectedKey={values.pbsInstructionFamilyId}
                              options={getInstructionFamily()}
                              disabled={!values?.instructionType}
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: any,
                              ) => {
                                input.onChange(item.key);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div>
                      <div className={`${classNames.fullWidth}`}>
                        <Label>{`${t('instructionDetails')}`}</Label>
                        <Field name='instructionsDetails'>
                          {({ input, meta }) => (
                            <TextEditor
                              value={values?.instructionsDetails ? values?.instructionsDetails : ''}
                              onChange={(value: any) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div>
                      <Field name='pbsInstructionLink'>
                        {({ input, meta }) => (
                          <div style={{ width: '100%' }}>
                            <DataGridWithModal
                              dataList={values?.pbsInstructionLink}
                              modalTitle={t('links')}
                              deleteDataGrid={async (selection: any) => {
                                const selectedList = selection.map((item: any) => {
                                  return item.id;
                                });
                                if (selectedList && selectedList[0]) {
                                  let updatedList = values?.pbsInstructionLink.filter((obj: any) => {
                                    return obj.id !== selectedList[0];
                                  });
                                  input.onChange(updatedList);
                                }

                              }}
                              readOnly={false}
                              title={`${t('links')}`}
                              columns={instructionLinkColumns}
                              isLoaded={false}
                              keyProp={'instructionLinkId'}
                              onDismiss={() => {
                                setOpenModal(false);
                              }}
                              isCompact={true}
                              getEditItem={(editItem: any) => {
                                setSelectedLink(editItem);
                              }}
                              selectionMode={1}
                              onItemInvoked={(item: any) => {
                                console.log({ item });
                              }}
                              openModal={openModal}
                            >
                              <ModalGridContext.Consumer>
                                {(modalProps: any) => (
                                  <>
                                    <AddLink selectedLink={selectedLink} onChange={(item: any) => {
                                      let list = addOrUpdateItem(item);
                                      input.onChange(list);
                                      modalProps.closeModal();
                                    }} />
                                  </>
                                )}
                              </ModalGridContext.Consumer>
                            </DataGridWithModal>

                          </div>
                        )}
                      </Field>
                    </div>

                  </div>
                  {/*<div style={{width:500,height:600,overflow:'auto'}}>*/}
                  {/*  <pre>  {JSON.stringify(values,null,2)}</pre>*/}
                  {/*</div>*/}
                </form>
              );
            }}
          />
        </>
      </DefaultCustomModal>
      <Button disabled={!props?.isEdit} onClick={() => {
        messageService.sendMessage({ saveWbsTask: true });
        setIsOpen(true);
      }} size='small'>{t('addInstructions')}</Button>
    </>
  );

};

export default AddInstruction;
