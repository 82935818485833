import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import Input from '../../../shared/fluentUI9/Input/Input';
import { Icon } from '@fluentui/react';
import { getContractingUnit, getProject } from '../../../shared/util';
import Dropdown from '../../../shared/fluentUI9/Dropdown/Dropdown';
import i18n from '../../../../i18n';
import { Divider, Label } from '@fluentui/react-components';
import TextEditor from '../../../shared/textEditor/textEditor';
import Tags from '../../wbsTask/documentPane/components/Tags';
import PeopleTags from '../../wbsTask/documentPane/components/PeopleTags';
import Document from '../../wbsTask/documentPane/components/Document';
import ConversationTaskComponent from '../../wbsTask/documentPane/components/ConversationTaskComponent';
import TimeLine from '../../wbsTask/documentPane/components/TimeLine';
import { useDispatch, useSelector } from 'react-redux';
import { createForm } from 'final-form';
import { useTranslation } from 'react-i18next';
import TaskComponent from '../../wbsTask/documentPane/components/TaskComponent';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import { useParams } from 'react-router';
import { ParamTypes } from '../../../types/uPrince';
import {
  createWbsIssue,
  getWbsIssueById,
  getWbsIssueDropdowns,
  saveWbsIssueStateAttr,
} from '../../../reducers/wbsIssueReducer';
import WbsTaxonomy from '../../wbsTask/documentPane/components/WbsTaxonomy';
import ErrorLabel from '../../../shared/fluentUI9/Label/ErrorLabel';
import DatePickerF9 from '../../../shared/fluentUI9/Datepicker/DatePicker';
import { saveWbsCreateStateAttr } from '../../../reducers/wbsCreateReducer';

interface Props {
  isNew: any;
}

const formValue = {
  id: null,
  toPerson: [],
  cc: [],
};

const WbsIssueDocumentPane = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { featureId }: ParamTypes = params;

  const formData = useSelector((state: any) => state.wbsIssue.formData);
  const wbsIssueDropdownData = useSelector((state: any) => state.wbsIssue.wbsIssueDropdownData);

  useEffect(() => {
    dispatch(getWbsIssueDropdowns());

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.saveWbsIssue) {
          formRef.current.submit();
        }
      }
    });

    return () => {
      console.log('issue document pane unmounted');
      subscription.unsubscribe();
      dispatch(saveWbsIssueStateAttr('formData', {}));
    };
  }, []);

  useEffect(() => {
    // console.log({ params });
    featureId && getFormData(featureId);

  }, [params]);

  const getFormData = (id: string) => {
    if (id) {
      Promise.all([dispatch(getWbsIssueById(id))]).then((res: any) => {
        // dispatch(saveWbsCreateStateAttr('reloadWbsProjectListPane', true));
        // if(res[0]?.payload?.data?.result?.templateId) dispatch(readWbsTaxonomyByTemId(res[0]?.payload?.data?.result?.templateId));
      });
    }
  };

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    Promise.all([dispatch(createWbsIssue(values))]).then((res: any) => {
      if (res && res[0]?.payload?.data?.result) {
        const featureId = res[0]?.payload?.data?.result;

        if (location.pathname.split('/').pop() != 'wbs') {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/wbs/issue/${featureId}`);
        } else {
          getFormData(featureId);
          dispatch(saveWbsCreateStateAttr('wbsListPaneMsg', true));
        }
      }
    }).catch((err) => {
    });
  };

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (formData && formData?.id) {
      return { ...formData };
    } else if (props.isNew && !formData?.id) {
      return {
        ...formValue,
        wbsId: props?.isNew?.pbsId,
      };
    }

    return formValue;
  };

  // const handleInitialValueRenderer = () => {
  //   return false;
  // };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues(formData, formValue)}
        // keepDirtyOnReinitialize={true}
        // initialValuesEqual={handleInitialValueRenderer}
        mutators={{
          setToPersons: (args, state) => {
            const field = state.fields.toPerson;
            field.change(args[0]);
          },
          setCcPersons: (args, state) => {
            const field = state.fields.cc;
            field.change(args[0]);
          },
          setPbsIdPersons: (args, state) => {
            const field = state.fields.pbsId;
            field.change(args[0]);
          },
          setProductIdPersons: (args, state) => {
            const field = state.fields.productId;
            field.change(args[0]);
          },
          setRaisedByName: (args, state) => {
            const field = state.fields.raisedByName;
            field.change(args[0]);
          },
          setResponsibleName: (args, state) => {
            const field = state.fields.responsibleName;
            field.change(args[0]);
          },
        }}
        validate={values => {
          const errors: any = {};
          if (!values?.title) {
            errors.title = t('nameRequired');
          }
          if (!values?.wbsId) {
            errors.wbsId = t('productRequired');
          }
          if (!values?.dateRaised) {
            errors.dateRaised = t('dateRaisedRequired');
          }
          if (!values?.lastUpdate) {
            errors.lastUpdate = t('lastUpdateRequired');
          }
          if (!values?.closureDate) {
            errors.closureDate = t('closureDateRequired');
          }
          if (!values?.decisionDate) {
            errors.decisionDate = t('decisionDateRequired');
          }
          return errors;
        }}
        render={({
                   handleSubmit,
                   form,
                   // submitting,
                   // pristine,
                   dirty,
                   values,
                 }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div className='document-pane-card'>
                <Field
                  name='toPerson'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='cc'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='raisedByName'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='responsibleName'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='productId'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />

                <div className={`document-pane-stack-item`}>
                  <Field name='wbsId'>
                    {({ input, meta }) => (
                      <div>
                        <WbsTaxonomy
                          disabled={false}
                          value={values?.wbsId ?? ''}
                          onChange={(node: any) => {
                            input.onChange(node?.id);
                            // form.mutators.setPbsIdPersons(node?.pbsId);
                            form.mutators.setProductIdPersons(node?.productId);
                          }}
                          formData={{ ...formData, wbsTaxonomyId: formData?.wbsId }}
                        />
                        {meta.touched && meta.error && !meta.submitting && <ErrorLabel errMsg={meta.error} />}
                      </div>
                    )}
                  </Field>
                </div>

                <Field name='title'>
                  {({ input, meta }) => (
                    <Input
                      onChange={(value: any) => input.onChange(value)}
                      placeholder={t('issue')}
                      contentBefore={<Icon iconName={'Warning'} />}
                      appearance='underline'
                      value={values?.title}
                      errorMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                      focus={true}
                    />
                  )}
                </Field>

                <div className={`document-pane-stack`}>
                  <div className={`document-pane-stack-item`}>
                    <Field name='type'>
                      {({ input }) => (
                        <Dropdown
                          label={i18n.t('type')}
                          placeholder={i18n.t('issueType')}
                          options={wbsIssueDropdownData?.type ?? []}
                          value={values?.type}
                          onChange={(value: any) => {
                            if (value?.key) {
                              input.onChange(value?.key);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={`document-pane-stack-item`}>
                    <Field name='status'>
                      {({ input }) => (
                        <Dropdown
                          label={i18n.t('status')}
                          placeholder={i18n.t('status')}
                          options={wbsIssueDropdownData?.status ?? []}
                          value={values?.status}
                          onChange={(value: any) => {
                            if (value?.key) {
                              input.onChange(value?.key);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`document-pane-stack`}>
                  <div className={`document-pane-stack-item`}>
                    <Field name='priority'>
                      {({ input }) => (
                        <Dropdown
                          label={i18n.t('priority')}
                          placeholder={i18n.t('priority')}
                          options={wbsIssueDropdownData?.priority ?? []}
                          value={values?.priority}
                          onChange={(value: any) => {
                            if (value?.key) {
                              input.onChange(value?.key);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={`document-pane-stack-item`}>
                    <Field name='severity'>
                      {({ input }) => (
                        <Dropdown
                          label={i18n.t('severity')}
                          placeholder={i18n.t('severity')}
                          options={wbsIssueDropdownData?.severity ?? []}
                          value={values?.severity}
                          onChange={(value: any) => {
                            if (value?.key) {
                              input.onChange(value?.key);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`document-pane-stack`}>
                  <div className={`document-pane-stack-item`}>
                    <Field name='dateRaised'>
                      {({ input, meta }) => (
                        <DatePickerF9
                          label={t('dateRaised')}
                          placeholder={t('dateRaised')}
                          selectedDate={values?.dateRaised}
                          onChange={(date: any) => input.onChange(date)}
                          errMsg={meta.touched && meta.error && !meta.submitting ? meta.error : null}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={`document-pane-stack-item`}>
                    <Label weight='semibold' style={{ margin: '5px 0' }}>{`${t('raisedBy')}`}</Label>
                    <Field name='raisedBy'>
                      {({ input }) => (
                        <div
                          style={{ marginTop: 5 }}>    {/* TODO: temp solution until make the UI changes for fluent 9*/}
                          <PeoplePicker
                            label={''}
                            placeHolder={i18n.t('raisedBy')}
                            isDisabled={false}
                            selectedItem={values?.raisedBy ? [{
                              key: values?.raisedBy,
                              text: values?.raisedByName,
                            }] : null}
                            onChange={(item: any) => {
                              input.onChange(null);

                              return item;
                            }}
                            onPersonSelected={(item: any) => {
                              if (item) {
                                // console.log(item);
                                input.onChange(item?.cabPersonId);
                                form.mutators.setRaisedByName(item?.text);
                              }

                              return item;
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`document-pane-stack`}>
                  <div className={`document-pane-stack-item`}>
                    <Field name='decisionDate'>
                      {({ input, meta }) => (
                        <DatePickerF9
                          label={t('decisionDate')}
                          placeholder={t('decisionDate')}
                          selectedDate={values?.decisionDate}
                          onChange={(date: any) => input.onChange(date)}
                          errMsg={meta.touched && meta.error && !meta.submitting ? meta.error : null}
                        />
                      )}
                    </Field>
                  </div>

                  <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Label weight='semibold' style={{ margin: '5px 0' }}>{`${t('responsible')}`}</Label>
                    <Field name='responsible'>
                      {({ input }) => (
                        <PeoplePicker
                          label={''}
                          placeHolder={i18n.t('responsible')}
                          isDisabled={false}
                          selectedItem={values?.responsible ? [{
                            key: values?.responsible,
                            text: values?.responsibleName,
                          }] : null}
                          onChange={(item: any) => {
                            input.onChange(null);

                            return item;
                          }}
                          onPersonSelected={(item: any) => {
                            if (item) {
                              //   console.log(item);
                              input.onChange(item?.cabPersonId);
                              form.mutators.setResponsibleName(item?.text);
                            }

                            return item;
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`document-pane-stack`}>
                  <div className={`document-pane-stack-item`}>
                    <Field name='closureDate'>
                      {({ input, meta }) => (
                        <DatePickerF9
                          label={t('closureDate')}
                          placeholder={t('closureDate')}
                          selectedDate={values?.closureDate}
                          onChange={(date: any) => input.onChange(date)}
                          errMsg={meta.touched && meta.error && !meta.submitting ? meta.error : null}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={`document-pane-stack-item`}>
                    <Field name='lastUpdate'>
                      {({ input, meta }) => (
                        <DatePickerF9
                          label={t('lastUpdate')}
                          placeholder={t('lastUpdate')}
                          selectedDate={values?.lastUpdate}
                          onChange={(date: any) => input.onChange(date)}
                          errMsg={meta.touched && meta.error && !meta.submitting ? meta.error : null}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`document-pane-field-wrapper ck-small-toolbar`}>
                  <Label weight='semibold'>{`${t('decision')}`}</Label>
                  <Field name='decision'>
                    {({ input }) => (
                      <TextEditor
                        value={values?.decision ?? ''}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`document-pane-field-wrapper`}>
                  <Field
                    name='tags'
                  >
                    {({ input }) => (
                      <Tags
                        value={values?.tags ? values?.tags : []}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`document-pane-field-wrapper`}>
                  <Field
                    name='peopleTags'
                  >
                    {({ input }) => (
                      <PeopleTags
                        to={values?.toPerson ? values?.toPerson : []}
                        cc={values?.cc ? values?.cc : []}
                        onToChange={(value: any) => {
                          if (value) {
                            // console.log("To",{value});
                            form.mutators.setToPersons(value);
                          }
                        }}
                        onCCChange={(value: any) => {
                          if (value) {
                            // console.log('cc', { value });
                            form.mutators.setCcPersons(value);
                          }
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`document-pane-field-wrapper`}>
                  <Field name='tasks'>
                    {({ input, meta }) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                          <TaskComponent
                            tasks={values?.tasks ?? []}
                            onChange={() => form.submit()}
                            isDisabled={true}
                            isTaskDeleteEnabled={false}
                            onClickAddTask={() => {
                              if (getProject()) {
                                /**
                                 *  Project level (WBS) product add task
                                 */
                                if (window?.location?.pathname?.split('/')?.pop() != 'new') {
                                  history.push({
                                    pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task/new`,
                                    state: { groupBy: null, wbsTaxonomyId: values?.id, productId: values?.productId },
                                  });
                                }
                              } else {
                                /**
                                 *  CU level (WBS Create) product add task
                                 */
                                if (window?.location?.pathname?.split('/')?.pop() != 'new') {
                                  history.push({
                                    pathname: `/CU/${getContractingUnit()}/wbs-cu-task/new`,
                                    state: { groupBy: null, wbsTaxonomyId: values?.id, productId: values?.productId },
                                  });
                                }
                              }

                              messageService.sendMessage({ resetWbsTaskForm: true });
                              // dispatch(saveWbsStateAttr('taxonomyFeatureId', featureId));
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>

                <div className={`document-pane-field-wrapper`}>
                  <Field
                    name='documents'
                  >
                    {({ input }) => (
                      <Document
                        documents={values?.documents ?? []}
                        onChange={(value: any) => {
                          const linkAsUrl = value.map((item: any) => {
                            return { ...item, url: item.link };
                          });
                          input.onChange(linkAsUrl);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`document-pane-field-wrapper`}>
                  <Field name='conversations'>
                    {({ input }) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                          <Label style={{ marginTop: 10 }} size='large'> {t('conversations')} </Label>
                        </div>
                        <div style={{ marginTop: 5, marginBottom: 10 }}><Divider /></div>
                        <div style={{ marginBottom: 10 }}>
                          <ConversationTaskComponent
                            conversations={values.conversations ?? []}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>


                <div className={`document-pane-field-wrapper`}>
                  <Field name='createdDateTime'>
                    {({ input }) => (
                      <TimeLine
                        value={{ creationDate: values?.createdDateTime }}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                        label={i18n.t('issueCreated')}
                      />
                    )}
                  </Field>
                </div>
              </div>

              {/*<div style={{ width: 500, height: 600, overflow: 'auto' }}>*/}
              {/*  <pre>  {JSON.stringify(values, null, 2)}</pre>*/}
              {/*</div>*/}

            </form>
          );
        }}
      />
    </>
  );
};

export default WbsIssueDocumentPane;