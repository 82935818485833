import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';
import { qrCode } from '../../../types/qrCode';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import {
  createOrgTaxonomy,
  deleteOrgTaxonomy,
  readByOrganisationSettingId,
  readOrganizationSettingTaxonomyLevels,
  readOrgPersonTypeDropdown,
  saveFormData,
} from '../../../reducers/organisationSettingReducer';
import { v4 as uuidv4 } from 'uuid';
import { OrganisationSettingListItem } from '../../../types/organisationSetting';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  uid: string | null;
  formData: any,
  filter: any,
}

interface Props extends WithTranslation {
  saveFormData: (data: any) => void;
  getAllProjectDropdowns: () => void;
  formData: any,
  isNew: boolean,
  isEdit: boolean;
  uid: string | null;
  // dropDowns: any;
  showProgressBar: boolean;
  isExpand: boolean;
  // osList: OrganisationSettingListItem[];
  orgTaxonomyLevels: any[];
  createOrgTaxonomy: any;
  readOrganizationSettingTaxonomyLevels: any;
  readByOrganisationSettingId: (id: string) => void;
  osId: string;
  selectedOrgSetting: any;
  deleteOrgTaxonomy: any
  personRoleDropdowns: any;
}

class DocumentPane extends React.Component<Props, State> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: qrCode,
      uid: null,
      isClear: false,
      filter: {},
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    }
    if (prevProps.osId !== this.props.osId) {
      if (this.props.osId) {
        this.props.readByOrganisationSettingId(this.props.osId);
      }
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData = this.props.formData;
          this.setState({ formData });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({ formData: formData });
        }
      }
    }
  }

  componentDidMount() {
    // get document pane dropdowns
    this.props.getAllProjectDropdowns();
    this.setState({ uid: uuidv4(), isClear: this.props.isNew });
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewOS) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }

          if (this.form) {
            this.form.reset();
            // this.form.resetFieldState('name');
          }
        }
        if (data.data.createNewNode) {
          this.props.createOrgTaxonomy(data.data.createNewNode);
        }
        if (data.data.closeTaxonomyModal) {
          this.props.readOrganizationSettingTaxonomyLevels();
        }
        if (data.data.saveOSData) {
          this.submitExternal();
        }
        if (data.data.osFilter) {
          this.setState({ filter: data.data.osFilter });
        }
        if (data.data.deleteOrgTaxonmy) {
          this.props.deleteOrgTaxonomy(data.data.deleteOrgTaxonmy.id);
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { osFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          // dropDowns={{ ...this.props.dropDowns, osList: this.props.osList }}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.showProgressBar}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          orgTaxonomyLevels={this.props.orgTaxonomyLevels}
          selectedOrgSetting={this.props.selectedOrgSetting}
          personRoleDropdowns={this.props.personRoleDropdowns}/>

      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.os.isEdit,
    isNew: state.os.isNew,
    uid: state.os.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.os.showProgressBar,
    selectedProject: state.project.selectedProject,
    // dropDowns: state.os.osDropDowns,
    // osList: state.os.taxonomyList,
    orgTaxonomyLevels: state.os.orgTaxonomyLevels,
    osId: state.os.osId,
    personRoleDropdowns: state.os.personRoleDropdowns,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  createOrgTaxonomy,
  readOrganizationSettingTaxonomyLevels,
  readByOrganisationSettingId,
  deleteOrgTaxonomy,
  readOrgPersonTypeDropdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane));
