import React from 'react';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../../services/messageService';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useSelector } from 'react-redux';

const WBSCmdBarLeft = () => {
  const { t } = useTranslation();
  let cmdBarItems: any[] = [];

  const selectedProject = useSelector((state: any) => state.project.selectedProject);

  const getItems = () => {
    const generateNew = {
      key: 'new',
      text: t('selectTemplate'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        messageService.sendMessage({ generateNew: true });
      },
      disabled: (selectedProject?.wbsTemplateId === undefined || selectedProject?.wbsTemplateId)
    };

    cmdBarItems.push(generateNew);

    return cmdBarItems;
  };

  return <div>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
  </div>;
};

export default WBSCmdBarLeft;
