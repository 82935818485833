import * as React from 'react';
import { createForm } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../shared/fluentUI9/Input/Input';
import { Icon } from '@fluentui/react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { messageService } from '../../../services/messageService';
import { saveNewWbsNode } from '../../../reducers/wbsCreateReducer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { WBS_TAXONOMY_LEVEL } from '../../../types/wbs';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { useEffect } from 'react';

const CreateWbsDocumentPane = (props:{selectedItem:any,onSaveWBs?:any}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const { selectedItem } =props;
  const onSubmit = async (values: any) => {
    const formData={
      ...values,
      id:uuidv4(),
    }
    Promise.all([dispatch(saveNewWbsNode(formData))]).then((res: any) => {
      console.log({res});
      props?.onSaveWBs(values)
      messageService.sendMessage({ reloadWbsListPane: true });
    })
  }


  const getInitialFormValues = () => {
    return {
      id: null,
      templateId: selectedItem?.templateId,
      title: null,
      wbsTaxonomyLevelId: selectedItem?.wbsTaxonomyLevelId,
      parentId: selectedItem?.id,
      isDefault: false,

    };
  }

const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  useEffect(() => {
    formRef.current.reset(getInitialFormValues())
  }, [selectedItem]);

const getItems=()=>{
 return [ {
    key: 'save',
    text: t('save'),
    iconProps: { iconName: 'Save' },
    onClick: () => {
      formRef.current.submit()
    },
    disabled: false,
  }];
}
 return (
  <div style={{height:"100%",width:"100%",overflow:"hidden"}}>
    <div className={'wbs__command__bar'}>
      <CommandBar
        styles={{ root: { height: 26 } }}
        items={getItems()}
        ariaLabel='Use left and right arrow keys to navigate between commands'
      />
      <ProgressBar show={showWbsProgressBar}/>
    </div>
    <div className={'wbs__form-container'}>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues()}
        validate={values => {
          const errors: any = {};
          if (!values?.title) {
            errors.title = t('titleRequired');
          }
          return errors;
        }}
        render={({
                   handleSubmit,
                   form,
                   submitting,
                   pristine,
                   values,
                 }) => {
          formRef.current = form;
          return (
            <form style={{height:"100%",width:"100%"}} onSubmit={handleSubmit} noValidate>
              <div className={'wbs__card'} style={{paddingBottom:30}}>
                <Field name='title'>
                  {({ input, meta }) => (
                    <Input
                      onChange={(value: any) => {
                      input.onChange(value);
                    }}
                     placeholder={t("title")}
                     appearance='underline'
                     value={values?.title}
                     errorMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                     focus={true}
                    />
                  )}
                </Field>
              {/*<pre> {JSON.stringify(values,null,2)}</pre>*/}
              </div>
            </form>
          );
        }}
      />
    </div>

  </div>
 );

};

export default CreateWbsDocumentPane
