import React, {useEffect, useState} from 'react';
import {ChartType, Task} from '../../types/public-types';
import SortableTree, {
    addNodeUnderParent,
    changeNodeAtPath,
    getFlatDataFromTree,
    getNodeAtPath,
    getTreeFromFlatData,
    insertNode,
    removeNodeAtPath,
    toggleExpandedForAll,
    TreeItem,
    walk
} from '@nosferatu500/react-sortable-tree';
import {initListTasks} from '../../../../components/visualPlan/listPane/helper';
import {
    CommandBarButton,
    ContextualMenuItemType,
    DirectionalHint,
    FontIcon,
    FontWeights,
    IButtonStyles,
    Icon,
    IIconProps,
    ITheme,
    Label,
    memoizeFunction,
    TextField,
    TooltipHost,
} from '@fluentui/react';
import {uPrinceTheme} from '../../../../../theme';
import {messageService} from '../../../../services/messageService';
import {
  createPBSCloneOrVirginChildOrSibling,
  createPRFromBor, deleteMidTermAssignedLabour,
  deleteVpShiftItem,
  readVPWareHouseTaxanomyData,
  saveVPStateAttr,
  updateProductTaxonomyTreeIndex,
  updateTreeIndexOfNewSibling,
} from '../../../../reducers/visualPlanReducer';
import {useDispatch, useSelector, useStore} from 'react-redux';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import useExpand from '../../../../hooks/useExpand';
import {PBS_STATE} from '../../../../components/projectVisualPlan/productTaxonomyListPane/component';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import {
  getBorNode,
  getClonedNode, getCPCNode,
  getEmptyRows,
  getNewBorNode,
  getNewBorNodepath,
  getNewPBSNodepath,
  getNewPMOLNode,
  getNewPMOLNodeForGeneratePMOlFromPBS,
  getNewPMOLNodepath, getPbsAssignedLabourNode,
  getPBSNode,
  getPBSNodeMyEnv,
  getPMOLResourceChildrens,
  getProjectNode,
} from './task-list-helper';
import {getById, savePbsStateAttr} from '../../../../reducers/projectBreakdownReducer';
import {saveBORStateAttr} from '../../../../reducers/billOfResourcesReducer';
import {readByPurchaseOrderId} from "../../../../reducers/purchaseOrderReducer";
import {readPMOLByID, savePMOLStateAttr} from '../../../../reducers/pmolReducer';
import DiscardDialog from '../../../../components/cuProjects/discardDialog';
import {Popover} from '@varld/popover';
import StatusPopOver from './statusPopOver';
import useMoreMenu from '../../../../hooks/useMoreMenu';
import PmolStatusPopOver from './pmolStatusPopOver';
import client from '../../../../api';
import {isCuProjectPlanning, isMyEnvironment} from '../../../util';
import {saveStateAttr} from '../../../../reducers/uprinceReducer';
import ProjectScopePopOver from './projectScopePopOver';
import {saveProjectStateAttr} from '../../../../reducers/projectReducer';
import _ from 'lodash';


export const TaskListTableDefault: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  listTasks: Task[];
  isInitialCollaps:boolean;
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
  onVisibilityChanged: (data: any) => void;
  canDrag:(dragProps:any) => boolean;
  canDrop:(dropProp:any) => boolean;
  onMoveNode:(treeData:any, node:any, nextParentNode:any, prevPath:any, prevTreeIndex:any, nextPath:any, nextTreeIndex:any, nodeTwo?:any) => void
  chartType:ChartType;
  onTreeDataChange:(treeData:any) => void;
  searchQuery: string;
  searchMethod:({ node, searchQuery }:any) => any
  searchFocusOffset: number;
  searchFinishCallback: ((matches:any) => void )
  onlyExpandSearchedNodes: boolean
  onVisibilityToggle: (data:any) => void
}> = ({
    rowHeight,
    rowWidth,
    tasks,
    listTasks,
    isInitialCollaps,
    fontFamily,
    fontSize,
    locale,
    onExpanderClick,
    onVisibilityChanged,
    canDrag,
    canDrop,
    onMoveNode,
    chartType,
    onTreeDataChange,
    searchQuery,
    searchMethod,
    searchFocusOffset,
    searchFinishCallback,
    onlyExpandSearchedNodes,
    onVisibilityToggle
}) => {
    const browserLocale = navigator.language;
    const [modalVisible, setModalVisible] = useState(false);
    const [pendingNode, setPendingNode] = useState<any>(null);
    const {isExpand,isApplyExpandContext, setIsApplyExpandContext} = useExpand()
    const {isPmolCreate, setPmolCreate, product, setProduct} = useMoreMenu()
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isDocumentPaneShow: boolean = useSelector((state: any) => state.vp.showvpProjectDocumentPane);
    const createdPrId: string = useSelector((state: any) => state.vp.createdPrId);
    const { projectScopeStatus }: any = useSelector((projectState: any) => projectState.project);

  const isPBSDirty: boolean = useSelector(
    (state: any) => state.vp.isPBSDirty);

  const siblingCount: number = useSelector(
    (state: any) => state.vp.siblingCount);

  const vpLTPpath: boolean = useSelector(
      (state: any) => state.vp.vpLTPpath);

  const { isPBSOpenFromVP} = useSelector(
      (state: any) => state.projectBreakdown,
  );

  const { borCreatedFromVP} = useSelector(
      (state: any) => state.billOfResource,
  );

  const { selectedProjectItemType } = useSelector(
    (state: any) => state.vp
  );

  const store = useStore();
  let createdVPBOR = store.getState()?.billOfResource?.createdVPBOR
  let pmolCreatedVP = store.getState()?.pmol?.pmolCreatedVP
  let pmolCreatedFromVP = store.getState()?.pmol?.pmolCreatedFromVP
  let isPMOLOpenFromVP = store.getState()?.pmol?.isPMOLOpenFromVP
  let updatedVPPMOL = store.getState()?.pmol?.updatedVPPMOL
  let isBOROpenFromVP = store.getState()?.billOfResource?.isBOROpenFromVP
  let updatedVPPBS = store.getState()?.projectBreakdown?.updatedVPPBS
  let isLabourTeamAssigned = store.getState()?.vp?.isLabourTeamAssigned
  let childCount = store.getState()?.vp?.childCount
  let expandNode = store.getState()?.vp?.expandNode
  let vpPMOLGeneratedWithPBS = store.getState()?.projectBreakdown?.vpPMOLGeneratedWithPBS
  let pmolCreatedWithResources = store.getState()?.projectBreakdown?.pmolCreatedWithResources
  let isDeletedFromVp = store.getState()?.projectBreakdown?.isDeletedFromVp
  let personUpdatedDateVP = store.getState()?.vp?.personUpdatedDateVP
  let resourcePaths = store.getState()?.vp?.resourcePaths
  let labourPersonId = store.getState()?.vp?.labourPersonId
  let updatedProjectFromPP = store.getState()?.project?.updatedProjectFromPP
  let updatedPbsMyEnv = store.getState()?.projectBreakdown?.updatedPbsMyEnv
  let createdNewProductFromMyEnv = store.getState()?.vp?.createdNewProductFromMyEnv
  let pbsAssignedLabourNodes = store.getState()?.vp?.pbsAssignedLabourNodes
  let deleteMidTermLabourSuccess = store.getState()?.vp?.deleteMidTermLabourSuccess
  let deletingMidTermLabourData = store.getState()?.vp?.deletingMidTermLabourData
  let createLabourStatus = store.getState()?.projectBreakdown?.createLabourStatus
  let labours = store.getState()?.projectBreakdown?.labours
  let readLabourStatus = store.getState()?.projectBreakdown?.readLabourStatus
  // let siblingCount = store.getState()?.vp?.siblingCount

  const getButtonStyle = (isSelected:boolean) => {
    return {
      root: {
          minWidth: 0,
          padding: '0 4px',
          alignSelf: 'stretch',
          height: 30,
          width: 20,
          fontSize: 20,
          background: 'transparent'
      },
      menuIcon: {
          fontSize: 20,
          color: isSelected ? '#FFFFFF': uPrinceTheme.palette.themePrimary
      }
  }
  }

  const addIcon: IIconProps = { iconName: 'MoreVertical' };
  const buttonStyles: Partial<IButtonStyles> = {
      root: {
          minWidth: 0,
          padding: '0 4px',
          alignSelf: 'stretch',
          height: 30,
          width: 20,
          fontSize: 20,
          background: 'transparent'
      },
      menuIcon: {
          fontSize: 20,
          color: uPrinceTheme.palette.themePrimary
      }
  };

  const [ treeListData, setTreeData ]: any = useState(initListTasks());
  const [ treeListDataO, setTreeDataO ]: any = useState(treeListData);

  const {
    vpProjectSelectedShortcutpaneId,
    vpPBSCloneSuccess,
    vpClonedPBSID,
    expandPath,
    siblingIndex,
  }: any = useSelector(
    (state: any) => state.vp,
  );

    // keeping the same tree structure even after the document pane save and update the list pane
    useEffect(() => {

        if (chartType === ChartType.PBS || chartType === ChartType.PBSTAXONOMY) {
            const comNode: any = getNodeAtPath({
                treeData: treeListData,
                path: expandPath,
                getNodeKey: getNodeKey,
            });

            if (!isPBSOpenFromVP && comNode && updatedVPPBS && !isMyEnvironment()) {
                const treeData = changeNodeAtPath({
                    treeData: treeListData,
                    path: expandPath,
                    newNode: ({ node }: any) => getPBSNode(updatedVPPBS, siblingIndex, comNode, isDocumentPaneShow),
                    getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
                // dispatch(savePbsStateAttr('updatedVPPBS', null));    need the value for handlePBSRemove in commandBarRight.tsx
            }

            if (!isPBSOpenFromVP && comNode && updatedPbsMyEnv && (isMyEnvironment() || isCuProjectPlanning())) {

                if (createdNewProductFromMyEnv) {
                    let t: TreeItem[] | undefined = [];
                    t = addNodeUnderParent({
                        treeData: treeListData,
                        parentKey: expandPath[expandPath.length - 1],
                        getNodeKey,
                        newNode: getPBSNodeMyEnv(updatedPbsMyEnv, siblingIndex + 1, comNode, isDocumentPaneShow),
                        addAsFirstChild: true,
                        expandParent: true,
                    }).treeData;
                    // console.log('t', t)
                    setTreeData(t);
                    dispatch(savePbsStateAttr('updatedPbsMyEnv', null));
                    dispatch(saveVPStateAttr('createdNewProductFromMyEnv', false));
                } else {
                    const treeData = changeNodeAtPath({
                        treeData: treeListData,
                        path: expandPath,
                        newNode: ({ node }: any) => getPBSNode(updatedPbsMyEnv, siblingIndex + 1, comNode, isDocumentPaneShow),
                        getNodeKey: ({ treeIndex }) => treeIndex,
                    });
                    setTreeData(treeData);
                    dispatch(savePbsStateAttr('updatedPbsMyEnv', null));
                }

            }

            if (isLabourTeamAssigned && isCuProjectPlanning()) {
              if (isLabourTeamAssigned) {
                const treeData = changeNodeAtPath({
                  treeData: treeListData,
                  path: expandPath,
                  newNode: ({ node }: any) => getPbsAssignedLabourNode(node, pbsAssignedLabourNodes, comNode, siblingIndex),
                  getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
                dispatch(savePbsStateAttr('updatedPbsMyEnv', null));
                dispatch(saveVPStateAttr('isLabourTeamAssigned', false));
                dispatch(saveVPStateAttr('pbsAssignedLabourNodes', []));
              }
            }

            // When adding a new labour in the PBS document pane  in midTermPlanning
            if (createLabourStatus && !readLabourStatus && isCuProjectPlanning()) {
              if (createLabourStatus) {

                const newCPCNode = labours?.filter((cpc: any) => !comNode?.node?.children?.some((child: any) => child?.cpcId === cpc?.cpcKey));

                if (!_.isEmpty(newCPCNode)) {
                  let t: TreeItem[] | undefined = [];
                  t = addNodeUnderParent({
                    treeData: treeListData,
                    parentKey: expandPath[expandPath.length - 1],
                    getNodeKey,
                    newNode: getCPCNode(newCPCNode[0], comNode),
                    addAsFirstChild: true,
                    expandParent: true,
                  }).treeData;
                  setTreeData(t);
                  dispatch(savePbsStateAttr('createLabourStatus', false));
                }
              }
            }

            if (!isPBSOpenFromVP && comNode && updatedProjectFromPP && (isMyEnvironment() || isCuProjectPlanning())) {
                const treeData = changeNodeAtPath({
                    treeData: treeListData,
                    path: expandPath,
                    newNode: ({ node }: any) => getProjectNode(node, siblingIndex, comNode, isDocumentPaneShow, updatedProjectFromPP),
                    getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
                dispatch(saveProjectStateAttr('updatedProjectFromPP', null));
            }

            if (!isPMOLOpenFromVP && comNode && updatedVPPMOL) {
                const treeData = changeNodeAtPath({
                    treeData: treeListData,
                    path: expandPath,
                    newNode: getNewPMOLNode(updatedVPPMOL, siblingIndex, comNode, store.getState()?.vp?.selectedProjectItemType),
                    getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
                dispatch(savePMOLStateAttr('updatedVPPMOL', null));
                // dispatch(saveVPStateAttr('vpProjectBarList', [...formatVpProductBarDataWithoutRefresh(treeData)[0]]));
            }

            if (!isBOROpenFromVP && comNode && createdVPBOR) {
                const treeData = changeNodeAtPath({
                    treeData: treeListData,
                    path: expandPath,
                    newNode: getBorNode(createdVPBOR, siblingIndex, comNode),
                    getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
                dispatch(saveBORStateAttr('createdVPBOR', null));
            }

            // Deletes a node from the tree after Clicking remove button from the command bar
            if (isDeletedFromVp) {
                const treeData = removeNodeAtPath({
                    treeData: treeListData,
                    path: expandPath,
                    getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
                dispatch(savePbsStateAttr('isDeletedFromVp', false));
            }

            if (deleteMidTermLabourSuccess) {
              const getPbsAssignedLabourNodeToDelete = (treeData: any): any => {
                let pathOfNodeToBeDeleted: never[] = [];

                const callback = ({ node, path }: any) => {
                  if (node?.cpcId === deletingMidTermLabourData?.cpcId && (node?.cabPersonId === deletingMidTermLabourData?.cabPersonId) || (node?.personId === deletingMidTermLabourData?.cabPersonId)) {
                    pathOfNodeToBeDeleted = path;
                  }
                };

                walk({
                  treeData,
                  getNodeKey,
                  callback,
                  ignoreCollapsed: true,
                });

                return pathOfNodeToBeDeleted;
              };

              if (!_.isEmpty(getPbsAssignedLabourNodeToDelete(treeListData))) {
                const treeData = removeNodeAtPath({
                  treeData: treeListData,
                  path: getPbsAssignedLabourNodeToDelete(treeListData),
                  getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
              }
              dispatch(saveVPStateAttr('deleteMidTermLabourSuccess', false));
              dispatch(saveVPStateAttr('deletingMidTermLabourData', null));
            }
        }
    }, [updatedVPPBS, isPBSOpenFromVP, isPMOLOpenFromVP, updatedVPPMOL, isBOROpenFromVP, createdVPBOR, isDeletedFromVp, updatedProjectFromPP, isLabourTeamAssigned, deleteMidTermLabourSuccess, readLabourStatus]);

    useEffect(() => {

        const tData = getTreeFromFlatData({
            flatData: listTasks,
            getKey: (node: any) => node.id,
            getParentKey: (node: any) => node.parentId,
            // @ts-ignore
            rootKey: null
        });
      setTreeDataO(tData);
        if(isApplyExpandContext) {
            setTreeData(
                toggleExpandedForAll({
                    treeData: tData,
                    expanded: !isExpand
                })
            );
          } else {
            setTreeData(tData)
          }

    }, [ listTasks, isInitialCollaps, isExpand, isApplyExpandContext ]);

    useEffect(() => {
        onTreeDataChange(treeListData)
        if (chartType === ChartType.RESOURCE) {
          getResourcePath(treeListData)
        }
    },[treeListData])

    useEffect(() => {
     if (!isDocumentPaneShow){
      resetSelectedItem()
     }
    },[isDocumentPaneShow])

    useEffect(() => {

      if (isLabourTeamAssigned) {
        if (chartType === ChartType.PO && vpLTPpath && Array.isArray(vpLTPpath)&& vpLTPpath.length !== 0) {
          const comNode: any = getNodeAtPath({
            treeData: treeListData,
            path: vpLTPpath,
            getNodeKey: getNodeKey,
          });
          if (comNode) {
              const treeData = removeNodeAtPath({
                      treeData: treeListData,
                      path: vpLTPpath,   // You can use path from here

                      // TODO - need to handle the case where a node cannot be deleted
                      getNodeKey: getNodeKey,
                      // ignoreCollapsed: false,
                  })

                  setTreeData(treeData);
                }
           }
           dispatch(saveVPStateAttr('vpLTPpath',[]))
           dispatch(saveVPStateAttr('isLabourTeamAssigned',false))
      }

     },[isLabourTeamAssigned, vpLTPpath])

    useEffect(() => {
      const comNode: any = getNodeAtPath({
        treeData: treeListData,
        path: expandPath,
        getNodeKey: getNodeKey,
      })

        if (vpPBSCloneSuccess) {
            if (chartType === ChartType.PBSTAXONOMY) {
                // insert newly created item without refreshing, remove dummy data and calculate.then push dummy data
                if (expandPath) {
                  let t :TreeItem[] | undefined = []
                  if(siblingIndex === -2) { // Creating a Sibling node

                    treeListData.splice(treeListData.length-100, 100)
                    t = insertNode({
                      treeData: treeListData,
                      depth: expandPath?.length - 1,
                      minimumTreeIndex: getSiblingRequesterNodeIndex(treeListData) + 1,
                      expandParent: true,
                      getNodeKey,
                      newNode: getClonedNode(vpClonedPBSID, childCount, vpClonedPBSID.id),
                    }).treeData

                  } else if (siblingIndex === -1) { // Creating child node

                   treeListData.splice(treeListData.length-100, 100)
                   t =  insertNode({
                    treeData: treeListData,
                    depth: expandPath?.length,
                    expandParent: true,
                    getNodeKey,
                    minimumTreeIndex: getSiblingRequesterNodeIndex(treeListData) + 1,
                    newNode: getClonedNode(vpClonedPBSID,childCount),
                  }).treeData


                  const comNode: any = getNodeAtPath({
                    treeData: t,
                    path: expandPath,
                    getNodeKey: getNodeKey,
                  });

                  if (comNode) {
                    t = changeNodeAtPath({
                      treeData: t,
                      path: expandPath,
                      newNode: ({ node }:any) => ({ ...node, expanded: true }),
                      getNodeKey,
                    });
                  }
                }

                  if (treeListData[0]?.isFilter) {
                    // Node that requested a sibling node
                    const initialNode: any = getNodeAtPath({
                      treeData: t,
                      path: expandPath,
                      getNodeKey: getNodeKey,
                    });

                    const addedNewNode = [
                      {
                        pbsProductId: vpClonedPBSID.pbsSequenceId, // sequenceId of the newly created node
                        treeIndex: initialNode.node.treeIndex + 1, // required treeIndex of the newly created node
                      },
                    ];

                    if (initialNode && addedNewNode) {
                      // If filtered, then updating only the tree indexes of the newly created node
                      dispatch(updateTreeIndexOfNewSibling({ pbsTreeIndex: addedNewNode }));
                    }
                  } else {
                    // If not filtered, then updating the whole tree indexes
                    const getTreeIndexList = (treeData: TreeItem[] | undefined) => {
                      let modifiedArray: any[] = [];
                      const callback = (props: any) => {
                        if (props.node.id.length > 4) {
                          modifiedArray.push({
                            pbsProductId: props.node.id,
                            treeIndex: props.treeIndex,
                          });
                        }
                      };

                      walk({
                        treeData,
                        getNodeKey,
                        callback,
                        ignoreCollapsed: false,
                      });
                      return modifiedArray;
                    };
                    dispatch(updateProductTaxonomyTreeIndex({ pbsTreeIndex: getTreeIndexList(t) }));
                  }

                  t?.push(...getEmptyRows())
                  setTreeData(t)
                  const fData = getFlatDataFromTree({
                    treeData: t,
                    getNodeKey: (node: any) => node.pbsSequenceId,
                    ignoreCollapsed: false,
                  });

                  let modifiedIndex = fData.findIndex((t1:any) => {
                    if(t1?.node?.pbsSequenceId === vpClonedPBSID.pbsSequenceId){
                      return true
                    } else {
                      return false
                    }
                  })
                  let dataSet: any = fData.map((item, index) => {
                    return {
                      ...item?.node,
                      isSelected: (index === modifiedIndex),
                    };
                  });
                  const tDatax = getTreeFromFlatData({
                    flatData: dataSet,
                    getKey: (node: any) => node.id,
                    getParentKey: (node: any) => node.parentId,
                    // @ts-ignore
                    rootKey: null,
                  });
                  dispatch(saveVPStateAttr('expandPath', getNewPBSNodepath(tDatax,vpClonedPBSID?.pbsSequenceId)));
                  dispatch(saveVPStateAttr('vpProducTaxonomyList', [...dataSet]))
                  dispatch(saveVPStateAttr('expandNode', getClonedNode(vpClonedPBSID,childCount)))
              }
                  dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                  dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
                  dispatch(saveVPStateAttr('slectedPBSForBor', null));
                  dispatch(saveVPStateAttr('selectedPBS', vpClonedPBSID?.pbsSequenceId));
                  dispatch(saveVPStateAttr('vpPBSCloneSuccess',false))
                  dispatch(saveVPStateAttr('borInheritedPBSTitle',null))
            }
        }
        if (personUpdatedDateVP) {
          if (chartType === ChartType.RESOURCE) {
            let t :TreeItem[] | undefined = []
            t = addNodeUnderParent({
              treeData: treeListData,
              parentKey:  resourcePaths.get(labourPersonId)[resourcePaths.get(labourPersonId).length - 1],
              expandParent: true,
              getNodeKey,
              newNode: getClonedNode(personUpdatedDateVP[0],siblingIndex),
              addAsFirstChild: true,
            }).treeData
            setTreeData(t)
            dispatch(saveVPStateAttr('personUpdatedDateVP',null))
          }
      }

        if (borCreatedFromVP){
          if (chartType === ChartType.PBS) {
            if (expandPath) {
              const t = addNodeUnderParent({
                treeData: treeListData,
                parentKey:  expandPath[expandPath.length - 1],
                expandParent: true,
                getNodeKey,
                newNode: getNewBorNode(createdVPBOR,siblingIndex),
                addAsFirstChild: true,
              })
              dispatch(saveVPStateAttr('expandPath', getNewBorNodepath(t.treeData,createdVPBOR?.id)));
              setTreeData(t.treeData)
              const fData = getFlatDataFromTree({
                treeData: t.treeData,
                getNodeKey: (node: any) => node.id,
                ignoreCollapsed: false,
              });

              let dataSet: any = fData.map((item) => {
                return {
                  ...item?.node,
                };
              });
              dispatch(saveVPStateAttr('vpProjectList', [...dataSet]))
              if (createdVPBOR){
                dispatch(saveBORStateAttr("borCreatedFromVP",false))
                dispatch(saveBORStateAttr("isBOROpenFromVP",true))
              }
          }
          }
        }
        if (pmolCreatedFromVP || vpPMOLGeneratedWithPBS){
            if (chartType === ChartType.PBS) {
                if (expandPath) {
                    if (pmolCreatedVP) {
                        addPmolUnderPbs(treeListData, pmolCreatedVP, null, null)
                    } else if (pmolCreatedWithResources && Array.isArray(pmolCreatedWithResources) && pmolCreatedWithResources.length > 0) {
                        // const currentDate = new Date()
                        let tree = treeListData
                        pmolCreatedWithResources.forEach(async (pmol) => {
                            let childrenRes: any[] = []
                            // console.log('pmolCreatedWithResources>',pmol);
                            const response: any = await client.get(`Pmol/ReadPmolId/${pmol?.projectMoleculeId}`)

                            if (response?.data?.result && response?.data?.result.plannedResource) {
                                childrenRes = getPMOLResourceChildrens(response, pmol)
                            }
                            pmol.isSelected = true
                            tree = addPmolUnderPbs(tree, pmol, comNode, childrenRes)
                        });
                        dispatch(savePbsStateAttr('pmolCreatedWithResources', []))
                    }
                }
            }

          return () => {


          }
        }
    },[vpPBSCloneSuccess, borCreatedFromVP, pmolCreatedFromVP, createdVPBOR, pmolCreatedVP, vpPMOLGeneratedWithPBS, personUpdatedDateVP])

    const checkIndex = (rowInfo : any) => {
      return rowInfo.id == expandNode.id;
    }
    const addPmolUnderPbs = (treeData:any,pmolCreatedVP:any, pbsNode:any, children:any) => {
      const t = addNodeUnderParent({
        treeData: treeData,
        parentKey: expandPath[expandPath.length-1],
        expandParent: true,
        getNodeKey,
        newNode: pbsNode ? getNewPMOLNodeForGeneratePMOlFromPBS(pmolCreatedVP, siblingIndex, pbsNode, children) : getNewPMOLNode(pmolCreatedVP,siblingIndex,pbsNode, store.getState()?.vp?.selectedProjectItemType),
        addAsFirstChild: true,
      }).treeData

      const fData = getFlatDataFromTree({
        treeData: t,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false,
      });
      let dataSet: any = fData.map((item) => {
        return {
          ...item?.node,
        };
      });
      dispatch(saveVPStateAttr('vpProjectList', [...dataSet]));
      dispatch(savePMOLStateAttr("isPMOLOpenFromVP",false));
      dispatch(saveVPStateAttr('expandPath', getNewPMOLNodepath(t,pmolCreatedVP?.header?.id)));
      dispatch(savePMOLStateAttr("pmolCreatedVP",null))
      dispatch(savePMOLStateAttr("pmolCreatedFromVP",false))
      dispatch(saveVPStateAttr('selectedBORforPMOL', null));
      dispatch(saveVPStateAttr('slectedPBSForPMOL', null));
      dispatch(saveVPStateAttr('pmolInheritedBORTitle', null));

      return t
    }

    const removeDuplicates = (listTask:any) => {
      const fData = getFlatDataFromTree({
        treeData: listTask,
        getNodeKey: (node: any) => node.pbsSequenceId,
        ignoreCollapsed: false,
      });

      let nodes: any = fData.map((item) => {
        return {
          ...item?.node,
        };
      });

      const ids = nodes.map((o:any )=> o.id)
      const filtered = nodes.filter(({id}:any, index:any) => !ids.includes(id, index + 1))
      //let filterNodes = nodes.filter((item:any, i:any) => nodes.indexOf(item) === i)

      return filtered
    }

    const enableMoreButton = () => {
        let enable = false
        switch(vpProjectSelectedShortcutpaneId[0]) {
            case '0':
                enable =  true
                break
            case '1':
                enable =  true
                break
            case '2':
                enable =  true
                break
            case '7':
                enable =  true
                break
            case '5':
                enable =  true
                break
            case '6':
                enable =  true
                break
            case '12':
                enable =  true
                break
            case null:
                enable =  true
                break
            default:
                enable =  false
                break;
        }
        return enable
    }

    const manuallyExpanAllChildrens = (node: any) => {
      const ffData = getFlatDataFromTree({
        treeData: [node],
        getNodeKey: (node: any) => node.pbsSequenceId,
        ignoreCollapsed: false,
      });
      let updated: any = ffData.map((item) => {
        return {
          ...item?.node,
          expanded: !item?.node?.expanded,
        };
      });

      const tData = getTreeFromFlatData({
        flatData: updated,
        getKey: (node: any) => node.id,
        getParentKey: (node: any) => node.parentId,
        // @ts-ignore
        rootKey: null,
      });
      return tData;
    };

    const getMenuProps = (rowInfo:any, chartType:ChartType) => {
        const pbsMenuItems: any = [
          {
            key: 'newSiblingPBS',
            text: t('newSiblingPBS'),
            iconProps: { iconName: 'Copy' },
            onClick: () => {
              if (isMyEnvironment()) {
                dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
              }
              if (isCuProjectPlanning()) {
                dispatch(saveVPStateAttr('selectedProject',rowInfo?.node?.project));
              }
              dispatch(
                createPBSCloneOrVirginChildOrSibling({
                  pbsId: rowInfo?.node?.pbsSequenceId,
                  isClone: false,
                  treeType: '2', // sibling
                },
                  rowInfo?.node?.project // This was added to fix API call issue in cuProjectPlanning
                ),
              );
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
              dispatch(saveVPStateAttr('expandNode', rowInfo?.node));
              // dispatch(saveVPStateAttr('siblingIndex', rowInfo?.node?.treeIndex));
              dispatch(saveVPStateAttr('siblingIndex', -2));
              dispatch(saveVPStateAttr('childCount', rowInfo?.treeIndex+2 ));
            },
          },
          {
            key: 'newChildPBS',
            text: t('newChildPBS'),
            iconProps: { iconName: 'RowsChild' },
            onClick: () => {
              if (isMyEnvironment()) {
                dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
              }
              if (isCuProjectPlanning()) {
                    dispatch(saveVPStateAttr('selectedProject',rowInfo?.node?.project));
              }
              dispatch(
                createPBSCloneOrVirginChildOrSibling({
                  pbsId: rowInfo?.node?.pbsSequenceId,
                  isClone: false,
                  treeType: '1', // sibling
                },
                  rowInfo?.node?.project
                ),
              );
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
              dispatch(saveVPStateAttr('expandNode', rowInfo?.node));
              dispatch(saveVPStateAttr('siblingIndex', -1));
              dispatch(saveVPStateAttr('childCount', rowInfo?.node?.children ? rowInfo?.node?.children?.length: rowInfo?.treeIndex ));
            },
          },
          {
            key: 'cloneAsChildPBS',
            text: t('cloneAsChildPBS'),
            iconProps: { iconName: 'RowsChild' },
            onClick: () => {
              if (isMyEnvironment()) {
                dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
              }
              if (isCuProjectPlanning()) {
                    dispatch(saveVPStateAttr('selectedProject',rowInfo?.node?.project));
              }
              dispatch(
                createPBSCloneOrVirginChildOrSibling({
                  pbsId: rowInfo?.node?.pbsSequenceId,
                  isClone: true,
                  treeType: '1', // child
                },
                  rowInfo?.node?.project
                ),
              );
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
              dispatch(saveVPStateAttr('expandNode', rowInfo?.node));
              dispatch(saveVPStateAttr('siblingIndex', -1));
              dispatch(saveVPStateAttr('childCount', rowInfo?.node?.children ? rowInfo?.node?.children?.length: rowInfo?.treeIndex ));
            },
          },
          {
            key: 'cloneAsSiblingPBS',
            text: t('cloneAsSiblingPBS'),
            iconProps: { iconName: 'Copy' },
            onClick: () => {
              if (isMyEnvironment()) {
                dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
              }
              if (isCuProjectPlanning()) {
                    dispatch(saveVPStateAttr('selectedProject',rowInfo?.node?.project));
              }
              dispatch(
                createPBSCloneOrVirginChildOrSibling({
                  pbsId: rowInfo?.node?.pbsSequenceId,
                  isClone: true,
                  treeType: '2', // sibling
                },
                  rowInfo?.node?.project
                ),
              );
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
              dispatch(saveVPStateAttr('siblingIndex', -1));
              dispatch(saveVPStateAttr('childCount',  rowInfo?.treeIndex+2 ));
              dispatch(saveVPStateAttr('expandNode', rowInfo?.node));
            },
          },
          {
            key: 'newBor',
            text: t('newBor'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
              if (isMyEnvironment()) {
                dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
              }
              if (isCuProjectPlanning()) {
                    dispatch(saveVPStateAttr('selectedProject',rowInfo?.node?.project));
              }
              messageService.sendMessage({
                rightClickTask: {
                  handleWidth: 8,
                  height: 37.2,
                  hideChildren: undefined,
                  id: rowInfo?.node?.id,
                  index: 0,
                  isHidden: false,
                  linkId: undefined,
                  milestoneSequanceId: null,
                  name: rowInfo?.node?.name,
                  parentId: null,
                  pbsSequenceId: rowInfo?.node?.pbsSequenceId,
                  pmolSequenceId: null,
                  poId: undefined,
                  progress: 0,
                  progressWidth: 0,
                  progressX: 105,
                  project: undefined,
                  requiredQuantity: undefined,
                  resourceType: undefined,
                  start: null,
                  teams: undefined,
                  title: rowInfo?.node?.title,
                  toolsPool: undefined,
                  type: rowInfo?.node?.type?.toLowerCase(),
                  typeInternal: rowInfo?.node?.type?.toLowerCase(),
                  x1: 105,
                  x2: 225,
                  y: 12.399999999999999,
                },
              });
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
              dispatch(saveVPStateAttr('siblingIndex', rowInfo?.node?.treeIndex));
              dispatch(saveBORStateAttr("isBOROpenFromVP",true))
            },
          },
          {
            key: 'open',
            text: t('open'),
            iconProps: { iconName: 'FabricOpenFolderHorizontal' },
            onClick: () => {
              if (isMyEnvironment()) {
                dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
              }
              if (isCuProjectPlanning()) {
                dispatch(saveVPStateAttr('selectedProject', rowInfo?.node?.project));
              }
              updateSelectedItem(rowInfo);
              dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
              dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
              dispatch(saveVPStateAttr('slectedPBSForBor', null));
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
              dispatch(saveVPStateAttr('selectedPBS', rowInfo?.node?.pbsSequenceId));
            },
          },
          {
            key: 'expand',
            text: t('expandThisProduct'),
            disabled: !rowInfo?.node?.parentId ? (rowInfo?.node?.children?.length > 0 ? false: true) : true,
            iconProps: { iconName: 'FabricOpenFolderHorizontal' },
            onClick: () => {
              const newPath = [...rowInfo?.path];
              const treeData = changeNodeAtPath({
                treeData: treeListData,
                path: newPath,
                newNode: ({ node }: any) => {
                  return { ...manuallyExpanAllChildrens(node)[0] };
                },
                getNodeKey: ({ treeIndex }) => treeIndex,
                });
                setTreeData(treeData);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'logout',
            text: 'Cancel',
            iconProps: { iconName: 'Cancel' },
            onClick: () => {},
          },
        ];

        const borMenuItems:any = {
            items: [
                {
                    key: 'newPMOL',
                    text: t('newPMOL'),
                    iconProps: { iconName: 'Add' },
                    onClick: () => {
                        messageService.sendMessage({
                        newPMOLfromVP: true,
                        rightClickTask: {
                            handleWidth: 8,
                            height: 37.2,
                            hideChildren: undefined,
                            id: rowInfo?.node?.id,
                            index: 0,
                            isHidden: false,
                            linkId: undefined,
                            milestoneSequanceId: null,
                            name: rowInfo?.node?.name,
                            parentId: null,
                            pbsSequenceId: rowInfo?.node?.pbsSequenceId,
                            pmolSequenceId: null,
                            poId: undefined,
                            progress: 0,
                            progressWidth: 0,
                            progressX: 105,
                            project: undefined,
                            requiredQuantity: undefined,
                            resourceType: undefined,
                            start: null,
                            teams: undefined,
                            title: rowInfo?.node?.title,
                            toolsPool: undefined,
                            borSequenceId:rowInfo?.node?.borSequenceId,
                            type: rowInfo?.node?.type?.toLowerCase(),
                            typeInternal: rowInfo?.node?.type?.toLowerCase(),
                            x1: 105,
                            x2: 225,
                            y: 12.399999999999999
                        }
                        })

                        dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
                        dispatch(saveVPStateAttr('siblingIndex', rowInfo?.node?.treeIndex));
                    }
                },
                {
                  key: 'pr',
                  text: 'New PR',
                  disabled: rowInfo?.node?.isUsed,
                  iconProps: { iconName: 'Add' },
                  onClick: () => {
                      Promise.all([
                          dispatch(createPRFromBor({borId:rowInfo?.node?.id})),
                          dispatch(saveVPStateAttr('prCreatedBorId', rowInfo?.node?.borSequenceId)),
                          dispatch(saveVPStateAttr('showvpProjectDocumentPane', true)),
                          dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pr')),
                          dispatch(saveVPStateAttr('slectedPBSForBor', null)),
                      ]).then(() => {
                          // dispatch(readByPurchaseOrderId(createdPrId));
                          dispatch(readByPurchaseOrderId(store.getState()?.vp?.createdPrId));

                          const treeData = changeNodeAtPath({
                            treeData: treeListData,
                            path: rowInfo?.path,
                            newNode: ({node}: any) => {
                              return {...node, isUsed: true};
                            },
                            getNodeKey: getNodeKey,
                          })
                          setTreeData(treeData)

                      });
                  }
              },
                {
                    key: 'divider_1',
                    itemType: ContextualMenuItemType.Divider
                },
                {
                    key: 'logout',
                    text: 'Cancel',
                    iconProps: { iconName: 'Cancel' },
                    onClick: () => {}
                }
            ]
        };

        const prMenuItems:any = {
            items: [
                {
                    key: 'open',
                    text: t('open'),
                    iconProps: { iconName: 'FabricOpenFolderHorizontal' },
                    onClick: () => {
                        dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                        dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pr'));
                        dispatch(readByPurchaseOrderId(rowInfo?.node?.poId));
                    },
                },
                {
                    key: 'divider_1',
                    itemType: ContextualMenuItemType.Divider
                },
                {
                    key: 'logout',
                    text: 'Cancel',
                    iconProps: { iconName: 'Cancel' },
                    onClick: () => {}
                }
            ]
        };

        const projectMenu: any = {
          items: [
            {
              key: 'newProduct',
              text: t('newProduct'),
              iconProps: { iconName: 'Add' },
              onClick: () => {
                dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
                dispatch(saveVPStateAttr('selectedProject',rowInfo?.node?.project));
                dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
                // dispatch(saveVPStateAttr('childCount',  rowInfo?.treeIndex+2 ));
                dispatch(saveVPStateAttr('childCount', rowInfo?.node?.children ? rowInfo?.node?.children?.length: rowInfo?.treeIndex ));
                dispatch(saveVPStateAttr('siblingIndex', -1));
                dispatch(saveVPStateAttr('expandNode', rowInfo?.node));
                dispatch(saveVPStateAttr('createdNewProductFromMyEnv', true));
                if (isMyEnvironment()) {
                  dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                  dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
                }
              },
            },
          ],
        };

        const pmolMenuItems:any = {
          items: [
              {
                  key: 'open',
                  text: t('open'),
                  iconProps: { iconName: 'FabricOpenFolderHorizontal' },
                  onClick: () => {
                      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                      dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pmol'));
                      dispatch(readPMOLByID(rowInfo?.node?.pmolSequenceId));
                  },
              },
              {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider
              },
              {
                  key: 'logout',
                  text: 'Cancel',
                  iconProps: { iconName: 'Cancel' },
                  onClick: () => {}
              }
          ]
      };

        const vpResourceMenuItems:any = {
        items: [
          {
            key: 'delete',
            text: t('delete'),
            iconProps: { iconName: 'Delete' },
            onClick: () => {
              if (isCuProjectPlanning() && selectedProjectItemType === 7) {
                // Deleting a labour in Mid-term planning
                const deletingMidTermLabourData = {
                  cabPersonId: rowInfo?.node?.personId,
                  cpcId: rowInfo?.node?.cpcId,
                  project: rowInfo?.node?.projectSequenceCode,
                };
                dispatch(deleteMidTermAssignedLabour(deletingMidTermLabourData));
                dispatch(saveVPStateAttr('deletingMidTermLabourData', deletingMidTermLabourData));
              } else {
                dispatch(deleteVpShiftItem(rowInfo?.node?.id));
                dispatch(readVPWareHouseTaxanomyData({ wareHouseId: null }));
              }
              const treeData = removeNodeAtPath({
                treeData: treeListData,
                path: rowInfo?.path,
                getNodeKey: getNodeKey,
              })
              setTreeData(treeData);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider
          },
          {
            key: 'logout',
            text: 'Cancel',
            iconProps: { iconName: 'Cancel' },
            onClick: () => {}
          }
        ]
      };

        const weekPlanMenu: any = [
          {
            key: 'pmol',
            text: t('newPMOL'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
              setPmolCreate(true);
              setProduct(rowInfo?.node);
            },
          },
          {
            key: 'evaluate',
            text: t('evaluate'),
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
              if (rowInfo?.node.type === 'PBS' || rowInfo?.node.type === 'parentProduct' || rowInfo?.node.type === 'PBS') {
                dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
                dispatch(getById(rowInfo?.node?.pbsSequenceId));
                dispatch(savePbsStateAttr('isPBSOpenFromVP', false));
              }

            },
          }];

        const labourItemMenuItems:any = {
        items: [
          {
            key: 'addTeamOrLabour',
            text: t('addTeamOrLabour'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
              dispatch(saveVPStateAttr('isOrgTaxonomyModalOpen', true));
              dispatch(saveVPStateAttr('cpcNodeInfo', rowInfo?.node));
              dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
            }
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider
          },
          {
            key: 'logout',
            text: 'Cancel',
            iconProps: { iconName: 'Cancel' },
            onClick: () => {}
          }
        ]
      };

      if (rowInfo.node.type === 'PBS' && chartType !== ChartType.PBSTAXONOMY) {
          pbsMenuItems.splice(0, 4)
          if (store.getState()?.vp?.selectedProjectItemType === 6) {
            pbsMenuItems.splice(0,1,...weekPlanMenu)
          }
          return{items: pbsMenuItems};
      } else if ( chartType === ChartType.PBSTAXONOMY) {
        if ((isCuProjectPlanning() || isMyEnvironment()) && rowInfo.node.type === 'Project') {
            return projectMenu
        } else if ((isCuProjectPlanning() && rowInfo.node.type === 'PbsLabour')) {
          return labourItemMenuItems
        } else if ((isCuProjectPlanning() && rowInfo.node.type === 'PbsAssignedLabour')) {
          return [];
        } else {
          pbsMenuItems.splice(4, 1)
          return {
            items:pbsMenuItems
          };
        }
      } else if (rowInfo.node.type === 'BOR') {
          return borMenuItems;
      } else if (rowInfo.node.type === 'PMOL') {
        return pmolMenuItems;
      } else if (isMyEnvironment() && rowInfo.node.type === 'Project') {
        return projectMenu
      } else if (chartType === ChartType.PR) {
          return prMenuItems;
      } else if (chartType === ChartType.RESOURCE) {
        return vpResourceMenuItems;
      }
      return borMenuItems;
    }

    const getStatusText = (status:string) => {
      switch (status) {
        case PBS_STATE.PENDDING_DEVELOPMENT:
          return t('pendingDevelopment');
        case PBS_STATE.IN_DEVELOPMENT:
          return t('inDevelopment');
        case PBS_STATE.IN_REVIEW:
          return t('inReview');
        case PBS_STATE.APPROVED:
          return t('approved');
        case PBS_STATE.HAND_OVER:
          return t('handedOver');
        default:
          return t('pendingDevelopment');
      }
    }

    const getProductStatus = (node: any) => {
      if (node?.type === 'PBS') {
        return getStatusText(node?.pbsStatus)
      } else if (node?.type === 'BOR') {
        return getStatusText(node?.borStatus)
      } else if (node?.type === 'PMOL') {
        return getStatusText(node?.pmolStatus)
      }

    };

    const getProductStatusColors = (status:string) => {
      switch (status)
      {
         case PBS_STATE.PENDDING_DEVELOPMENT:
           return 'pie-chart-pending-development'
         case PBS_STATE.IN_DEVELOPMENT:
          return 'pie-chart-in-development'
        case PBS_STATE.IN_REVIEW:
            return 'pie-chart-in-review'
        case PBS_STATE.APPROVED:
            return 'pie-chart-approved'
        case PBS_STATE.HAND_OVER:
            return 'pie-chart-handed-over'
         default:
          return 'pie-chart-pending-development'
      }
    }

    const getProjectScopeStatusColors = (projectScopeStatusId:string) => {
      switch (projectScopeStatusId)
      {
        case projectScopeStatus[0].key:
          return 'pie-chart-pending-development'
        case projectScopeStatus[1].key:
          return 'pie-chart-in-development'
        case projectScopeStatus[2].key:
          return 'pie-chart-in-development'
        case projectScopeStatus[3].key:
          return 'pie-chart-in-review'
        case projectScopeStatus[4].key:
          return 'pie-chart-in-review'
        case projectScopeStatus[5].key:
          return 'pie-chart-approved'
        case projectScopeStatus[6].key:
          return 'pie-chart-handed-over'
        default:
          return 'pie-chart-pending-development'
      }
    }

    const getProjectScopeStatusText = (status: string) => {
    const statusObj = projectScopeStatus.find((obj: any) => obj.key === status);
    return statusObj ? statusObj.text : '';
  };

    const getOrderTooltipText = (status:string) => {
        switch (status) {
            case "0":
                return t('PRNotCreatedOrPRCreatedButNotSent');
            case "1":
                return t('sentToCUButNotYetApproved');
            case "2":
                return t('approvedByCU');
            default:
                return t('notRequested');
        }
    }

    const getDeliveryTooltipText = (status:string) => {
        switch (status) {
            case "0":
                return t('warfQuantityIsZeroForAllResources');
            case "1":
                return t('resourcesArePartiallyAvailable');
            case "2":
                return t('allResourcesHavingWarQuantity');
            default:
                return t('notRequested');
        }
    }

    const getBOROrderStatusColors = (status:string) => {
        switch (status)
        {
            case "0":
                return 'bor-not-requested'
            case "1":
                return 'bor-not-yet-approved'
            case "2":
                return 'bor-approved'
            default:
                return 'bor-not-requested'
        }
    }

    const getBORDeliveryStatusColors = (status:string) => {
        switch (status)
        {
            case "0":
                return 'no-war-quantity'
            case "1":
                return 'partial-war-quantity'
            case "2":
                return 'all-war-quantity'
            default:
                return 'no-war-quantity'
        }
    }

    const getPBSAllStatusColors = (node:any) => {
      if (node?.type === 'PBS') {
        switch (node?.pbsStatus)
        {
           case PBS_STATE.PENDDING_DEVELOPMENT:
             return 'pie-chart-pending-development'
           case PBS_STATE.IN_DEVELOPMENT:
            return 'pie-chart-in-development'
          case PBS_STATE.IN_REVIEW:
              return 'pie-chart-in-review'
          case PBS_STATE.APPROVED:
              return 'pie-chart-approved'
          case PBS_STATE.HAND_OVER:
              return 'pie-chart-handed-over'
           default:
            return 'pie-chart-pending-development'
        }
      } else if (node?.type === 'BOR') {
        switch (node?.borStatus)
        {
           case PBS_STATE.PENDDING_DEVELOPMENT:
             return 'pie-chart-pending-development'
           case PBS_STATE.IN_DEVELOPMENT:
            return 'pie-chart-in-development'
          case PBS_STATE.IN_REVIEW:
              return 'pie-chart-in-review'
          case PBS_STATE.APPROVED:
              return 'pie-chart-approved'
          case PBS_STATE.HAND_OVER:
              return 'pie-chart-handed-over'
           default:
            return 'pie-chart-pending-development'
        }
      } else if (node?.type === 'PMOL') {
        switch (node?.pmolStatus)
        {
           case PBS_STATE.PENDDING_DEVELOPMENT:
             return 'pie-chart-pending-development'
           case PBS_STATE.IN_DEVELOPMENT:
            return 'pie-chart-in-development'
          case PBS_STATE.IN_REVIEW:
              return 'pie-chart-in-review'
          case PBS_STATE.APPROVED:
              return 'pie-chart-approved'
          case PBS_STATE.HAND_OVER:
              return 'pie-chart-handed-over'
           default:
            return 'pie-chart-pending-development'
        }
      }
    }

    const getNodeKey = ({ treeIndex }: any) => treeIndex;
    const updateClickstate = (treeData: any) => {
      let indexss = '';
      const callback = ({ node }: any) => {
          node.isSelected = false
      };
      walk({
          treeData,
          getNodeKey,
          callback,
          ignoreCollapsed: true
      });

      return indexss;
  };

    const getResourcePath = (treeData: any) => {
      let resourcePathMap: any = new Map();
      const callback = ({ node, path }: any) => {
        resourcePathMap.set(node.id, path);
        node.isSelected = false;
      };
      walk({
        treeData,
        getNodeKey,
        callback,
        ignoreCollapsed: true,
      });
      dispatch(saveVPStateAttr('resourcePaths', resourcePathMap));
  }

    const getSiblingRequesterNodeIndex = (treeData: any): number => {
      let foundIndex = -1;

      const callback = ({ node, path }: any) => {
        if (node.id === expandNode.id) {
          foundIndex = path[path.length - 1];
        }
      };

      walk({
        treeData,
        getNodeKey,
        callback,
        ignoreCollapsed: true,
      });

      return foundIndex;
    };

  const onItemclock = (rowInfo:any) => {
        if (chartType === ChartType.PBS || chartType === ChartType.PBSTAXONOMY || chartType === ChartType.MS) {
            console.log("BBBBBB", rowInfo?.node)
            switch (rowInfo?.node?.type) {
                case 'PBS':
                    if (!isPBSDirty) {
                      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                      dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
                      dispatch(saveVPStateAttr('slectedPBSForBor', null));
                      dispatch(saveVPStateAttr('pbsTaxSerarchString', rowInfo?.node?.pbsSequenceId));
                      dispatch(saveVPStateAttr('selectedPBS', rowInfo?.node?.pbsSequenceId));
                      // dispatch(saveVPStateAttr('selectedPBS', rowInfo?.node?.pbsSequenceId));
                      if (isMyEnvironment()) {
                        dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                        dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
                      }
                      if (isCuProjectPlanning()) {
                          dispatch(saveVPStateAttr('selectedProject', rowInfo?.node?.project));
                      }
                      updateSelectedItem(rowInfo)
                      dispatch(savePbsStateAttr('isPBSOpenFromVP',true))
                      dispatch(saveVPStateAttr('expandPath', rowInfo?.path));

                    } else {
                      setPendingNode(rowInfo)
                      setModalVisible(true);
                    }

                    break;
                case 'Project' :
                    dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                    dispatch(saveVPStateAttr('selectedVpProjectBarType', 'project'));
                    dispatch(saveVPStateAttr('selectedBORforPMOL', null));
                    dispatch(saveVPStateAttr('selectedProject',rowInfo?.node?.project));
                    dispatch(saveBORStateAttr("isBOROpenFromVP",true))
                    dispatch(saveBORStateAttr("createdVPBOR",null))
                    dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
                    updateSelectedItem(rowInfo)

                break;
                case 'BOR':
                  if (!isPBSDirty) {
                    dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                    dispatch(saveVPStateAttr('selectedVpProjectBarType', 'bor'));
                    dispatch(saveVPStateAttr('selectedBORforPMOL', null));
                    dispatch(saveVPStateAttr('selectedBOR', rowInfo?.node?.borSequenceId));
                    dispatch(saveBORStateAttr("isBOROpenFromVP",true))
                    dispatch(saveBORStateAttr("createdVPBOR",null))
                    dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
                    messageService.sendMessage({selectItemFromVP: true})
                  } else {
                    setPendingNode(rowInfo)
                    setModalVisible(false);
                  }
                    break;
                case 'PMOL':
                  if (!isPBSDirty) {
                    dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                    dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pmol'));
                    dispatch(saveVPStateAttr('selectedPMOL', rowInfo?.node?.pmolSequenceId));
                    dispatch(savePMOLStateAttr('isPMOLOpenFromVP',false))
                    dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
                    updateSelectedItem(rowInfo)
                    messageService.sendMessage({selectItemFromVP: true})
                  } else {
                    setPendingNode(rowInfo)
                    setModalVisible(true);
                  }
                    break;
                case 'milestone':
                    dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                    dispatch(saveVPStateAttr('selectedVpProjectBarType', 'milestone'));
                    dispatch(saveVPStateAttr('selectedMileStone', rowInfo?.node?.milestoneSequanceId));
                    break
                default:
                  if(rowInfo?.node?.pbsSequenceId){
                    if (!isPBSDirty) {
                      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                      dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
                      dispatch(saveVPStateAttr('slectedPBSForBor', null));
                      dispatch(saveVPStateAttr('pbsTaxSerarchString', rowInfo?.node?.pbsSequenceId));
                      dispatch(saveVPStateAttr('selectedPBS', rowInfo?.node?.pbsSequenceId));
                      if (isMyEnvironment()) {
                        dispatch(saveStateAttr("contractingUinit",rowInfo?.node?.cu))
                        dispatch(saveStateAttr("projectSequenceCode",rowInfo?.node?.project))
                      }
                      if (isCuProjectPlanning()) {
                          dispatch(saveVPStateAttr('selectedProject', rowInfo?.node?.project));
                      }
                      updateSelectedItem(rowInfo)
                      dispatch(savePbsStateAttr('isPBSOpenFromVP',true))
                      dispatch(saveVPStateAttr('expandPath', rowInfo?.path));
                    } else {
                      setPendingNode(rowInfo)
                      setModalVisible(true);
                    }

                    messageService.sendMessage({selectItemFromVP: true})
                  }
                break;
            }
        } else if (chartType === ChartType.PR) {
            updateSelectedItem(rowInfo)
            dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
            dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pr'));
            dispatch(readByPurchaseOrderId(rowInfo?.node?.poId));
        }
  }

    const getFlatData = () => {
      const fData = getFlatDataFromTree({
        treeData: treeListData,
        getNodeKey: (node: any) => node.pbsSequenceId,
        ignoreCollapsed: false,
      });

      let dataSet: any = fData.map((item) => {
        if (item?.node.title) {
        }
        return {
          ...item?.node,
        };
      });
      return dataSet;
    };

    const updateSelectedItem = (rowInfo:any) => {
      const fData = getFlatDataFromTree({
        treeData: treeListData,
        getNodeKey: (node: any) => node.pbsSequenceId,
        ignoreCollapsed: false,
      });

      fData.map((item: any) => {
        item.node.isSelected = item?.node?.id === rowInfo?.node?.id;
      });
    }

    const resetSelectedItem = () => {
      const fData = getFlatDataFromTree({
        treeData: treeListData,
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false,
      });

      fData.map((item: any) => {
        item.node.isSelected = false
      });
    }

    const getRowContent = (rowInfo:any) => {
       switch (chartType)
       {
          case ChartType.PO:
            return getGeneratedPropsPO(rowInfo)
          case ChartType.PBSTAXONOMY:
            return getGeneratedPropsPBS(rowInfo)
          case ChartType.MS:
              return getGeneratedPropsMS(rowInfo)
          case ChartType.RESOURCE:
              return getGeneratedPropsVPResource(rowInfo)
          default:
              return getGeneratedProps(rowInfo)
       }
    }

    const isLabourItemOrTool = (type:any) => {
      const nodeTypes = ['Labour', 'Labours', 'LabourItem', 'Tool', 'Tools']
      return nodeTypes.includes(type);
    }

    const getGeneratedPropsMS = (rowInfo:any) => {
      return <div style={{display: 'flex', flexDirection: 'row'}}>
          <Label
            style={{ width:60, fontSize:12, marginRight:20 }}
            onClick={() => {onItemclock(rowInfo)}}
          >
             {moment(rowInfo.node?.start).format('DD/MM/YYYY')}
          </Label>
          {(chartType === ChartType.PBS || chartType === ChartType.PBSTAXONOMY) && enableMoreButton() &&
              <CommandBarButton
                  ariaLabel="More items"
                  role="menuitem"
                  styles={ buttonStyles }
                  menuIconProps={ addIcon }
                  menuProps={ getMenuProps(rowInfo, chartType) }
          />
          }
        </div>
    }

    const getGeneratedPropsVPResource = (rowInfo: any) => {
      return <div style={{display: 'flex', flexDirection: 'row'}}>
        {(rowInfo.node?.displayOrder === 0) &&
            <CommandBarButton
                ariaLabel="More items"
                role="menuitem"
                styles={buttonStyles}
                menuIconProps={addIcon}
                menuProps={getMenuProps(rowInfo, chartType)}
            />
        }
      </div>
    }

    const getGeneratedProps = (rowInfo: any) => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
            {!rowInfo?.node?.isHidden &&
                <>
                    {(chartType === ChartType.PBS) &&

                        <>
                            {(rowInfo.node.type === "BOR" || rowInfo.node.type === "Bor") && <span>
                     <TooltipHost
                         key={'All-link1'}
                         content={getOrderTooltipText(rowInfo.node.orderStatus)}
                         id="filter-opt-1"
                         calloutProps={{gapSpace: 0}}
                         directionalHint={DirectionalHint.rightCenter}
                     >
                      <FontIcon
                        // BOR Order Status
                          iconName={getBOROrderStatusColors(rowInfo.node.orderStatus)}
                          className="ms-Icon"
                          style={{
                              marginTop: '10px',
                              marginLeft: 'auto',
                              marginRight: '5px',
                              fontSize: '4px',
                              width: 15,
                              height: 15,
                              color: '#B2BABB',
                              paddingBottom: 15
                          }}
                      />
                     </TooltipHost>

                     <TooltipHost
                         key={'All-link1'}
                         content={getDeliveryTooltipText(rowInfo.node.isWarfAvailable)}
                         id="filter-opt-1"
                         calloutProps={{gapSpace: 0}}
                         directionalHint={DirectionalHint.rightCenter}
                     >
                      <FontIcon
                        // BOR Delivery Status
                          iconName={getBORDeliveryStatusColors(rowInfo.node.isWarfAvailable)}
                          className="ms-Icon"
                          style={{
                              marginTop: '10px',
                              marginLeft: 'auto',
                              marginRight: '5px',
                              fontSize: '4px',
                              width: 15,
                              height: 15,
                              color: '#B2BABB',
                              paddingBottom: 15
                          }}
                      />
                     </TooltipHost>
                  </span>
                            }

                            <Popover popover={({visible, open, close}) => {
                                if (rowInfo.node.type === "PMOL") {
                                    return (
                                      <PmolStatusPopOver
                                        visible={visible}
                                        pmolSequenceId={rowInfo.node?.pmolSequenceId}
                                        path={rowInfo?.path}
                                        close={close}
                                        cu={rowInfo.node?.cu}
                                        project={rowInfo.node?.project}
                                      />
                                    )
                                } else {
                                    return (
                                      (!(rowInfo?.node?.type === "Project" && isMyEnvironment())) ? <StatusPopOver
                                        visible={visible}
                                        productId={rowInfo.node?.pbsSequenceId}
                                        path={rowInfo?.path}
                                        close={close}
                                        node={rowInfo?.node}
                                        cu={rowInfo.node?.cu}
                                        project={rowInfo.node?.project}
                                      /> : <ProjectScopePopOver
                                        visible={visible}
                                        projectId={rowInfo.node?.id}
                                        cu={rowInfo.node?.cu}
                                        project={rowInfo.node?.project}
                                        path={rowInfo?.path}
                                        close={close}
                                        projectScopeStatusId={rowInfo?.node?.projectScopeStatusId}
                                      />
                                    )
                                }
                            }}>
                                <TooltipHost
                                    key={'All-link1'}
                                    // content={getProductStatus(rowInfo.node)}
                                    content={!(rowInfo?.node?.type === "Project" && isMyEnvironment()) ? getProductStatus(rowInfo.node) : getProjectScopeStatusText(rowInfo.node?.projectScopeStatusId)}
                                    id="filter-opt-1"
                                    calloutProps={{gapSpace: 0}}
                                    directionalHint={DirectionalHint.rightCenter}
                                >
                                    <FontIcon
                                        // iconName={getPBSAllStatusColors(rowInfo.node)}
                                      iconName={!(rowInfo?.node?.type === "Project" && isMyEnvironment()) ? getPBSAllStatusColors(rowInfo.node) : getProjectScopeStatusColors(rowInfo.node?.projectScopeStatusId)}
                                        className="ms-Icon"
                                        style={{
                                            marginTop: '10px',
                                            marginLeft: 'auto',
                                            marginRight: '5px',
                                            fontSize: '4px',
                                            width: 15,
                                            height: 15,
                                            color: '#B2BABB',
                                            paddingBottom: 15
                                        }}
                                    />
                                </TooltipHost>
                            </Popover>

                            {(rowInfo.node.type === "ResourceItem") &&
                                <span>
                     <TooltipHost
                         key={'All-link1'}
                         content={rowInfo.node?.isPoApproved ? t('isPRApproved') : t('isPRNotApproved')}
                         id="filter-opt-1"
                         calloutProps={{gapSpace: 0}}
                         directionalHint={DirectionalHint.rightCenter}
                     >
                          <Icon
                              style={{
                                  fontSize: 14,
                                  marginTop: 8,
                                  paddingRight: 10,
                                  color: rowInfo.node?.isPoApproved ? '#85ca79' : '#D42361'
                              }}
                              iconName={'PaymentCard'}
                              onClick={() => {

                              }}
                          />
                     </TooltipHost>

                     <TooltipHost
                         key={'All-link1'}
                         content={rowInfo.node?.isStock ? t('isOnStock') : t('isNotOnStock')}
                         id="filter-opt-1"
                         calloutProps={{gapSpace: 0}}
                         directionalHint={DirectionalHint.rightCenter}
                     >
                          <Icon
                              style={{
                                  fontSize: 14,
                                  marginTop: 8,
                                  paddingRight: 10,
                                  color: rowInfo.node?.isStock ? '#85ca79' : '#CCCCCC'
                              }}
                              iconName={'ExternalBuild'}
                              onClick={() => {

                              }}
                          />
                     </TooltipHost>
              </span>}
                        </>
                    }

                    <Label
                        style={{
                            width: 80,
                            marginLeft: '20px !important',
                            fontSize: 12,
                            color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000'
                        }}
                        onClick={() => {
                            onItemclock(rowInfo)
                        }}
                    >
                        {rowInfo.node?.startDate ? `${moment(rowInfo.node?.endDate).diff(moment(rowInfo.node?.startDate), 'days') + 1} days` : '1 day'}
                    </Label>

                    <Label
                        style={{
                            width: 80,
                            marginLeft: '20px !important',
                            fontSize: 12,
                            color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000'
                        }}
                        onClick={() => {
                            onItemclock(rowInfo)
                        }}
                    >
                        {moment(rowInfo.node?.start).format('DD/MM/YYYY')}
                    </Label>
                    <Label
                        style={{
                            width: 80,
                            marginLeft: '20px !important',
                            fontSize: 12,
                            color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000'
                        }}
                        onClick={() => {
                            onItemclock(rowInfo)
                        }}
                    >
                        {moment(rowInfo.node?.end).subtract(1, 'day').format('DD/MM/YYYY')}
                    </Label>
                    {(chartType === ChartType.PBS || chartType === ChartType.PBSTAXONOMY || chartType === ChartType.PR) && enableMoreButton() &&
                        <CommandBarButton
                            ariaLabel="More items"
                            role="menuitem"
                            styles={getButtonStyle(rowInfo.node?.isSelected)}
                            menuIconProps={addIcon}
                            menuProps={getMenuProps(rowInfo, chartType)}
                            disabled={isLabourItemOrTool(rowInfo?.node?.type)}
                        />
                    }
                </>
            }
        </div>
    }

    const getClassName = (rowInfo:any) => {
      if (rowInfo?.node?.isHidden && rowInfo?.node?.wareHouseId) {
        return 'dataRow'
      } else if (rowInfo?.node?.isHidden){
        return 'emptyRow'
      } else {
        return 'dataRow'
      }
    }

    const getGeneratedPropsPBS = (rowInfo:any) => {
      return <div  style={{display: 'flex', flexDirection: 'row'}}>
         { !rowInfo?.node?.isHidden &&
          <>
        {(chartType === ChartType.PBSTAXONOMY) &&


        <Popover popover={({ visible, open, close }) => {
              return (
                (!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning()))) ? <StatusPopOver
                  visible={visible}
                  productId={rowInfo.node?.id}
                  cu={rowInfo.node?.cu}
                  project={rowInfo.node?.project}
                  path={rowInfo?.path}
                  close={close}
                  node={rowInfo?.node}
                /> : <ProjectScopePopOver
                  visible={visible}
                  projectId={rowInfo.node?.id}
                  cu={rowInfo.node?.cu}
                  project={rowInfo.node?.project}
                  path={rowInfo?.path}
                  close={close}
                  projectScopeStatusId={rowInfo?.node?.projectScopeStatusId}
                />
              )
            }}>
              <TooltipHost
                key={'All-link1'}
                content={!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning())) ? getStatusText(rowInfo.node?.pbsProductStatusId) : getProjectScopeStatusText(rowInfo.node?.projectScopeStatusId)}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
                >
                <FontIcon
                    iconName={!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning())) ? getProductStatusColors(rowInfo.node?.pbsProductStatusId) : getProjectScopeStatusColors(rowInfo.node?.projectScopeStatusId)}
                    className="ms-Icon"
                    style={{
                      marginTop: '10px',
                      marginLeft: 'auto',
                      marginRight: '5px',
                      fontSize: '4px',
                      width:15,
                      height:15,
                      color:'#B2BABB',
                      paddingBottom:15
                    }}
                  />

          </TooltipHost>
          </Popover>

          }

            <Label
              style={{ width:80, marginLeft:'20px !important', fontSize:12, color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000'  }}
              onClick={() => {
                onItemclock(rowInfo)
              }}
            >
              {isMyEnvironment() ? rowInfo.node?.startDate ?
                  `${moment(rowInfo.node?.endDate).diff(moment(rowInfo.node?.startDate), 'days') + 1} ${t('days')}` : `1 ${t('day')}` :
                <span style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: 55,
                }}>
                {rowInfo.node?.consolidatedDuration?.toLocaleString(browserLocale, {})} H
                </span>
              }
            </Label>


          <Label
            style={{ margin: "0 3px", width:80, fontSize:12, color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000' }}
            onClick={() => {onItemclock(rowInfo)}}
          >
            {moment(rowInfo.node?.start).format('DD/MM/YYYY')}
          </Label>
          <Label
            style={{ margin: "0 3px", width:60, fontSize:12, color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000' }}
            onClick={() => {onItemclock(rowInfo)}}
          >
            {moment(rowInfo.node?.end).subtract(1, 'day').format('DD/MM/YYYY')}
          </Label>
          {(chartType === ChartType.PBS || chartType === ChartType.PBSTAXONOMY) && enableMoreButton() &&
              <CommandBarButton
                  ariaLabel="More items"
                  role="menuitem"
                  styles={ getButtonStyle(rowInfo.node?.isSelected) }
                  menuIconProps={ addIcon }
                  menuProps={ getMenuProps(rowInfo, chartType) }
          />

          }
           </>
          }
        </div>
    }

    const getDuration = (row:any) => {
        if (row?.node?.type === 'project') {
          return (row.node?.startDate  && row?.node?.endDate ? `${ moment(row.node?.endDate).diff(moment(row.node?.startDate, 'YYYY-MM-DD').toDate(), 'days')+1} days `: '-' )
        } else if (row?.node?.type === 'po') {
          return (row.node?.start ? `${ moment(row.node?.end).diff(moment(row.node?.start, 'YYYY-MM-DD').toDate(), 'days')+1} days `: '1 day' )
        } else if (row?.node?.type === "resourceitem") {
          return (row.node?.start ? `${ moment(row.node?.end, 'YYYY-MM-DD').diff(moment(row.node?.start, 'YYYY-MM-DD').toDate(), 'days')+1} days `: '1 day' )
        } else {
          return '-'
        }
    }

    const getGeneratedPropsPO = (rowInfo:any) => {
      return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
         { !rowInfo?.node?.isHidden &&
         <>
          {(chartType === ChartType.PO && rowInfo.node?.type ===  "resourceitem") &&

              <TooltipHost
                key={'All-link1'}
                content={rowInfo.node?.startDate && moment(rowInfo.node?.deliveryDate).diff(rowInfo.node?.start, 'days') <= 1 ? t('deliveryOnTime'):t('deliveryDelay')}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <Icon
                style={{ fontSize: 14, marginTop:8, paddingRight:10 , color:  moment(moment(rowInfo.node?.deliveryDate, 'YYYY-MM-DD').toDate()).isSameOrAfter(moment(rowInfo.node?.expectedDate, 'YYYY-MM-DD').toDate()) ? '#3BC4A8' : '#D42361' }}
                // style={{ fontSize: 14, marginTop:8, paddingRight:10 , color:  rowInfo.node?.end && moment(rowInfo.node?.end).diff(moment(rowInfo.node?.deliveryDate, 'YYYY-MM-DD').toDate(), 'days') <= 1 ? '#3BC4A8' : '#D42361' }}
                iconName={'DeliveryTruck'}
                onClick={() => {

                }}
                />

              </TooltipHost>
          }
          <Label
            style={{ width:80, marginLeft:'20px !important', fontSize:12  }}
            onClick={() => {
              onItemclock(rowInfo)
            }}
          >
            {
            //  (rowInfo.node?.startDate ? `${ moment(rowInfo.node?.deliveryDate).diff(moment(rowInfo.node?.createdDate, 'YYYY-MM-DD').toDate(), 'days')+1} days `: '1 day' )
             getDuration(rowInfo)
            }
          </Label>

          <Label
            style={{ margin: "0 3px", width:80, fontSize:12 }}
            onClick={() => {onItemclock(rowInfo)}}
          >
            {moment(rowInfo.node?.type === 'project' ? rowInfo.node?.start :rowInfo.node?.requestedDate).format('DD/MM/YYYY')}
          </Label>
          <Label
            style={{ margin: "0 3px", width:60, fontSize:12 }}
            onClick={() => {onItemclock(rowInfo)}}
          >
            { rowInfo.node?.expectedDatedAdded ?
              moment(rowInfo.node?.type === 'project' ? rowInfo.node?.end :rowInfo.node?.expectedDate).subtract(1, 'day').format('DD/MM/YYYY')
              :
              moment(rowInfo.node?.type === 'project' ? rowInfo.node?.end :rowInfo.node?.endDate).format('DD/MM/YYYY')
            }
          </Label>
          </>
          }
        </div>
      )

    }

    const getDescriptionStyles = memoizeFunction((theme: ITheme, isSelected:boolean) => ({
        fieldGroup:{backgroundColor: 'transparent', cursor: 'pointer'},
        root: { color: theme.palette.black, fontWeight: FontWeights.bold, backgroundColor: 'transparent', width:'90%', cursor: 'pointer'},
        field:{
          color: isSelected ? '#FFFFFF' : '#000000',
        }
      }));


      const closeModal = () => {
        setModalVisible(false);
        // dispatch(saveVPStateAttr('isPBSDirty', false));
       // messageService.sendMessage({ resetListPaneSelection: true });
      };

      const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        dispatch(saveVPStateAttr('isPBSDirty', false));
        dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
        dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
        dispatch(saveVPStateAttr('slectedPBSForBor', null));
        dispatch(saveVPStateAttr('pbsTaxSerarchString', pendingNode?.node?.pbsSequenceId));
        dispatch(saveVPStateAttr('selectedPBS', pendingNode?.node?.pbsSequenceId));
        updateSelectedItem(pendingNode)
        messageService.sendMessage({selectItemFromVP: true})

      };

      const onClickSaveDialog = () => {
        setModalVisible(false);
        dispatch(saveVPStateAttr('isPBSDirty', false));
        messageService.sendMessage({ saveFormDataPBS: true });
      };

    return (
        <div className="chart" style={ { height: (rowHeight * tasks.length), color: 'black' } }>

          <DiscardDialog
            show={!modalVisible}
            onClickCancelDialog={closeModal}
            onClickConfirmDialog={handleConfirmNavigationClick}
            onClickSaveDialog={onClickSaveDialog}
          />
            <SortableTree
                treeData={ treeListData }
                onChange={ treeData => {
                    setTreeData(treeData);
                    onVisibilityChanged(treeData);
                    onTreeDataChange(treeData);
                } }
                onVisibilityToggle={ ({ treeData, node, expanded}) => {
                  setIsApplyExpandContext(false)
                  onVisibilityToggle({treeData, node, expanded});                }}
                // isVirtualized={ true }
                //searchMethod={ customSearchMethod}
                searchFocusOffset={ searchFocusOffset }
                 searchFinishCallback={ (matches) => {
                     searchFinishCallback(matches)
                 } }
                onlyExpandSearchedNodes={ true }
                canDrag={ canDrag }
                canDrop={ canDrop }
                onMoveNode={({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex}:any) => {
                    onMoveNode(treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex, treeListDataO[nextPath]);
                  setTreeDataO(treeData);
                    // if (isDocumentPaneShow){

                    // }
                }}
                getNodeKey={({ node, treeIndex}:any) => treeIndex}
                dndType={'external'}
                shouldCopyOnOutsideDrop={(props:any) => {
                    return true;
                }}
                onDragStateChanged={({ isDragging, draggedNode }) => {
                    if (draggedNode && draggedNode.children && chartType === ChartType.RESOURCE) {
                        //draggedNode.children = []
                    }
                }}
                rowHeight={36}
                //theme={ treeTheme as any }
                theme={FileExplorerTheme as any}
                generateNodeProps={(rowInfo:any) => ({
                    onClick: () => {
                        // onItemclock(rowInfo)
                    },
                    buttons:[getRowContent(rowInfo)],
                        title: [
                            <div className={getClassName(rowInfo)} style={ { paddingLeft: 5, cursor: canDrag(rowInfo)?'move':'not-allowed'} }>
                                <TooltipHost content={ rowInfo.node.title} id={'projectPlanningToolTip'}>
                                  <TextField value={ rowInfo.node.title} borderless  styles={getDescriptionStyles(uPrinceTheme, rowInfo?.node?.isSelected)} readOnly={true} onClick={() =>{onItemclock(rowInfo)}}/>
                                  {/*UNCOMMENT BELOW LINE TO SEE TREE INDEX IN THE DB*/}
                                  {/*<TextField value={ `${rowInfo.node.treeIndex} --> ${rowInfo.node.title}`} borderless  styles={getDescriptionStyles(uPrinceTheme, rowInfo?.node?.isSelected)} readOnly={true} onClick={() =>{onItemclock(rowInfo)}} />*/}
                                </TooltipHost>
                            </div>
                    ],
                    style:{ backgroundColor: rowInfo?.node?.isSelected ? uPrinceTheme.palette.themePrimary:'transparent'}
                  })}
            />
        </div>
    );
};
