export class WbsIssueListPaneFilter {
  sorter: Sorter = new Sorter();
  title: string | null = null;
  productId: string | null = null;
  status: string | null = null;
  priority: string | null = null;
  severity: string | null = null;
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}