import { ChoiceGroup } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectDefinition, updateProjectDefinition } from '../../../../reducers/projectReducer';
import { saveFormData, savePbsStateAttr } from '../../../../reducers/projectBreakdownReducer';
import { saveVPStateAttr } from '../../../../reducers/visualPlanReducer';
import { isMyEnvironment } from '../../../util';
import { saveStateAttr } from '../../../../reducers/uprinceReducer';

const ProjectScopePopOver = (props: {
  projectId: any,
  visible: boolean,
  path: any,
  close: any,
  cu: any,
  project: any,
  projectScopeStatusId: any
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { projectScopeStatus, selectedProject }: any = useSelector((projectState: any) => projectState.project);

  useEffect(() => {
    if (props.visible) {
      dispatch(saveVPStateAttr('expandPath', props.path));
      dispatch(savePbsStateAttr('isPBSOpenFromVP',false))
      const fetchProject = async () => {
        dispatch(getProjectDefinition(props.project));
      };
      fetchProject().then(r => {
      });
    }
  }, [props.projectId, props.visible]);

  function _onChange(ev: any, option: any): void {
    if (option) {
      props.close();
      Promise.all([dispatch(updateProjectDefinition({
        ...selectedProject,
        kpi: { ...selectedProject?.projectKpi, projectId: selectedProject?.id },  // Needed this for Update API
        projectFinance: { ...selectedProject?.projectFinance, projectId: selectedProject?.id }, // Needed this for Update API
        projectTime: { ...selectedProject?.projectTime, projectId: selectedProject?.id }, // Needed this for Update API
        projectScopeStatusId: option?.key,
      }))]).then(() => {
        dispatch(getProjectDefinition(props.project));
        console.log("ProjectScopePopOver")
      });
    }
  }

  return (
    <div style={{ width: 200, height: 'auto' }}>
      {props?.projectScopeStatusId && <ChoiceGroup
        selectedKey={props?.projectScopeStatusId}
        options={projectScopeStatus}
        onChange={_onChange}
        label={t('scopeStatus')}
      />}
    </div>
  );
};

export default ProjectScopePopOver;