import React from 'react';
import { connect } from 'react-redux';
import { DocumentPaneComponent } from './component';
import { ActionButtonState, NickName, SelectedNickNameItem, Vendor } from '../../../types/corporateProductCatalogue';
import { Selection } from '@fluentui/react/lib/DetailsList';
import UprinceLogger from '../../../shared/Logger/logger';
import {
  clearFormData,
  clearInstructionData,
  createCompetence,
  createConsumable,
  createEnvInstruction,
  createHelthInstruction,
  createLabour,
  createMaterials,
  createPBSService,
  createQuality,
  createRisk,
  createSaftyInstruction,
  createTaxonomyNode,
  createTechInstruction,
  createTools,
  deleteCompetence,
  deleteConsumable,
  deleteEnvInstruction,
  deleteHelthInstruction,
  deleteLabours,
  deleteMaterials,
  deleteQuality,
  deleteRisk,
  deleteSaftyInstruction,
  deleteTechInstruction,
  deleteTools,
  filterCompetenceList,
  filterQualityList,
  filterRiskList,
  getById,
  getRiskDropDownsList,
  readCompetenceDropDown,
  readConsumableData,
  readEnvInstruction,
  readHelthInstruction,
  readInstructionDropDown,
  readLabourData,
  readMaterialsData,
  readPBSService,
  readPMOLAllInstruction,
  readRiskById,
  readSaftyInstruction,
  readTaxonomyData,
  readTaxonomyLevel,
  readTechInstruction,
  readToolsData,
  resetRiskGridList,
  saveFormData,
  savePbsInstruction,
  savePbsQuality,
  savePbsRisk,
  savePbsStateAttr,
  uploadInstructionDoc,
} from '../../../reducers/projectBreakdownReducer';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import client from '../../../api';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import RiskModal from '../risk/modal/component';
import InstructionModal from '../instructions/modal/component';
import { IPersonaProps, ITag } from '@fluentui/react';
import {
  Competence,
  FilterItem,
  Instruction,
  InstructionLink,
  PBS_TYPE,
  PbsInstruction,
  PbsQuality,
  PbsRisk,
  PBSService,
  PRODUCT_ITEM_TYPE_REG_CONTRACT_ID,
  PRODUCT_ITEM_TYPE_SUB_CONTRACT_ID,
  ProjectBreakdown,
  ProjectBreakdownValidationMessage,
  Quality,
  ReadRisk,
  Resource,
  Risk,
} from '../../../types/projectBreakdownStructure';
import QualityModal from '../quality/modal/component';
import ResourceModal from '../resources/material/modal/component';
import { messageService } from '../../../services/messageService';
import LinkModal from '../instructions/link/component';
import { formatCpcResponse, formatPersonResponse, getProject, MSG_AUTO_CLOSE_TIME } from '../../../shared/util';
import CompetenceModal from '../competency/modal/component';
import { ResourceTypes } from '../../../types/billOfResources';
import { createMSTaxonomyNode } from '../../../reducers/mileStonesReducer';
import CustomAlert from '../../../shared/alert/customAlert';
import { clearCpcData } from '../../../reducers/billOfResourcesReducer';
import { RESOURCE_TYPE } from '../../../types/purchaseOrder';
import { BOR_READ_BY_PRODUCT_DATA } from '../../../shared/endpoints';
import { readByInstructionRegisterId } from '../../../reducers/instructionRegisterReducer';
import { v4 as uuidv4 } from 'uuid';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import SquareMetersModal from '../squareMeters/squareMetersModal';

export interface State {
  feild: string | null,
  isMouValid: boolean;
  isCollapsed: boolean;
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  uploadedImages: string | null;
  resourceFamily: any;
  qualitySelectionDetails: {} | string;
  qualityActionButtonState: ActionButtonState;
  formData: ProjectBreakdown;
  filter: FilterItem;
  riskSelectionDetails: {} | string;
  riskActionButtonState: ActionButtonState;
  filesToUpLoad: File | null;

  quality: Quality;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  isQualityEdit: boolean;

  qualityDeleteItems: [] | string[];
  riskDeleteItems: [] | string[];
  validationMessages: ProjectBreakdownValidationMessage;
  resourceFamilySearchString: string;
  submitCpc: boolean;

  isRiskEdit: boolean;
  isRiskModalVisible: boolean;
  isQualityModalVisible: boolean;
  isNewRisk: boolean;
  risk: ReadRisk;
  riskList: ReadRisk[];
  confimRiskDelete: boolean;
  qualityList: Quality[];
  materialList: Resource[];
  isMaterialModalVisible: boolean;
  materialSelectionDetails: {} | string;
  materialActionButtonState: ActionButtonState;
  material: Resource;
  isMaterialEdit: boolean;
  materialDeleteItems: [] | string[];
  tools: Resource[];
  isToolsModalVisible: boolean;
  toolsSelectionDetails: {} | string;
  toolsActionButtonState: ActionButtonState;
  tool: Resource;
  readToolsStatus: boolean;
  toolsDeleteItems: [] | string[];
  isToolEdit: boolean;
  labours: Resource[];
  isLabourModalVisible: boolean;
  labourSelectionDetails: {} | string;
  labourActionButtonState: ActionButtonState;
  labour: Resource;
  readLabourStatus: boolean;
  labourDeleteItems: [] | string[];
  isLabourEdit: boolean;
  isConsumablesModalVisible: boolean;
  consumableSelectionDetails: {} | string;
  consumableActionButtonState: ActionButtonState;
  consumable: Resource;
  readConsumableStatus: boolean;
  consumableDeleteItems: [] | string[];
  isConsumableEdit: boolean;
  consumables: Resource[];
  techInstructions: Instruction[];
  readTechInstructionStatus: boolean;
  techInstruction: any;
  //linkActionButtonState: ActionButtonState;
  isOpenTechInstructionModal: boolean;
  //instructionLink: InstructionLink;
  isOpenTechLinkModal: boolean;
  uploadedTechInstructionLink: string;
  //techInstructionLinks: InstructionLink[];
  techLinksSelectionDetails: {} | string;
  techLinksActionButtonState: ActionButtonState;
  techLink: InstructionLink;
  isEditTechLinks:boolean
  techInstructionSelectionDetails: {} | string;
  techInstructionActionButtonState: ActionButtonState;
  isEditTechInstruction:boolean
  editTechLinkIndex:number
  competenceList:Competence[];
  competenceSelectionDetails:{} | string;
  competenceActionButtonState:ActionButtonState;
  competence:Competence;
  competenceDeleteItems:[] | string[];
  isCompetenceModalVisible:boolean;
  isCompetenceEdit:boolean;
  isOpenSafetyInstructionModal: boolean;
  isOpenSafetyLinkModal: boolean;
  uploadedSaftyInstructionLink: string;
  saftyInstructionSelectionDetails: {} | string;
  safetyLinksSelectionDetails: {} | string;
  safetyLinksActionButtonState: ActionButtonState;
  safetyLink: InstructionLink;
  isEditSaftyLinks:boolean
  saftyInstruction: Instruction;
  saftyInstructions: Instruction[];
  isEditSaftyInstruction:boolean
  saftyInstructionActionButtonState: ActionButtonState
  editSaftyLinkIndex:number
  //
  isOpenEnvInstructionModal: boolean;
  isOpenEnvLinkModal: boolean;
  uploadedenvInstructionLink: string;
  envInstructionSelectionDetails: {} | string;
  envLinksSelectionDetails: {} | string;
  envLinksActionButtonState: ActionButtonState;
  envLink: InstructionLink;
  isEditEnvLinks:boolean
  envInstruction: Instruction;
  envInstructions: Instruction[];
  isEditEnvInstruction:boolean
  envInstructionActionButtonState: ActionButtonState
  editEnvLinkIndex:number
  //
  isOpenHelthInstructionModal: boolean;
  isOpenHelthLinkModal: boolean;
  uploadedhelthInstructionLink: string;
  helthInstructionSelectionDetails: {} | string;
  helthLinksSelectionDetails: {} | string;
  helthLinksActionButtonState: ActionButtonState;
  helthLink: InstructionLink;
  isEditHelthLinks:boolean
  helthInstruction: Instruction;
  helthInstructions: Instruction[];
  isEditHelthInstruction:boolean
  helthInstructionActionButtonState: ActionButtonState
  editHelthLinkIndex:number;
  selectedNodeLevel:any;
  selectedResourceTypeId:string|null;
  pbsType:PBS_TYPE;
  service: PBSService;

  selectedInstruction:any;
  riskData: ReadRisk; //to load risk data into modal
  qualityData: Quality; //to load quality data into modal
  hasBorCreatedForThisProduct: boolean;
}

interface Props extends WithTranslation {
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  uploadedImages: string | null;
  resourceTypes: [];
  resourceFamily: [];
  languageCodes: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [];
  cpcMaterial: [];
  cpcBrand: [];
  cpcUnitOfSizeMeasure: [];
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  organizations: [];
  handelGetOrganizationByName: (name: string) => void;
  submitCpc: boolean;
  projectId: string | null;
  locationTaxonomy: [];
  utilityTaxonomy: [];
  productTaxonomy: [];
  machineTaxonomy: [];
  utilityTaxonomyLevel:[],
  locationTaxonomyLevel:[],
  machineTaxonomyLevel:[],
  toleranceStates: [];
  productStates: [];
  itemTypes: [];
  formData: ProjectBreakdown;
  isNew: boolean;
  uid: string | null;
  saveFormData: (formData: ProjectBreakdown) => any;
  readTaxonomyData: () => void;
  readTaxonomyLevel: () => void;
  createTaxonomyNode: (newNode:any) => void;
  createMSTaxonomyNode: (newNode:any) => void;

  getRiskDropDownsList: any;
  createRisk: any;
  filterRiskList: any;
  readRiskStatus: boolean;
  riskList: ReadRisk[];
  createRiskStatus: boolean;
  deleteRiskStatus: boolean;
  saveRiskStatus: boolean;
  newRiskId: string | null;
  createInstructionStatus: boolean;
  newInstructionId: string | null;
  savePbsInstruction: (instructionData: PbsInstruction) => void;
  deleteRisk: any;
  savePbsRisk: (riskData: PbsRisk) => void;
  resetRiskGridList: () => void;
  readRiskById: (id: string) => void;//to load risk data into modal

  filterQualityList: any;
  readQualityStatus: boolean;
  qualityList: Quality[];
  createQuality: any;
  createQualityStatus: any;
  savePbsQuality: (quality: PbsQuality) => void;
  saveQualityStatus: boolean;
  newQualityId: string | null;
  deleteQuality: any;
  deleteQualityStatus: boolean;
  readMaterialsData: (id: string) => void;
  readMaterialsStatus: boolean;
  materialList: Resource[];
  createMaterials: any;
  createMaterialsStatus: boolean;
  readToolsData: (id: string) => void;
  readToolsStatus: boolean;
  tools: Resource[];
  createTools: any;
  createToolsStatus: boolean;
  deleteMaterials: any;
  deleteMaterialsStatus: boolean;
  deleteTools: any;
  deleteToolsStatus: boolean;
  readLabourData: (id: string) => void;
  readLabourStatus: boolean;
  labours: Resource[];
  createLabourStatus: boolean;
  createLabour: any;
  deleteLabours: any;
  deleteLabourStatus: boolean;
  readConsumableStatus: boolean;
  deleteConsumableStatus: boolean;
  createConsumable: any;
  deleteConsumable: any;
  consumables: Resource[];
  readConsumableData: (id: string) => void;
  createConsumableStatus: boolean;
  readInstructionDropDown: any;
  readTechInstruction: (pbsId:string, type:string) => void;
  uploadInstructionDoc: any;
  instructionUploadStatus: boolean;
  uploadedInstructionLink: string;
  createTechInstruction:any
  readTechInstructionStatus:boolean
  techInstructions:Instruction[]
  competenceList:Competence[];
  filterCompetenceList:any;
  pbsSkills: [];
  pbsExperience: [];
  readCompetenceDropDown:any;
  createCompetence:(competence:Competence)=>any;
  saveCompetenceStatus:boolean,
  deleteCompetenceStatus:boolean,
  deleteCompetence:(data:any[]|[])=>any;
  deleteTechInstruction:any
  createTechInstructionStatus:boolean
  deleteTechInstructionStatus:boolean
  createSaftyInstruction:(instruction:Instruction) => any
  readSaftyInstruction:(pbsId:string, type:string) => void
  readSaftyInstructionStatus:boolean
  saftyInstructions:Instruction[],
  createSaftyInstructionStatus:boolean,
  deleteSaftyInstructionStatus:boolean,
  deleteSaftyInstruction:any,
  ////
  createEnvInstruction:(instruction:Instruction) => any
  readEnvInstruction:(pbsId:string, type:string) => void
  readEnvInstructionStatus:boolean
  envInstructions:Instruction[],
  createEnvInstructionStatus:boolean,
  deleteEnvInstructionStatus:boolean,
  deleteEnvInstruction:any,
  //
  createHelthInstruction:(instruction:Instruction) => any
  readHelthInstruction:(pbsId:string, type:string) => void
  readHelthInstructionStatus:boolean
  helthInstructions:Instruction[],
  createHelthInstructionStatus:boolean,
  deleteHelthInstructionStatus:boolean,
  deleteHelthInstruction:any,
  createNewNode:boolean;
  readPMOLAllInstruction: (pbsId:string) => void
  location:any;
  createPBSService:any;
  service: PBSService;
  readPBSService:any;
  clearFormData:any;
  clearInstructionData:any;
  pbsCreated:boolean
  getById:(id:string)=>void;
  clearCpcData: () => void;
  selectedInstruction:any;
  readByInstructionRegisterId: (id: string) => void;


  riskData:any; //to load risk data into modal
  riskValues:any;
  savePbsStateAttr:any;
  qualityData:any; //to load quality data into modal
  saveVPStateAttr:any
}

const acceptedTypes: string[] = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
  'pdf',
  'doc',
  'docx',
];

class DocumentPane extends React.Component<Props, any> {
  private nickName: NickName;
  private vendor: Vendor;
  private _qualitySelection: Selection;
  private _riskSelection: Selection;
  private _materialsSelection: Selection;
  private _toolsSelection: Selection;
  private _labourSelection: Selection;
  private _techLinksSelection: Selection;
  private _consumableSelection: Selection;
  private _techInstructionSelection: Selection;
  private _competenceSelection: Selection;
  private _saftyInstructionSelection: Selection;
  private _saftyLinksSelection: Selection;
  private _envInstructionSelection: Selection;
  private _envLinksSelection: Selection;
  private _helthInstructionSelection: Selection;
  private _helthLinksSelection: Selection;
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasBorCreatedForThisProduct:false,
      feild: null,
      selectedInstruction: [],
      isMouValid: true,
      isCollapsed: false,
      submitCpc: false,
      isDirty: false,
      selectedCpcId: null,
      isEdit: false,
      uploadedImages: null,
      isRiskModalVisible: false,
      isNewRisk: true,
      resourceFamily: [],
      redirect: false,
      loading: false,
      qualitySelectionDetails: {},
      qualityActionButtonState: { add: false, edit: true, remove: true },
      formData: {
        id: null,
        title: null,
        headerTitle: null,
        contract: null,
        name: null,
        pbsProductStatusId: null,
        pbsProductItemTypeId: PRODUCT_ITEM_TYPE_REG_CONTRACT_ID,
        pbsToleranceStateId: null,
        productComposition: null,
        productDerivation: null,
        productFormatPresentation: null,
        productId: null,
        projectId: this.props.projectId,
        productPurpose: null,
        scope: null,
        historyLog: null,
        locationTaxonomyParentId: null,
        utilityTaxonomyParentId: null,
        machineTaxonomyParentId: null,
        parentId: null,
        pbsQualityResponsibilityId: null,
        qualityProducer: null,
        qualityProducerId: null,
        qualityReviewer: null,
        qualityReviewerId: null,
        qualityApprover: null,
        qualityApproverId: null,
        isSaved:false,
        pbsType:PBS_TYPE.REGULAR,
        startDate: null,
        endDate: null,
      },
      filter: {
        title: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        scope: null,
        taxonomy: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      filesToUpLoad: null,
      quality: new Quality(),
      isQualityEdit: false,

      qualityDeleteItems: [],
      riskDeleteItems: [],
      validationMessages: {
        nameErrorMsg: '',
        contractErrorMsg: '',
        ItemTypeErrorMsg: '',
        statusErrorMsg: '',
        toleranceStateErrorMsg: '',
        mouErrorMsg: '',
      },
      resourceFamilySearchString: '',

      riskActionButtonState: { add: false, edit: true, remove: true },
      riskSelectionDetails: {},
      isRiskEdit: false,
      riskList: [],
      // readRiskStatus:false,
      risk: new ReadRisk(),
      riskData: new ReadRisk(),
      confimRiskDelete: false,
      isQualityModalVisible: false,
      qualityList: [],
      materialList: [],
      isMaterialModalVisible: false,
      materialSelectionDetails: {},
      materialActionButtonState: { add: false, edit: true, remove: true },
      material: new Resource(),
      isMaterialEdit: false,
      materialDeleteItems: [],
      readToolsStatus: false,
      tools: [],
      tool: new Resource(),
      isToolsModalVisible: false,
      toolsActionButtonState: { add: false, edit: true, remove: true },
      toolsSelectionDetails: {},
      toolsDeleteItems: [],
      isToolEdit: false,
      labours: [],
      labour: new Resource(),
      readLabourStatus: false,
      isLabourModalVisible: false,
      labourActionButtonState: { add: false, edit: true, remove: true },
      labourSelectionDetails: {},
      labourDeleteItems: [],
      isLabourEdit: false,
      isConsumablesModalVisible: false,
      consumableSelectionDetails: {},
      consumableActionButtonState: { add: false, edit: true, remove: true },
      consumable: new Resource(),
      readConsumableStatus: false,
      consumableDeleteItems: [],
      isConsumableEdit: false,
      consumables: [],
      techInstructions: [],
      readTechInstructionStatus: false,
      techInstruction: new Instruction(),
      //linkActionButtonState: { add: false, edit: true, remove: true },
      isOpenTechInstructionModal: false,
      //instructionLink: new InstructionLink(),
      isOpenTechLinkModal: false,
      uploadedTechInstructionLink: '',
      //techInstructionLinks: [],
      techLinksSelectionDetails: {},
      techLinksActionButtonState: { add: false, edit: true, remove: true },
      techLink: new InstructionLink(),
      isEditTechLinks: false,
      techInstructionSelectionDetails: {},
      techInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      isEditTechInstruction: false,
      editTechLinkIndex:-1,
      competenceList:[],
      competence:new Competence(),
      competenceActionButtonState:{ add: false, edit: true, remove: true },
      competenceDeleteItems:[],
      competenceSelectionDetails:{},
      isCompetenceModalVisible:false,
      isCompetenceEdit:false,
      isOpenSafetyInstructionModal: false,
      isOpenSafetyLinkModal: false,
      uploadedSaftyInstructionLink: '',
      safetyLinksSelectionDetails: {},
      safetyLinksActionButtonState: { add: false, edit: true, remove: true },
      safetyLink: new InstructionLink(),
      isEditSaftyLinks:false,
      saftyInstruction: new Instruction(),
      isEditSaftyInstruction:false,
      saftyInstructions:[],
      saftyInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      saftyInstructionSelectionDetails:{},
      editSaftyLinkIndex:-1,
      ///
      isOpenEnvInstructionModal: false,
      isOpenEnvLinkModal: false,
      uploadedenvInstructionLink: '',
      envLinksSelectionDetails: {},
      envLinksActionButtonState : { add: false, edit: true, remove: true },
      envLink : new InstructionLink(),
      isEditEnvLinks:false,
      envInstruction: new Instruction(),
      isEditEnvInstruction:false,
      envInstructions:[],
      envInstructionSelectionDetails: {
        add: false,
        edit: true,
        remove: true,
      },
      envInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      editEnvLinkIndex:-1,
      //
      isOpenHelthInstructionModal: false,
      isOpenHelthLinkModal: false,
      uploadedhelthInstructionLink: '',
      helthLinksSelectionDetails: {},
      helthLinksActionButtonState : { add: false, edit: true, remove: true },
      helthLink : new InstructionLink(),
      isEditHelthLinks:false,
      helthInstruction: new Instruction(),
      isEditHelthInstruction:false,
      helthInstructions:[],
      helthInstructionSelectionDetails: {
        add: false,
        edit: true,
        remove: true,
      },
      helthInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      editHelthLinkIndex:-1,
      selectedNodeLevel:null,
      selectedResourceTypeId:null,
      pbsType:PBS_TYPE.REGULAR,
      service:{
        projectTitle: getProject(),
        productTitle: null,
        quantity: null,
        mou: null,
        unitPrice: null,
        totalPrice: null,
        documents: null,
        comments:null,
      },
      qualityData : new Quality() //to load quality data into modal
      
    };

    // Populate with items for demos.
    this.nickName = {
      id: null,
      uid: null,
      language: null,
      nickName: null,
      localeCode: null,
    };

    this.vendor = {
      companyId: null,
      company: {
        id: null,
        name: null,
      },
      companyName: null,
      uid: null,
      id: null,
      maxOrderQuantity: null,
      minOrderQuantity: null,
      preferredParty: false,
      purchasingUnit: null,
      resourceLeadTime: null,
      resourceNumber: null,
      resourcePrice: null,
      resourceTitle: null,
      roundingValue: null,
    };

    this._qualitySelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_qualitySelection',
          this._getQualitySelectionDetails()
        );
        this.setState({
          qualitySelectionDetails: this._getQualitySelectionDetails(),
          qualityActionButtonState: this._getQualityActionButtonState(),
          qualityData: this._qualityEditDetails(), //to load quality data into modal
          qualityDeleteItems: this._qualityDeleteDetails(),
          //cpcNickNameDeleteUnSavedItems: this._nickNameDeleteUnSavedDetails(),
        });
      },
    });

    this._riskSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_riskSelection', this._getRiskSelectionDetails());
        this.setState({
          riskSelectionDetails: this._getRiskSelectionDetails(),
          riskActionButtonState: this._getRiskActionButtonState(),
          riskData: this._riskEditDetails(),
          riskDeleteItems: this._riskDeleteDetails(),
          //cpcVendorDeleteUnSavedItems: this._vendorDeleteUnSavedDetails(),
        });
      },
    });

    this._materialsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_materialsSelection',
          this._getMaterialSelectionDetails()
        );
        this.setState({
          materialSelectionDetails: this._getMaterialSelectionDetails(),
          materialActionButtonState: this._getMaterialActionButtonState(),
          material: this._materialEditDetails(),
          materialDeleteItems: this._resourceDeleteDetails(
            this._materialsSelection
          ),
        });
      },
    });

    this._toolsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_toolsSelection', this._getToolsSelectionDetails());
        this.setState({
          toolsSelectionDetails: this._getToolsSelectionDetails(),
          toolsActionButtonState: this._getToolsActionButtonState(),
          tool: this._toolsEditDetails(),
          toolsDeleteItems: this._resourceDeleteDetails(this._toolsSelection),
        });
      },
    });

    this._labourSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_labourSelection',
          this._getLabourSelectionDetails()
        );
        this.setState({
          labourSelectionDetails: this._getLabourSelectionDetails(),
          labourActionButtonState: this._getLabourActionButtonState(),
          labour: this._labourEditDetails(),
        });
      },
    });

    this._consumableSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_consumableSelection',
          this._getConsumableSelectionDetails()
        );
        this.setState({
          consumableSelectionDetails: this._getConsumableSelectionDetails(),
          consumableActionButtonState: this._getConsumableActionButtonState(),
          consumable: this._consumableEditDetails(),
        });
      },
    });

    this._techLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_linksSelection',
          this._getConsumableSelectionDetails()
        );
        this.setState({
          techLinksSelectionDetails: this._getTechLinkSelectionDetails(),
          techLinksActionButtonState: this._getTechLinkActionButtonState(),
          techLink: this._techLinkEditDetails(),
        });
      },
    });

    this._saftyLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_linksSelection',
          this._getSaftyLinkSelectionDetails()
        );
        this.setState({
          safetyLinksSelectionDetails: this._getSaftyLinkSelectionDetails(),
          safetyLinksActionButtonState: this._getSaftyLinkActionButtonState(),
          safetyLink: this._saftyLinkEditDetails(),
        });
      },
    });

    this._envLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_linksSelection',
          this._getSaftyLinkSelectionDetails()
        );
        this.setState({
          envLinksSelectionDetails: this._getEnvLinkSelectionDetails(),
          envLinksActionButtonState: this._getEnvLinkActionButtonState(),
          envLink: this._envLinkEditDetails(),
        });
      },
    });

    this._helthLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_helthLinksSelection',
          this._getSaftyLinkSelectionDetails()
        );
        this.setState({
          helthLinksSelectionDetails: this._getHelthLinkSelectionDetails(),
          helthLinksActionButtonState: this._getHelthLinkActionButtonState(),
          helthLink: this._helthLinkEditDetails(),
        });
      },
    });

    this._techInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_techInstructionSelection',
          this._getConsumableSelectionDetails()
        );
        this.setState({
          techInstructionSelectionDetails: this._getTechInstructionSelectionDetails(),
          techInstructionActionButtonState: this._getTechInstructionActionButtonState(),
          techInstruction: this._techInstructionEditDetails(),
        });
      },
    });

    this._competenceSelection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          competenceSelectionDetails: this._getCompetenceSelectionDetails(),
          competenceActionButtonState: this._getCompetenceActionButtonState(),
          competence: this._competenceEditDetails(),
          competenceDeleteItems: this._competenceDeleteDetails(),
          //cpcNickNameDeleteUnSavedItems: this._nickNameDeleteUnSavedDetails(),
        });
      },
    });

    this._saftyInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_saftyInstructionSelection',
          this._getSaftyInstructionSelectionDetails()
        );
        this.setState({
          saftyInstructionSelectionDetails: this._getSaftyInstructionSelectionDetails(),
         // safetyLinksActionButtonState: this._getSaftyInstructionActionButtonState(),
          saftyInstructionActionButtonState: this._getSaftyInstructionActionButtonState(),
          techInstruction: this._saftyInstructionEditDetails(),
        });
      },
    });

    this._envInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_envInstructionSelection',
          this._getSaftyInstructionSelectionDetails()
        );
        this.setState({
          envInstructionSelectionDetails: this._getEnvInstructionSelectionDetails(),
         // safetyLinksActionButtonState: this._getSaftyInstructionActionButtonState(),
          envInstructionActionButtonState: this._getEnvInstructionActionButtonState(),
          techInstruction: this._envInstructionEditDetails(),
        });
      },
    });

    this._helthInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_helthInstructionSelection',
          this._getSaftyInstructionSelectionDetails()
        );
        this.setState({
          helthInstructionSelectionDetails: this._getHelthInstructionSelectionDetails(),
         // safetyLinksActionButtonState: this._getSaftyInstructionActionButtonState(),
          helthInstructionActionButtonState: this._getHelthInstructionActionButtonState(),
          techInstruction: this._helthInstructionEditDetails(),
        });
      },
    });
  }


  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.readRiskStatus != this.props.readRiskStatus) {
      this.setState({ riskList: this.props.riskList });
    }

    if (prevProps.riskList != this.props.riskList) {
      this.setState({ riskList: this.props.riskList });
    }

    // if (prevProps.riskData !== this.props.riskData) {
    //   this.setState({isRiskEdit:true})
    // }

    if ((prevProps.readQualityStatus != this.props.readQualityStatus) ||  this.state.qualityList !== this.props.qualityList) {
      this.setState({ qualityList: this.props.qualityList });
    }

    if (prevProps.pbsCreated !== this.props.pbsCreated && this.props.pbsCreated) {
      const paths = window.location.pathname.split('/');
       if(paths && paths.length >=5 && paths[5] === 'project-planning') {
        messageService.sendMessage({pbsCreatedFromVP: true})
       }
    }

    if (prevProps.readMaterialsStatus != this.props.readMaterialsStatus) {
      this.setState({ materialList: this.props.materialList });
    }
    if (prevProps.materialList != this.props.materialList) {
      this.setState({ materialList: this.props.materialList });
    }
    if (prevProps.selectedInstruction !== this.props.selectedInstruction) {
      this.setState({ techInstruction: this.props.selectedInstruction });
    }
    if (prevProps.competenceList != this.props.competenceList) {
      this.setState({ competenceList: this.props.competenceList });
    }

    if (prevProps.createRiskStatus != this.props.createRiskStatus) {
      //this.setState({ isRiskModalVisible: false });
      if (this.props.createRiskStatus) {
        this.savePbsRisk();
      }
    }
    if (prevProps.saveRiskStatus != this.props.saveRiskStatus) {
      // this.getPbsRiskList();
      this.props.filterRiskList(this.state.formData.id!!);
    }

    //to load risk data into modal
    if (prevProps.riskData !== this.props.riskData) {
      this.setState({ riskData: this.props.riskData });
    }

    //to load quality data into modal
    if (prevProps.qualityData !== this.props.qualityData) {
      this.setState({ qualityData: this.props.qualityData });
    }


    if (prevProps.createQualityStatus != this.props.createQualityStatus) {
      //this.setState({ isQualityModalVisible: false });
      if (this.props.createQualityStatus) {
        this.savePbsQuality();
        //this.props.filterQualityList(this.state.formData.id!!);
      }
    }
    if (prevProps.saveQualityStatus != this.props.saveQualityStatus) {
      if (this.props.saveQualityStatus) {
        //this.getPbsQualityList();
        this.props.filterQualityList(this.state.formData.id!!);
      }
    }

    if (prevProps.deleteRiskStatus != this.props.deleteRiskStatus) {
      this.getPbsRiskList();
    }

    if (prevProps.deleteQualityStatus != this.props.deleteQualityStatus) {
      this.getPbsQualityList();
    }

    if (prevProps.createMaterialsStatus != this.props.createMaterialsStatus) {
      //this.setState({ isMaterialModalVisible: false });
      if(this.props.createMaterialsStatus){
          this.props.readMaterialsData(this.state.formData.id!!);
      }
    }
    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      let formData = this.state.formData;
      formData.id = this.props.uid;
      this.setState({ formData });
      this.props.saveVPStateAttr('isPBSDirty', false)
    }
    if (this.props?.location?.state?.type && this.props?.location?.state?.type !== this.state.pbsType) {
      if ( this.props?.location?.state?.type === PBS_TYPE.SUB) {
        let pbsNewData = {...this.props.formData, pbsProductItemTypeId: PRODUCT_ITEM_TYPE_SUB_CONTRACT_ID }
        this.setState({ pbsType: this.props?.location?.state?.type, formData:pbsNewData})
      } else {
        let pbsNewData = {...this.props.formData, pbsProductItemTypeId: PRODUCT_ITEM_TYPE_REG_CONTRACT_ID }
        this.setState({ pbsType: this.props?.location?.state?.type, formData:pbsNewData})
      }

    }

    if (prevProps.service !== this.props?.service){
      this.setState({ service: _.isArray(this.props?.service) ? this.props?.service[0]:null})
    }

    if (prevProps.formData != this.props.formData) {
      this.props.saveVPStateAttr('isPBSDirty', false)
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          this.getPbsRiskList();
          this.getBorListByName(this.props.formData.productId!!);
          this.getPbsQualityList();
          this.getBorListByName(this.props.formData.productId!!)
          if (this.props.formData.pbsType === PBS_TYPE.REGULAR || this.props.formData.pbsType === null) {
            this.getPbsToolsList();
            this.getPbsLabourList();
            this.getMaterisList();
            this.getPbsConsumablesList();
          }

          this.getAllTechInstructionList()
          this.getPbsService();
          // this.getPbsTechInstructionList();
          // this.getPbsSaftyInstructionList();
          // this.getPbsEnvInstructionList();
          // this.getPbsHelthInstructionList()
          //this.getPbsInstructionList();
          this.getPbsCompetenceList();
          this.props.readTaxonomyData(); 
          let pbsNewData;
          if ( this.props.formData.pbsType === PBS_TYPE.SUB) {
            pbsNewData = {...this.props.formData, pbsProductItemTypeId: PRODUCT_ITEM_TYPE_SUB_CONTRACT_ID }
            //this.setState({ pbsType: this.props?.location?.state?.type, formData:pbsNewData})
          } else {
            pbsNewData = {...this.props.formData, pbsProductItemTypeId: PRODUCT_ITEM_TYPE_REG_CONTRACT_ID }
            //this.setState({ pbsType: this.props?.location?.state?.type, formData:pbsNewData})
          }
          this.setState({
            formData: pbsNewData,
            pbsType:this.props.formData.pbsType?this.props.formData.pbsType:PBS_TYPE.REGULAR,
          });
          messageService.sendMessage({ pbsTypeFromRead: this.props.formData.pbsType?this.props.formData.pbsType:PBS_TYPE.REGULAR });
        }
      }
      if(prevProps.formData.productId != this.props.formData.productId){
        this.resetValidation();
      }
    }
    // if (prevState.formData !== this.state.formData) {
    //   if (!_.isEmpty(this.state.formData) && this.state.formData.id === null) {
    //     let formData = this.state.formData;
    //     formData.id = this.props.uid;
    //     this.props.readMaterialsData(this.props.uid!!);
    //     this.setState({ formData });
    //   }
    // }

    if (prevProps.readToolsStatus != this.props.readToolsStatus) {
      this.setState({ tools: this.props.tools });
    }
    if (prevProps.tools != this.props.tools) {
      this.setState({ tools: this.props.tools });
    }

    if (prevProps.createToolsStatus != this.props.createToolsStatus) {
      //this.setState({ isToolsModalVisible: false });
      this.props.readToolsData(this.state.formData.id!!);
      //this.getPbsToolsList();
    }

    if (prevProps.deleteMaterialsStatus != this.props.deleteMaterialsStatus) {
      this.props.readMaterialsData(this.state.formData.id!!);
    }

    if (prevProps.deleteToolsStatus != this.props.deleteToolsStatus) {
      this.props.readToolsData(this.state.formData.id!!);
    }

    if (prevProps.readLabourStatus != this.props.readLabourStatus) {
      this.setState({ labours: this.props.labours });
    }
    if (prevProps.labours != this.props.labours) {
      this.setState({ labours: this.props.labours });
    }

    if (prevProps.createLabourStatus != this.props.createLabourStatus) {
      //this.setState({ isLabourModalVisible: false });
      this.props.readLabourData(this.state.formData.id!!);
      //this.getPbsLabourList();
    }

    if (prevProps.deleteLabourStatus != this.props.deleteLabourStatus) {
      this.props.readLabourData(this.state.formData.id!!);
    }

    if (prevProps.readConsumableStatus != this.props.readConsumableStatus) {
      this.setState({ consumables: this.props.consumables });
    }

    if (prevProps.consumables != this.props.consumables) {
      this.setState({ consumables: this.props.consumables });
    }

    if (prevProps.createConsumableStatus != this.props.createConsumableStatus) {
      this.props.readConsumableData(this.state.formData.id!!);
      //this.setState({ isConsumablesModalVisible: false });
      //this.getPbsConsumablesList();
    }

    if (prevProps.deleteConsumableStatus != this.props.deleteConsumableStatus) {
      this.props.readConsumableData(this.state.formData.id!!);
    }

    if (
      prevProps.instructionUploadStatus != this.props.instructionUploadStatus &&
      this.props.uploadedInstructionLink
    ) {
      this.setState({
        uploadedTechInstructionLink: this.props.uploadedInstructionLink,
        uploadedSaftyInstructionLink: this.props.uploadedInstructionLink,
        uploadedenvInstructionLink:this.props.uploadedInstructionLink,
        uploadedhelthInstructionLink:this.props.uploadedInstructionLink
      });
    }

    if (
      (prevProps.readTechInstructionStatus !=
      this.props.readTechInstructionStatus) || this.state.techInstructions !== this.props.techInstructions
    ) {
      this.setState({ techInstructions: this.props.techInstructions });
    }

    if (prevProps.saveCompetenceStatus !== this.props.saveCompetenceStatus) {
      this.props.filterCompetenceList(this.state.formData.id!!);
      // if(this.props.saveCompetenceStatus){
      //   this.getPbsCompetenceList();
      // }
    }

    if (prevProps.deleteCompetenceStatus != this.props.deleteCompetenceStatus) {
      if(this.props.deleteCompetenceStatus){
      this.getPbsCompetenceList();
      }
    }

    if (prevProps.createTechInstructionStatus != this.props.createTechInstructionStatus) {
      if(this.props.createTechInstructionStatus){
        //this.setState({ isOpenTechInstructionModal: false });
        this.setState({ techInstruction: new Instruction() });
        this.savePbsInstruction().then(() => {
        this.getPbsTechInstructionList();
        });
      }
    }

    if (prevProps.createSaftyInstructionStatus != this.props.createSaftyInstructionStatus) {
      if(this.props.createSaftyInstructionStatus){
        //this.setState({ isOpenSafetyInstructionModal: false });
        this.setState({ saftyInstruction: new Instruction() });
        this.savePbsInstruction().then(() => {
          this.getPbsSaftyInstructionList()
        });
      }
    }

    if (prevProps.deleteTechInstructionStatus != this.props.deleteTechInstructionStatus) {
        this.getPbsTechInstructionList();
    }

    if ((prevProps.readSaftyInstructionStatus != this.props.readSaftyInstructionStatus) || this.state.saftyInstructions !== this.props.saftyInstructions ) {
      this.setState({ saftyInstructions: this.props.saftyInstructions });
    }

    if (prevProps.deleteSaftyInstructionStatus != this.props.deleteSaftyInstructionStatus) {
      this.getPbsSaftyInstructionList()
    }

    if (prevProps.createEnvInstructionStatus != this.props.createEnvInstructionStatus) {
      if(this.props.createEnvInstructionStatus){
        //this.setState({ isOpenEnvInstructionModal: false });
        this.setState({ envInstruction:  new Instruction() });
        this.savePbsInstruction().then(() => {
          this.getPbsEnvInstructionList()
        })
      }
    }

    if (prevProps.deleteEnvInstructionStatus != this.props.deleteEnvInstructionStatus) {
      this.getPbsEnvInstructionList();
    }

  if ((prevProps.readEnvInstructionStatus != this.props.readEnvInstructionStatus) || this.state.envInstructions !== this.props.envInstructions) {
    this.setState({ envInstructions: this.props.envInstructions });
  }

  if (prevProps.createHelthInstructionStatus != this.props.createHelthInstructionStatus) {
    if(this.props.createHelthInstructionStatus){
      //this.setState({ isOpenHelthInstructionModal: false });
      this.setState({ helthInstruction: new Instruction() });
      this.savePbsInstruction().then(() => {
        // console.log("GET LIST")
        this.getPbsHelthInstructionList()
      });
    }
  }

  if (prevProps.deleteHelthInstructionStatus != this.props.deleteHelthInstructionStatus) {
    this.getPbsHelthInstructionList();
  }

  if ((prevProps.readHelthInstructionStatus != this.props.readHelthInstructionStatus) || this.state.helthInstructions !== this.props.helthInstructions) {
    this.setState({ helthInstructions: this.props.helthInstructions });
  }

  if(prevProps.createNewNode != this.props.createNewNode){
    if(this.props.createNewNode){
     // this.props.readTaxonomyData();
    }
  }

  }

 getBorListByName = async(pbsId:string) => {
   
    const filter = {
        borTitle: null,
        product: pbsId,
        locationParent: null,
        utilityParent: null,
        sorter: {
            attribute: null,
            order: null
        }
    };
    const response = await client.post(
        BOR_READ_BY_PRODUCT_DATA, filter
    );

    if (response.data.result.length > 0) {
      this.setState({hasBorCreatedForThisProduct:true})
    } else {
      this.setState({hasBorCreatedForThisProduct:false})
    }
};

  resetSelection = () => {
    this._riskSelection.selectToKey('', true);
    this._qualitySelection.selectToKey('', true);
    this._techInstructionSelection.selectToKey('', true);
    this._saftyInstructionSelection.selectToKey('', true);
    this._envInstructionSelection.selectToKey('', true);
    this._helthInstructionSelection.selectToKey('', true);
  };

  savePbsRisk = () => {
    if (!_.isEmpty(this.state.formData) && this.props.newRiskId) {
      let riskData: PbsRisk = {
        pbsProductId: this.state.formData.id,
        riskId: this.props.newRiskId,
      };
      this.props.savePbsRisk(riskData);
    }
  };

  savePbsInstruction = async () => {
    if (!_.isEmpty(this.state.formData) && this.props.selectedInstruction.id && !this.state.isEditTechInstruction) {
      let instructionData: PbsInstruction = {
        id: uuidv4(),
        pbsProductId: this.state.formData.id,
        instructionsId: this.props.selectedInstruction.id,
      };
      await this.props.savePbsInstruction(instructionData);
    }
  };

  savePbsQuality = () => {
    if (!_.isEmpty(this.state.formData) && this.props.newQualityId) {
      let quality: PbsQuality = {
        pbsProductId: this.state.formData.id,
        qualityId: this.props.newQualityId,
      };
      this.props.savePbsQuality(quality);
    }
  };

  getPbsCompetenceList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.filterCompetenceList(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.filterCompetenceList(this.state.formData.id);
    }
  };

  getPbsRiskList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.filterRiskList(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.filterRiskList(this.state.formData.id);
    }
  };

  getPbsQualityList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.filterQualityList(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.filterQualityList(this.state.formData.id);
    }
  };

  getPbsToolsList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readToolsData(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.readToolsData(this.state.formData.id);
    }
  };

  getPbsLabourList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readLabourData(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.readLabourData(this.state.formData.id);
    }
  };

  getPbsService = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readPBSService(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.readPBSService(this.state.formData.id);
    }
  };

  getMaterisList = () => {
    // if (
    //   !_.isEmpty(this.props.formData) &&
    //   this.props.formData.id !== null &&
    //   this.props.isEdit
    // ) {
    //   this.props.readMaterialsData(this.props.formData.id!!);
    // }
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readMaterialsData(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.readMaterialsData(this.state.formData.id);
    }
  };

  getPbsConsumablesList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readConsumableData(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.readConsumableData(this.state.formData.id);
    }
  };

  getAllTechInstructionList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readPMOLAllInstruction(
        this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null && this.props.formData.headerTitle
    ) {
      this.props.readPMOLAllInstruction(this.state.formData.id);
    }
  };

  getPbsTechInstructionList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readTechInstruction(
        this.props.formData.id,
        INSTRUCTION_TYPE.TECH
      );
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null
    ) {
      this.props.readTechInstruction(
        this.state.formData.id,
        INSTRUCTION_TYPE.TECH
      );
    }
  };

  getPbsSaftyInstructionList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readSaftyInstruction(
        this.props.formData.id,
        INSTRUCTION_TYPE.SAFTY
      );
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null
    ) {
      this.props.readSaftyInstruction(
        this.state.formData.id,
        INSTRUCTION_TYPE.SAFTY
      );
    }
  };

  getPbsEnvInstructionList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readEnvInstruction(
        this.props.formData.id,
        INSTRUCTION_TYPE.ENVIRONMENT
      );
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null
    ) {
      this.props.readEnvInstruction(
        this.state.formData.id,
        INSTRUCTION_TYPE.ENVIRONMENT
      );
    }
  };

  getPbsHelthInstructionList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.readHelthInstruction(
        this.props.formData.id,
        INSTRUCTION_TYPE.HELTH
      );
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null
    ) {
      this.props.readHelthInstruction(
        this.state.formData.id,
        INSTRUCTION_TYPE.HELTH
      );
    }
  };

  componentDidMount() {
    this.props.getRiskDropDownsList();
    this.props.readInstructionDropDown();
    this.props.readCompetenceDropDown();
    this.props.readTaxonomyData();
    this.props.readTaxonomyLevel();
    this.getPbsRiskList();
    //this.props.filterQualityList();
    this.getMaterisList();
    this.getPbsQualityList();
    this.getPbsToolsList();
    this.getPbsLabourList();
    this.getPbsConsumablesList();
    this.getAllTechInstructionList()
    this.getPbsService()
    // this.getPbsTechInstructionList();
    // this.getPbsSaftyInstructionList()
    // this.getPbsEnvInstructionList();
    // this.getPbsHelthInstructionList()

    //this.getPbsInstructionList();
    this.getPbsCompetenceList();

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.collapseDocumentPane) {
          this.setState({
            isCollapsed: !this.state.isCollapsed
          })
        }
        if (data.data.isNewPbs) {
          this.setState({isMouValid:true})
          this.resetFormData();
          this.resetValidation();
          //this.props.clearInstructionData();
          this.props.clearFormData();
          this.props.resetRiskGridList();
        }
        if (data.data.pbsType) {
          if (data.data.pbsType === PBS_TYPE.SUB) {
            let pbsNewData = {...this.props.formData, isSaved: false, pbsProductItemTypeId: PRODUCT_ITEM_TYPE_SUB_CONTRACT_ID }
            this.setState({pbsType:data.data.pbsType, formData:pbsNewData})
          } else if(data.data.pbsType === PBS_TYPE.REGULAR) {
            let pbsNewData = {...this.props.formData, isSaved: false, pbsProductItemTypeId: PRODUCT_ITEM_TYPE_REG_CONTRACT_ID }
            //this.resetFormData()
            this.setState({pbsType:data.data.pbsType, formData: pbsNewData})

          }
        }
        if (data.data.saveFormData) {
          this.saveFormData();
        }
        if (data.data.saveFormDataPBS) {
          this.saveFormData();
        }
        if (data.data.pbsFilter) {
          this.setState({
            filter: data.data.pbsFilter,
          });
        }
        if (data.data.createNewNode) {
          this.createNewNode(data.data.createNewNode);
        }
        if (data.data.selectedNodeLevel) {
          this.setState({
            selectedNodeLevel: data.data.selectedNodeLevel,
          });

        }
        if(data.data.openTaxonomyModal){
          this.props.readTaxonomyData();
        }
        if(data.data.closeTaxonomyModal){
          this.props.readTaxonomyData();
        }
        if(data.data.addNewNodeName){
          //this.props.readTaxonomyData();
        }
        if (data.data.createNewMSNode) {
          this.createNewMSNode(data.data.createNewMSNode);
        }

        if(data.data.pbsResourceEditItem) {
          this.openResourceModal(data.data.pbsResourceEditItem)
        }

        if (data.data.selectItemFromVP){
          this.setState({isDirty:false})
        }

        if (data.data.pbsTypeFromRead) {
          this.setState({pbsType:data.data.pbsTypeFromRead})
        }
      }
    });
  }


  openResourceModal = (pbsResourceEditItem:any) => {
    if (pbsResourceEditItem.type === RESOURCE_TYPE.MATERIALS) {
      this.setState({
        isMaterialModalVisible: !this.state.isMaterialModalVisible,
        material: pbsResourceEditItem.item,
        feild: pbsResourceEditItem.feild,
        selectedResourceTypeId:ResourceTypes.materials.id
      });
    } else if (pbsResourceEditItem.type === RESOURCE_TYPE.TOOLS) {
      this.setState({
        isToolsModalVisible: !this.state.isToolsModalVisible,
        tool: pbsResourceEditItem.item,
        feild: pbsResourceEditItem.feild,
        selectedResourceTypeId:ResourceTypes.tools.id
      });
    } else if (pbsResourceEditItem.type === RESOURCE_TYPE.CONSUMABLE) {
      this.setState({
        isConsumablesModalVisible: !this.state.isConsumablesModalVisible,
        consumable: pbsResourceEditItem.item,
        feild: pbsResourceEditItem.feild,
        selectedResourceTypeId:ResourceTypes.consumable.id
      });
    } else if (pbsResourceEditItem.type === RESOURCE_TYPE.LABOUR) {
      this.setState({
        isLabourModalVisible: !this.state.isLabourModalVisible,
        labour: pbsResourceEditItem.item,
        feild: pbsResourceEditItem.feild,
        selectedResourceTypeId:ResourceTypes.labour.id
      });
    }
  }




  componentWillUnmount(){
    this.subscription.unsubscribe()
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { pbsFilter: this.state.filter },
    });
  };

  onAddTechInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.techInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editTechLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type ? instructionLink.type : 'Website',
      });
    } else {
      if (links.length >= this.state.editTechLinkIndex+1) {
        links[this.state.editTechLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type ? instructionLink.type : 'Website',
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({ techInstruction: instruction,
      //isOpenTechLinkModal: false
    });
  };

  onAddSaftyInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.techInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editSaftyLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type ? instructionLink.type : 'Website',
      });
    } else {
      if (links.length >= this.state.editSaftyLinkIndex+1) {
        links[this.state.editSaftyLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type ? instructionLink.type : 'Website',
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({ techInstruction: instruction,
      //isOpenSafetyLinkModal: false
    });
  };

  onAddEnvInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.techInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editEnvLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type ? instructionLink.type : 'Website',
      });
    } else {
      if (links.length >= this.state.editEnvLinkIndex+1) {
        links[this.state.editEnvLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type ? instructionLink.type : 'Website',
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({ techInstruction: instruction,
      //isOpenEnvLinkModal: false
    });
  };

  onAddHelthInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.techInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editHelthLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type ? instructionLink.type : 'Website',
      });
    } else {
      if (links.length >= this.state.editHelthLinkIndex+1) {
        links[this.state.editHelthLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type ? instructionLink.type : 'Website',
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({ techInstruction: instruction,
      //isOpenHelthLinkModal: false
    });
  };

  createNewNode=(newNode:any)=>{
    if(newNode){
     this.props.createTaxonomyNode(newNode);
    }
  };

  createNewMSNode=(newNode:any)=>{
    if(newNode){
      this.props.createMSTaxonomyNode(newNode);
    }
  };

  private cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(this.getCpcByName(inputValue));
      // }, 1000);
    });

  private getCpcByName = async (name: string) => {
    let filter={
      resourceTypeId: this.state.selectedResourceTypeId,
      resourceFamilyId: null,
      resourceNumber: null,
      title:name,
      pbsId: this.state.formData.id,
      status: 1,
      sorter: {
        attribute: 'title',
        order: 'asc',
      },
    };
    if (name.length>=2) {
      let response = await client.post(
        `ContractingUnitTenants/PBSCPCFilter`,
        filter
      );
      return this.formatCpcResponse(response);
    }

  };

  private formatCpcResponse=(response:any)=>{
    let localOptions: { value: string; label: string }[] = [];
    let contractingUnitOptions: { value: string; label: string }[] = [];
    let centralOptions: { value: string; label: string }[] = [];
    let groupedOptions = [
      {
        label: this.props.t('project'),
        options: localOptions,
      },
      {
        label: this.props.t('contractingUnit'),
        options: contractingUnitOptions,
      },
      {
        label: this.props.t('organization'),
        options: centralOptions,
      },
    ];
    if (response && response.data) {
      let localData=response.data.local;
      let contractingUnit=response.data.contractingUnit;
      let central=response.data.central;

      if(localData && _.isArray(localData)){
        localOptions = localData.map((item: any) => {
          return {
            value: item.id,
            label: item.title,
            environment:"local",
          };
        });
      }
      if(contractingUnit && _.isArray(contractingUnit)){
        contractingUnitOptions = contractingUnit.map((item: any) => {
          return {
            value: item.id,
            label: item.title,
            environment:"cu",
          };
        });
      }
      if(central && _.isArray(central)){
        centralOptions = central.map((item: any) => {
          return {
            value: item.id,
            label: item.title,
            environment:"central",
          };
        });
      }
      groupedOptions = [
        {
          label:  this.props.t('project'),
          options: localOptions,
        },
        {
          label: this.props.t('contractingUnit'),
          options: contractingUnitOptions,
        },
        {
          label: this.props.t('organization'),
          options: centralOptions,
        },
      ];

    }else{

    }


    return groupedOptions;
  };

  onChangeService = (prop:string, value:any) => {
      this.setState((prevSate:any)=>({
        service:{
          ...prevSate.service,
          [prop]:value
        }
      }), ()=>{
        let unitPrice = this.state?.service?.unitPrice ? parseFloat(this.state?.service?.unitPrice) : 0
        let quantity = this.state?.service?.quantity ? parseFloat(this.state?.service?.quantity) : 0
        let totPrice = unitPrice * quantity
        this.setState({service: {...this.state.service, totalPrice: totPrice.toString()}})
      })
  }

  render() {
    const { t } = this.props;
    //let readonly = this.state.formData.hasOwnProperty('isSaved') && !this.state.formData.isSaved?true:false
    let readonly = this.state.formData.headerTitle?false:true
    return (
      <div style={{ width: '100%' }}>
        <div id={'topDiv'} style={{position: 'absolute',width: '75%', zIndex: 1000}}>
        {(!this.state.isMouValid) &&
          <CustomAlert
            showMsg={true}
            msg={this.props.t("mouErrorMsg")}
            msgType={'error'}
            handleCustomAlert={() => {this.setState({isMouValid: false})}}
          />
        }
        </div>
  
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
        
        <DocumentPaneComponent
          dropDownOptions={{
            toleranceStates: this.props.toleranceStates,
            productStates: this.props.productStates,
            itemTypes: this.props.itemTypes,
            locationTaxonomy: this.props.locationTaxonomy,
            utilityTaxonomy: this.props.utilityTaxonomy,
            productTaxonomy: this.props.productTaxonomy,
            machineTaxonomy: this.props.machineTaxonomy,
            locationTaxonomyLevel: this.props.locationTaxonomyLevel,
            utilityTaxonomyLevel: this.props.utilityTaxonomyLevel,
            machineTaxonomyLevel: this.props.machineTaxonomyLevel,
          }}
          projectBreakdown={this.state.formData}
          handleFormChange={(
            projectBreakdown: ProjectBreakdown,
            save: boolean
          ) => {
            this.handleFormChange(projectBreakdown, save);
          }}
          saveFormData={() => this.saveFormData()}
          resetSelection={() => this.resetSelection()}
          submitCpc={this.state.submitCpc}
          isCollapsed={this.state.isCollapsed}
          isEdit={this.props.isEdit}
          // openNickNameModel={() => {
          //   this.openNickNameModel();
          // }}
          // handleNickNameDelete={() => {
          //   this.handleNickNameDelete();
          // }}
          handleRiskDelete={() => {
            this.handleRiskDelete();
          }}
          //qualitySelectionDetails={this.state.qualitySelectionDetails}
          qualityActionButtonState={this.state.qualityActionButtonState}
          qualitySelection={this._qualitySelection}
          selectedResourceFamily={this.state.resourceFamily}
          onChangeResourceFamily={(id: string | undefined) =>
            this.onChangeResourceFamily(id)
          }
          openRiskModal={() => {
            this.openRiskModal();
          }}
          riskActionButtonState={this.state.riskActionButtonState}
          riskSelection={this._riskSelection}
          cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
          cpcPressureClass={this.props.cpcPressureClass}
          cpcMaterial={this.props.cpcMaterial}
          cpcBrand={this.props.cpcBrand}
          resourceTypes={this.props.resourceTypes}
          resourceFamily={this.state.resourceFamily}
          languageCodes={this.props.languageCodes}
          cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
          savePicturesOfTheInstallation={(files: FileList | null) =>
            this.props.savePicturesOfTheInstallation(files)
          }
          validationMessages={this.state.validationMessages}
          riskList={this.state.riskList}
          openQualityModal={() => {
            this.openQualityModal();
          }}
          qualityList={this.state.qualityList}
          handleQualityDelete={() => {
            this.handleQualityDelete();
          }}
          materials={this.state.materialList}
          openMaterialModal={() => {
            this.openMaterialModal();
          }}
          materialActionButtonState={this.state.materialActionButtonState}
          materialSelection={this._materialsSelection}
          tools={this.state.tools}
          toolsActionButtonState={this.state.toolsActionButtonState}
          openToolsModal={() => this.openToolsModal()}
          toolSelection={this._toolsSelection}
          handleMaterialDelete={() => {
            this.handleMaterialsDelete();
          }}
          handleToolsDelete={() => {
            this.handleToolsDelete();
          }}
          materialLoading={this.props.readMaterialsStatus}
          toolsLoading={this.props.readToolsStatus}
          labours={this.state.labours}
          labourActionButtonState={this.state.labourActionButtonState}
          openLabourModal={() => this.openLabourModal()}
          labourSelection={this._labourSelection}
          labourLoading={this.props.readLabourStatus}
          handleLabourDelete={() => {
            this.handleLaboursDelete();
          }}
          openConsumableModal={() => {
            this.openConsumaleModal();
          }}
          consumableSelection={this._consumableSelection}
          consumableActionButtonState={this.state.consumableActionButtonState}
          consumables={this.state.consumables}
          consumalbeLoading={this.props.readConsumableStatus}
          handleConsumableDelete={() => {
            this.handleConsumableDelete();
          }}
          openTechInstuctionModal={() => {
            this.openTechInstructionModal();
          }}
          onFilterPersonChanged={this.onFilterPersonChanged}
          techInstructionsLoading={this.props.readTechInstructionStatus}
          techInstructions={this.state.techInstructions}
          techInstructionSelection={this._techInstructionSelection}
          techInstructionActionButtonState={
            this.state.techInstructionActionButtonState
          }
          onQualityFilterPersonChanged={this.onQualityFilterPersonChanged}
          competenceList={this.state.competenceList}
          competenceSelection={this._competenceSelection}
          competenceActionButtonState={this.state.competenceActionButtonState}
          openCompetenceModal={()=>{
            this.openCompetenceModal();
          }}
          handleCompetenceDelete={() => {
            this.handleCompetenceDelete();
          }}
          handleDTechInstructionDelete={()=>{
            this.handleTechInstructionDelete()
          }}
          openSaftyInstuctionModal={() => {
            this.openSaftyInstructionModal();
          }}
          saftyInstructionsLoading={this.props.readSaftyInstructionStatus}
          saftyInstructions={this.state.saftyInstructions}
          saftyInstructionSelection={this._saftyInstructionSelection}
          saftyInstructionActionButtonState={
            this.state.saftyInstructionActionButtonState
          }
          handleSaftyInstructionDelete={()=>{
            this.handleSaftyInstructionDelete()
          }}
          openEnvInstuctionModal={() => {
            this.openEnvInstructionModal();
          }}
          envInstructionsLoading={this.props.readEnvInstructionStatus}
          envInstructions={this.state.envInstructions}
          envInstructionSelection={this._envInstructionSelection}
          envInstructionActionButtonState={
            this.state.envInstructionActionButtonState
          }
          handleEnvInstructionDelete={()=>{
            this.handleEnvInstructionDelete()
          }}
          //
          openHelthInstuctionModal={() => {
            this.openHelthInstructionModal();
          }}
          helthInstructionsLoading={this.props.readHelthInstructionStatus}
          helthInstructions={this.state.helthInstructions}
          helthInstructionSelection={this._helthInstructionSelection}
          helthInstructionActionButtonState={
            this.state.helthInstructionActionButtonState
          }
          handleHelthInstructionDelete={()=>{
            this.handleHelthInstructionDelete()
          }}
          onFilterTaxonomyNodeChanged={this.onFilterTaxonomyNodeChanged}
          readOnly={readonly}
          pbsType={this.state.pbsType}
          onChangeService={(prop, val)=> { this.onChangeService(prop, val)}}
          service={this.state.service}
          feild={this.state.feild}
          hasBorCreatedForThisProduct={this.state.hasBorCreatedForThisProduct}
        />

        <ResourceModal
          clearCpcData={() => this.props.clearCpcData()}
          isOpen={this.state.isMaterialModalVisible}
          openResourceModal={() => this.openMaterialModal()}
          isEdit={this.state.isMaterialEdit}
          onFilterResourceChanged={this.onFilterMaterialsChanged}
          createResource={(resource: Resource, project? : string) => {
            this.props.createMaterials(resource, project);
          }}
          resource={this.state.material}
          title={this.state.isMaterialEdit?t('editMaterial'):t('addMaterial')}
          pbsID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          feild={this.state.feild}
          projectSequenceCode={this.state.formData.project}
        />

        <ResourceModal
          clearCpcData={() => this.props.clearCpcData()}
          isOpen={this.state.isToolsModalVisible}
          openResourceModal={() => this.openToolsModal()}
          isEdit={this.state.isToolEdit}
          onFilterResourceChanged={this.onFilterToolsChanged}
          createResource={(resource: Resource, project? : string) => {
            this.props.createTools(resource, project);
          }}
          resource={this.state.tool}
          title={this.state.isToolEdit?t('editTool'):t('addTool')}
          pbsID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          feild={this.state.feild}
          projectSequenceCode={this.state.formData.project}
        />

        <ResourceModal
          clearCpcData={() => this.props.clearCpcData()}
          isOpen={this.state.isLabourModalVisible}
          openResourceModal={() => this.openLabourModal()}
          isEdit={this.state.isLabourEdit}
          onFilterResourceChanged={this.onFilterLaboursChanged}
          createResource={(resource: Resource, project? : string) => {
            this.props.createLabour(resource, project);
          }}
          resource={this.state.labour}
          title={this.state.isLabourEdit?t('editLabour'):t('addLabour')}
          pbsID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          feild={this.state.feild}
          // projectSequenceCode={this.state.formData.projectSequenceCode}
          projectSequenceCode={this.state.formData.project}
        />

        <ResourceModal
          clearCpcData={() => this.props.clearCpcData()}
          isOpen={this.state.isConsumablesModalVisible}
          openResourceModal={() => this.openConsumaleModal()}
          isEdit={this.state.isConsumableEdit}
          onFilterResourceChanged={this.onFilterConsumalesChanged}
          createResource={(resource: Resource, project? : string) => {
            this.props.createConsumable(resource, project);
          }}
          resource={this.state.consumable}
          title={this.state.isConsumableEdit?t('editConsumable'):t('addConsumable')}
          pbsID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          feild={this.state.feild}
          projectSequenceCode={this.state.formData.project}
        />

        <RiskModal
          isOpen={this.state.isRiskModalVisible}
          openRiskModal={() => this.openRiskModal()}
          isEdit={this.state.isRiskEdit}
          onFilterPersonChanged={this.onFilterPersonChanged}
          createRisk={(risk: Risk) => this.createRisk(risk)}
          //to load risk data into modal
          risk={this.state.riskData}
          riskValues={this.props.riskValues}
          pbsId={this.state.formData.id}
        />

        <QualityModal
          isOpen={this.state.isQualityModalVisible}
          openQualityModal={() => this.openQualityModal()}
          isEdit={this.state.isQualityEdit}
          createQuality={(qality: Quality) => {
            this.props.createQuality(qality);
          }}
          quality={this.state.qualityData} //to load quality data into modal
          pbsId={this.state.formData.id}
        />

        <InstructionModal
          isOpen={this.state.isOpenTechInstructionModal}
          openInstructionModal={() => this.openTechInstructionModal()}
          isEdit={this.state.techInstruction}
         // onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createTechInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={t('technicalInstructions')}
          selection={this._techLinksSelection}
          linkActionButtonState={this.state.techLinksActionButtonState}
          openLinkModal={() => this.openTechLinkModalModal()}
          //links={this.state.techInstructionLinks}
          handleLinkDelete={() => this.handleTechLinkDelete()}
          type={INSTRUCTION_TYPE.TECH}
          pbsId={this.state.formData.id}

          readByInstructionRegisterId={(id: string) => 
            this.props.readByInstructionRegisterId(id)
          }  
        />

        <InstructionModal
          isOpen={this.state.isOpenSafetyInstructionModal}
          openInstructionModal={() => this.openSaftyInstructionModal()}
          isEdit={this.state.techInstruction}
          //onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createSaftyInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={t('saftyInstructions')}
          selection={this._saftyLinksSelection}
          linkActionButtonState={this.state.safetyLinksActionButtonState}
          openLinkModal={() => this.openSaftyLinkModalModal()}
          //links={this.state.isEditSaftyLinks}
          handleLinkDelete={()=>this.handleSaftyLinkDelete()}
          type={INSTRUCTION_TYPE.SAFTY}
          pbsId={this.state.formData.id}

          readByInstructionRegisterId={(id: string) => 
            this.props.readByInstructionRegisterId(id)
          }  
        />

        <InstructionModal
          isOpen={this.state.isOpenEnvInstructionModal}
          openInstructionModal={() => this.openEnvInstructionModal()}
          isEdit={this.state.techInstruction}
          //onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createEnvInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={t('environmentInstruction')}
          selection={this._envLinksSelection}
          linkActionButtonState={this.state.envLinksActionButtonState}
          openLinkModal={() => this.openEnvLinkModalModal()}
          //links={this.state.isEditSaftyLinks}
          handleLinkDelete={()=>this.handleEnvLinkDelete()}
          type={INSTRUCTION_TYPE.ENVIRONMENT}
          pbsId={this.state.formData.id}

          readByInstructionRegisterId={(id: string) => 
            this.props.readByInstructionRegisterId(id)
          }  
        />

        <InstructionModal
          isOpen={this.state.isOpenHelthInstructionModal}
          openInstructionModal={() => this.openHelthInstructionModal()}
          isEdit={this.state.techInstruction}
          //onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createHelthInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={t('healthInstructions')}
          selection={this._helthLinksSelection}
          linkActionButtonState={this.state.helthLinksActionButtonState}
          openLinkModal={() => this.openHelthLinkModalModal()}
          //links={this.state.isEditSaftyLinks}
          handleLinkDelete={()=>this.handleHelthLinkDelete()}
          type={INSTRUCTION_TYPE.HELTH}
          pbsId={this.state.formData.id}

          readByInstructionRegisterId={(id: string) => 
            this.props.readByInstructionRegisterId(id)
          }  
        />

        <LinkModal
          isOpen={this.state.isOpenTechLinkModal}
          openLinkModal={() => this.openTechLinkModalModal()}
          isEdit={this.state.isEditTechLinks}
          instructionLink={this.state.techLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedTechInstructionLink}
          onAddInstructionLink={this.onAddTechInstructionLink}
        />

        <LinkModal
          isOpen={this.state.isOpenSafetyLinkModal}
          openLinkModal={() => this.openSaftyLinkModalModal()}
          isEdit={this.state.isEditSaftyLinks}
          instructionLink={this.state.safetyLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedSaftyInstructionLink}
          onAddInstructionLink={this.onAddSaftyInstructionLink}
        />

        <LinkModal
          isOpen={this.state.isOpenEnvLinkModal}
          openLinkModal={() => this.openEnvLinkModalModal()}
          isEdit={this.state.isEditEnvLinks}
          instructionLink={this.state.envLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedenvInstructionLink}
          onAddInstructionLink={this.onAddEnvInstructionLink}
        />

        <LinkModal
          isOpen={this.state.isOpenHelthLinkModal}
          openLinkModal={() => this.openHelthLinkModalModal()}
          isEdit={this.state.isEditHelthLinks}
          instructionLink={this.state.helthLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedhelthInstructionLink}
          onAddInstructionLink={this.onAddHelthInstructionLink}
        />


        <CompetenceModal
          isOpen={this.state.isCompetenceModalVisible}
          openCompetenceModal={() => this.openCompetenceModal()}
          isEdit={this.state.isCompetenceEdit}
          createCompetence={(competence: Competence) => {
            this.props.createCompetence(competence);
          }}
          dropDownOptions={{
            pbsExperience: this.props.pbsExperience,
            pbsSkills: this.props.pbsSkills,
          }}
          competence={this.state.competence}
          pbsId={this.state.formData.id}
        />

        <SquareMetersModal/>



        {/* <ConfirmationDialog
          hidden={this.state.confimRiskDelete}
          title={'Are you sure?'}
          subText={"You won't be able to revert this!"}
          onClickConfirmDialog={this.onRemoveConfirm}
          onClickCancelDialog={this.onRemoveCancel}
          confirmButtonText={'Yes, delete it!'}
        /> */}
      </div>
    );
  }

  private isValidFileType = (fileType: string): boolean => {
    return acceptedTypes.includes(fileType);
  };

  onLinkFileChange = (event: any) => {
    if (event && event.target && event.target.files && event.target.files[0]) {
      // if (!this.isValidFileType(event.target.files[0].type)) {
      //   return;
      // }
      const formData = new FormData();

      if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
        formData.append('file', event.target.files[0]);
        formData.append('id', this.props.formData.id!!);
        this.props.uploadInstructionDoc(formData);
      } else if (
        !_.isEmpty(this.state.formData) &&
        this.state.formData.id !== null
      ) {
        formData.append('file', event.target.files[0]);
        formData.append('id', this.state.formData.id);
        this.props.uploadInstructionDoc(formData);
      }
      event.target.value = null;
    }
  };

  private createRisk = (risk: Risk) => {
    this.props.createRisk(risk);
  };

  private getMaterialsByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadMaterial?name=${name}`);
    return formatCpcResponse(response);
  };

  private getToolsByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadTool?name=${name}`);
    return formatCpcResponse(response);
  };

  private getLaboursByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadLabour?name=${name}`);
    return formatCpcResponse(response);
  };

  private getConsumalbesByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadConsumable?name=${name}`);
    return formatCpcResponse(response);
  };

  private getPersonByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabPersonList/${name}`
    );
    return formatPersonResponse(response);
  };
  private getPersonByNameForQulity = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    let response = await client.post(
      `CentralAddressBook/PersonFilterByName`,{fullName:name}
    );
    if (response && response.data && _.isArray(response.data.result)) {
      data=response.data.result;
    }
    return data;
  };



  private onFilterPersonChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getPersonByName(filterText);
    } else {
      return [];
    }
  };
  private onQualityFilterPersonChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getPersonByNameForQulity(filterText);
    } else {
      return [];
    }
  };

  private onFilterMaterialsChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getMaterialsByName(filterText);
    } else {
      return [];
    }
  };

  private onFilterToolsChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getToolsByName(filterText);
    } else {
      return [];
    }
  };

  private onFilterLaboursChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getLaboursByName(filterText);
    } else {
      return [];
    }
  };

  private onFilterConsumalesChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getConsumalbesByName(filterText);
    } else {
      return [];
    }
  };




  private getTaxonomyByName = async (name: string) => {
    let filter={
      title:name,
      pbsTaxonomyLevelId:(this.state.selectedNodeLevel)?this.state.selectedNodeLevel.id:null,
    };
    let response = await client.post(`Pbs/FilterProductByTaxonomyLevel`,filter);
      return this.formatTaxonomyResponse(response,name);

  };

  formatTaxonomyResponse = (response: any,name:string) => {
    let data:ITag[]|any = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          name: item.text,
          key: item.key,
          isNewItem:false,
        };
      });
      let obj = data.find((o:any) => o.name.toLowerCase() === name.toLowerCase());
      if(!obj){
        data.unshift({ key: "", name: name,isNewItem:true })
      }
    }else{
      return [{ key: "", name: name,isNewItem:true }];
    }
    return data;
  };

  private onFilterTaxonomyNodeChanged = (
    filterText: string,
    currentPersonas: ITag[],
    limitResults?: number
  ): ITag[] | Promise<ITag[]> => {
    if (filterText) {
      return this.getTaxonomyByName(filterText);
    } else {
      return [{ key: "", name: filterText}];
    }
  };

  private handleFormChange = (formData: ProjectBreakdown, save: boolean) => {
    console.log("IS DIRTY111", true)
    this.props.saveVPStateAttr('isPBSDirty', true)
    this.setState({
      isDirty: true,
    });

    this.setState({
      formData: formData,
    });
  };

  private _getQualitySelectionDetails(): string | number | {} | [] {
    return this._qualitySelection.getSelection();
  }

  private _qualityEditDetails() {
    const selectionCount = this._qualitySelection.getSelectedCount();
    let quality = new Quality();

    if (selectionCount == 1) {
      this.setState({
        isQualityEdit: true,
      });
      UprinceLogger.log(
        '_nickNameSelection',
        this._qualitySelection.getSelection()
      );
      quality = this._qualitySelection.getSelection()[0] as Quality;
    } else {
      this.setState({
        isQualityEdit: false,
      });
    }
    return quality;
  }

  private _qualityDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedNickName = this._qualitySelection.getSelection() as SelectedNickNameItem[];
    savedDeleteList = selectedNickName.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: string }) => {
      return item.id;
    });
    return deleteList;
  }

  private _getQualityActionButtonState(): ActionButtonState {
    const selectionCount = this._qualitySelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }



  private _getCompetenceSelectionDetails(): string | number | {} | [] {
    return this._competenceSelection.getSelection();
  }

  private _competenceEditDetails() {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let competence = new Competence();

    if (selectionCount == 1) {
      this.setState({
        isCompetenceEdit: true,
      });
      UprinceLogger.log(
        '_nickNameSelection',
        this._competenceSelection.getSelection()
      );
      competence = this._competenceSelection.getSelection()[0] as Competence;
    } else {
      this.setState({
        isCompetenceEdit: false,
      });
    }
    return competence;
  }

  private _competenceDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedNickName = this._competenceSelection.getSelection() as SelectedNickNameItem[];
    savedDeleteList = selectedNickName.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: string }) => {
      return item.id;
    });
    return deleteList;
  }

  private _getCompetenceActionButtonState(): ActionButtonState {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }

  private handleCompetenceDelete = () => {
    let selectedList = this._competenceSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });

    if (selectedList.length > 0) {
      this.props.deleteCompetence(selectedList);
    }
    this._competenceSelection.setAllSelected(false);
  };

  private saveFormData = () => {
    if (this.validate()) {
      let formData = this.state.formData;
      formData.projectId = this.props.projectId;
      formData.pbsType = this.state.pbsType
      if (formData.scope === null) {
        formData.scope = '0';
      }
      if(!formData.id){
        formData.id = this.props.uid ? this.props.uid : uuidv4();
      }

      this.props.saveFormData(formData).then((res:any) => {
        this.props.savePbsStateAttr('isPBSOpenFromVP',false)
        let pbs = res.payload.data.result
        let formData=this.state.formData
        if(pbs){
          formData.title=pbs.title;
          formData.headerTitle=pbs.headerTitle;
          formData.productId=pbs.productId;
          this.setState({
            formData
          });
          this.props.getById(pbs.productId);
          if (pbs.pbsType===PBS_TYPE.SUB){
          this.props.createPBSService({...this.state.service,productId:pbs.id, documents: this.state.service?.documents? this.state.service?.documents:[]}).then((response:any) => {
            this.props.readPBSService(this.state.formData.id);
          })
        }
        }
      });

      this.setState({
        isDirty: false
      });
    }
  };

  private validate = (): boolean => {
    let validationMessages = this.state.validationMessages;
    let name = this.state.formData.name;
    let scope = this.state.formData.scope;
    let contract = this.state.formData.contract;
    let mou = this.state.service?.mou;
    let quantity = this.state.service?.quantity;
    let isValid = true;
    if (_.isNil(name) || _.isEmpty(name)) {
      validationMessages.nameErrorMsg = i18n.t('nameRequired');
      isValid = false;
    } else {
      validationMessages.nameErrorMsg = '';
    }

    if (
      _.isNil(this.state.formData.pbsToleranceStateId) ||
      _.isEmpty(this.state.formData.pbsToleranceStateId)
    ) {
      validationMessages.toleranceStateErrorMsg = i18n.t('toleranceStaterequired');
      isValid = false;
    } else {
      validationMessages.toleranceStateErrorMsg = '';
    }

    if (
      _.isNil(this.state.formData.pbsProductStatusId) ||
      _.isEmpty(this.state.formData.pbsProductStatusId)
    ) {
      validationMessages.statusErrorMsg = i18n.t('statusRequired');
      isValid = false;
    } else {
      validationMessages.statusErrorMsg = '';
    }

    if (
      _.isNil(this.state.formData.pbsProductItemTypeId) ||
      _.isEmpty(this.state.formData.pbsProductItemTypeId)
    ) {
      validationMessages.ItemTypeErrorMsg = i18n.t('itemTypeRequired');
      isValid = false;
    } else {
      validationMessages.ItemTypeErrorMsg = '';
    }

    if (_.isNil(contract)) {
      validationMessages.contractErrorMsg = i18n.t('contractRequired');
      isValid = false;
    } else {
      validationMessages.contractErrorMsg = '';
    }
    if(this.state.pbsType===PBS_TYPE.SUB){
      if (!_.isEmpty(quantity) && _.isNil(mou)) {
        validationMessages.mouErrorMsg = i18n.t('basicUnitOfMeasureRequired');
        this.setState({isMouValid: false});
        setTimeout(()=>{this.setState({isMouValid:true})},MSG_AUTO_CLOSE_TIME)
        isValid = false;
      } else {
        validationMessages.mouErrorMsg = '';
        this.setState({isMouValid: true});
      }
    }

    this.setState({ validationMessages: validationMessages });
    return isValid;
  };

  private resetValidation = () => {
    let validationMessages = this.state.validationMessages;
    validationMessages.toleranceStateErrorMsg = '';
    validationMessages.statusErrorMsg = '';
    validationMessages.ItemTypeErrorMsg = '';
    validationMessages.contractErrorMsg = '';
    validationMessages.nameErrorMsg = '';
    validationMessages.mouErrorMsg = '';

    this.setState({ validationMessages: validationMessages });
  };
  private resetFormData = () => {
    let formData={
      id: this.props.uid,
      title: null,
      headerTitle: null,
      contract: null,
      name: null,
      pbsProductItemTypeId: null,
      pbsProductStatusId: null,
      pbsToleranceStateId: null,
      productComposition: null,
      productDerivation: null,
      productFormatPresentation: null,
      productId: null,
      projectId: this.props.projectId,
      productPurpose: null,
      scope: null,
      historyLog: null,
      locationTaxonomyParentId: null,
      utilityTaxonomyParentId: null,
      machineTaxonomyParentId: null,
      parentId: null,
      pbsQualityResponsibilityId: null,
      qualityProducer: null,
      qualityProducerId: null,
      qualityReviewer: null,
      qualityReviewerId: null,
      qualityApprover: null,
      qualityApproverId: null,
      isSaved:false,
      pbsType:PBS_TYPE.REGULAR,
      startDate: null,
      endDate: null,
      };
    this.setState({ formData: formData,competenceList:[], service:{
      projectTitle: getProject(),
      productTitle: null,
      quantity: null,
      mou: null,
      unitPrice: null,
      totalPrice: null,
      documents: null,
      comments:null,
    }});
  };

  private openRiskModal = (): void => {
    this.setState({
      isRiskModalVisible: !this.state.isRiskModalVisible,
      riskData: this._riskEditDetails(),
    });
  };

  private openTechInstructionModal = (): void => {
    this.setState({
      isOpenTechInstructionModal: !this.state.isOpenTechInstructionModal,
      techInstruction: this._techInstructionEditDetails(),
    });
  };

  private openSaftyInstructionModal = (): void => {
    this.setState({
      isOpenSafetyInstructionModal: !this.state.isOpenSafetyInstructionModal,
      techInstruction: this._saftyInstructionEditDetails(),
    });
  };

  private openHelthInstructionModal = (): void => {
    this.setState({
      isOpenHelthInstructionModal: !this.state.isOpenHelthInstructionModal,
      techInstruction: this._helthInstructionEditDetails(),
    });
  };

  private openEnvInstructionModal = (): void => {
    this.setState({
      isOpenEnvInstructionModal: !this.state.isOpenEnvInstructionModal,
      techInstruction: this._envInstructionEditDetails(),
    });
  };

  private openTechLinkModalModal = (): void => {
    this.setState({
      isOpenTechLinkModal: !this.state.isOpenTechLinkModal,
      techLink: this._techLinkEditDetails(),
      uploadedTechInstructionLink:''
    });
  };

  private openSaftyLinkModalModal = (): void => {
    this.setState({
      isOpenSafetyLinkModal: !this.state.isOpenSafetyLinkModal,
      safetyLink: this._saftyLinkEditDetails(),
      uploadedSaftyInstructionLink:''
    });
  };

  private openEnvLinkModalModal = (): void => {
    this.setState({
      isOpenEnvLinkModal: !this.state.isOpenEnvLinkModal,
      envLink: this._envLinkEditDetails(),
      uploadedenvInstructionLink:''
    });
  };

  private openHelthLinkModalModal = (): void => {
    this.setState({
      isOpenHelthLinkModal: !this.state.isOpenHelthLinkModal,
      helthLink: this._helthLinkEditDetails(),
      uploadedhelthInstructionLink:''
    });
  };

  private openMaterialModal = (): void => {
    this.setState({
      isMaterialModalVisible: !this.state.isMaterialModalVisible,
      material: this._materialEditDetails(),
      selectedResourceTypeId:ResourceTypes.materials.id
    });
  };

  private openToolsModal = (): void => {
    this.setState({
      isToolsModalVisible: !this.state.isToolsModalVisible,
      tool: this._toolsEditDetails(),
      selectedResourceTypeId:ResourceTypes.tools.id
    });
  };

  private openLabourModal = (): void => {
    this.setState({
      isLabourModalVisible: !this.state.isLabourModalVisible,
      labour: this._labourEditDetails(),
      selectedResourceTypeId:ResourceTypes.labour.id
    });
  };

  private openConsumaleModal = (): void => {
    this.setState({
      isConsumablesModalVisible: !this.state.isConsumablesModalVisible,
      consumable: this._consumableEditDetails(),
      selectedResourceTypeId:ResourceTypes.consumable.id
    });
  };

  private openQualityModal = (): void => {
    this.setState({
      isQualityModalVisible: !this.state.isQualityModalVisible,
      qualityData: this._qualityEditDetails(), //to load quality data into modal
    });
  };

  private onChangeResourceFamily = (treeData: any): void => {
    if (treeData) {
      this.setState({ resourceFamily: treeData });
    }
    this.setState({
      isDirty: true,
    });
  };

  private openCompetenceModal = (): void => {
    this.setState({
      isCompetenceModalVisible: !this.state.isCompetenceModalVisible,
      competence: this._competenceEditDetails(),
    });
  };

  private _riskDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedRisk = this._riskSelection.getSelection() as Risk[];
    savedDeleteList = selectedRisk.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: any }) => {
      return item.id;
    });
    return deleteList;
  }

  private _resourceDeleteDetails(selection: Selection) {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedRisk = selection.getSelection() as Resource[];
    savedDeleteList = selectedRisk.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: any) => {
      return item.id;
    });
    return deleteList;
  }

  private handleRiskDelete = () => {
    const selectedList = this._riskSelection.getSelection().map((item: any) => {
      return item.pbsRiskId;
    });
    if (selectedList.length > 0) {
      this.props.deleteRisk(selectedList);
    }
    this._riskSelection.setAllSelected(false);
  };

  private handleMaterialsDelete = () => {
    const selectedList = this._materialsSelection
      .getSelection()
      .map((item: any) => {
        return item.resourceId;
      });
    if (selectedList.length > 0) {
      this.props.deleteMaterials(selectedList);
    }
    this._materialsSelection.setAllSelected(false);
  };

  private handleTechLinkDelete = () => {
    let selectedList = [];
    selectedList = this._techLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.techInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.techInstruction;
      instruction.pbsInstructionLink = array
      this.setState({ techInstruction: instruction }, () => {
        if (this.state.techInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            techLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
            techLink:new InstructionLink()
          });
          let count  = this._techLinksSelection.setKeySelected('', false, false)


        }
      });
    }
    this._techLinksSelection.selectToKey('', true);
    //this._techLinksSelection.setAllSelected(false)

  };


  private handleSaftyLinkDelete = () => {
    let selectedList = [];
    selectedList = this._saftyLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.techInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.techInstruction;
      instruction.pbsInstructionLink = array
      this.setState({ techInstruction: instruction }, () => {
        if (this.state.techInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            safetyLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
          });
        }
      });
    }
    // this._saftyLinksSelection.selectToKey('', true);
    this._saftyLinksSelection.setAllSelected(false);
  };

  private handleHelthLinkDelete = () => {
    let selectedList = [];
    selectedList = this._helthLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.techInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.techInstruction;
      instruction.pbsInstructionLink = array
      this.setState({ techInstruction: instruction }, () => {
        if (this.state.techInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            helthLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
          });
        }
      });
    }
    // this._helthLinksSelection.selectToKey('', true);
    this._helthLinksSelection.setAllSelected(false);
  };

  private handleEnvLinkDelete = () => {
    let selectedList = [];
    selectedList = this._envLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.techInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.techInstruction;
      instruction.pbsInstructionLink = array
      this.setState({ techInstruction: instruction }, () => {
        if (this.state.techInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            envLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
          });
        }
      });
    }
    // this._envLinksSelection.selectToKey('', true);
    this._envLinksSelection.setAllSelected(false);
  };


  private handleToolsDelete = () => {
    const selectedList = this._toolsSelection
      .getSelection()
      .map((item: any) => {
        return item.resourceId;
      });
    if (selectedList.length > 0) {
      this.props.deleteTools(selectedList);
    }
    this._toolsSelection.setAllSelected(false);
  };

  private handleLaboursDelete = () => {
    const selectedList = this._labourSelection
      .getSelection()
      .map((item: any) => {
        return item.resourceId;
      });
    if (selectedList.length > 0) {
      this.props.deleteLabours(selectedList);
    }
    this._labourSelection.setAllSelected(false);
  };

  private handleConsumableDelete = () => {
    const selectedList = this._consumableSelection
      .getSelection()
      .map((item: any) => {
        return item.resourceId;
      });
    if (selectedList.length > 0) {
      this.props.deleteConsumable(selectedList);
    }
    this._consumableSelection.setAllSelected(false);
  };

  private handleTechInstructionDelete = () => {
    const selectedList = this._techInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteTechInstruction(selectedList);
    }
    this._techInstructionSelection.setAllSelected(false);
  };

  private handleSaftyInstructionDelete = () => {
    const selectedList = this._saftyInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteSaftyInstruction(selectedList);
    }
    this._saftyInstructionSelection.setAllSelected(false);
  };

  private handleEnvInstructionDelete = () => {
    const selectedList = this._envInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteEnvInstruction(selectedList);
    }
    this._envInstructionSelection.setAllSelected(false);
  };

  private handleHelthInstructionDelete = () => {
    const selectedList = this._helthInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteHelthInstruction(selectedList);
    }
    this._helthInstructionSelection.setAllSelected(false);
  };

  private handleQualityDelete = () => {
    let selectedList = this._qualitySelection
      .getSelection()
      .map((item: any) => {
        return item.uid;
      });

    if (selectedList.length > 0) {
      this.props.deleteQuality(selectedList);
    }
    this._qualitySelection.setAllSelected(false);
  };

  private _getRiskSelectionDetails(): string | number | {} | [] {
    return this._riskSelection.getSelection();
  }

  private _getMaterialSelectionDetails(): string | number | {} | [] {
    return this._materialsSelection.getSelection();
  }

  private _getToolsSelectionDetails(): string | number | {} | [] {
    return this._toolsSelection.getSelection();
  }

  private _getLabourSelectionDetails(): string | number | {} | [] {
    return this._labourSelection.getSelection();
  }

  private _getConsumableSelectionDetails(): string | number | {} | [] {
    return this._consumableSelection.getSelection();
  }

  private _getTechLinkSelectionDetails(): string | number | {} | [] {
    return this._techLinksSelection.getSelection();
  }

  private _getSaftyLinkSelectionDetails(): string | number | {} | [] {
    return this._saftyLinksSelection.getSelection();
  }

  private _getEnvLinkSelectionDetails(): string | number | {} | [] {
    return this._envLinksSelection.getSelection();
  }

  private _getHelthLinkSelectionDetails(): string | number | {} | [] {
    return this._helthLinksSelection.getSelection();
  }

  private _getTechInstructionSelectionDetails(): string | number | {} | [] {
    return this._techInstructionSelection.getSelection();
  }

  private _getSaftyInstructionSelectionDetails(): string | number | {} | [] {
    return this._saftyInstructionSelection.getSelection();
  }

  private _getEnvInstructionSelectionDetails(): string | number | {} | [] {
    return this._envInstructionSelection.getSelection();
  }

  private _getHelthInstructionSelectionDetails(): string | number | {} | [] {
    return this._helthInstructionSelection.getSelection();
  }


  private _riskEditDetails() {
    const selectionCount = this._riskSelection.getSelectedCount();
    let riskData = new ReadRisk();

    if (selectionCount == 1) {
      this.setState({
        isRiskEdit: true,
      });

      let selectedRisk = this._riskSelection.getSelection()[0] as ReadRisk;
      riskData = selectedRisk;
      //assign
    } else {
      this.setState({
        isRiskEdit: false,
      });
    }
    return riskData;
  }

  private _techLinkEditDetails() {
    const selectionCount = this._techLinksSelection.getSelectedCount();
    let techLink = new InstructionLink();
    if (selectionCount == 1 && !(this.state.techInstruction.pbsInstructionLink.length === 0)) {
      let index = this._techLinksSelection.getSelectedIndices();
      this.setState({
        isEditTechLinks: true,
        editTechLinkIndex: index[0],
      });

      let selectedLink = this._techLinksSelection.getSelection()[0] as InstructionLink;
      techLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditTechLinks: false,
        editTechLinkIndex: -1,
      });
    }
    return techLink;
  }

  private _saftyLinkEditDetails() {
    const selectionCount = this._saftyLinksSelection.getSelectedCount();
    let saftyLink = new InstructionLink();

    if (selectionCount == 1 && !(this.state.techInstruction.pbsInstructionLink.length === 0)) {
      let index = this._saftyLinksSelection.getSelectedIndices();
      this.setState({
        isEditSaftyLinks: true,
        editSaftyLinkIndex: index[0],
      });

      let selectedLink = this._saftyLinksSelection.getSelection()[0] as InstructionLink;
      saftyLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditSaftyLinks: false,
        editSaftyLinkIndex: -1,
      });
    }
    return saftyLink;
  }

  private _envLinkEditDetails() {
    const selectionCount = this._envLinksSelection.getSelectedCount();
    let envLink = new InstructionLink();

    if (selectionCount == 1 && !(this.state.techInstruction.pbsInstructionLink.length === 0)) {
      let index = this._envLinksSelection.getSelectedIndices();
      this.setState({
        isEditEnvLinks: true,
        editEnvLinkIndex: index[0],
      });

      let selectedLink = this._envLinksSelection.getSelection()[0] as InstructionLink;
      envLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditSaftyLinks: false,
        editSaftyLinkIndex: -1,
      });
    }
    return envLink;
  }

  private _helthLinkEditDetails() {
    const selectionCount = this._helthLinksSelection.getSelectedCount();
    let helthLink = new InstructionLink();

    if (selectionCount == 1 && !(this.state.techInstruction.pbsInstructionLink.length === 0)) {
      let index = this._helthLinksSelection.getSelectedIndices();
      this.setState({
        isEditHelthLinks: true,
        editHelthLinkIndex: index[0],
      });

      let selectedLink = this._helthLinksSelection.getSelection()[0] as InstructionLink;
      helthLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditHelthLinks: false,
        editHelthLinkIndex: -1,
      });
    }
    return helthLink;
  }

  private _techInstructionEditDetails() {
    const selectionCount = this._techInstructionSelection.getSelectedCount();
    let techInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditTechInstruction: true,
      });

      let selectedLink = this._techInstructionSelection.getSelection()[0] as Instruction;
      techInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditTechInstruction: false,
      });
    }
    return techInstruction;
  }


  private _saftyInstructionEditDetails() {
    const selectionCount = this._saftyInstructionSelection.getSelectedCount();
    let saftyInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditSaftyInstruction: true,
      });

      let selectedLink = this._saftyInstructionSelection.getSelection()[0] as Instruction;
      saftyInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditSaftyInstruction: false,
      });
    }
    return saftyInstruction;
  }

  private _envInstructionEditDetails() {
    const selectionCount = this._envInstructionSelection.getSelectedCount();
    let envInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditEnvInstruction: true,
      });

      let selectedLink = this._envInstructionSelection.getSelection()[0] as Instruction;
      envInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditEnvInstruction: false,
      });
    }
    return envInstruction;
  }

  private _helthInstructionEditDetails() {
    const selectionCount = this._helthInstructionSelection.getSelectedCount();
    let helthInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditHelthInstruction: true,
      });

      let selectedLink = this._helthInstructionSelection.getSelection()[0] as Instruction;
      helthInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditHelthInstruction: false,
      });
    }
    return helthInstruction;
  }

  private _materialEditDetails() {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let material = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isMaterialEdit: true,
      });

      let selectedMaterial = this._materialsSelection.getSelection()[0] as Resource;
      material = selectedMaterial;
      //assign
    } else {
      this.setState({
        isMaterialEdit: false,
      });
    }
    return material;
  }

  private _toolsEditDetails() {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let tool = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isToolEdit: true,
      });

      let selectedTool = this._toolsSelection.getSelection()[0] as Resource;
      tool = selectedTool;
      //assign
    } else {
      this.setState({
        isToolEdit: false,
      });
    }
    return tool;
  }

  private _labourEditDetails() {
    const selectionCount = this._labourSelection.getSelectedCount();
    let labour = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isLabourEdit: true,
      });

      let selectedLabour = this._labourSelection.getSelection()[0] as Resource;
      labour = selectedLabour;
      //assign
    } else {
      this.setState({
        isLabourEdit: false,
      });
    }
    return labour;
  }

  private _consumableEditDetails() {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let consumable = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isConsumableEdit: true,
      });

      let selectedConsumable = this._consumableSelection.getSelection()[0] as Resource;
      consumable = selectedConsumable;
      //assign
    } else {
      this.setState({
        isConsumableEdit: false,
      });
    }
    return consumable;
  }

  private _getRiskActionButtonState(): ActionButtonState {
    const selectionCount = this._riskSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private _getMaterialActionButtonState(): ActionButtonState {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private _getToolsActionButtonState(): ActionButtonState {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getLabourActionButtonState(): ActionButtonState {
    const selectionCount = this._labourSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getConsumableActionButtonState(): ActionButtonState {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getTechLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._techLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getSaftyLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._saftyLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getEnvLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._envLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getHelthLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._helthLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }


  private _getTechInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._techInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getSaftyInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._saftyInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getEnvInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._envInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getHelthInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._helthInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    riskList: state.projectBreakdown.riskList,
    readRiskStatus: state.projectBreakdown.readRiskStatus,
    createRiskStatus: state.projectBreakdown.createRiskStatus,
    deleteRiskStatus: state.projectBreakdown.deleteRiskStatus,
    qualityList: state.projectBreakdown.qualityList,
    readQualityStatus: state.projectBreakdown.readQualityStatus,
    createInstructionStatus: state.projectBreakdown.createInstructionStatus,
    createQualityStatus: state.projectBreakdown.createQualityStatus,
    deleteQualityStatus: state.projectBreakdown.deleteQualityStatus,
    toleranceStates: state.projectBreakdown.toleranceStates,
    productStates: state.projectBreakdown.productStates,
    itemTypes: state.projectBreakdown.itemTypes,
    isNew: state.projectBreakdown.isNew,
    uid: state.projectBreakdown.uid,
    materialList: state.projectBreakdown.materials,
    readMaterialsStatus: state.projectBreakdown.readMaterialsStatus,
    isEdit: state.projectBreakdown.isEdit,
    createMaterialsStatus: state.projectBreakdown.createMaterialsStatus,
    saveRiskStatus: state.projectBreakdown.saveRiskStatus,
    newRiskId: state.projectBreakdown.newRiskId,
    saveQualityStatus: state.projectBreakdown.saveQualityStatus,
    newQualityId: state.projectBreakdown.newQualityId,
    readToolsStatus: state.projectBreakdown.readToolsStatus,
    createToolsStatus: state.projectBreakdown.createToolsStatus,
    tools: state.projectBreakdown.tools,
    deleteMaterialsStatus: state.projectBreakdown.deleteMaterialsStatus,
    deleteToolsStatus: state.projectBreakdown.deleteToolsStatus,
    labours: state.projectBreakdown.labours,
    readLabourStatus: state.projectBreakdown.readLabourStatus,
    createLabourStatus: state.projectBreakdown.createLabourStatus,
    deleteLabourStatus: state.projectBreakdown.deleteLabourStatus,
    readConsumableStatus: state.projectBreakdown.readConsumableStatus,
    crateConsumableStatus: state.projectBreakdown.crateConsumableStatus,
    deleteConsumableStatus: state.projectBreakdown.deleteConsumableStatus,
    consumables: state.projectBreakdown.consumables,
    createConsumableStatus: state.projectBreakdown.createConsumableStatus,
    readTechInstructionStatus: state.projectBreakdown.readTechInstructionStatus,
    techInstructions: state.projectBreakdown.techInstructions,
    uploadedInstructionLink: state.projectBreakdown.uploadedInstructionLink,
    instructionUploadStatus: state.projectBreakdown.instructionUploadStatus,
    locationTaxonomy: state.projectBreakdown.locationTaxonomy,
    utilityTaxonomy: state.projectBreakdown.utilityTaxonomy,
    productTaxonomy: state.projectBreakdown.productTaxonomy,
    machineTaxonomy: state.projectBreakdown.machineTaxonomy,
    utilityTaxonomyLevel: state.projectBreakdown.utilityTaxonomyLevel,
    createNewNode: state.projectBreakdown.createNewNode,
    locationTaxonomyLevel: state.projectBreakdown.locationTaxonomyLevel,
    machineTaxonomyLevel: state.projectBreakdown.machineTaxonomyLevel,
    competenceList: state.projectBreakdown.competenceList,
    pbsSkills: state.projectBreakdown.pbsSkills,
    pbsExperience: state.projectBreakdown.pbsExperience,
    saveCompetenceStatus: state.projectBreakdown.saveCompetenceStatus,
    deleteCompetenceStatus: state.projectBreakdown.deleteCompetenceStatus,
    createTechInstructionStatus: state.projectBreakdown.createTechInstructionStatus,
    deleteTechInstructionStatus: state.projectBreakdown.deleteTechInstructionStatus,
    readSaftyInstructionStatus:state.projectBreakdown.readSaftyInstructionStatus,
    createSaftyInstructionStatus:state.projectBreakdown.createSaftyInstructionStatus,
    saftyInstructions:state.projectBreakdown.saftyInstructions,
    deleteSaftyInstructionStatus:state.projectBreakdown.deleteSaftyInstructionStatus,
    readEnvInstructionStatus:state.projectBreakdown.readEnvInstructionStatus,
    createEnvInstructionStatus:state.projectBreakdown.createEnvInstructionStatus,
    envInstructions:state.projectBreakdown.envInstructions,
    deleteEnvInstructionStatus:state.projectBreakdown.deleteEnvInstructionStatus,
    readHelthInstructionStatus:state.projectBreakdown.readHelthInstructionStatus,
    createHelthInstructionStatus:state.projectBreakdown.createHelthInstructionStatus,
    helthInstructions:state.projectBreakdown.helthInstructions,
    deleteHelthInstructionStatus:state.projectBreakdown.deleteHelthInstructionStatus,
    service:state.projectBreakdown.pbsService,
    createNewMSNode: state.ms.createNewMSNode,
    pbsCreated: state.projectBreakdown.pbsCreated,
    selectedInstruction: state.ir.formData,
    riskData: state.projectBreakdown.riskData, //to load risk data into modal
    qualityData: state.projectBreakdown.qualityData //to load quality data into modal
  };
};

const mapDispatchToProps = {
  getRiskDropDownsList,
  createRisk,
  filterRiskList,
  deleteRisk,
  filterQualityList,
  createQuality,
  deleteQuality,
  saveFormData,
  readMaterialsData,
  createMaterials,
  savePbsRisk,
  savePbsInstruction,
  resetRiskGridList,
  savePbsQuality,
  readToolsData,
  createTools,
  deleteMaterials,
  deleteTools,
  readLabourData,
  createLabour,
  deleteLabours,
  createConsumable,
  deleteConsumable,
  readConsumableData,
  readInstructionDropDown,
  readTechInstruction,
  uploadInstructionDoc,
  readTaxonomyData,
  readTaxonomyLevel,
  createTechInstruction,
  filterCompetenceList,
  readCompetenceDropDown,
  createCompetence,
  deleteCompetence,
  deleteTechInstruction,
  createSaftyInstruction,
  readSaftyInstruction,
  deleteSaftyInstruction,
  createEnvInstruction,
  readEnvInstruction,
  deleteEnvInstruction,
  createHelthInstruction,
  readHelthInstruction,
  deleteHelthInstruction,
  createTaxonomyNode,
  readPMOLAllInstruction,
  createPBSService,
  readPBSService,
  clearFormData,
  clearInstructionData,
  createMSTaxonomyNode,
  getById,
  clearCpcData,

  savePbsStateAttr,
  readByInstructionRegisterId,
  readRiskById, //to load risk data into modal
  saveVPStateAttr
};

export enum INSTRUCTION_TYPE {
  TECH = '100',
  ENVIRONMENT = '200',
  SAFTY = '300',
  HELTH = '400'
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane))
);
