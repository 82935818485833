import { getTheme, Icon, Image, Label, Link } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import DataGrid from '../../../shared/dataGrid/component';
import CustomFileUploader from '../../../shared/fileUploader/customFileUploader';
import {
  ExtraWork as ExtraWorkTypes,
  Journal,
  PMOL_TYPE,
  PMOLService,
  ProjectMolecule,
  ProjectMoleculeValidationMessage,
  Resource,
} from '../../../types/projectMolecule';
import HistoryBar from '../../../shared/historyBar/historyBar';
import AddressLocationComponent from '../../../shared/location/address/component';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import { BorResources } from '../../../types/billOfResources';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import {
  Competence,
  Instruction,
  PbsInstructionLink,
  Quality,
  ReadRisk,
} from '../../../types/projectBreakdownStructure';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import ExtraWork from '../extraWork/extraWork';
import SignatureDataGrid from '../handshake/component';
import { Signature } from '../handshake/modal/component';
import InstructionDataGrid from '../instructions/dataGrid/component';
import ProjectMoleculerJournal from '../journal/projectMoleculerJournal';
import ProjectMoleculerHeader from '../project/projectMoleculerHeader';
import QualityGrid from '../quality/component';
import RiskGrid from '../risk/component';
import {connect, useDispatch} from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import ServiceUI from '../service/componant';
import CustomAlert from '../../../shared/alert/customAlert';
import {savePMOLStateAttr} from "../../../reducers/pmolReducer";



// Tokens definition

const theme = getTheme();
const NOT_APPROVE_MESSAGE_NO_END_TIME = "oneOrMoreTeamMembersHaveNoEndTime"
const NOT_APPROVE_MESSAGE_QUANTITY_MISMATCH = "consumedQuantityIsLessThanThePlannedQuantity"

export const DocumentPaneComponent = (props: {
  loadApproveMsg: string;
  projectHeader: any;
  dropDownOptions: any;
  isEdit: boolean;
  formData: ProjectMolecule;
  extraWork: ExtraWorkTypes;
  saveFormData: () => void;
  PMOLSaveJournal: () => void;
  handleFormChange: (projectMolecule: ProjectMolecule, save: boolean) => void;
  validationMessages: ProjectMoleculeValidationMessage;
  borResources: BorResources;

  handleRiskDelete: () => void;
  qualitySelection: any;
  //qualitySelectionDetails: {};
  qualityActionButtonState: ActionButtonState;
  selectedResourceFamily: string;
  onChangeResourceFamily: (treeData: any) => void;
  openRiskModal: () => void;
  riskSelection: any;
  riskActionButtonState: ActionButtonState;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  resourceTypes: [];
  resourceFamily: any;
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [];
  cpcMaterial: [];
  cpcBrand: [];
  languageCodes: [];
  cpcUnitOfSizeMeasure: [];

  resetSelection: () => void;
  submitCpc: boolean;
  riskList: ReadRisk[];
  openQualityModal: () => void;
  qualityList: Quality[];
  handleQualityDelete: () => void;
  materials: Resource[];
  openMaterialModal: () => void;
  materialActionButtonState: ActionButtonState;
  materialSelection: any;
  tools: Resource[];
  toolsActionButtonState: ActionButtonState;
  openToolsModal: () => void;
  toolSelection: any;
  handleMaterialDelete: () => void;
  handleToolsDelete: () => void;
  materialLoading: boolean;
  toolsLoading: boolean;
  labours: Resource[];
  labourActionButtonState: ActionButtonState;
  openLabourModal: () => void;
  labourSelection: any;
  labourLoading: boolean;
  handleLabourDelete: () => void;
  openConsumableModal: () => void;
  consumableSelection: any;
  consumableActionButtonState: ActionButtonState;
  consumables: Resource[];
  consumalbeLoading: boolean;
  handleConsumableDelete: () => void;
  openTechInstuctionModal: () => void;
  onFilterPersonChanged: any;
  techInstructionsLoading: boolean;
  techInstructions: Instruction[];
  techInstructionSelection: any;
  techInstructionActionButtonState: ActionButtonState;
  onQualityFilterPersonChanged: any;
  competenceList: Competence[];
  competenceSelection: any;
  competenceActionButtonState: ActionButtonState;
  openCompetenceModal: () => void;
  handleCompetenceDelete: () => void;
  handleDTechInstructionDelete: () => void;
  openSaftyInstuctionModal: () => void;
  saftyInstructionsLoading: boolean,
  saftyInstructions: Instruction[];
  saftyInstructionSelection: any,
  saftyInstructionActionButtonState: ActionButtonState,
  handleSaftyInstructionDelete: () => void;
  //
  openEnvInstuctionModal: () => void;
  envInstructionsLoading: boolean,
  envInstructions: Instruction[];
  envInstructionSelection: any,
  envInstructionActionButtonState: ActionButtonState,
  handleEnvInstructionDelete: () => void;
  //
  openHelthInstuctionModal: () => void;
  helthInstructionsLoading: boolean,
  helthInstructions: Instruction[];
  helthInstructionSelection: any;
  helthInstructionActionButtonState: ActionButtonState;
  handleHelthInstructionDelete: () => void;
  setJournal: (feild: any, value: any) => void;
  setJournalImage: (type: string, response: any) => void;
  position: Position;
  setLocationAddress: (position: Position, address: LocationAddress) => void;
  openMapModal: () => void;
  address: string;
  readOnly: boolean;
  openSignatureModal: () => void;
  signatures: Signature[];
  handshakeDocs: string[];
  readHandShakeStatus: boolean;
  saveHandshakeDocuments: (data: {
    pmolId: string,
    docLinks: string[]
  }) => void;
  journal: Journal;
  readExtraWork: boolean;
  borStatusDropdowns: { key: string, text: string }[];
  isLoading: boolean;
  isDisabled: boolean;
  onVisibitiltyChangeStartHandShake: (isVisible: boolean) => void;
  onVisibilityChangePlannedWorks: (isVisible: boolean) => void;
  isRisksLoading: boolean;
  pmolType: PMOL_TYPE;
  onChangeService: (prop: string, value: string) => void;
  service: PMOLService
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCustomAlertDisplayed, setIsCustomAlertDisplayed] = useState(false);

  useEffect(() => {
    props.resetSelection();
  }, [props.formData.id]);

  useEffect(() => {
    if (props.submitCpc) {
      props.saveFormData();
    }
  }, [props.submitCpc]);

  useEffect(() => {
    if (props.loadApproveMsg === NOT_APPROVE_MESSAGE_NO_END_TIME) {
      setIsCustomAlertDisplayed(true);
    }
    if (props.loadApproveMsg === NOT_APPROVE_MESSAGE_QUANTITY_MISMATCH) {
      setIsCustomAlertDisplayed(true);
    }
  }, [props.loadApproveMsg])

  const riskColumns = [
    {
      key: 'column1',
      name: t('ID'),
      fieldName: 'sequenceCode',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('owner'),
      fieldName: 'owner',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('status'),
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const plannedWorkColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('plannedQuantity'),
      fieldName: 'required',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('consumedquantity'),
      fieldName: 'consumedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('unit'),
      fieldName: 'unit',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const handShakeColumns = [
    {
      key: 'column1',
      name: t('name'),
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('signature'),
      fieldName: 'link',
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
      onRender: (item: Signature) => {
        return (
          <div>
            <div key={item.name}>
              <Image
                src={item.link ? item.link : ''}
                alt=""
                width={100}
                height={30}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const InstructionColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('family'),
      fieldName: 'pbsInstructionFamilyName',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('links'),
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Instruction) => {
        let array: PbsInstructionLink[] = item && item.pbsInstructionLink && Array.isArray(item.pbsInstructionLink) ? item.pbsInstructionLink : [];
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridGap: '2px',
              gridAutoRows: 'minMax(10px, auto)',
            }}
          >
            {array.map((link) => (

              <div key={link.id}>
                <Icon
                  style={{ cursor: 'pointer', color: uPrinceTheme.palette.themePrimary }}
                  iconName={link.type!!}
                  key={link.id}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(link.link!!);
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const qualtyColumns = [
    {
      key: 'column1',
      name: t('qualityCriteria'),
      fieldName: 'criteria',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.criteria ? item.criteria!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
    {
      key: 'column2',
      name: t('qualityTolerance'),
      fieldName: 'tolerance',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.tolerance ? item.tolerance!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
    {
      key: 'column3',
      name: t('qualityMethod'),
      fieldName: 'method',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.method ? item.method!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
    {
      key: 'column4',
      name: t('qualitySkillRequired'),
      fieldName: 'skills',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.skills ? item.skills!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
  ];


  return (
    <div style={{ width: '100%' }} id={'ProjectBreakdownForm'}>

      {/*APPROVE PMOL ERROR MESSAGE*/}

      {/*<div style={{ position: "sticky", top: 0, zIndex: 100 }}>*/}
      {/*  <CustomAlert*/}
      {/*    showMsg={isCustomAlertDisplayed}*/}
      {/*    msg={t(props?.loadApproveMsg)}*/}
      {/*    msgType={'error'}*/}
      {/*    handleCustomAlert={() => {*/}
      {/*      setIsCustomAlertDisplayed(false)*/}
      {/*      dispatch(savePMOLStateAttr('loadApproveMsg', ''));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="proj-detail-block">
        <ProjectHeader projectDetails={props.projectHeader}/>
      </div>

      <div className="proj-detail-block" id="1">
        <ProjectMoleculerHeader
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(projectMolecule: ProjectMolecule, save: boolean) =>
            props.handleFormChange(projectMolecule, save)
          }
          validationMessages={props.validationMessages}
          borStatusDropdowns={props.borStatusDropdowns}
          isLoading={props.isLoading}
          isDisabled={props.isDisabled}
          pmolType={props.pmolType}
        />
      </div>


      <div className="proj-detail-block" id="2">
        <div className="document-pane-card">
          <div className={'card-header'}>
            <Link href="#" id="project-moleculer-header">
              <Label>2. {t('startHandshake')} </Label>
            </Link>
          </div>

          <VisibilitySensor scrollCheck
                            onChange={props.onVisibitiltyChangeStartHandShake}>

            <div className={'card-header'}>
              <div className="proj-detail-block" id="3">
                <Link href="#" id="start-handshake-id-2-1">
                  <Label>2.1 {t('pMOLAddress')} </Label>
                </Link>
              </div>
            </div>
          </VisibilitySensor>


          <div style={{ zIndex: 10000000 }}>
            <AddressLocationComponent
              setLocationAddress={(address) => {
                props.setLocationAddress(address.position, address);
              }}
              position={props.position}
              openMapModal={() => props.openMapModal()}
              address={props.address}
              disabled={props.readOnly}
              lable={t('pMOLCoordinates')}
              addressLable={t('pMOLAddress')}
            />
          </div>

          <div className={'card-header'}>
            <div className="proj-detail-block" id="4">
              <Link href="#" id="start-handshake-id-2-2">
                <Label>2.2 {t('instructions')} </Label>
              </Link>
            </div>
          </div>


          <div className={'card-body'}>
            <div className="">
              <VisibilitySensor scrollCheck
                                onChange={props.onVisibitiltyChangeStartHandShake}>
                <InstructionDataGrid
                  dataList={props.techInstructions}
                  editDataGrid={() => props.openTechInstuctionModal()}
                  openModel={() => {
                    props.openTechInstuctionModal();
                  }}
                  selection={props.techInstructionSelection}
                  actionButtonState={props.techInstructionActionButtonState}
                  title={'2.2.1 ' + t('technicalInstructions')}
                  deleteDataGrid={() => {
                    props.handleDTechInstructionDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={InstructionColumns}
                  isLoaded={props.techInstructionsLoading}
                />
              </VisibilitySensor>

              <VisibilitySensor scrollCheck
                                onChange={props.onVisibitiltyChangeStartHandShake}>
                <InstructionDataGrid
                  dataList={props.saftyInstructions}
                  editDataGrid={() => props.openSaftyInstuctionModal()}
                  openModel={() => {
                    props.openSaftyInstuctionModal();
                  }}
                  selection={props.saftyInstructionSelection}
                  actionButtonState={props.saftyInstructionActionButtonState}
                  title={'2.2.2 ' + t('saftyInstructions')}
                  deleteDataGrid={() => {
                    props.handleSaftyInstructionDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={InstructionColumns}
                  isLoaded={props.saftyInstructionsLoading}
                />
              </VisibilitySensor>

              <VisibilitySensor scrollCheck
                                onChange={props.onVisibitiltyChangeStartHandShake}>
                <InstructionDataGrid
                  dataList={props.envInstructions}
                  editDataGrid={() => props.openEnvInstuctionModal()}
                  openModel={() => {
                    props.openEnvInstuctionModal();
                  }}
                  selection={props.envInstructionSelection}
                  actionButtonState={props.envInstructionActionButtonState}
                  title={'2.2.3 ' + t('environmentalInstructions')}
                  deleteDataGrid={() => {
                    props.handleEnvInstructionDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={InstructionColumns}
                  isLoaded={props.envInstructionsLoading}
                />
              </VisibilitySensor>

              <VisibilitySensor scrollCheck
                                onChange={props.onVisibitiltyChangeStartHandShake}>
                <InstructionDataGrid
                  dataList={props.helthInstructions}
                  editDataGrid={() => props.openHelthInstuctionModal()}
                  openModel={() => {
                    props.openHelthInstuctionModal();
                  }}
                  selection={props.helthInstructionSelection}
                  actionButtonState={props.helthInstructionActionButtonState}
                  title={'2.2.4 ' + t('healthInstructions')}
                  deleteDataGrid={() => {
                    props.handleHelthInstructionDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={InstructionColumns}
                  isLoaded={props.helthInstructionsLoading}
                />
              </VisibilitySensor>
            </div>
            <div className="proj-detail-block" id="5">
              <div id="start-handshake-id-2-3">
                <VisibilitySensor scrollCheck
                                  onChange={props.onVisibitiltyChangeStartHandShake}>
                  <RiskGrid
                    dataList={props.riskList ? props.riskList : []}
                    editDataGrid={() => props.openRiskModal()}
                    openModel={() => {
                      props.openRiskModal();
                    }}
                    selection={props.riskSelection}
                    actionButtonState={props.riskActionButtonState}
                    title={t('risk')}
                    deleteDataGrid={() => {
                      props.handleRiskDelete();
                    }}
                    readOnly={props.readOnly}
                    columns={riskColumns}
                    isLoaded={props.isRisksLoading}
                  />
                </VisibilitySensor>
              </div>
            </div>
            <div className="proj-detail-block" id="6">
              <div id="start-handshake-id-2-4">
                <VisibilitySensor scrollCheck
                                  onChange={props.onVisibitiltyChangeStartHandShake}>
                  <QualityGrid
                    dataList={props.qualityList}
                    editDataGrid={() => props.openQualityModal()}
                    openModel={() => {
                      props.openQualityModal();
                    }}
                    selection={props.qualitySelection}
                    actionButtonState={props.qualityActionButtonState}
                    title={t('quality')}
                    deleteDataGrid={() => {
                      props.handleQualityDelete();
                    }}
                    readOnly={props.readOnly}
                    columns={qualtyColumns}
                  />
                </VisibilitySensor>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="proj-detail-block" id="7">
        {/* <VisibilitySensor onChange={props.onVisibilityChangePlannedWorks}> */}
        {props.pmolType === PMOL_TYPE.REGULAR &&
        <div>
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="planned-work">
                <Label>3. {t('plannedwork')} </Label>
              </Link>
            </div>
            <div className={'card-body'}>
              <div className="">
                <div className="proj-detail-block" id="8">
                  <div id={'planned-work-id-3-1'}>
                    <VisibilitySensor scrollCheck
                                      onChange={props.onVisibilityChangePlannedWorks}>
                      <DataGrid
                        dataList={
                          props.borResources.materials
                            ? props.borResources.materials
                            : []
                        }
                        editDataGrid={() => props.openMaterialModal()}
                        openModel={() => {
                          props.openMaterialModal();
                        }}
                        selection={props.materialSelection}
                        actionButtonState={props.materialActionButtonState}
                        title={'3.1 ' + t('material')}
                        deleteDataGrid={() => {
                          props.handleMaterialDelete();
                        }}
                        readOnly={props.readOnly}
                        columns={plannedWorkColumns}
                        isLoaded={props.materialLoading}
                      />
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="proj-detail-block" id="9">
                  <div id={'planned-work-id-3-2'}>
                    <VisibilitySensor scrollCheck
                                      onChange={props.onVisibilityChangePlannedWorks}>
                      <DataGrid
                        dataList={
                          props.borResources.tools ? props.borResources.tools : []
                        }
                        editDataGrid={() => props.openToolsModal()}
                        openModel={() => {
                          props.openToolsModal();
                        }}
                        selection={props.toolSelection}
                        actionButtonState={props.toolsActionButtonState}
                        title={'3.2 ' + t('tools')}
                        deleteDataGrid={() => {
                          props.handleToolsDelete();
                        }}
                        readOnly={props.readOnly}
                        columns={plannedWorkColumns}
                        isLoaded={props.toolsLoading}
                      />
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="proj-detail-block" id="10">
                  <div id={'planned-work-id-3-3'}>
                    <VisibilitySensor scrollCheck
                                      onChange={props.onVisibilityChangePlannedWorks}>
                      <DataGrid
                        dataList={
                          props.borResources.consumable
                            ? props.borResources.consumable
                            : []
                        }
                        editDataGrid={() => props.openConsumableModal()}
                        openModel={() => {
                          props.openConsumableModal();
                        }}
                        selection={props.consumableSelection}
                        actionButtonState={props.consumableActionButtonState}
                        title={'3.3 ' + t('consumables')}
                        deleteDataGrid={() => {
                          props.handleConsumableDelete();
                        }}
                        readOnly={props.readOnly}
                        columns={plannedWorkColumns}
                        isLoaded={props.consumalbeLoading}
                      />
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="proj-detail-block" id="11">
                  <div id={'planned-work-id-3-4'}>
                    <VisibilitySensor scrollCheck
                                      onChange={props.onVisibilityChangePlannedWorks}>
                      <DataGrid
                        dataList={
                          props.borResources.labour ? props.borResources.labour : []
                        }
                        editDataGrid={() => props.openLabourModal()}
                        openModel={() => {
                          props.openLabourModal();
                        }}
                        selection={props.labourSelection}
                        actionButtonState={props.labourActionButtonState}
                        title={'3.4 ' + t('labour')}
                        deleteDataGrid={() => {
                          props.handleLabourDelete();
                        }}
                        readOnly={props.readOnly}
                        columns={plannedWorkColumns}
                        isLoaded={props.labourLoading}
                      />
                    </VisibilitySensor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {/* </VisibilitySensor> */}
      </div>

      {props.pmolType === PMOL_TYPE.REGULAR &&
      <div className="proj-detail-block" id="12">

        <ExtraWork
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          extraWork={props.extraWork}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(projectMolecule: ProjectMolecule, save: boolean) =>
            props.handleFormChange(projectMolecule, save)
          }
          validationMessages={props.validationMessages}
          readOnly={props.readOnly}
          readExtraWork={props.readExtraWork}
        />

      </div>
      }


      {props.pmolType === PMOL_TYPE.SUB &&
      <div className="proj-detail-block" id="30">
        <ServiceUI
          service={props.service}
          pmol={props.formData}
          onChangeService={(prop: string, val: any) => props.onChangeService(prop, val)}
          saveFormData={() => props.saveFormData()}
          productTitle={props.formData.productTitle ? props.formData.productTitle : ''}
          projectTitle={props.formData?.projectDefinition?.title ? props.formData?.projectDefinition?.title : ''}
          cpcBasicUnitOfMeasure={props.cpcBasicUnitOfMeasure}
          validationMessages={props.validationMessages}
        />
      </div>
      }


      <div className="proj-detail-block" id="18">
        <div className="document-pane-card">
          <div className={'card-header'}>
            <div className="proj-detail-block" id="19">
              <Link href="#" id="journal-5-1">
                <Label>5. {t('projectMoleculerJournal')} </Label>
              </Link>
            </div>
          </div>
          <div className={'card-body'}>
            <VisibilitySensor scrollCheck
                              onChange={props.onVisibilityChangePlannedWorks}>
              <ProjectMoleculerJournal
                PMOLSaveJournal={() => props.PMOLSaveJournal()}
                setJournal={(
                  feild: any,
                  value: any,
                ) => props.setJournal(feild, value)}
                setJournalImage={(
                  type: string,
                  response: any,
                ) => props.setJournalImage(type, response)}
                journal={props.journal}
                readOnly={props.readOnly}
              />
            </VisibilitySensor>
          </div>
        </div>
      </div>

      <div className="proj-detail-block" id="20">
        <div className="document-pane-card">
          <div className={'card-header'}>
            <div className="proj-detail-block" id="21">
              <Link href="#" id="stop-handshake-6-1">
                <Label>6. {t('stopHandshake')} </Label>
              </Link>
            </div>
          </div>
          <div className={'card-body'}>
            <div className="">
              <VisibilitySensor scrollCheck
                                onChange={props.onVisibilityChangePlannedWorks}>
                <SignatureDataGrid
                  dataList={props.signatures}
                  editDataGrid={() => props.openTechInstuctionModal()}
                  openModel={() => {
                    props.openSignatureModal();
                  }}
                  selection={null}
                  actionButtonState={{ add: false, edit: false, remove: false }}
                  title={t('signatures')}
                  deleteDataGrid={() => {
                    props.handleDTechInstructionDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={handShakeColumns}
                  isLoaded={!props.readHandShakeStatus}
                />
              </VisibilitySensor>
            </div>

            <div style={{ marginTop: 20, marginLeft: 10 }}>
              <CustomFileUploader
                uploadedImages={props.handshakeDocs}
                label={t('documents')}
                uploadUrl={'Pmol/UploadStopHandShakeFile'}
                onSaveSuccess={(response: any) => {
                  if (props.formData.id) {
                    props.saveHandshakeDocuments({ pmolId: props.formData.id!!, docLinks: [response] });
                  }
                }
                }
                disable={props.readOnly}
              />
            </div>
          </div>
        </div>
      </div>

      {props.isEdit &&
      props.formData.historyLog &&
      props.formData.historyLog.createdByUser && (
        <div className="proj-detail-block" id="22">
          <div className="document-pane-card">
            <div className="marginTop marginBottom">
              <Link href="#" id="history">
                <Label>7. {t('history')} </Label>
              </Link>

              <HistoryBar
                createdByUser={
                  props.formData.historyLog.createdByUser
                    ? props.formData.historyLog.createdByUser
                    : ''
                }
                updatedByUser={
                  props.formData.historyLog.updatedByUser
                    ? props.formData.historyLog.updatedByUser
                    : ''
                }
                createdDateTime={
                  props.formData.historyLog.createdDateTime
                    ? props.formData.historyLog.createdDateTime
                    : ''
                }
                updatedDateTime={
                  props.formData.historyLog.updatedDateTime
                    ? props.formData.historyLog.updatedDateTime
                    : ''
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPaneComponent);
