export class WbsCreateListPaneFilter {
    title: string | null = null;
    sorter: Sorter = new Sorter();
}

class Sorter {
    attribute: string | null = null;
    order: string | null = null;
}

export interface NodeData {
    id: string | null;
    name?: string | null;
    sequenceId?: string | null;
    title?: string | null;
    wbsTaxonomyLevelId: string | null;
    parentId: string | null;
    createdDateTime?: Date | undefined;
    updatedDateTime?: Date | undefined;
    createdBy?: string | null;
    updatedBy?: string | null;
    isDefault: boolean;
    templateId: string | undefined;
    children?: NodeData[];
}

export enum WBS_TAXONOMY_LEVEL {
    WBS = "41ce52c0-058b-wbs-afbd-1d2d24105ebc",
    PRODUCT = "e1ce52c0-058b-prod-afbd-1d2d24105ebc",
    TASK = "i1ce52c0-058b-issu-afbd-1d2d24105ebc",
    ISSUE = "pouoe52c0-hvkhl-lbjm-jvhj-1d2d241ouyi",
}


export const WBS_TASK_STATUS = {
    PENDING_DEVELOPMENT: '0e1b34bc-f2c3-4778-8250-9666ee96ae59',
    IN_DEVELOPMENT: '3960193f-99e0-43c6-a6cc-4919e5d345c5',
    IN_REVIEW: '40843898-54EE-473D-A661-194F1DA0CE48',
    APPROVED: '5684969c-f3e8-49ac-9746-51e7e23f2782',
    HAND_OVER: 'vvvv969c-f3e8-49ac-9746-51e7e23f2782',
};
export const WBS_TASK_DELIVERY_STATUS = {
    OVERDUE: 'i1ce52c0-058b-issu-afbd-1d2d24105ebc',
    WITHIN_7_DAY: '2210e768-msms-po02-Lot5-ee367a82ad22',
    MORE_THEN_7_DAY: '12a22319-8ca7-temp-b588-6fab99474130',
    BY_TODAY: 'jdjj52c0-058b-issu-afbd-1d2d24105ebc',
};

export const WBS_TASK_GROUP_BY={
    NO_GROUPING:null,
    DUE_DATE:'Date',
    PROJECT:'project',
    TAG:'Tags'
}
export const WBS_TASK_SORT_BY={
    MANUAL:'displayOrder',
    CREATION_DATE:'creationDate',
    COMPLETION_DATE:'completionDate',
    DUE_DATE:'date',
    STARRED:'isFavourite',
}

export const WBS_TASK_SHOWN_BY={
    OPEN_TASK:'openTask',
    COMPLETED_TASK:'completedTask',
}

export const WBS_TASK_PEOPLE={
    MY_TASKS:'myTasks',
    OTHER_TASKS:'otherTask',
}

export const getDeliveryStatusColor=(status:string)=>{
    switch (status) {
        case WBS_TASK_DELIVERY_STATUS.OVERDUE:
            return taskPieChartColor.overdueColor;
        case WBS_TASK_DELIVERY_STATUS.WITHIN_7_DAY:
            return  taskPieChartColor.within7DayColor
        case WBS_TASK_DELIVERY_STATUS.MORE_THEN_7_DAY:
            return  taskPieChartColor.moreThen7DayColor
        case WBS_TASK_DELIVERY_STATUS.BY_TODAY:
            return  taskPieChartColor.byToday
        default:
            return ""
    }

}

export const taskPieChartColor={
    fillColor:"#B25329",
    overdueColor:"#DA3B01",
    within7DayColor:"#EAA300",
    moreThen7DayColor:"#13A10E",
    byToday:"#EAA300"
}


export const instructionTypeIds={
    technical:'100',
    environment:'200',
    safety:'300',
    health:'400',
}

export class WbsTaskInstructionList {
    taskId: string | null = null;
    productId: string | null = null;
}


export const WBS_ADD_ICON_MENU={
    WBS:'wbs',
    PRODUCT:'product',
    TASK:'task',
    ISSUE:'issue',
}
