import * as React from 'react';
import { useEffect, useState } from 'react';
import TaxonomyGrid from '../../../../shared/taxonomyField/taxonomyGrid';
import TaxonomyField from '../../../../shared/taxonomyField/taxonomyField';
import { Checkbox } from 'office-ui-fabric-react';
import { getWbsTaxonomy } from '../../../../reducers/wbsTaskReducer';
import { useDispatch, useSelector } from 'react-redux';
import { WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';
import { getPerentFromTreeData, getProject } from '../../../../shared/util';
import _ from 'lodash';

const WbsTaxonomy = ({ value, onChange, disabled, formData }: any) => {
  const dispatch = useDispatch();
  const [selectedParentIds, setSelectedParentIds]: any = useState([]);
  const [selectItemIds, setSelectItemId]: any[] = useState([value]);
  const [treeData, setTreeData] = useState();

  const wbsTaxonomy = useSelector((state: any) => state.wbsTask.wbsTaxonomy);
  const wbsTemplateTaxonomyData = useSelector((state: any) => state.wbsCreate.wbsTemplateTaxonomyData);

  const getTaxonomy = async () => {
    dispatch(getWbsTaxonomy());
  };

  useEffect(() => {
    if (getProject()) {
      getTaxonomy().then(() => {
        setTreeData(wbsTaxonomy);
      });
    } else {
      const setTaxonomyOnCuLevel = async () => {
        setTreeData(wbsTemplateTaxonomyData);
      };

      setTaxonomyOnCuLevel().then();
    }
  }, []);

  useEffect(() => {
    setSelectItemId([value]);
    if (value) {
      setSelectedParentIds(
        getPerentFromTreeData(treeData, value),
      );
    } else {
      setSelectedParentIds([]);
    }
  }, [value]);

  useEffect(() => {
    wbsTemplateTaxonomyData && !_.isEmpty(wbsTemplateTaxonomyData) && setTreeData(wbsTemplateTaxonomyData);
  }, [wbsTemplateTaxonomyData]);

  const handleNodeCheckboxClick = (rowInfo: any) => {
    console.log({ rowInfo });
    if (rowInfo?.node?.id) {
      //pbsId:'1c6baa27-eddf-4608-83da-e40d653bf7ea',productId:'PBS-3631'
      onChange({ ...rowInfo?.node });
    }
  };

  const renderButtons = (rowInfo: any) => {
    // const isParent =false;
    const isParent = selectedParentIds.includes(rowInfo.node.id);
    const isSelected = selectItemIds.includes(rowInfo.node.id);

    const disableSelect = () => {
      if (window?.location?.pathname?.split('/')?.pop() === 'new') {
        return false;
      } else return formData?.wbsTaxonomyId;
    };

    if (isParent) {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          {/* if u remove this part parent selection not working properly */}
          <span style={{ display: 'none' }}>{JSON.stringify(isParent)}</span>
          <Checkbox
            className='btn btn-outline-success'
            style={{ verticalAlign: 'middle' }}
            defaultIndeterminate
            onChange={() => handleNodeCheckboxClick(rowInfo)}
            disabled={disableSelect()}
          />
        </div>
      );
    } else {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          {rowInfo?.node?.wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.PRODUCT && <Checkbox
            className='btn btn-outline-success'
            style={{ verticalAlign: 'middle' }}
            checked={isSelected}
            onChange={() => handleNodeCheckboxClick(rowInfo)}
            disabled={disableSelect()}
          />}
        </div>
      );
    }
  };

  return (
    <>
      <TaxonomyField treeData={treeData ?? []} selectItemId={value ?? ''} disabled={disabled}>
        <TaxonomyGrid
          treeData={treeData ?? []}
          renderButtons={renderButtons}
        />
      </TaxonomyField>
    </>
  );
};

export default WbsTaxonomy;
