import * as React from 'react';
import { useEffect } from 'react';
import Tree from '../../../shared/tree/Tree';
import { Avatar, Tooltip } from '@fluentui/react-components';
import {
  Attach24Filled,
  CalendarArrowCounterclockwise24Regular,
  CheckboxChecked24Regular,
  Mail24Regular,
  Star24Filled,
  Star24Regular,
} from '@fluentui/react-icons';
import moment from 'moment';
import { registerIcons } from '@fluentui/react';
import { SvgIcons } from '../../../shared/svgIcons/svgIcons';
import { getContractingUnit, getProject } from '../../../shared/util';
import { getDeliveryStatusColor, taskPieChartColor, WBS_TASK_PEOPLE, WBS_TASK_STATUS } from '../../../types/wbs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWbsGroupTaskList,
  getWbsTaskList,
  saveWbsTasIsFavorite,
  saveWbsTaskStateAttr,
} from '../../../reducers/wbsTaskReducer';
import { useLocation } from 'react-router-dom';
import WbsTaskGroupListPane from './wbsTaskGroupListPane';
import history from '../../../history';
import { ParamTypes } from '../../../types/uPrince';
import { useParams } from 'react-router';
import { Popover } from '@varld/popover';
import WbsStatusPopover from './component/WbsStatusPopover';

registerIcons(SvgIcons);

const WbsTaskListPane = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { featureId }: ParamTypes = useParams();
  const { state }: any = location;

  const { wbsTaskList, wbsTaskGroupList, isLoadingWbsFilter, wbsTaskDropdowns } = useSelector(
    (state: any) => state.wbsTask,
  );
  const wbsTaskGroupFilters = useSelector((state: any) => state.wbsTask.wbsTaskGroupFilters);
  const reloadListPane = useSelector((state: any) => state.wbsTask.reloadListPane);

  useEffect(() => {
    getFilterData();
  }, [wbsTaskGroupFilters]);

  useEffect(() => {
    if (reloadListPane) {
      getFilterData();
    }
  }, [reloadListPane]);

  const getFilterData = () => {
    if (wbsTaskGroupFilters?.groupItem) {
      dispatch(getWbsGroupTaskList(getFilters()));
    } else {
      dispatch(getWbsTaskList(getFilters()));
    }
  };

  const getFilters = () => {
    return wbsTaskGroupFilters;
  };

  const resetFilters = async () => {
    const filters = {
      name: state?.name ?? null,
      deliveryStatusId: state?.deliveryStatusId ?? null,
      statusId: state?.statusId ?? null,
      groupItem: state?.groupBy ?? null,
      shownBy: state?.shownBy ?? null,
      isMyTask: state?.people === WBS_TASK_PEOPLE.MY_TASKS ? true : null,
      personId: null,
      IsTo: null,
      IsCc: null,
      sorter: {
        attribute: state?.sortBy ? state?.sortBy : null,
        order: null,
      },
    };

    dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', filters));
  };

  useEffect(() => {
    return () => {
      console.log('cleaned up');
    };
  }, []);


  useEffect(() => {
    if (!featureId) {
      resetFilters().then();
    }
  }, [location]);

  const renderStatusPieChart = (status: string, deliveryStatus: string) => {
    return <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' fill='none'>
      <circle cx='11' cy='11' r='10' fill='#F5F5F5' stroke={getDeliveryStatusColor(deliveryStatus)} strokeWidth='2' />
      {getStatusChart(status)}
    </svg>;
  };

  const getStatusChart = (status: string) => {
    switch (status) {
      case WBS_TASK_STATUS.PENDING_DEVELOPMENT:
        return '';
      case WBS_TASK_STATUS.IN_DEVELOPMENT:
        return <path
          d='M11 2C12.1819 2 13.3522 2.23279 14.4442 2.68508C15.5361 3.13738 16.5282 3.80031 17.364 4.63604C18.1997 5.47177 18.8626 6.46392 19.3149 7.55585C19.7672 8.64778 20 9.8181 20 11L11 11V2Z'
          fill={taskPieChartColor.fillColor} />;
      case WBS_TASK_STATUS.IN_REVIEW:
        return <path
          d='M11 2C12.1819 2 13.3522 2.23279 14.4442 2.68508C15.5361 3.13738 16.5282 3.80031 17.364 4.63604C18.1997 5.47177 18.8626 6.46392 19.3149 7.55585C19.7672 8.64778 20 9.8181 20 11C20 12.1819 19.7672 13.3522 19.3149 14.4442C18.8626 15.5361 18.1997 16.5282 17.364 17.364C16.5282 18.1997 15.5361 18.8626 14.4441 19.3149C13.3522 19.7672 12.1819 20 11 20L11 11V2Z'
          fill={taskPieChartColor.fillColor} />;
      case WBS_TASK_STATUS.APPROVED:
        return <path
          d='M11 2C12.78 2 14.5201 2.52784 16.0001 3.51677C17.4802 4.50571 18.6337 5.91131 19.3149 7.55585C19.9961 9.20038 20.1743 11.01 19.8271 12.7558C19.4798 14.5016 18.6226 16.1053 17.364 17.364C16.1053 18.6226 14.5016 19.4798 12.7558 19.8271C11.01 20.1743 9.20038 19.9961 7.55585 19.3149C5.91131 18.6337 4.50571 17.4802 3.51677 16.0001C2.52784 14.5201 2 12.78 2 11H11V2Z'
          fill={taskPieChartColor.fillColor} />;
      case WBS_TASK_STATUS.HAND_OVER:
        return <circle cx='11' cy='11' r='9' fill={taskPieChartColor.fillColor} />;
      default:
        return '';
    }
  };

  const onClickIsFav = (e: any, item: any, status: boolean) => {
    e.stopPropagation();
    dispatch(saveWbsTasIsFavorite({ id: item?.id, isFavourite: status }));
  };

  const renderToPersons = (toPersons: any) => {
    return <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {toPersons?.map((person: any) => {
          return <span>{person?.personName}</span>;
        })}
      </div>
    </>;
  };

  const renderAsideContent = (props: any) => {
    return <>
      {props?.toPerson && props?.toPerson.length > 0 &&
        <Tooltip content={renderToPersons(props?.toPerson)} relationship='label'>
          <div className={'tree-aside-content'} style={{ minWidth: 30 }}><Avatar size={20} aria-label='Guest' /></div>
        </Tooltip>}
      {props?.isFavorite &&
        <div className={'tree-aside-content'} style={{ minWidth: 30 }}><Star24Filled onClick={(e) => {
          onClickIsFav(e, props, false);
        }} /></div>}
      {!props?.isFavorite &&
        <div className={'tree-aside-content'} style={{ minWidth: 30 }}><Star24Regular onClick={(e) => {
          onClickIsFav(e, props, true);
        }} /></div>}
      {props?.date && <div className={'tree-aside-content date'}>
        <CalendarArrowCounterclockwise24Regular />{moment(props?.date).format('D/MM')}</div>}
      {props?.totalTask && <div className={'tree-aside-content task'}>
        <CheckboxChecked24Regular /> {`${props?.completedTask}/${props?.totalTask}`}</div>}
      <div className={'tree-aside-content'} style={{ minWidth: 40 }}><Attach24Filled /> {props?.totalAttachment}</div>
      <div className={'tree-aside-content'} style={{ minWidth: 40 }}><Mail24Regular /> {props?.totalMail}</div>
    </>;
  };

  const renderMediaContent = (props: any) => {
    return <Popover popover={({ visible, open, close }) => {
      return (
        <div>
          <WbsStatusPopover
            visible={visible}
            props={props}
            close={close}
          />
        </div>
      );
    }
    }>
      <div style={{ display: 'flex', height: 20, width: 20 }}>
        {renderStatusPieChart(props?.status, props?.deliveryStatus)}
      </div>
    </Popover>;
  };

  const renderTreeList = (taskList: any) => {
    return <Tree
      flatTreeItems={taskList}
      asideContent={renderAsideContent}
      mediaContent={renderMediaContent}
      onClickTreeItem={onClickTreeItem}
    />;
  };

  const onClickTreeItem = (item: any) => {
    if (getProject()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task/${item?.id}`,
        state: {
          groupBy: state?.groupBy,
          statusId: wbsTaskGroupFilters?.statusId,
          deliveryStatusId: wbsTaskGroupFilters?.deliveryStatusId,
          name: wbsTaskGroupFilters?.name,
          people: wbsTaskGroupFilters?.people,
        },
      });
    } else {
      history.push(`/CU/${getContractingUnit()}/wbs-cu-task/${item?.id}`);
    }
  };

  return (
    <>
      <div style={{
        padding: 10,
        height: '100%',
        overflow: 'auto',
      }}>
        {isLoadingWbsFilter && <div
          style={{ height: 30, width: '100%' }}
          className={'loading-shimmer'}
        ></div>}
        {wbsTaskGroupFilters?.groupItem ?
          <WbsTaskGroupListPane
            groupBy={wbsTaskGroupFilters?.groupItem}
            groupList={wbsTaskGroupList}
            renderTreeList={renderTreeList} /> :
          <Tree
            flatTreeItems={wbsTaskList}
            asideContent={renderAsideContent}
            mediaContent={renderMediaContent}
            onClickTreeItem={onClickTreeItem} />
        }
      </div>
    </>
  );
};

export default WbsTaskListPane;
