import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { messageService } from '../../../../services/messageService';
import { Switch } from '@fluentui/react-components';

const WBSCreateCmdBarLeft = (props: { isTemplate: boolean }) => {
  const { t } = useTranslation();
  let cmdBarItems: any[] = [];

  const [expand, setExpand] = React.useState(false);

  const onChangeSwitch = React.useCallback(
    (ev) => {
      setExpand(ev.currentTarget.checked);
      messageService.sendMessage({ isWbsExpand: {status:ev.currentTarget.checked} });
    },
    [setExpand],
  );

  const checkedString = expand ? 'collapse' : 'expand';

  const getItems = () => {
    const newBtn = {
      key: 'newWbs',
      text: t('createTemplate'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        messageService.sendMessage({ isNewWbs: true });
      },
      disabled: false,
    };

    if (!props.isTemplate) {
      cmdBarItems.push(newBtn);
    }

    return cmdBarItems;
  };

  return <div style={{ display: 'flex' }}>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
    {props.isTemplate && <div>
      <Switch
        checked={expand}
        label={checkedString}
        labelPosition='after'
        onChange={onChangeSwitch}
      />
    </div>
    }
  </div>;
};

export default WBSCreateCmdBarLeft;
