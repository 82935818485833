import ENV from './../../env.json';

export class UprincePermissions {
  isProjectManager: boolean = false;
  isApplicationManager: boolean = false;
  hasOrganisationPermission: boolean = false;
  hasContractingUnitPermission: boolean = false;
  isPlanningManager: boolean = false;
  isHRManager: boolean = false;
  isBackOfficer: boolean = false;
  isCUTenderManager: boolean = false;
  isProjectTenderManager: boolean = false;
  isProjectTeamManager: boolean = false;
}

export enum AccessLevel {
  ORGANIZATION = 0,
  CONTRACTING_UNIT = 1,
  PROJECT = 2,
  PROJECT_FEATURE = 3,
  ORGANIZATION_FEATURE = 4,
  CONTRACTING_UNIT_FEATURE = 5,
  MY_ENV = 6,
}

export enum UserRole {
  PROJECT_MANAGER = 'Project Managers',
  APPLICATION_MANAGER = 'Application Manager',
  PLANNING_MANAGER = 'Planning Managers',
  HR_MANAGER = 'HR Managers',
  BACK_OFFICER = 'Back Officer',
  CU_TENDER_MANAGER = 'CU Tender Manager',
  PROJECT_TENDER_MANAGER = 'Project Tender Manager',
  PROJECT_TEAM = 'Project Team',
}

export const ORGANISATION_NAME = ENV.ORGANISATION_NAME;
export const ORGANISATION_ID = ENV.ORGANISATION_ID;
export const IS_ALLOW_ONLY_ORG =
  ENV.FEATURES_CARD_CONFIG.ALLOW_ACCESS_ONLY_ORGANIZATION;

export enum CU_FEATURES {
  PROJECT = 'Project',
  TIME_CLOCK = 'TimeClock',
  QR_CODE = 'QRCode',
  VP = 'VP',
  DP = 'DP',
  CPC = 'CPC',
  PO = 'PO',
  PS = 'PS',
  INVOICE = 'Invoice',
  SM = 'SM',
  STOCK = 'Stock',
  WBS = 'Wbs',
  INSTRUCTION = 'Instruction',
}

export enum ORG_FEATURS {
  CU = 'CU',
  CAB = 'CAB',
  CBC = 'CBC',
  CPC = 'CPC',
  ORG_SETTING = 'ORG_SETTING',
  TRANSLATION = 'Translation',
  DROPDOWN_TRANSLATION = 'DropDownTranslation',
}

export enum PROJECT_FEATURS {
  PROJECT_DEF = 'PROJECT_DEF',
  PBS = 'PBS',
  CPC = 'CPC',
  BOR = 'BOR',
  PO = 'PO',
  PMOL = 'PMOL',
  PS = 'PS',
  INVOICE = 'Invoice',
  STOCK = 'Stock',
  VP = 'VP',
  DP = 'DP',
  CONTRACT = 'CONTRACT',
}

export enum MY_ENV_FEATURES {
  MY_VP = 'MY_VP',
  MY_PP = 'MY_PP',
  DAY_PLANNING = 'DAY_PLANNING',
  DAY_PLANNING_NEW = 'DAY_PLANNING_NEW',
  PROJECT_DAY_PLANNING = 'PROJECT_DAY_PLANNING',
  TH_AUTOMATION = 'PROJECT_DAY_PLANNING',
}
