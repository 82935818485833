export const LOAD_VISUAL_PLAN_SHORTCUTPANE = 'uprince/LOAD_VISUAL_PLAN_SHORTCUTPANE';
export const LOAD_VISUAL_PLAN_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_VISUAL_PLAN_SHORTCUTPANE_SUCCESS';
export const LOAD_VISUAL_PLAN_SHORTCUTPANE_FAIL = 'uprince/LOAD_VISUAL_PLAN_SHORTCUTPANE_FAIL';

export const LOAD_VISUAL_PLAN_LSITPANE = 'uprince/LOAD_VISUAL_PLAN_LSITPANE';
export const LOAD_VISUAL_PLAN_LSITPANE_SUCCESS = 'uprince/LOAD_VISUAL_PLAN_LSITPANE_SUCCESS';
export const LOAD_VISUAL_PLAN_LSITPANE_FAIL = 'uprince/LOAD_VISUAL_PLAN_LSITPANE_FAIL';

export const LOAD_VISUAL_PLAN_DROPDOWN = 'uprince/LOAD_VISUAL_PLAN_DROPDOWN';
export const LOAD_VISUAL_PLAN_DROPDOWN_SUCCESS = 'uprince/LOAD_VISUAL_PLAN_DROPDOWN_SUCCESS';
export const LOAD_VISUAL_PLAN_DROPDOWN_FAIL = 'uprince/LOAD_VISUAL_PLAN_DROPDOWN_FAIL';

export const SAVE_VISUAL_PLAN = 'uprince/SAVE_VISUAL_PLAN';
export const SAVE_VISUAL_PLAN_SUCCESS = 'uprince/SAVE_VISUAL_PLAN_SUCCESS';
export const SAVE_VISUAL_PLAN_FAIL = 'uprince/SAVE_VISUAL_PLAN_FAIL';

export const LOAD_VISUAL_PLAN_BY_ID = 'uprince/LOAD_VISUAL_PLAN_BY_ID';
export const LOAD_VISUAL_PLAN_BY_ID_SUCCESS = 'uprince/LOAD_VISUAL_PLAN_BY_ID_SUCCESS';
export const LOAD_VISUAL_PLAN_BY_ID_FAIL = 'uprince/LOAD_VISUAL_PLAN_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_VISUAL_PLAN_UID = 'uprince/SET_VISUAL_PLAN_UID';
export const CLEAR_VISUAL_PLAN_UID = 'uprince/CLEAR_VISUAL_PLAN_UID';
export const CLEAR_VISUAL_PLAN_FORM_DATA = 'uprince/CLEAR_VISUAL_PLAN_FORM_DATA';
export const RESET_VISUAL_PLAN_IS_CHANGE = 'uprice/RESET_VISUAL_PLAN_IS_CHANGE';
export const SAVE_VP_STATE_ATTR = 'uprice/SAVE_VP_STATE_ATTR';

export const LOAD_VP_ORG_SETTING_BY_ID = 'uprince/LOAD_VP_ORG_SETTING_BY_ID';
export const LOAD_VP_ORG_SETTING_BY_ID_SUCCESS = 'uprince/LOAD_VP_ORG_SETTING_BY_ID_SUCCESS';
export const LOAD_VP_ORG_SETTING_BY_ID_FAIL = 'uprince/LOAD_VP_ORG_SETTING_BY_ID_FAIL';

export const LOAD_WAREHOUSE_TOOLS = 'uprince/LOAD_WAREHOUSE_TOOLS';
export const LOAD_WAREHOUSE_TOOLS_SUCCESS = 'uprince/LOAD_WAREHOUSE_TOOLS_SUCCESS';
export const LOAD_WAREHOUSE_TOOLS_FAIL = 'uprince/LOAD_WAREHOUSE_TOOLS_FAIL';

export const LOAD_VP_WAREHOUSE_TOOLS = 'uprince/LOAD_VP_WAREHOUSE_TOOLS';
export const LOAD_VP_WAREHOUSE_TOOLS_SUCCESS = 'uprince/LOAD_VP_WAREHOUSE_TOOLS_SUCCESS';
export const LOAD_VP_WAREHOUSE_TOOLS_FAIL = 'uprince/LOAD_VP_WAREHOUSE_TOOLS_FAIL';

export const LOAD_CPC_RESOURCE_FAMILY_FOR_VP = 'uprince/LOAD_CPC_RESOURCE_FAMILY_FOR_VP';
export const LOAD_CPC_RESOURCE_FAMILY_FOR_VP_SUCCESS = 'uprince/LOAD_CPC_RESOURCE_FAMILY_FOR_VP_SUCCESS';
export const LOAD_CPC_RESOURCE_FAMILY_FOR_VP_FAIL = 'uprince/LOAD_CPC_RESOURCE_FAMILY_FOR_VP_FAIL';

export const LOAD_VP_CUHR = 'uprince/LOAD_VP_CUHR';
export const LOAD_VP_CUHR_SUCCESS = 'uprince/LOAD_VP_CUHR_SUCCESS';
export const LOAD_VP_CUHR_FAIL = 'uprince/LOAD_VP_CUHR_FAIL';

export const ADD_PO_LABOUR_TEAM = 'uprince/ADD_PO_LABOUR_TEAM';
export const ADD_PO_LABOUR_TEAM_SUCCESS = 'uprince/ADD_PO_LABOUR_TEAM_SUCCESS';
export const ADD_PO_LABOUR_TEAM_FAIL = 'uprince/ADD_PO_LABOUR_TEAM_FAIL';

export const UPDATE_VP_PO = 'uprince/UPDATE_VP_PO';
export const UPDATE_VP_PO_SUCCESS = 'uprince/UPDATE_VP_PO_SUCCESS';
export const UPDATE_VP_PO_FAIL = 'uprince/UPDATE_VP_PO_FAIL';

export const UPDATE_PERSON_DATES = 'uprince/UPDATE_PERSON_DATES';
export const UPDATE_PERSON_DATES_SUCCESS = 'uprince/UPDATE_PERSON_DATES_SUCCESS';
export const UPDATE_PERSON_DATES_FAIL = 'uprince/UPDATE_PERSON_DATES_FAIL';

export const ADD_TOOLS_POOLS = 'uprince/ADD_TOOLS_POOLS';
export const ADD_TOOLS_POOLS_SUCCESS = 'uprince/ADD_TOOLS_POOLS_SUCCESS';
export const ADD_TOOLS_POOLS_FAIL = 'uprince/ADD_TOOLS_POOLS_FAIL';

export const READ_VP_PROJECT = 'uprince/READ_VP_PROJECT';
export const READ_VP_PROJECT_SUCCESS = 'uprince/READ_VP_PROJECT_SUCCESS';
export const READ_VP_PROJECT_FAIL = 'uprince/READ_VP_PROJECT_FAIL';

export const READ_VP_PROJECT_RESOURCE = 'uprince/READ_VP_PROJECT_RESOURCE';
export const READ_VP_PROJECT_RESOURCE_SUCCESS = 'uprince/READ_VP_PROJECT_RESOURCE_SUCCESS';
export const READ_VP_PROJECT_RESOURCE_FAIL = 'uprince/READ_VP_PROJECT_RESOURCE_FAIL';

export const READ_MILESTONE_SHORTCUT = 'uprince/READ_MILESTONE_SHORTCUT';
export const READ_MILESTONE_SHORTCUT_SUCCESS = 'uprince/READ_MILESTONE_SHORTCUT_SUCCESS';
export const READ_MILESTONE_SHORTCUT_FAIL = 'uprince/READ_MILESTONE_SHORTCUT_FAIL';

export const UPDATE_PMOL_LABOUR_TEAM = 'uprince/UPDATE_PMOL_LABOUR_TEAM';
export const UPDATE_PMOL_LABOUR_TEAM_SUCCESS = 'uprince/UPDATE_PMOL_LABOUR_TEAM_SUCCESS';
export const UPDATE_PMOL_LABOUR_TEAM_FAIL = 'uprince/UPDATE_PMOL_LABOUR_TEAM_FAIL';

export const UPDATE_PMOL_TOOL_TEAM = 'uprince/UPDATE_PMOL_TOOL_TEAM';
export const UPDATE_PMOL_TOOL_TEAM_SUCCESS = 'uprince/UPDATE_PMOL_TOOL_TEAM_SUCCESS';
export const UPDATE_PMOL_TOOL_TEAM_FAIL = 'uprince/UPDATE_PMOL_TOOL_TEAM_FAIL';

export const UPDATE_PROJECT_ITEMS_DATE = 'uprince/UPDATE_PROJECT_ITEMS_DATE';
export const UPDATE_PROJECT_ITEMS_DATE_SUCCESS = 'uprince/UPDATE_PROJECT_ITEMS_DATE_SUCCESS';
export const UPDATE_PROJECT_ITEMS_DATE_FAIL = 'uprince/UPDATE_PROJECT_ITEMS_DATE_FAIL';

export const CLEAR_VISUAL_PLANUID = 'uprice/CLEAR_VISUAL_PLANUID';

export const GET_VP_FILTER_DROPDOWN_DATA = 'uprince/GET_VP_FILTER_DROPDOWN_DATA';
export const GET_VP_FILTER_DROPDOWN_DATA_SUCCESS = 'uprince/GET_VP_FILTER_DROPDOWN_DATA_SUCCESS';
export const GET_VP_FILTER_DROPDOWN_DATA_FAIL = 'uprince/GET_VP_FILTER_DROPDOWN_DATA_FAIL';

export const READ_VP_PRODUCT_TAXONOMY = 'uprince/READ_VP_PRODUCT_TAXONOMY';
export const READ_VP_PRODUCT_TAXONOMY_SUCCESS = 'uprince/READ_VP_PRODUCT_TAXONOMY_SUCCESS';
export const READ_VP_PRODUCT_TAXONOMY_FAIL = 'uprince/READ_VP_PRODUCT_TAXONOMY_FAIL';

export const UPDATE_PBS_PARENT = 'uprince/UPDATE_PBS_PARENT';
export const UPDATE_PBS_PARENT_SUCCESS = 'uprince/UPDATE_PBS_PARENT_SUCCESS';
export const UPDATE_PBS_PARENT_FAIL = 'uprince/UPDATE_PBS_PARENT_FAIL';

export const CREATE_PR_FROM_BOR = 'uprince/CREATE_PR_FROM_BOR';
export const CREATE_PR_FROM_BOR_SUCCESS = 'uprince/CREATE_PR_FROM_BOR_SUCCESS';
export const CREATE_PR_FROM_BOR_FAIL = 'uprince/CREATE_PR_FROM_BOR_FAIL';

export const READ_VP_UTILITY_TAXONOMY = 'uprince/READ_VP_UTILITY_TAXONOMY';
export const READ_VP_UTILITY_TAXONOMY_SUCCESS = 'uprince/READ_VP_UTILITY_TAXONOMY_SUCCESS';
export const READ_VP_UTILITY_TAXONOMY_FAIL = 'uprince/READ_VP_UTILITY_TAXONOMY_FAIL';

export const CREATE_PBS_CLONE = 'uprince/CREATE_PBS_CLONE';
export const CREATE_PBS_CLONE_SUCCESS = 'uprince/CREATE_PBS_CLONE_SUCCESS';
export const CREATE_PBS_CLONE_FAIL = 'uprince/CREATE_PBS_CLONE_FAIL';

export const READ_VP_PROJECT_WITHOUT_REFRESH = 'uprince/READ_VP_PROJECT_WITHOUT_REFRESH';
export const READ_VP_PROJECT_WITHOUT_REFRESH_SUCCESS = 'uprince/READ_VP_PROJECT_WITHOUT_REFRESH_SUCCESS';
export const READ_VP_PROJECT_WITHOUT_REFRESH_FAIL = 'uprince/READ_VP_PROJECT_WITHOUT_REFRESH_FAIL';

export const READ_VP_PRODUCT_TAXONOMY_REFRESH = 'uprince/READ_VP_PRODUCT_TAXONOMY_REFRESH';
export const READ_VP_PRODUCT_TAXONOMY_REFRESH_SUCCESS = 'uprince/READ_VP_PRODUCT_TAXONOMY_REFRESH_SUCCESS';
export const READ_VP_PRODUCT_TAXONOMY_REFRESH_FAIL = 'uprince/READ_VP_PRODUCT_TAXONOMY_REFRESH_FAIL';

export const UPDATE_VP_PT_TREE_INDEX = 'uprince/UPDATE_VP_PT_TREE_INDEX';
export const UPDATE_VP_PT_TREE_INDEX_SUCCESS = 'uprince/UPDATE_VP_PT_TREE_INDEX_SUCCESS';
export const UPDATE_VP_PT_TREE_INDEX_FAIL = 'uprince/UPDATE_VP_PT_TREE_INDEX_FAIL';

export const UPDATE_TREE_INDEX_OF_NEW_SIBLING = 'uprince/UPDATE_VP_PT_TREE_INDEX_REFRESH';
export const UPDATE_TREE_INDEX_OF_NEW_SIBLING_SUCCESS = 'uprince/UPDATE_VP_PT_TREE_INDEX_REFRESH_SUCCESS';
export const UPDATE_TREE_INDEX_OF_NEW_SIBLING_FAIL = 'uprince/UPDATE_VP_PT_TREE_INDEX_REFRESH_FAIL';

export const UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER = 'uprince/UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER';
export const UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_SUCCESS = 'uprince/UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_SUCCESS';
export const UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_FAIL = 'uprince/UPDATE_VP_MY_ENV_PBS_DISPLAY_ORDER_FAIL';

export const READ_VP_PURCHASE_TAXONOMY = 'uprince/READ_VP_PURCHASE_TAXONOMY';
export const READ_VP_PURCHASE_TAXONOMY_SUCCESS = 'uprince/READ_VP_PURCHASE_TAXONOMY_SUCCESS';
export const READ_VP_PURCHASE_TAXONOMY_FAIL = 'uprince/READ_VP_PURCHASE_TAXONOMY_FAIL';

export const READ_VP_PURCHASE_PR_TAXONOMY = 'uprince/READ_VP_PURCHASE_PR_TAXONOMY';
export const READ_VP_PURCHASE_PR_TAXONOMY_SUCCESS = 'uprince/READ_VP_PURCHASE_PR_TAXONOMY_SUCCESS';
export const READ_VP_PURCHASE_PR_TAXONOMY_FAIL = 'uprince/READ_VP_PURCHASE_PR_TAXONOMY_FAIL';

export const READ_VP_MACHINE_TAXONOMY = 'uprince/READ_VP_MACHINE_TAXONOMY';
export const READ_VP_MACHINE_TAXONOMY_SUCCESS = 'uprince/READ_VP_MACHINE_TAXONOMY_SUCCESS';
export const READ_VP_MACHINE_TAXONOMY_FAIL = 'uprince/READ_VP_MACHINE_TAXONOMY_FAIL';

export const READ_VP_PURCHASE_CU_TAXONOMY = 'uprince/READ_VP_PURCHASE_CU_TAXONOMY';
export const READ_VP_PURCHASE_CU_TAXONOMY_SUCCESS = 'uprince/READ_VP_PURCHASE_CU_TAXONOMY_SUCCESS';
export const READ_VP_PURCHASE_CU_TAXONOMY_FAIL = 'uprince/READ_VP_PURCHASE_CU_TAXONOMY_FAIL';

export const DELETE_VP_SHIFT_RESOURCE_ITEM = 'uprince/DELETE_VP_SHIFT_RESOURCE_ITEM';
export const DELETE_VP_SHIFT_RESOURCE_ITEM_SUCCESS = 'uprince/DELETE_VP_SHIFT_RESOURCE_ITEM_SUCCESS';
export const DELETE_VP_SHIFT_RESOURCE_ITEM_FAIL = 'uprince/DELETE_VP_SHIFT_RESOURCE_ITEM_FAIL';

export const READ_ALL_PBS_LABOUR = 'uprince/READ_ALL_PBS_LABOUR';
export const READ_ALL_PBS_LABOUR_SUCCESS = 'uprince/READ_ALL_PBS_LABOUR_SUCCESS';
export const READ_ALL_PBS_LABOUR_FAIL = 'uprince/READ_ALL_PBS_LABOUR_FAIL';

export const READ_ALL_PMOL_LABOUR = 'uprince/READ_ALL_PMOL_LABOUR';
export const READ_ALL_PMOL_LABOUR_SUCCESS = 'uprince/READ_ALL_PMOL_LABOUR_SUCCESS';
export const READ_ALL_PMOL_LABOUR_FAIL = 'uprince/READ_ALL_PMOL_LABOUR_FAIL';

export const READ_RESOURCE_MATRIX = 'uprince/READ_RESOURCE_MATRIX';
export const READ_RESOURCE_MATRIX_SUCCESS = 'uprince/READ_RESOURCE_MATRIX_SUCCESS';
export const READ_RESOURCE_MATRIX_FAIL = 'uprince/READ_RESOURCE_MATRIX_FAIL';

export const READ_MIDTERM_CAPACITY_DATA = 'uprince/READ_MIDTERM_CAPACITY_DATA';
export const READ_MIDTERM_CAPACITY_DATA_SUCCESS = 'uprince/READ_MIDTERM_CAPACITY_DATA_SUCCESS';
export const READ_MIDTERM_CAPACITY_DATA_FAIL = 'uprince/READ_MIDTERM_CAPACITY_DATA_FAIL';

export const READ_PROJECT_TEAM_CAPACITY_DATA = 'uprince/READ_PROJECT_TEAM_CAPACITY_DATA';
export const READ_PROJECT_TEAM_CAPACITY_DATA_SUCCESS = 'uprince/READ_PROJECT_TEAM_CAPACITY_DATA_SUCCESS';
export const READ_PROJECT_TEAM_CAPACITY_DATA_FAIL = 'uprince/READ_PROJECT_TEAM_CAPACITY_DATA_FAIL';

export const READ_MID_TERM_PLANNING = 'uprince/READ_MID_TERM_PLANNING';
export const READ_MID_TERM_PLANNING_SUCCESS = 'uprince/READ_MID_TERM_PLANNING_SUCCESS';
export const READ_MID_TERM_PLANNING_FAIL = 'uprince/READ_MID_TERM_PLANNING_FAIL';

export const ADD_MID_TERM_LABOUR = 'uprince/ADD_MID_TERM_LABOUR';
export const ADD_MID_TERM_LABOUR_SUCCESS = 'uprince/ADD_MID_TERM_LABOUR_SUCCESS';
export const ADD_MID_TERM_LABOUR_FAIL = 'uprince/ADD_MID_TERM_LABOUR_FAIL';

export const READ_MID_TERM_ORG_TAXONOMY = 'uprince/READ_MID_TERM_ORG_TAXONOMY';
export const READ_MID_TERM_ORG_TAXONOMY_SUCCESS = 'uprince/READ_MID_TERM_ORG_TAXONOMY_SUCCESS';
export const READ_MID_TERM_ORG_TAXONOMY_FAIL = 'uprince/READ_MID_TERM_ORG_TAXONOMY_FAIL';

export const DELETE_MID_TERM_LABOUR = 'uprince/DELETE_MID_TERM_LABOUR';
export const DELETE_MID_TERM_LABOUR_SUCCESS = 'uprince/DELETE_MID_TERM_LABOUR_SUCCESS';
export const DELETE_MID_TERM_LABOUR_FAIL = 'uprince/DELETE_MID_TERM_LABOUR_FAIL';