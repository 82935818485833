export const LOAD_DAY_PLANNINNG_SHORTCUTPANE = 'uprince/LOAD_DAY_PLANNINNG_SHORTCUTPANE';
export const LOAD_DAY_PLANNINNG_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_DAY_PLANNINNG_SHORTCUTPANE_SUCCESS';
export const LOAD_DAY_PLANNINNG_SHORTCUTPANE_FAIL = 'uprince/LOAD_DAY_PLANNINNG_SHORTCUTPANE_FAIL';

export const LOAD_DAY_PLANNINNG_PMOL_LSITPANE = 'uprince/LOAD_DAY_PLANNINNG_PMOL_LSITPANE';
export const LOAD_DAY_PLANNINNG_PMOL_LSITPANE_SUCCESS = 'uprince/LOAD_DAY_PLANNINNG_PMOL_LSITPANE_SUCCESS';
export const LOAD_DAY_PLANNINNG_PMOL_LSITPANE_FAIL = 'uprince/LOAD_DAY_PLANNINNG_PMOL_LSITPANE_FAIL';

export const LOAD_DAY_PLANNINNG_TEAMS_LIST = 'uprince/LOAD_DAY_PLANNINNG_TEAMS_LIST';
export const LOAD_DAY_PLANNINNG_TEAMS_LIST_SUCCESS = 'uprince/LOAD_DAY_PLANNINNG_TEAMS_LIST_SUCCESS';
export const LOAD_DAY_PLANNINNG_TEAMS_LIST_FAIL = 'uprince/LOAD_DAY_PLANNINNG_TEAMS_LIST_FAIL';

export const LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE = 'uprince/LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE';
export const LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS = 'uprince/LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS';
export const LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL = 'uprince/LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL';

export const LOAD_DAY_PLANNINNG_DROPDOWN = 'uprince/LOAD_DAY_PLANNINNG_DROPDOWN';
export const LOAD_DAY_PLANNINNG_DROPDOWN_SUCCESS = 'uprince/LOAD_DAY_PLANNINNG_DROPDOWN_SUCCESS';
export const LOAD_DAY_PLANNINNG_DROPDOWN_FAIL = 'uprince/LOAD_DAY_PLANNINNG_DROPDOWN_FAIL';

export const SAVE_DAY_PLANNINNG = 'uprince/SAVE_DAY_PLANNINNG';
export const SAVE_DAY_PLANNINNG_SUCCESS = 'uprince/SAVE_DAY_PLANNINNG_SUCCESS';
export const SAVE_DAY_PLANNINNG_FAIL = 'uprince/SAVE_DAY_PLANNINNG_FAIL';

export const LOAD_DAY_PLANNINNG_BY_ID = 'uprince/LOAD_DAY_PLANNINNG_BY_ID';
export const LOAD_DAY_PLANNINNG_BY_ID_SUCCESS = 'uprince/LOAD_DAY_PLANNINNG_BY_ID_SUCCESS';
export const LOAD_DAY_PLANNINNG_BY_ID_FAIL = 'uprince/LOAD_DAY_PLANNINNG_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_DAY_PLANNINNG_UID = 'uprince/SET_DAY_PLANNINNG_UID';
export const CLEAR_DAY_PLANNINNG_UID = 'uprince/CLEAR_DAY_PLANNINNG_UID';
export const CLEAR_DAY_PLANNINNG_FORM_DATA = 'uprince/CLEAR_DAY_PLANNINNG_FORM_DATA';
export const RESET_DAY_PLANNINNG_IS_CHANGE = 'uprice/RESET_DAY_PLANNINNG_IS_CHANGE';

export const DND_ITEM_TO_PMOL = 'uprince/DND_ITEM_TO_PMOL';
export const DND_ITEM_TO_PMOL_SUCCESS = 'uprince/DND_ITEM_TO_PMOL_SUCCESS';
export const DND_ITEM_TO_PMOL_FAIL = 'uprince/DND_ITEM_TO_PMOL_FAIL';

export const CLEAR_DAY_PLANNINNGUID = 'uprice/CLEAR_DAY_PLANNINNGUID';

export const SELECTED_GRID_TYPE = 'uprice/SELECTED_GRID_TYPE';

export const SAVE_DAY_PLANNING_STATE_ATTR = 'uprice/SAVE_DAY_PLANNING_STATE_ATTR';

export const READ_MY_CALENDER_PBS_TAXONOMY = 'uprince/READ_MY_CALENDER_PBS_TAXONOMY';
export const READ_MY_CALENDER_PBS_TAXONOMY_SUCCESS = 'uprince/READ_MY_CALENDER_PBS_TAXONOMY_SUCCESS';
export const READ_MY_CALENDER_PBS_TAXONOMY_FAIL = 'uprince/READ_MY_CALENDER_PBS_TAXONOMY_FAIL';

export const CREATE_MY_CALENDAR_NEW_PMOL = 'uprince/CREATE_MY_CALENDAR_NEW_PMOL';
export const CREATE_MY_CALENDAR_NEW_PMOL_SUCCESS = 'uprince/CREATE_MY_CALENDAR_NEW_PMOL_SUCCESS';
export const CREATE_MY_CALENDAR_NEW_PMOL_FAIL = 'uprince/CREATE_MY_CALENDAR_NEW_PMOL_FAIL';

export const GET_MY_CALENDAR_ORGANIZATION_TAXONOMY = 'uprince/GET_MY_CALENDAR_ORGANIZATION_TAXONOMY';
export const GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_SUCCESS = 'uprince/GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_SUCCESS';
export const GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_FAIL = 'uprince/GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_FAIL';
