import {
    CREATE_MY_CALENDAR_NEW_PMOL_EP,
    DND_ITEM_TO_PMOL_EP,
    GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_EP,
    READ_DAY_PLANNINNG_BY_ID_EP,
    READ_DAY_PLANNINNG_DROPDOWN_EP,
    READ_DAY_PLANNINNG_PMOL_LIST_EP,
    READ_DAY_PLANNINNG_REOSURCE_LIST_EP,
    READ_DAY_PLANNINNG_SHORTCUT_PANE_EP,
    READ_DAY_PLANNINNG_TEAMS_LIST_EP,
    READ_MY_CALENDER_PBS_TAXONOMY_EP,
} from '../shared/endpoints';
import {
    CLEAR_DAY_PLANNINNG_FORM_DATA,
    CLEAR_DAY_PLANNINNGUID,
    CREATE_MY_CALENDAR_NEW_PMOL,
    CREATE_MY_CALENDAR_NEW_PMOL_FAIL,
    CREATE_MY_CALENDAR_NEW_PMOL_SUCCESS,
    DND_ITEM_TO_PMOL,
    DND_ITEM_TO_PMOL_FAIL,
    DND_ITEM_TO_PMOL_SUCCESS,
    GET_MY_CALENDAR_ORGANIZATION_TAXONOMY,
    GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_FAIL,
    GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_SUCCESS,
    LOAD_DAY_PLANNINNG_BY_ID,
    LOAD_DAY_PLANNINNG_BY_ID_FAIL,
    LOAD_DAY_PLANNINNG_BY_ID_SUCCESS,
    LOAD_DAY_PLANNINNG_DROPDOWN,
    LOAD_DAY_PLANNINNG_DROPDOWN_FAIL,
    LOAD_DAY_PLANNINNG_DROPDOWN_SUCCESS,
    LOAD_DAY_PLANNINNG_PMOL_LSITPANE,
    LOAD_DAY_PLANNINNG_PMOL_LSITPANE_FAIL,
    LOAD_DAY_PLANNINNG_PMOL_LSITPANE_SUCCESS,
    LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE,
    LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL,
    LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS,
    LOAD_DAY_PLANNINNG_SHORTCUTPANE,
    LOAD_DAY_PLANNINNG_SHORTCUTPANE_FAIL,
    LOAD_DAY_PLANNINNG_SHORTCUTPANE_SUCCESS,
    LOAD_DAY_PLANNINNG_TEAMS_LIST,
    LOAD_DAY_PLANNINNG_TEAMS_LIST_FAIL,
    LOAD_DAY_PLANNINNG_TEAMS_LIST_SUCCESS,
    READ_MY_CALENDER_PBS_TAXONOMY,
    READ_MY_CALENDER_PBS_TAXONOMY_FAIL,
    READ_MY_CALENDER_PBS_TAXONOMY_SUCCESS,
    RESET_DAY_PLANNINNG_IS_CHANGE,
    SAVE_DAY_PLANNING_STATE_ATTR,
    SELECTED_GRID_TYPE,
    SET_DAY_PLANNINNG_UID
} from '../actionTypes/dayPlanningActionTypes';
import {v4 as uuidv4} from 'uuid';
import {POFilter} from '../types/purchaseOrder';
import {DayPlanningList, SelectedGridType} from '../types/dayPlanning';


const defaultState: any = {
    teamList: [],
    dplanList: [],
    resourceList: [],
    pmolListPaneItems: [],
    isDPLANListLoaded: false,
    isNew: false,
    isChange: false,
    isEdit: false,
    showProgressBar: false,
    dplanDropDowns: {},
    formData: {},
    uid: null,
    loadMsg: '',
    dplanShortCutPaneData: [],
    reloadDPListPane: false,
    borResources: {},
    isLoadingDayPlanning: false,
    isResourceDataLoaded: false,
    dplanId: null,
    selectedGridType: SelectedGridType.DAY,
    dayPlanningDate: new Date(),
    dayPlanningWeek: {startDate: null, endDate: null, toDate: new Date()},
    dPlanFullScreen: false,
    myCalendarSlotData: {},
    myCalenderPbsTaxonomy: [],
    showMyCalenderPbsTaxonomyProgressBar: false,
    myCalOsList: [],
    isMyEnv: false,
    myCalCuListData: [],
    resourceSelectorData: null,
    createNewPmolSuccess: false,
};

export default function dayPlanningReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {
            case LOAD_DAY_PLANNINNG_SHORTCUTPANE:
                return {...state};
            case LOAD_DAY_PLANNINNG_SHORTCUTPANE_SUCCESS:
                return {
                    ...state,
                    dplanShortCutPaneData: action.payload.data.result,
                };
            case LOAD_DAY_PLANNINNG_SHORTCUTPANE_FAIL:
                return {...state, dplanShortCutPaneData: []};

            case LOAD_DAY_PLANNINNG_PMOL_LSITPANE:
                return {...state, isDPLANListLoaded: false};
            case LOAD_DAY_PLANNINNG_PMOL_LSITPANE_SUCCESS:
                return {
                    ...state,
                    isDPLANListLoaded: true,
                    dplanList: action.payload.data.result,
                    loadMsg: !action.payload.data.status
                      ? action.payload.data.message
                      : '',
                };
            case LOAD_DAY_PLANNINNG_PMOL_LSITPANE_FAIL:
                return {
                    ...state,
                    isDPLANListLoaded: true,
                    dplanList: [],
                    loadMsg: action.error.response.data.message,
                };

            case LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE:
                return {...state, isResourceDataLoaded: false};
            case LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS:
                return {
                    ...state,
                    isResourceDataLoaded: true,
                    resourceList: action.payload.data.result,
                    loadMsg: !action.payload.data.status
                      ? action.payload.data.message
                      : '',
                    reloadBottomListPane: false,
                };
            case LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL:
                return {
                    ...state,
                    isResourceDataLoaded: true,
                    reloadBottomListPane: false,
                    resourceList: [],
                    loadMsg: action?.error?.response?.data?.message
                };

            case LOAD_DAY_PLANNINNG_TEAMS_LIST:
                return {...state, isDPLANListLoaded: false, teamList: []};
            case LOAD_DAY_PLANNINNG_TEAMS_LIST_SUCCESS:
                return {
                    ...state,
                    isDPLANListLoaded: true,
                    teamList: action.payload.data.result,
                    loadMsg: !action.payload.data.status
                      ? action.payload.data.message
                      : '',
                };
            case LOAD_DAY_PLANNINNG_TEAMS_LIST_FAIL:
                return {
                    ...state,
                    isDPLANListLoaded: true,
                    teamList: [],
                    loadMsg: action.error.response.data.message,
                };

            case RESET_DAY_PLANNINNG_IS_CHANGE:
                return {
                    ...state,
                    isChange: false,
                };

            case LOAD_DAY_PLANNINNG_DROPDOWN:
                return {...state, dplanDropDowns: {}};
            case LOAD_DAY_PLANNINNG_DROPDOWN_SUCCESS:
                return {...state, dplanDropDowns: action.payload.data.result};
            case LOAD_DAY_PLANNINNG_DROPDOWN_FAIL:
                return {
                    ...state,
                    dplanDropDowns: {},
                };
            case LOAD_DAY_PLANNINNG_BY_ID:
                return {...state, formData: {}, showProgressBar: true, isLoadingDayPlanning: true};
            case LOAD_DAY_PLANNINNG_BY_ID_SUCCESS:
                return {
                    ...state,
                    isEdit: true,
                    formData: action.payload.data.result,
                    showProgressBar: false,
                    isLoadingDayPlanning: false,
                };
            case LOAD_DAY_PLANNINNG_BY_ID_FAIL:
                return {
                    ...state,
                    formData: {},
                    showProgressBar: false,
                    isLoadingDayPlanning: false,
                };

            case DND_ITEM_TO_PMOL:
                return {
                    ...state,
                    showProgressBar: true,
                    dplanId: null
                };
            case DND_ITEM_TO_PMOL_SUCCESS:
                return {
                    ...state,
                    isChange: true,
                    isNew: false,
                    showProgressBar: false,
                    reloadDPListPane: true,
                };
            case DND_ITEM_TO_PMOL_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                };

            case SET_DAY_PLANNINNG_UID:
                return {
                    ...state,
                    isEdit: false,
                    uid: uuidv4(),
                };
            case CLEAR_DAY_PLANNINNGUID:
                return {
                    ...state,
                    uid: null,
                    isChange: false,
                };
            case CLEAR_DAY_PLANNINNG_FORM_DATA:
                return {
                    ...state,
                    uid: null,
                    isNew: true,
                    isChange: false,
                    formData: {},
                };

            case SELECTED_GRID_TYPE:
                return {
                    ...state,
                    selectedGridType: action.selectedGridType,
                };

            case SAVE_DAY_PLANNING_STATE_ATTR:
                const attr = Object.keys(action)[1];
                const value = Object.values(action)[1];
                return {
                    ...state,
                    [attr]: value
                };

            case READ_MY_CALENDER_PBS_TAXONOMY:
                return {
                    ...state,
                    myCalenderPbsTaxonomy: [],
                    showMyCalenderPbsTaxonomyProgressBar: true,
                };
            case READ_MY_CALENDER_PBS_TAXONOMY_SUCCESS:
                return {
                    ...state,
                    myCalenderPbsTaxonomy: action.payload.data.result,
                    showMyCalenderPbsTaxonomyProgressBar: false,
                };
            case READ_MY_CALENDER_PBS_TAXONOMY_FAIL:
                return {
                    ...state,
                    myCalenderPbsTaxonomy: [],
                    showMyCalenderPbsTaxonomyProgressBar: false,
                };

            case CREATE_MY_CALENDAR_NEW_PMOL:
                return {
                    ...state,
                    showMyCalenderPbsTaxonomyProgressBar: true,
                    reloadDPListPane: false,
                    createNewPmolSuccess: false,
                };
            case CREATE_MY_CALENDAR_NEW_PMOL_SUCCESS:
                return {
                    ...state,
                    showMyCalenderPbsTaxonomyProgressBar: false,
                    reloadDPListPane: true,
                    createNewPmolSuccess: true,
                };
            case CREATE_MY_CALENDAR_NEW_PMOL_FAIL:
                return {
                    ...state,
                    showMyCalenderPbsTaxonomyProgressBar: false,
                    reloadDPListPane: false,
                    createNewPmolSuccess: false,
                };

            case GET_MY_CALENDAR_ORGANIZATION_TAXONOMY:
                return {
                    ...state,
                };
            case GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_SUCCESS:
                return {
                    ...state,
                    myCalOsList: action.payload.data.result,
                };
            case GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_FAIL:
                return {
                    ...state,
                    myCalOsList: [],
                };

            default:
                return state;
        }
    }
}

export const readDayPlanningShortCutPaneData = () => {
    return {
        types: [
            LOAD_DAY_PLANNINNG_SHORTCUTPANE,
            LOAD_DAY_PLANNINNG_SHORTCUTPANE_SUCCESS,
            LOAD_DAY_PLANNINNG_SHORTCUTPANE_FAIL,
        ],
        payload: {request: {url: READ_DAY_PLANNINNG_SHORTCUT_PANE_EP}},
    };
};

export const readDayPlanningDropDownData = () => {
    return {
        types: [
            LOAD_DAY_PLANNINNG_DROPDOWN,
            LOAD_DAY_PLANNINNG_DROPDOWN_SUCCESS,
            LOAD_DAY_PLANNINNG_DROPDOWN_FAIL,
        ],
        payload: {request: {method: 'GET', url: READ_DAY_PLANNINNG_DROPDOWN_EP}},
    };
};

export const filterDayPlanningPmolListPaneData = (filter: POFilter) => {
    return {
        types: [
            LOAD_DAY_PLANNINNG_PMOL_LSITPANE,
            LOAD_DAY_PLANNINNG_PMOL_LSITPANE_SUCCESS,
            LOAD_DAY_PLANNINNG_PMOL_LSITPANE_FAIL,
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_DAY_PLANNINNG_PMOL_LIST_EP,
                data: filter,
            },
        },
    };
};

export const filterDayPlanningTeamsData = (filter: DayPlanningList) => {
    return {
        types: [
            LOAD_DAY_PLANNINNG_TEAMS_LIST,
            LOAD_DAY_PLANNINNG_TEAMS_LIST_SUCCESS,
            LOAD_DAY_PLANNINNG_TEAMS_LIST_FAIL,
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_DAY_PLANNINNG_TEAMS_LIST_EP,
                data: filter,
            },
        },
    };
};

export const filterDayPlanningResourceListPaneData = (filter: POFilter) => {
    return {
        types: [
            LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE,
            LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS,
            LOAD_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL,
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_DAY_PLANNINNG_REOSURCE_LIST_EP,
                data: filter,
            },
        },
    };
};


export const readByDayPlanningId = (id: string) => {
    return {
        types: [
            LOAD_DAY_PLANNINNG_BY_ID,
            LOAD_DAY_PLANNINNG_BY_ID_SUCCESS,
            LOAD_DAY_PLANNINNG_BY_ID_FAIL],
        payload: {request: {url: READ_DAY_PLANNINNG_BY_ID_EP + id}},
    };
};

export const addWorkersAndVehicleToPmol = (data: any) => {
    return {
        types: [
            DND_ITEM_TO_PMOL,
            DND_ITEM_TO_PMOL_SUCCESS,
            DND_ITEM_TO_PMOL_FAIL],
        payload: {
            request: {
                method: 'POST',
                url: DND_ITEM_TO_PMOL_EP,
                data: data,
            },
        },
    };
};

export const resetIsChange = () => {
    return {type: RESET_DAY_PLANNINNG_IS_CHANGE};
};

export const setUid = () => {
    return {type: SET_DAY_PLANNINNG_UID};
};

export const setSelectedGridType = (selectedGridType: SelectedGridType) => {
    return {type: SELECTED_GRID_TYPE, selectedGridType: selectedGridType};
};

export const clearFormData = () => {
    return {type: CLEAR_DAY_PLANNINNG_FORM_DATA};
};

export function saveDayPlanningStateAttr(attr: string, value: any) {
    return {
        type: SAVE_DAY_PLANNING_STATE_ATTR,
        [attr]: value
    };
}

export const readMyCalendarPbsTaxonomy = (projectSequenceCode: string) => {
    return {
        types: [
            READ_MY_CALENDER_PBS_TAXONOMY,
            READ_MY_CALENDER_PBS_TAXONOMY_SUCCESS,
            READ_MY_CALENDER_PBS_TAXONOMY_FAIL,
        ],
        payload: {
            request: {
                method: 'GET',
                url: READ_MY_CALENDER_PBS_TAXONOMY_EP + projectSequenceCode
            }
        },
    };
};

export const createMyCalendarNewPmol = (data: any) => {
    return {
        types: [
            CREATE_MY_CALENDAR_NEW_PMOL,
            CREATE_MY_CALENDAR_NEW_PMOL_SUCCESS,
            CREATE_MY_CALENDAR_NEW_PMOL_FAIL,
        ],
        payload: {
            request: {
                method: 'POST',
                url: CREATE_MY_CALENDAR_NEW_PMOL_EP,
                data: data
            }
        },
    };
};

export const getMyCalOrgList = (data: any) => {
    return {
        types: [
            GET_MY_CALENDAR_ORGANIZATION_TAXONOMY,
            GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_SUCCESS,
            GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_FAIL,
        ],
        payload: {
            request: {
                method: 'POST',
                url: GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_EP,
                data: data
            }
        },
    };
};
