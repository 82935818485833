import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../../services/messageService';
import { WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';

const WBSCreateCmdBarRight = ({ isTemplate, wbsTaxonomyLevel }: any) => {
  const { t } = useTranslation();
  let cmdBarItems: any[] = [];

  const getItems = () => {
    const newWbs = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        messageService.sendMessage({ isNewWbs: true });
      },
      disabled: false,
    };

    const reNew = {
      key: 'reNew',
      text: t('reNew'),
      iconProps: { iconName: 'Assign' },
      onClick: () => {
        messageService.sendMessage({ reNewWbsCreate: true });
      },
      disabled: false,
    };

    const saveWbsProduct = {
      key: 'saveWbsProduct',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        messageService.sendMessage({ saveWbsProduct: true });
      },
      disabled: false,
    };

    const saveWbsTask = {
      key: 'saveWbsProduct',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        messageService.sendMessage({ saveWbsTask: true });
      },
      disabled: false,
    };

    const deleteWbs = {
      key: 'delete',
      text: t('delete'),
      iconProps: { iconName: 'Delete' },
      onClick: () => {
        messageService.sendMessage({ deleteWbsCreate: true });
      },
      disabled: false,
    };

    const addTopNode = {
      key: 'topNode',
      text: t('addTopNode'),
      iconProps: { iconName: 'StepSharedAdd' },
      onClick: () => {
        messageService.sendMessage({ addWbsTopNode: true });
      },
      disabled: false,
    };

    const renameTemp = {
      key: 'renameTemp',
      text: t('renameTemplate'),
      iconProps: { iconName: 'Rename' },
      onClick: () => {
        messageService.sendMessage({ renameTemplate: true });
      },
      disabled: false,
    };

    if (isTemplate) {
      if (wbsTaxonomyLevel === WBS_TAXONOMY_LEVEL.TASK) {
        cmdBarItems.push(saveWbsTask);
      } else if (wbsTaxonomyLevel === WBS_TAXONOMY_LEVEL.WBS) {
        cmdBarItems.push(addTopNode);
        cmdBarItems.push(renameTemp);
      } else {
        // cmdBarItems.push(newWbs);
        // cmdBarItems.push(reNew);
        cmdBarItems.push(saveWbsProduct);
        cmdBarItems.push(deleteWbs);
      }
    } else {
      cmdBarItems.push(addTopNode);
      cmdBarItems.push(renameTemp);
    }

    return cmdBarItems;
  };

  return <div>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
  </div>;
};

export default WBSCreateCmdBarRight;
