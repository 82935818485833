import * as React from 'react';
import { useEffect } from 'react';
import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  TableColumnDefinition,
} from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import { getContractingUnit, getProject } from '../../../shared/util';
import { ParamTypes } from '../../../types/uPrince';
import { useParams } from 'react-router';
import i18n from 'i18next';
import { readWbsIssueList, saveWbsIssueStateAttr } from '../../../reducers/wbsIssueReducer';
import { WbsIssueListPaneFilter } from '../../../types/wbsIssue';

// type FileCell = {
//   label: string;
//   icon: JSX.Element;
// };

type Item = {
  id: string,
  title: string,
  productId: string,
  status: string,
  responsible: string,
  priority: string,
  severity: string,
};

const columnSizingOptions = {
  title: {
    defaultWidth: 280,
    minWidth: 180,
    idealWidth: 280,
  },
  product: {
    minWidth: 100,
    defaultWidth: 120,
  },
  status: {
    minWidth: 120,
    defaultWidth: 140,
  },
  responsible: {
    minWidth: 100,
    defaultWidth: 120,
  },
  priority: {
    minWidth: 100,
    defaultWidth: 120,
  },
  severity: {
    minWidth: 100,
    defaultWidth: 120,
  },
};

export const WbsIssueListComponent = ({ isCollapsed }: any) => {
  const dispatch = useDispatch();
  const { featureId }: ParamTypes = useParams();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});

  const wbsIssueList = useSelector((state: any) => state.wbsIssue.wbsIssueList);
  const reloadListPane = useSelector((state: any) => state.wbsIssue.reloadListPane);
  const wbsIssueFilter = useSelector((state: any) => state.wbsIssue.wbsIssueFilter);

  useEffect(() => {
    dispatch(readWbsIssueList(new WbsIssueListPaneFilter()));

    return () => {
      console.log('unmounting wbs issue list pane');
      dispatch(saveWbsIssueStateAttr('reloadListPane', false));
      dispatch(saveWbsIssueStateAttr('wbsIssueFilter', new WbsIssueListPaneFilter()));
    };
  }, []);

  useEffect(() => {
    reloadListPane && refreshListPane();
  }, [reloadListPane]);

  useEffect(() => {
    dispatch(readWbsIssueList(wbsIssueFilter));
  }, [wbsIssueFilter]);

  const handleSelectionChange = (e: any, data: any) => {
    const featureId = Array.from(data.selectedItems)[0];
    history.push(`/CU/${getContractingUnit()}/project/${getProject()}/wbs/issue/${featureId}`);
  };

  const handleSortChange = (e: any, sortState: any) => {
    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
      ...wbsIssueFilter,
      sorter: {
        attribute: sortState?.sortColumn ?? null,
        order: sortState?.sortDirection === 'ascending' ? 'asc' : 'desc',
      },
    }));
  };

  // const handleFilterChange = (filter: WbsCreateListPaneFilter) => {
  //   // console.log(filter)
  //   if (filter.title !== '') {
  //     setFilter((prevState: any) => ({
  //       ...prevState,
  //       title: filter.title ?? null,
  //     }));
  //     dispatch(readWbsCreateListData(filter));
  //   }
  // };

  const refreshListPane = () => {
    dispatch(readWbsIssueList(new WbsIssueListPaneFilter()));
  };

  const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: 'title',
      compare: (a, b) => {
        return a?.title?.localeCompare(b?.title);
      },
      renderHeaderCell: () => {
        return `${i18n.t('title')}`;
      },
      renderCell: (item) => {
        return (
          <span>{item?.title ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'product',
      compare: (a, b) => {
        return a?.productId?.localeCompare(b?.productId);
      },
      renderHeaderCell: () => {
        return `${i18n.t('product')}`;
      },
      renderCell: (item) => {
        return (
          <span>{item?.productId ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'status',
      compare: (a, b) => {
        return a?.status?.localeCompare(b?.status);
      },
      renderHeaderCell: () => {
        return `${i18n.t('status')}`;
      },
      renderCell: (item) => {
        return (
          <span>{item?.status ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'responsible',
      compare: (a, b) => {
        return a?.responsible?.localeCompare(b?.responsible);
      },
      renderHeaderCell: () => {
        return `${i18n.t('responsible')}`;
      },
      renderCell: (item) => {
        return (
          <span>{item?.responsible ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'priority',
      compare: (a, b) => {
        return a?.priority?.localeCompare(b?.priority);
      },
      renderHeaderCell: () => {
        return `${i18n.t('priority')}`;
      },
      renderCell: (item) => {
        return (
          <span>{item?.priority ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'severity',
      compare: (a, b) => {
        return a?.severity?.localeCompare(b?.severity);
      },
      renderHeaderCell: () => {
        return `${i18n.t('severity')}`;
      },
      renderCell: (item) => {
        return (
          <span>{item?.severity ?? '-'}</span>
        );
      },
    }),
  ];

  const resizeColumns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: 'title',
      compare: (a, b) => {
        return a?.title?.localeCompare(b?.title);
      },
      renderHeaderCell: () => {
        return `${i18n.t('title')}`;
      },
      renderCell: (item) => {
        return (
          <span>{item?.title ?? '-'}</span>
        );
      },
    }),
  ];

  return (
    <div style={{ height: '100%' }}>
      <DataGrid
        items={wbsIssueList ?? []}
        columns={isCollapsed ? columns : resizeColumns}
        sortable
        getRowId={(item) => item?.id}
        selectionMode='single'
        resizableColumns
        columnSizingOptions={columnSizingOptions}
        size='small'
        onSelectionChange={handleSelectionChange}
        onSortChange={handleSortChange}
        selectedItems={featureId ? [featureId] : []}
      >
        <DataGridHeader>
          <DataGridRow selectionCell={{ 'aria-label': 'Select all rows' }}>
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <Menu openOnContext>
                  <MenuTrigger>
                    <DataGridHeaderCell
                      ref={(el) => (refMap.current[columnId] = el)}
                    >
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                          columnId,
                        )}
                      >
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item>
              key={rowId}
              selectionCell={{ 'aria-label': 'Select row' }}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  );
};
