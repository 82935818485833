import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ActionButton,
  Checkbox,
  Dropdown,
  getTheme,
  IBasePickerSuggestionsProps,
  IIconProps,
  IPersona,
  IPersonaProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  MaskedTextField,
  mergeStyleSets,
  NormalPeoplePicker,
  Separator,
  Stack,
  TextField,
} from '@fluentui/react';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import {uPrinceTheme} from '../../../../theme';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import TaxonomyField from '../../billOfResources/product/taxonomy';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import TextEditor from '../../../shared/textEditor/textEditor';
import client from '../../../api';
import _ from 'lodash';
import {
  BOR_READ_BY_PRODUCT_DATA,
  GET_FOREMAN_MOBILE_EP,
  GET_PROJECT_FOREMAN_BY_NAME_EP,
} from '../../../shared/endpoints';
import CustomSearchDropdown from '../../../shared/customSearchDropdown/customSearchDropdown';
import {messageService} from '../../../services/messageService';
import {
  PENDING_DEVELOPMENT_STATUS_ID,
  PMOL_TYPE,
  ProjectMolecule,
  ProjectMoleculeValidationMessage,
} from '../../../types/projectMolecule';
import {formatDateTimeToISOString, isPlanning, maskFormat} from '../../../shared/util';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {savePMOLStateAttr} from '../../../reducers/pmolReducer';

const PMOL_TYPE_WORK = "5bb656-f708-4a0d-9973-3d834ffe757d01"

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackItemStylesColThree: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const theme = getTheme();
const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
});


const ProjectMoleculerHeader = (props: {
  isEdit: boolean,
  dropDownOptions: any,
  formData: ProjectMolecule;
  saveFormData: () => void;
  handleFormChange: (
    formData: any,
    save: boolean,
  ) => void;
  validationMessages: ProjectMoleculeValidationMessage;
  borStatusDropdowns: any;
  isLoading: boolean;
  isDisabled: boolean;
  pmolType: PMOL_TYPE
}) => {
  const { t } = useTranslation();
  const [foreman, setForeman] = useState<IPersona[]>([]);
  const [vpPmolTitle, setPMOLTitle] = useState<string|null>(null);
  const [pmolType, setPMOLType] = useState<PMOL_TYPE>(PMOL_TYPE.REGULAR);
  const [bor, setBor] = useState<null | {}>(null);
  const borForMPOL = useSelector((state: any) => state.vp.selectedBORforPMOL);
  const pbsForPMOL = useSelector((state: any) => state.vp.slectedPBSForPMOL);
  const pmolTitle = useSelector((state: any) => state.vp.pmolInheritedBORTitle);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.formData.foremanId) {
      const item = { key: props.formData.foremanId, text: props.formData.foreman };
      setForeman([item]);
    } else {
      setForeman([]);
    }
    if (props.formData.bor && props.formData.bor.id) {
      const borSelectedItem = { value: props.formData.bor.itemId, label: props.formData.bor.borTitle };
      setBor(borSelectedItem);
    } else {
      setBor(null);
    }
    if (!props.formData.statusId) {
      props.formData.statusId = PENDING_DEVELOPMENT_STATUS_ID;
    }
  }, [props.formData]);

  useEffect(() => {
    if (pbsForPMOL) {
     
      if (vpPmolTitle !== pmolTitle) {
        setPMOLTitle(pmolTitle)
        props.formData.productTitle = pbsForPMOL;
        props.formData.productId = pbsForPMOL;
        props.formData.name = pmolTitle;
        props.handleFormChange(
          props.formData,
          true,
        );
      }
    }
  }, [pbsForPMOL]);

  useEffect(() => {
    if (borForMPOL) {
      client.get(`Bor/ReadBor/${borForMPOL}`).then((res) => {
        isPlanning() ? props.formData.typeId = PMOL_TYPE_WORK : props.formData.typeId = null;
        props.formData.bor.borTitle = res.data.result.borTitle;
        props.formData.bor.itemId = res.data.result.itemId;
        props.formData.productTitle = res.data.result.product.title;
        props.formData.productId = res.data.result.product.productId;
        props.formData.name = pmolTitle;
        setBor({
          value: res.data.result.itemId,
          label: res.data.result.borTitle,
        });
        props.handleFormChange(
          props.formData,
          false,
        );
        onChangeBor({
          value: res.data.result.itemId,
          pbsId: res.data.result.product.productId,
        });
      });
    }

  }, [borForMPOL]);

  useEffect(() => {
    setPMOLType(props.pmolType);
    if (location.pathname.split('/').pop() === 'new') {
      productOptions('');
    }
  }, [props.pmolType]);

  useEffect(() => {
    
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPmol && props.formData.bor) {
          props.formData.bor.borTitle = null;
          props.formData.bor.itemId = null;
          if (props.formData.bor.product) {
            props.formData.bor.product.locationTaxonomyParentId = null;
            props.formData.bor.product.utilityTaxonomyParentId = null;
          }
          props.formData.bor.borStatusId = null;
          setBor(null);
        }
      }
    });
    return () => {
        // Anything in here is fired on component unmount.
        console.log('PMOL HEADER UNMOUNTED')
        subscription.unsubscribe()
        dispatch(savePMOLStateAttr("pmolCreatedFromVP",false));
        dispatch(savePMOLStateAttr("pmolCreatedVP",null))
    }
}, [])


  const saveFormData = () => {
    messageService.sendMessage({ savePmolFormData: true });
  };

  const productOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      // test();
      resolve(getProductByName(inputValue));
      // }, 1000);
    });

  const getProductByName = async (name: string) => {
    const filter = { title: name, type: props.pmolType };
    if (location.pathname.split('/').pop() === 'new') {
      if (name && name.length >= 2) {
        const response = await client.post('/PbsFilter/PbsFilterHasBor', filter);
        return formatProductResponse(response);
      } else if (name === '' || name === null) {
        const response = await client.post('/PbsFilter/PbsFilterHasBor', { title: null, type: props.pmolType });
        return formatProductResponse(response);
      }
    }
  };

  const formatProductResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const titleNotEmptyList = response.data.result.filter((item: { text: string | null; }) => item.text !== null);
        const data = titleNotEmptyList.map((item: any) => {
          return {
            value: item.key,
            label: item.text,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  const promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getBorListByName(inputValue));
      // }, 1000);
    });

  const onChangeBor = (item: any) => {
    if (item) {
      messageService.sendMessage({ selectedBor: item });
    } else {
      messageService.sendMessage({ isBorCleared: true });
    }
  };

  const getPBSService = (item: any) => {
    if (item) {
      messageService.sendMessage({ selectedSuBPBS: item });
    } else {
      messageService.sendMessage({ isPBSCleared: true });
    }
  };

  const getBorListByName = async (name: string) => {
    if (location.pathname.split('/').pop() === 'new') {
      const filter = {
        borTitle: name,
        product: props.formData.productId,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      };
      const response = await client.post(
        BOR_READ_BY_PRODUCT_DATA, filter,
      );
      return formatCompanyResponse(response);
    }
  };

  const formatCompanyResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.itemId,
          label: item.borTitle,
          productId: item.productId,
          pbsId: item.pbsId,
        };
      });
    }
    return data;
  };


  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  const onForemanSelected = (item: any) => {
    if (item) {
      // let foremanMobileNumber:string=(mobileNo)?mobileNo:"";
      setForeman([item]);
      props.formData.foremanId = item.key;
      props.formData.foreman = item.text;
      props.handleFormChange(
        props.formData,
        true,
      );
      getPersonForemanMobile(item.key).then((mobile: any) => {
        props.formData.foremanMobileNumber = mobile;
        props.handleFormChange(
          props.formData,
          true,
        );
      });
    }
    return item;
  };

  const onForemanFilterPersonChanged = (
    filterText: string, selectedItems?: IPersonaProps[] | undefined,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return getPersonByNameForForeman(filterText);
    }
    return [];
  };

  const getPersonByNameForForeman = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    const response = await client.post(
      GET_PROJECT_FOREMAN_BY_NAME_EP, { fullName: name },
    );
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result;
    }
    return data;
  };

  const getPersonForemanMobile = async (id: string) => {
    let mobileNo: string;
    const response = await client.get(
      GET_FOREMAN_MOBILE_EP + id,
    );
    if (response && response.data && response.data.result) {
      mobileNo = response.data.result;
      return mobileNo;
    }
    return '';
  };

  function clearExecutionTimes() {
    props.formData.executionEndTime == undefined
  }

  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="project-moleculer-header">
          <Label>1. {t('projectMoleculerheader')}</Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">

          <div className="marginTop">
            {props.formData.projectMoleculeId && <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('title')}
                    value={(props.formData.title) ? props.formData.title : ''}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('projectMoleculeId')}
                    value={(props.formData.projectMoleculeId) ? props.formData.projectMoleculeId : ''}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>}
          </div>


          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    required={true}
                    label={t('name')}
                    value={(props.formData.name) ? props.formData.name : pmolTitle}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.name = value;
                      } else {
                        props.formData.name = null;
                      }
                      props.validationMessages.nameErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        false,
                      );
                    }}
                    errorMessage={
                      props.validationMessages.nameErrorMsg
                        ? props.validationMessages.nameErrorMsg
                        : ''
                    }
                    disabled={props.isDisabled}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    required={true}
                    placeholder={t('type')}
                    label={t('type')}
                    options={props.dropDownOptions.types}
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.typeId = value.key.toString();
                      } else {
                        props.formData.typeId = null;
                      }
                      props.validationMessages.typeErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}

                    selectedKey={props.formData.typeId ? props.formData.typeId : isPlanning() ? PMOL_TYPE_WORK : ''}
                    disabled={props.isDisabled}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>


          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  {props.isEdit || props.formData.isSaved ?
                    <div>
                      <TextField
                        label={t('productTitle')}
                        value={(props.formData.productId && props.formData.productTitle) ? props.formData.productTitle : ''}
                        readOnly={true}
                        disabled={props.isDisabled}
                      />
                    </div>
                    :
                    <div>
                      <Label required={true}>{t('productTitle')}</Label>
                      <CustomDropdown
                        reference={''}
                        //key={ props.pmolType }
                        // onChange={()=> props.handleVendorChange(props.vendor)}
                        onChange={(key: string, text: string) => {
                          if (key) {
                            // if(props.formData.productId){
                            props.formData.productTitle = text;
                            props.formData.productId = key;
                            // }else{
                            //   // let product=new Product();
                            //   // product.productId=key;
                            //   // product.productTitle=text;
                            //   props.formData.productId=null;
                            //   props.formData.productTitle=null;
                            // }
                            props.validationMessages.productTitleErrorMsg = '';
                          } else {
                            props.formData.productId = null;
                            props.formData.productTitle = null;
                            props.formData.bor.borTitle = null;
                            props.formData.bor.itemId = null;
                            props.formData.bor.product.locationTaxonomyParentId = null;
                            props.formData.bor.product.utilityTaxonomyParentId = null;
                            props.formData.bor.borStatusId = null;
                            setBor(null);
                          }
                          props.handleFormChange(
                            props.formData,
                            true,
                          );
                          if (props.pmolType === PMOL_TYPE.SUB) {
                            getPBSService(key);
                          }
                        }}
                        onFocus={() => {
                          //productOptions('00');
                        }}
                        selectedOption={(props.formData && props.formData.productId) ? {
                          value: props.formData.productId,
                          label: props.formData.productTitle,
                        } : (pbsForPMOL ? {
                          value: pbsForPMOL,
                          label: pbsForPMOL,
                        } : null)}
                        // promiseOptions={ pmolType === PMOL_TYPE.REGULAR ? productOptions : productOptions }
                        promiseOptions={productOptions}
                        validationMessage={props.validationMessages.productTitleErrorMsg}
                        required={true}
                      />
                    </div>
                  }


                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>

                </div>
              </Stack.Item>
            </Stack>
          </div>

          {props.pmolType === PMOL_TYPE.REGULAR &&
          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  {props.isEdit || props.formData.isSaved || (props.formData.productId === null && !borForMPOL) ?
                    <div>
                      <TextField
                        label={t('borTitle')}
                        value={(props.formData.bor && props.formData.bor.borTitle) ? props.formData.bor.borTitle : ''}
                        readOnly={true}
                        disabled={props.isDisabled}
                      />
                    </div>
                    :
                    <div>
                      <Label required={true}>{t('borTitle')}</Label>
                      <CustomSearchDropdown
                        // onChange={()=> props.handleVendorChange(props.vendor)}
                        onChange={(item: any) => {
                          if (item) {
                            props.formData.bor.borTitle = item.label;
                            props.formData.bor.itemId = item.value;
                            setBor(item);
                          } else {
                            props.formData.bor.borTitle = null;
                            props.formData.bor.itemId = null;
                            props.formData.bor.product.locationTaxonomyParentId = null;
                            props.formData.bor.product.utilityTaxonomyParentId = null;
                            props.formData.bor.borStatusId = null;
                            setBor(null);
                          }
                          onChangeBor(item);
                          props.validationMessages.borErrorMsg = '';
                          props.handleFormChange(props.formData, false);
                        }}
                        // selectedOption={(props.formData.bor.itemId)?{
                        //   value: props.formData.bor.itemId ,
                        //   label: props.formData.bor.borTitle ,}:null}
                        selectedOption={(bor) ? bor : null}
                        promiseOptions={promiseOptions}
                        validationMessage={props.validationMessages.borErrorMsg
                          ? props.validationMessages.borErrorMsg
                          : ''}
                        required={true}
                      />
                    </div>
                  }


                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Dropdown
                    placeholder={t('bORStatus')}
                    label={t('bORStatus')}
                    options={props.dropDownOptions?.status}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.bor.borStatusId = value.key.toString();
                      }
                      props.validationMessages.typeErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    selectedKey={
                      props.formData.bor?.borStatusId
                        ? props.formData.bor?.borStatusId
                        : ''
                    }
                    disabled={props.isDisabled}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
          }

          <div className="marginTop" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <TaxonomyField
              label={t('utilityTaxonomy')}
              treeData={props.dropDownOptions.utilityTaxonomy}
              selectItemId={props.formData.bor?.product
                ? props.formData.bor?.product.utilityTaxonomyParentId
                : ''}
            />
          </div>

          <div className="marginTop" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <TaxonomyField
              label={t('locationTaxonomy')}
              treeData={props.dropDownOptions.locationTaxonomy}
              selectItemId={props.formData.bor?.product
                ? props.formData.bor?.product?.locationTaxonomyParentId
                : ''}
            />
          </div>


          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStylesColThree}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    setMinDate={null}
                    setMaxDate={null}
                    isClearable={!props.isDisabled}
                    label={t('executiondate')}
                    setValue={(props.formData.executionDate) ? new Date(props.formData.executionDate) : null}
                    getValue={(date: Date) => {
                      const currentDate = props.formData.executionDate ? new Date(props.formData.executionDate) : null;
                      if (moment(date).format('DD/MM/YYYY') == moment(currentDate).format('DD/MM/YYYY')) {
                        props.formData.executionDate = null;
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      } else {
                        props.formData.executionDate = formatDateTimeToISOString(date);
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      }
                    }}
                    disabled={props.isDisabled}
                    onDateClear={
                      () => {
                        props.formData.executionStartTime = null,
                        props.formData.executionEndTime = null
                      }
                    }
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={2} align="end" styles={stackItemStylesColThree}>
                <div style={{ width: 80, marginLeft: 47 }}>
                  <MaskedTextField
                    label={t('startTime')}
                    value={props.formData.executionStartTime ? props.formData.executionStartTime : ''}
                    onChange={(e: any, newValue?: string) => {
                      const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
                      if (timeValidation && newValue) {
                        props.formData.executionStartTime = newValue;
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      } else {
                        props.formData.executionStartTime = null;
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      }
                    }}
                    mask="HH:mM"
                    maskFormat={maskFormat}
                    maskChar="_"
                    disabled={props.isDisabled}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={2} styles={stackItemStylesColThree}>
                <div style={{ width: 80 }}>
                  <MaskedTextField
                    label={t('endTime')}
                    value={props.formData.executionEndTime ? props.formData.executionEndTime : ''}
                    onChange={(e: any, newValue?: string) => {
                      const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
                      if (timeValidation && newValue) {
                        props.formData.executionEndTime = newValue;
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      } else {
                        props.formData.executionEndTime = null;
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      }
                    }}
                    mask="HH:mM"
                    maskFormat={maskFormat}
                    maskChar="_"
                    disabled={props.isDisabled}
                  />
                </div>
              </Stack.Item>
            </Stack>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStylesColThree}>
                <div className={`${classNames.fullWidth}`}>
                  <Dropdown
                    required={false}
                    placeholder={t('status')}
                    label={t('status')}
                    options={props.dropDownOptions.status}
                    errorMessage={
                      props.validationMessages.statusErrorMsg
                        ? props.validationMessages.statusErrorMsg
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.statusId = value.key.toString();
                      } else {
                        props.formData.statusId = null;
                      }
                      props.validationMessages.statusErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    isDisabled={true}
                    selectedKey={
                      props.formData.statusId
                        ? props.formData.statusId
                        : PENDING_DEVELOPMENT_STATUS_ID
                    }
                    disabled={true}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} align="end" styles={stackItemStylesColThree}>
                {props.pmolType === PMOL_TYPE.SUB &&
                <div className={`${classNames.fullWidth}`} style={{ marginTop: 30 }}>
                  <Checkbox
                    label={t('finished')}
                    disabled={true}
                    checked={props.formData.isFinished}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.isFinished = value;
                      } else {
                        props.formData.isFinished = false;
                      }
                      props.handleFormChange(props.formData, true);
                    }}
                  />
                </div>}
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}
                     style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                  <Label>{t('foreman')}</Label>
                  <NormalPeoplePicker
                    onChange={(items) => {
                      if (items && _.isArray(items) && items.length === 0) {
                        setForeman([]);
                        props.formData.foremanId = null;
                        props.formData.foremanMobileNumber = null;
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      }
                    }}
                    selectedItems={
                      foreman && foreman[0] && foreman[0] ? foreman : []
                    }
                    onResolveSuggestions={onForemanFilterPersonChanged}
                    onItemSelected={onForemanSelected}
                    pickerSuggestionsProps={limitedSearchAdditionalProps}
                    className={'ms-PeoplePicker'}
                    key={'foreman'}
                    itemLimit={1}
                    removeButtonAriaLabel={'Remove'}
                    resolveDelay={300}
                    disabled={props.isDisabled}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    readOnly={true}
                    label={t('foremanmobilenumber')}
                    value={(props.formData.foremanMobileNumber) ? props.formData.foremanMobileNumber : ''}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.foremanMobileNumber = value;
                      } else {
                        props.formData.foremanMobileNumber = null;
                      }
                      props.handleFormChange(
                        props.formData,
                        false,
                      );
                    }}
                    disabled={props.isDisabled}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className={`marginTop marginBottom ${classNames.textEditor}`} style={{height:'max-content', overflow:'auto'}} >
            {/* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={12} styles={stackItemStyles}> */}
                <div className={`${classNames.fullWidth}`} style={{height:'max-content', overflow:'auto'}}>
                  <Label>{t('comments')}</Label>
                  <TextEditor
                    readOnly={props.isDisabled}
                    value={
                      props.formData.comment
                        ? props.formData.comment
                        : ''
                    }
                    onChange={(value) => {
                      if (!props.isDisabled) {
                        if (value) {
                          props.formData.comment = value;
                        } else {
                          props.formData.comment = null;
                        }
                      }
                      // props.handleFormChange(
                      //   props.formData,
                      //   false
                      // );
                    }}/>

                </div>

              {/* </Stack.Item>
            </Stack> */}
          </div>

        </div>
      </div>

      <div className={'card-footer'}>
        <Separator/>
        <Stack
          styles={stackStyles}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                saveFormData();
              }}
              disabled={props.isLoading || props.isDisabled}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>

    </div>
  );
};

export default ProjectMoleculerHeader;