import {Person} from './addressBookItem';

export interface ShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
}

export interface ProductItemType {
  id: string | null;
  name: string | null;
  localeCode: string | null;
}
export interface ProductStatus {
  id: string | null;
  name: string | null;
  localeCode: string | null;
}
export interface ToleranceState {
  id: string | null;
  name: string | null;
  localeCode: string | null;
}

export interface ListPaneItem {
  key: string | null;
  id: string | null;
  productId: string | null;
  name: string | null;
  title: string | null;
  headerTitle: string | null;
  pbsProductItemTypeId: string | null;
  pbsProductItemType: string | null;
  pbsProductStatusId: string | null;
  pbsProductStatus: string | null;
  pbsToleranceStateId: string | null;
  pbsToleranceState: string | null;
}

export interface PbsRisk {
  pbsProductId:string|null,
  riskId:string|null,
}

export interface PbsInstruction {
  id: string | null;
  pbsProductId: string | null;
  instructionsId: string | null;
}
export interface PbsQuality {
  pbsProductId:string|null,
  qualityId:string|null,
}

export class Risk {
  id: string | null = null;
  name: string | null = null;
  riskDetails: string | null = null;
  riskTypeId: string | null = null;
  cabPersonId: string | null = null;
  riskStatusId: string | null = null;
  sequenceCode?: string | null = null;
  person?: Person | null = null;
  title: string | null = null;
  riskType?: RiskType | null = null;
  riskStatus?: RiskStatus | null = null;
  pbsRiskId?:string|null = null
}

export class ReadRisk {
  id: string | null = null;
  name: string | null = null;
  riskDetails: string | null = null;
  riskTypeId: string | null = null;
  cabPersonId: string | null = null;
  riskStatusId: string | null = null;
  sequenceCode?: string | null = null;
  title: string | null = null;
  headerTitle: string | null = null;
  riskType?: RiskType | null = null;
  person?: Person | null = null;
  riskStatus?: RiskStatus | null = null;
  pbsRiskId:string|null = null;
  riskId: string|null = null;
}

export interface RiskDropDown {
  riskStatus: RiskStatus[];
  riskTypes: RiskType[];
}

export interface RiskType {
  key: string;
  text: string;
}

export interface RiskStatus {
  key: string;
  text: string;
}

export interface FilterItem {
  title: string | null;
  pbsProductItemTypeId: string | null;
  qualityProducerCompany?: string | null;
  qualityProducerName?: string | null;
  qualityProducerId?: string | null;
  pbsProductStatusId: string | null;
  pbsToleranceStateId: string | null;
  scope: number | string | null;
  taxonomy: number | string | null;
  sorter: {
    attribute: string | null;
    order: string | null;
  };
}

export class RiskFilter {
  title: string | null = null;
  typeId: string | null = null;
  stateId: string | null = null;
  sortingModel: SortingModel = new SortingModel();
}

class SortingModel {
  attribute: string | null = null;
  order: string | null = null;
}

export class QualityFilter {
  title: string | null = null;
  sortingModel: SortingModel = new SortingModel();
}

export class Quality {
  criteria: string | null = null;
  tolerance: string | null = null;
  method: string | null = null;
  skills: string | null = null;
  id: string | null = null;
  sequenceCode: string | null = null;
  title: string | null = null;
  headerTitle: string | null = null;
  name: string | null = null;
  isDeleted: boolean = false;
  isSaved: boolean = false;
  pbsQualityId: string | null = null;
  uid: string | null = null;
}

export interface PbsQualityResponsibility {
  id:string|null,
  qualityProducerId:string|null,
  qualityReviewerId:string|null,
  qualityApproverId:string|null,

}

export interface PbsHistory {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export interface ProjectBreakdown {
  id: string | null;
  title: string | null;
  headerTitle: string | null;
  productId: string | null;
  projectId: string | null;
  name: string | null;
  pbsProductItemTypeId: string | null;
  pbsProductStatusId: string | null;
  pbsToleranceStateId: string | null;
  scope: string | null;
  contract: string | null;
  productPurpose: string | null;
  productComposition: string | null;
  productDerivation: string | null;
  productFormatPresentation: string | null;
  historyLog: PbsHistory | null;
  locationTaxonomyParentId: string | null;
  utilityTaxonomyParentId: string | null;
  machineTaxonomyParentId: string | null;
  parentId: string | null;
  pbsQualityResponsibilityId:string|null,
  qualityProducer:string|null,
  qualityProducerId:string|null,
  qualityReviewer:string|null,
  qualityReviewerId:string|null;
  qualityApprover:string|null;
  qualityApproverId:string|null;
  isSaved:boolean;
  pbsType:PBS_TYPE;
  startDate:string | null;
  endDate:string | null;
}

export interface ProjectBreakdownValidationMessage {
  nameErrorMsg: string | null;
  contractErrorMsg: string | null;
  ItemTypeErrorMsg: string | null;
  statusErrorMsg: string | null;
  toleranceStateErrorMsg: string | null;
  mouErrorMsg: string | null;
}

export class Resource {
  resourceId: string| null =null;
  pbsProductId: string | null = null;
  coperateProductCatalogId: string | null = null;
  quantity: number  = 0;
  sequenceCode: string | null = null;
  cpcText: string | null = null;
  cpcKey: string | null = null;
  resourceFamilyTitle: string|null = null
  unit: string|null = null
  cpcTitle: string|null = null
  environment: string|null = null
  consolidatedQuantity?: number = 0
}

export class CreatePbsMaterial {
  pbsProductId: string|null = null;
  coperateProductCatalogId: string|null = null;
  quantity: number = 0;
}

export class Instruction {
  instructionsDetails: string|null =  null;
  instructionType?: string|null = null;
  pbsProduct?: string|null =  null;
  // pbsProductId: string |null = null
  pbsInstructionFamily: PbsInstructionFamily = new PbsInstructionFamily();
  pbsInstructionLink: PbsInstructionLink[] = [];
  id: string|null = null;
  sequenceCode: string|null = null;
  title: string|null = null;
  headerTitle: string|null =  null;
  name: string|null = null;
  instructionId: string|null = null;
  isDeleted: boolean|null = null;
  isSaved: boolean= false;
  //instructionFamilyId: string|null = null
  pbsInstructionFamilyId:string|null = null
}

export class PbsInstructionLink {
  id: string|null = null;
  title: string|null = null;
  value: string|null = null;
  link?:string|null = null;
  pbsInstructionId: string|null = null;
  type:string|null = null
}

export class PbsInstructionFamily {
  key: string|null = null;
  text: string|null = null;
  // id: string|null = null;
  // family: string|null = null;

}

export interface InstructionDropDown {
  key:string,
  text:string
}

export class InstructionLink {
  type:any = null
  value:string|null =  null
  title:any = null
  id?:any = null
  pbsInstructionId?:any = null
  link:string|null = null;
}


export class Competence{
  id: string | null=null;
  pbsProductId: string | null=null;
  skillId: string | null=null;
  skill: string | null=null;
  experienceId: string | null=null ;
  experience: string | null=null ;
}

export interface PBSService {
  projectTitle: string|null;
  productTitle: string|null;
  quantity:string|null;
  mou:string|null;
  unitPrice:string|null;
  totalPrice:string|null;
  documents:string[]|null;
  comments:string|null;
}

export enum PBS_TYPE {
  REGULAR = 'regular',
  SUB = 'sub'
}

export const PRODUCT_ITEM_TYPE_SUB_CONTRACT_ID = "48a7dd9c-55ac-4e7c-a2f3-653811c0eb14"
export const PRODUCT_ITEM_TYPE_REG_CONTRACT_ID = "aa0c8e3c-f716-4f92-afee-851d485164da"
export const PRODUCT_TAXONOMY_SHORT_CUT_PANE_ID = "5"
export const MOU_SQUARE_METER = "88885800-91fd-4d99-8010-921a42f08888"

