import React, { useEffect, useRef, useState } from 'react';
import { ViewMode } from '../../../shared/ganttv2';
import { Label } from 'office-ui-fabric-react';
import { ViewModeContext } from '../productTaxonomyListPane/component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectTeamCapacityData } from '../../../reducers/visualPlanReducer';
import {
  DirectionalHint,
  FontIcon,
  IComboBoxOption,
  ITooltipHostStyles,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import { getBuDropdownData } from '../../../reducers/projectDayPlanningReducer';
import { store } from '../../../../index';
import { getBuGroups } from '../../../types/myDayPlanning';
import moment from 'moment/moment';
import { ViewSwitcher } from './view-switcher';
import { TableExpandProvider, useTableExpand } from '../../../hooks/useTableExpandContext';
import i18next from 'i18next';
import i18n from 'i18next';

const ROW_HEIGHT = 36;

const weekendColor = '#4f7fef33';

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const getToolTipContent = (projectName: string[] | undefined): React.ReactElement | undefined => {
  if (projectName && projectName.length > 0) {
    return (
      <ul style={{ margin: 2, padding: 0, fontSize: 14 }}>
        {projectName.map((project, index) => (
          <li key={index}>{project}</li>
        ))}
      </ul>
    );
  }
  return undefined;
};

const TableRow = ({ index, cuName, mainValues, weeks }: any) => {
  const [expanded, setExpanded] = useState(false);
  const { isAllExpanded } = useTableExpand();

  useEffect(() => {
    if (isAllExpanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [isAllExpanded]);

  return (
    <>
      <tr
        style={{ height: ROW_HEIGHT, border: '1px solid #BFC9CA' }}
        key={index}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <th style={{
          minWidth: '30vw',
          backgroundColor: '#F3F2F1',
          height: ROW_HEIGHT,
          borderRight: '1px solid #BFC9CA',
          padding: 0,
        }}>
          <div style={{ width: 'calc(100% - 5px)', display: 'flex', flexDirection: 'row', marginLeft: 5, padding: 0, height: 'inherit', borderRight: '1px solid #BFC9CA' }}>
            <FontIcon
              aria-label='ChevronRight'
              style={{ marginTop: 10, cursor: 'pointer' }}
              iconName={expanded ? 'ChevronDown' : 'ChevronRight'}
            />
            <Label style={{ marginTop: 4, marginLeft: 10, fontSize: 14 }}>{cuName}</Label>
          </div>
        </th>

        {/*In Week ViewMode, weeks is an object, not an array*/}
        {Object.entries(weeks)?.map(([]: any, childIndex: number) => (
          <td
            colSpan={7}
            key={childIndex}
            style={{
              backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
              minWidth: 60,
              height: ROW_HEIGHT,
              textAlignLast: 'end',
              paddingRight: 10,
              border: '1px solid #BFC9CA',
              fontFamily: 'Segoe UI',
              fontSize: 14,
            }}
          >
            {/*cu empty rows*/}
          </td>
        ))}
      </tr>
      {expanded && (
        <>
          {mainValues && mainValues?.bu?.map((e: any, index: number) => {
            return <TableRowChild key={index} index={index} buName={e.buTitle} mainValues={e} weeks={weeks} />;
          })}
        </>
      )}
    </>
  );
};

const TableRowChild = ({ index, buName, mainValues, weeks }: any) => {
  const [expandedChild, setExpandedChild] = useState(false);
  const { isAllExpanded } = useTableExpand();

  useEffect(() => {
    if (isAllExpanded) {
      setExpandedChild(true);
    } else {
      setExpandedChild(false);
    }
  }, [isAllExpanded]);

  return (
    <>
      <tr
        style={{ height: ROW_HEIGHT, border: '1px solid #BFC9CA' }}
        key={index}
        onClick={() => {
          setExpandedChild(!expandedChild);
        }}
      >
        <th style={{
          minWidth: 60,
          backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
          height: ROW_HEIGHT,
          borderRight: '1px solid #BFC9CA',
          padding: 0,
        }}>
          <div style={{ width: 'calc(100% - 5px)', display: 'flex', flexDirection: 'row', marginLeft: 5, padding: 0, height: 'inherit', borderRight: '1px solid #BFC9CA' }}>
            <FontIcon
              aria-label='ChevronRight'
              style={{ marginLeft: 10, marginTop: 10, cursor: 'pointer' }}
              iconName={expandedChild ? 'ChevronDown' : 'ChevronRight'}
            />
            <Label style={{ marginTop: 4, marginLeft: 10, fontSize: 14 }}>{buName}</Label>
          </div>
        </th>

        {Object.entries(weeks)?.map(([]: any, childIndex: number) => (
          <td
            colSpan={7}
            key={childIndex}
            style={{
              backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
              minWidth: 60,
              height: ROW_HEIGHT,
              textAlignLast: 'end',
              paddingRight: 10,
              border: '1px solid #BFC9CA',
              fontFamily: 'Segoe UI',
              fontSize: 14,
            }}
          >
            {/*bu empty rows*/}
          </td>
        ))}

      </tr>
      {expandedChild && (
        <>
          {mainValues && mainValues?.team?.map((e: any, index: number) => {
            return <TableRowChildTeam key={index} index={index} teamTitle={e.teamTitle} mainValues={e} weeks={weeks} />;
          })}
        </>
      )}
    </>
  );
};

function TableRowChildTeam({ index, teamTitle, mainValues, weeks }: any) {
  const [expandedChildTeam, setExpandedChildTeam] = useState(false);
  const { isAllExpanded } = useTableExpand();

  useEffect(() => {
    if (isAllExpanded) {
      setExpandedChildTeam(true);
    } else {
      setExpandedChildTeam(false);
    }
  }, [isAllExpanded]);

  return (
    <>
      <tr
        style={{ height: ROW_HEIGHT, border: '1px solid #BFC9CA', backgroundColor: '#F3F2F1' }}
        key={index}
        onClick={() => {
          setExpandedChildTeam(!expandedChildTeam);
        }}
      >
        <th style={{
          minWidth: 60,
          backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
          height: ROW_HEIGHT,
          borderRight: '1px solid #BFC9CA',
          padding: 0,
        }}>
          <div style={{ width: 'calc(100% - 25px)', display: 'flex', flexDirection: 'row', marginLeft: 25, padding: 0, height: 'inherit', borderRight: '1px solid #BFC9CA' }}>
            <FontIcon
              aria-label='ChevronRight'
              style={{ marginLeft: 20, marginTop: 10, cursor: 'pointer' }}
              iconName={expandedChildTeam ? 'ChevronDown' : 'ChevronRight'}
            />
            <Label style={{ marginTop: 3, marginLeft: 10, fontSize: 14 }}>{teamTitle}</Label>
          </div>
        </th>

        {Object.entries(weeks)?.map(([]: any, childIndex: number) => (
          <td
            colSpan={7}
            key={childIndex}
            style={{
              backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
              minWidth: 60,
              height: ROW_HEIGHT,
              textAlignLast: 'end',
              paddingRight: 10,
              border: '1px solid #BFC9CA',
              fontFamily: 'Segoe UI',
              fontSize: 14,
            }}
          >
            {/*team empty rows*/}
          </td>
        ))}

      </tr>
      {expandedChildTeam && (
        <>
          {mainValues?.person?.map((e: any, index: number) => {
            return (
              <tr
                className='expandRowMT'
                style={{
                  minWidth: 150,
                  height: ROW_HEIGHT,
                  border: '1px solid #BFC9CA',
                  backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
                }}
                key={index}
              >
                <td style={{
                  minWidth: 60,
                  backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
                  height: ROW_HEIGHT,
                  borderRight: '1px solid #BFC9CA',
                  padding: 0,
                }}>
                  {/*Person Names*/}
                  <Label style={{ width: 'calc(100% - 79px)', marginLeft: 80, fontSize: 14, borderRight: '1px solid #BFC9CA' }}>{e?.personName}</Label>
                </td>
                {e?.project?.map((projectName: any, index: number) => {
                    return (
                      <td
                        key={index}
                        style={{
                          background: (index + 1) % 7 === 0 || (index + 2) % 7 === 0 ? weekendColor : undefined,
                          textAlignLast: 'end',
                          paddingRight: 10,
                          maxWidth: '0.1vw',
                          border: '1px solid #BFC9CA',
                          maxHeight: ROW_HEIGHT,
                        }}
                      >
                        <TooltipHost
                          delay={TooltipDelay.zero}
                          directionalHint={DirectionalHint.topCenter}
                          styles={hostStyles}
                          content={getToolTipContent(projectName)}
                        >
                          <Label style={{ height: ROW_HEIGHT, padding: 0, marginLeft: 5, fontSize: 12, overflow: 'hidden' }}>
                            {projectName.length === 1 ? (
                              projectName[0]
                            ) : projectName.length > 0 ? (
                              projectName.length + ' ' + i18next.t('projects')
                            ) : undefined}
                          </Label>
                        </TooltipHost>

                      </td>
                    );
                  },
                )}
              </tr>
            );
          })}
        </>
      )}
    </>
  );
}

function ProjectTeamCapacityTable(props: { matrixType: string, height: number }) {
  const projectTeamCapacityData: any = useSelector((state: any) => state.vp.projectTeamCapacityData);
  const [isChecked, setIsChecked] = React.useState(true);
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);
  const [weeks, setWeeks] = React.useState<any[]>([]);
  const [resourceData, setResourceData] = React.useState<any>([]);
  const [weeklyGroupedDatesArray, setWeeklyGroupedDatesArray] = React.useState<any>([]);
  const {
    selectedBuInCuProjectPlanning,
    projectLevelReloadRequired,
    productTaxonomyReloadRequired,
  } = useSelector((state: any) => state.vp);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const matrixTableRef = useRef(null);

  const optionsForMePP: IComboBoxOption[] = [
    {
      key: ViewMode.Day,
      text: t('day'),
    },
    {
      key: ViewMode.Week,
      text: t('week'),
    },
    {
      key: ViewMode.Month,
      text: t('month'),
    },
  ];

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  const filterData = {
    title: null,
    contractingUnit: store.getState().uprince?.contractingUinit,
    businessUnit: getBuGroups(),
  };


  const lastWeekStartDate = moment().subtract(1, 'weeks').startOf('week').format('MM/DD/YYYY');
  const nextTwoWeeksLastDay = moment().add(2, 'weeks').endOf('week').format('MM/DD/YYYY');
  const lastMonthStartDate = moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
  const nextTwoMonthsLastDay = moment().add(2, 'months').endOf('month').format('MM/DD/YYYY');

  const datePayload = {
    startDate: view == ViewMode.Month ? lastMonthStartDate : lastWeekStartDate,
    endDate: view == ViewMode.Month ? nextTwoMonthsLastDay : nextTwoWeeksLastDay,
    type: view,
  };


  useEffect(() => {
    Promise.all([
      dispatch(getBuDropdownData(filterData)),
    ]).then(() => {
      if (props.matrixType === '500') {
        dispatch(getProjectTeamCapacityData(datePayload));
      } else {
        dispatch(getProjectTeamCapacityData(datePayload));
      }
    });

    return () => {
    };
  }, [props.matrixType, view, selectedBuInCuProjectPlanning, projectLevelReloadRequired, productTaxonomyReloadRequired]);

  useEffect(() => {
    if (projectTeamCapacityData) {

      let { cu, days, week } = projectTeamCapacityData;

      // Week and Month data comes with null days array
      if (!days) {
        days = week;
      }

      setResourceData(cu);

      const groupedArray = [];
      for (let i = 0; i < week?.length; i++) {
        const startIdx = i * 7;
        const endIdx = startIdx + 7;
        const weekDays = days?.slice(startIdx, endIdx);

        groupedArray.push({
          week: week[i],
          days: weekDays,
        });
      }

      setWeeklyGroupedDatesArray(groupedArray);
      setWeeks(week ?? days);


      // Scrolling table to current week
      // const currentWeekWithW = `W${moment().week()} `;
      // const currentWeekColumnIndex = week.findIndex((element: string | string[]) => element.includes(currentWeekWithW));
      //
      // const table: any = matrixTableRef.current;
      // const scrollValue = (currentWeekColumnIndex - 1) * 260;
      //
      // Promise.all([
      //   table.scrollLeft += -(52 * 260),
      // ]).then(() => {
      //   table.scrollLeft += scrollValue;
      // });
    }
  }, [projectTeamCapacityData]);


  return (
    <div
      className={'matrixTable'}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ViewModeContext.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
        }}
      >
        <TableExpandProvider>
          <ViewSwitcher
            onViewModeChange={(viewMode: ViewMode) => {
              onViewModeChange(viewMode);
            }}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            renderFilterComponent={() => <Label></Label>}
            option={optionsForMePP}
          />

          <div ref={matrixTableRef} id={'matrix-table-table'}
               style={{ width: '89vw', height: props.height - 96, overflow: 'overlay' }}>
            <table
              style={{
                borderCollapse: 'collapse',
                width: 'min-content',
                padding: 20,
                tableLayout: 'auto',
              }}
            >
              <thead>
              {view == ViewMode.Day && <tr style={{position: 'sticky', top: 0, zIndex: 10}}>
                <th style={{ minWidth: '28vw', height: ROW_HEIGHT, padding: 0 }}>
                  <div id={'matrix-table-table-header'} style={{
                    width: '100%',
                    height: 'inherit',
                    background: 'white',
                    borderRight: '1px solid #BFC9CA',
                    marginTop: -1,
                  }}></div>
                </th>
                {weeks && weeks?.map((weekNumber: any) => {
                  return <th colSpan={7} style={{
                    background: 'white',
                    border: '1px solid #BFC9CA',
                    verticalAlign: 'middle',
                  }}>
                    <Label style={{ fontSize: 14 }}>
                      {weekNumber}
                    </Label>
                  </th>;
                })}
              </tr>}

              <tr style={{
                position: 'sticky',
                top: view == ViewMode.Day ? ROW_HEIGHT : undefined,
                height: 2,
                border: '1px solid #BFC9CA',
                zIndex: 10,
              }}>
                <th style={{ minWidth: '28vw', height: ROW_HEIGHT, padding: 0 }}>
                  <div id={'matrix-table-table-header'} style={{ width: '100%', height: 'inherit', background: 'white', borderRight: '1px solid #BFC9CA' }}></div>
                </th>
                {
                  weeklyGroupedDatesArray && weeklyGroupedDatesArray?.map((e: any, index: number) => {
                    return (
                      <th key={index} colSpan={7} style={{
                        // background: '#724519',
                        // border: '1px solid #BFC9CA',
                        verticalAlign: 'middle',
                        padding: 0,
                        height: 'inherit'
                      }}>
                        {Object.entries(e.days).map(([key, dateAndName], index) => (
                          <th
                            key={index}
                            style={{
                              background: index === 5 || index === 6 ? weekendColor : 'white',
                              minWidth: view == ViewMode.Day ? '3.8vw' : '14.3vw',
                              width: '8vw',
                              verticalAlign: 'middle',
                              border: '1px solid #BFC9CA',
                              padding: 0,
                              height: ROW_HEIGHT,
                            }}
                          >
                            {view == ViewMode.Day ?
                              <>
                                <Label style={{ fontSize: 12, padding: 0 }}>
                                  {dateAndName && moment(dateAndName, 'ddd-MMM DD').format('ddd')}
                                </Label>
                                <Label style={{ fontSize: 12, padding: 0 }}>
                                  {dateAndName && moment(dateAndName).format('MMM DD')}
                                </Label>
                              </> :
                              <Label style={{ fontSize: 12 }}>
                                {dateAndName}
                              </Label>}
                          </th>
                        ))}

                      </th>
                    );
                  })
                }

              </tr>
              </thead>
              <tbody>
              {/*Data table*/}
              {resourceData && resourceData?.map((e: any, index: number) => {
                return <TableRow key={index} index={index} cuName={e?.cuTitle} mainValues={e}
                                 weeks={view == ViewMode.Day ? weeks : { weeks }} />;
              })}
              </tbody>
            </table>
          </div>
        </TableExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
}

export default ProjectTeamCapacityTable;
