import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  PrimaryButton,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { uPrinceTheme } from '../../../theme';
import i18n from '../../../i18n';
import SortableTree, { getTreeFromFlatData, toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import { getPerentFromTreeData } from '../util';

const theme = getTheme();

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};


// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
};

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const editWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const editIconWhite: IIconProps = {
  iconName: 'Edit',
  styles: editWhiteIconButtonStyles,
};

/**
 * Giving a height to the previous wrapper is important here
 *
 * TaxonomyGrid
 * @param props
 * @constructor
 */
const DndTaxonomyGrid = (props: {
  treeData: any;
  renderButtons?: any
  canDrag?: any
  canDrop?: any
  onChange?: any
  onMoveNode?: any
  selectedId?:any
}) => {
  const { t } = useTranslation();
  const [treeData, setTreeData]: any = useState([]);
  const [searchString, setSearchString]: any = useState('');
  const [searchFocusIndex, setSearchFocusIndex]: any = useState(0);
  const [searchFoundCount, setSearchFoundCount]: any = useState(null);


  useEffect(()=>{
    console.log("DataChange",props.treeData);
  },[props.treeData])


  const expandSelectedNodes=()=>{
    if(props?.selectedId){
      let defaultOpenItems=getPerentFromTreeData(props.treeData,props?.selectedId )
      return   props.treeData.map((node:any) => ({
        ...node,
        expanded:defaultOpenItems.includes(node?.id),
      }));
    }else{
      return props.treeData
    }
  }

  useEffect(() => {
    const tData = getTreeFromFlatData({
      flatData: expandSelectedNodes(),
      getKey: (node: any) => node.id,
      getParentKey: (node: any) => node.parentId,
      // @ts-ignore
      rootKey: null,
    });
    setTreeData(tData);
  }, [props.treeData]);

  const expand = (expanded: any) => {
    setTreeData(
      toggleExpandedForAll({
        treeData: treeData,
        expanded,
      }),
    );
  };


  const expandAll = () => {
    expand(true);
  };

  const collapseAll = () => {
    expand(false);
  };

  const handleTreeOnSearch = (searchString: string) => {
    setSearchString(searchString);
  };

  const selectPrevMatch = () => {
    let searchFoundCountVal = 0;
    let searchFocusIndexValue = 0;
    if (typeof searchFoundCount === 'number') {
      searchFoundCountVal = searchFoundCount;
    }
    if (typeof searchFocusIndex === 'number') {
      searchFocusIndexValue = searchFocusIndex;
    }

    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCountVal + searchFocusIndexValue - 1) %
        searchFoundCountVal
        : searchFoundCountVal - 1,
    );
  };


  // Case insensitive search of `node.title`
  const customSearchMethod = ({ node, searchQuery }: any) => {
    return (
      searchQuery &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  };


  const selectNextMatch = () => {
    let searchFoundCountVal = 0;
    if (typeof searchFoundCount === 'number') {
      searchFoundCountVal = searchFoundCount;
    }

    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFocusIndex + 1) % searchFoundCountVal
        : 0,
    );
  };

  const onChange=(treeData:any)=>{
    setTreeData(treeData)
    if(props?.onChange){
      props?.onChange(treeData)
    }
  }
  const onMoveNode=(treeData:any)=>{
    if(props?.onMoveNode){
      props?.onMoveNode(treeData)
    }

  }

  return (
    <div
      style={{
        height: '100%',
        marginBottom: 10,
      }}
      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
    >
      <div className="row">
        <Stack horizontal styles={stackStyles} tokens={stackTokens}>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={expandAll}>
              {t('expandAll')}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={collapseAll}>
              {t('collapseAll')}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={3} styles={stackItemStyles}>
            <TextField
              value={searchString}
              placeholder={i18n.t('search')}
              onChange={(event, value) => {
                if (value) {
                  handleTreeOnSearch(value);
                } else {
                  handleTreeOnSearch('');
                }
              }}
            />
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton
              style={{ minWidth: 25 }}
              disabled={!searchFoundCount}
              onClick={selectPrevMatch}
            >
              {' '}
              &lt;
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton
              style={{ minWidth: 25 }}
              disabled={!searchFoundCount}
              onClick={selectNextMatch}
            >
              &gt;
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <span style={{ marginTop: 6 }}>
              &nbsp;
              {searchFoundCount
                ? searchFoundCount > 0
                  ? searchFocusIndex + 1
                  : 0
                : 0}
              &nbsp;/&nbsp;
              {searchFoundCount || 0}
            </span>
          </Stack.Item>
        </Stack>
      </div>
      <label htmlFor="find-box"></label>
      <div className={"taxonomy-grid"} style={{height: "calc(100% - 60px)"}}>
        <SortableTree
          canDrag={props?.canDrag}
          canDrop={props?.canDrop}
          searchQuery={searchString}
          searchMethod={customSearchMethod}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={(matches) => {
            setSearchFoundCount(matches.length);
            setSearchFocusIndex(
              matches.length > 0 ? searchFocusIndex % matches.length : 0,
            );
          }}
          // isVirtualized={true}
          treeData={treeData}
          onChange={(treeData) => onChange(treeData)}
          onMoveNode={(treeData) => onMoveNode(treeData)}
          onlyExpandSearchedNodes={true}
          generateNodeProps={(rowInfo) => {
            const { path } = rowInfo;
            const node: any = rowInfo?.node;
            return {
              buttons: [
                // eslint-disable-next-line react/jsx-key
                <div style={{display: 'flex'}}>
                  {props.renderButtons && props.renderButtons(rowInfo)}
                </div>,
                // eslint-disable-next-line react/jsx-key

              ],
              onClick: () => {
                // handleNodeClick(rowInfo);
              },
            };
          }}
        />
      </div>
    </div>
  );
};

export default DndTaxonomyGrid;
