import * as React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { messageService } from '../../../services/messageService';
import WbsIssueDocumentPane from '../../wbsIssue/documentPane/WbsIssueDocumentPane';
import { Stack } from '@fluentui/react';
import { useSingleAndDoubleClick } from '../../../hooks/useSingleAndDoubleClick';

interface Props {
  isAddNew: any;
}

const CreateIssueDocumentPane = (props: Props) => {
  const { t } = useTranslation();
  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const getItems = () => {
    return [{
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: useSingleAndDoubleClick(
        () => messageService.sendMessage({ saveWbsIssue: true }),
        () => messageService.sendMessage({ saveWbsIssue: true })),
      disabled: false,
    }];
  };

  return (
    <>
      <Stack style={{ flexGrow: 1, borderLeft: '1px solid #ccc3c3' }}>
        <Stack
          className='project-list-header new-design'
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            paddingRight: 0,
          }}
          styles={{ root: { width: '100%', paddingRight: 0 } }}
        >
          <CommandBar
            styles={{ root: { height: 26 } }}
            items={getItems()}
            ariaLabel='Use left and right arrow keys to navigate between commands'
          />
          <ProgressBar show={showWbsProgressBar} />
        </Stack>

        <Stack className={`main-layout-document-pane-stack`}>
          <div style={{ width: '100%', padding: 0 }}>
            <WbsIssueDocumentPane isNew={props.isAddNew} />
          </div>
        </Stack>
      </Stack>
    </>
  );

};

export default CreateIssueDocumentPane;
