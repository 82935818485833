import {format24hDateTime} from '../shared/util';
import _ from 'lodash';
import {
  APPROVED_PMOL_EP,
  CLONE_PMOL_EP,
  CREATE_LOCATION_EP,
  CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_EP,
  DELETE_PMOL_CONSUMABLE_EP,
  DELETE_PMOL_LABOUR_TEAM_MEMBERS_EP,
  DELETE_PMOL_LABOURS_EP,
  DELETE_PMOL_MATERIALS_EP,
  DELETE_PMOL_TOOLS_EP,
  FINISHED_PMOL_EP,
  getPMOLInstructionByType,
  getPMOLRiskesByPBSId,
  LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_EP,
  LOAD_PMOL_LABOUR_TEAM_MEMBERS_EP,
  PBS_READ_SERVICE,
  PMOL_CREATE_SERVICE,
  PMOL_GET_PMOL_JOURNAL,
  PMOL_LOCATION_EP,
  PMOL_SAVE_EP,
  PMOL_SAVE_JOURNAL,
  READ_ALL_INSTRUCTIONS,
  READ_PMOL_BY_ID,
  READ_PMOL_BY_ID_EP,
  READ_PMOL_CONSUMABLE_EP,
  READ_PMOL_DATA_EP,
  READ_PMOL_DROPDOWN_EP,
  READ_PMOL_EXTRA_WORK_CONSUMABLE_EP,
  READ_PMOL_EXTRA_WORK_EP,
  READ_PMOL_EXTRA_WORK_LABOURS_EP,
  READ_PMOL_EXTRA_WORK_MATERIALS_EP,
  READ_PMOL_EXTRA_WORK_RESOURCES_EP,
  READ_PMOL_EXTRA_WORK_TOOLS_EP,
  READ_PMOL_LABOURS_EP,
  READ_PMOL_LIST_EP,
  READ_PMOL_LOCATION_EP,
  READ_PMOL_MATERIALS_EP,
  READ_PMOL_PDF_BY_ID,
  READ_PMOL_PLANNED_WORK_RESOURCES_EP,
  READ_PMOL_SHORTCUT_PANE_EP,
  READ_PMOL_TOOLS_EP,
  READ_RESOURCE_BY_BOR_ID_EP,
  READ_STOP_HANDSHAKE_EP,
  SAVE_CONSUMABLE_EP,
  SAVE_EXTRA_WORK_EP,
  SAVE_HANDSHAKE_DOCUMENTS_EP,
  SAVE_LABOURS_EP,
  SAVE_MATERIALS_EP,
  SAVE_TOOLS_EP,
  START_HANDSHAKE_EP,
  STOP_HANDSHAKE_EP,
  UPDATE_PMOL_LABOUR_END_TIME_EP,
  UPLOAD_SIGNATURE_EP
} from '../shared/endpoints';
import {
  APPROVED_PMOL,
  APPROVED_PMOL_FAIL,
  APPROVED_PMOL_SUCCESS,
  CLEAR_PMOL_FORM_DATA,
  CLEAR_PMOL_UID,
  CLONE_PMOL,
  CLONE_PMOL_FAIL,
  CLONE_PMOL_SUCCESS,
  CREATE_LOCATION,
  CREATE_LOCATION_FAIL,
  CREATE_LOCATION_SUCCESS,
  CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS,
  CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_FAIL,
  CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_SUCCESS,
  CREATE_PMOL_SERVICE,
  CREATE_PMOL_SERVICE_FAIL,
  CREATE_PMOL_SERVICE_SUCCESS,
  DELETE_PMOL_CONSUMABLE,
  DELETE_PMOL_CONSUMABLE_FAIL,
  DELETE_PMOL_CONSUMABLE_SUCCESS,
  DELETE_PMOL_LABOUR_TEAM_MEMBERS,
  DELETE_PMOL_LABOUR_TEAM_MEMBERS_FAIL,
  DELETE_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS,
  DELETE_PMOL_LABOURS,
  DELETE_PMOL_LABOURS_FAIL,
  DELETE_PMOL_LABOURS_SUCCESS,
  DELETE_PMOL_MATERIALS,
  DELETE_PMOL_MATERIALS_FAIL,
  DELETE_PMOL_MATERIALS_SUCCESS,
  DELETE_PMOL_TOOLS,
  DELETE_PMOL_TOOLS_FAIL,
  DELETE_PMOL_TOOLS_SUCCESS,
  FINISHED_PMOL,
  FINISHED_PMOL_FAIL,
  FINISHED_PMOL_SUCCESS,
  HIDE_PROGRESSBAR_PMOL,
  LOAD_LOCATION_BY_PROJECT,
  LOAD_LOCATION_BY_PROJECT_FAIL,
  LOAD_LOCATION_BY_PROJECT_SUCCESS,
  LOAD_PMOL_ALL_INSTRUCTION,
  LOAD_PMOL_ALL_INSTRUCTION_FAIL,
  LOAD_PMOL_ALL_INSTRUCTION_SUCCESS,
  LOAD_PMOL_BY_ID,
  LOAD_PMOL_BY_ID_FAIL,
  LOAD_PMOL_BY_ID_SUCCESS,
  LOAD_PMOL_CONSUMABLE,
  LOAD_PMOL_CONSUMABLE_FAIL,
  LOAD_PMOL_CONSUMABLE_SUCCESS,
  LOAD_PMOL_DATA,
  LOAD_PMOL_DATA_FAIL,
  LOAD_PMOL_DATA_SUCCESS,
  LOAD_PMOL_DETAILS,
  LOAD_PMOL_DETAILS_FAIL,
  LOAD_PMOL_DETAILS_SUCCESS,
  LOAD_PMOL_DROPDOWN,
  LOAD_PMOL_DROPDOWN_FAIL,
  LOAD_PMOL_DROPDOWN_SUCCESS,
  LOAD_PMOL_ENV_INSTRUCTION,
  LOAD_PMOL_ENV_INSTRUCTION_FAIL,
  LOAD_PMOL_ENV_INSTRUCTION_SUCCESS,
  LOAD_PMOL_EXTRA_WORK_CONSUMABLE,
  LOAD_PMOL_EXTRA_WORK_CONSUMABLE_FAIL,
  LOAD_PMOL_EXTRA_WORK_CONSUMABLE_SUCCESS,
  LOAD_PMOL_EXTRA_WORK_LABOURS,
  LOAD_PMOL_EXTRA_WORK_LABOURS_FAIL,
  LOAD_PMOL_EXTRA_WORK_LABOURS_SUCCESS,
  LOAD_PMOL_EXTRA_WORK_MATERIALS,
  LOAD_PMOL_EXTRA_WORK_MATERIALS_FAIL,
  LOAD_PMOL_EXTRA_WORK_MATERIALS_SUCCESS,
  LOAD_PMOL_EXTRA_WORK_RESOURCES,
  LOAD_PMOL_EXTRA_WORK_RESOURCES_FAIL,
  LOAD_PMOL_EXTRA_WORK_RESOURCES_SUCCESS,
  LOAD_PMOL_EXTRA_WORK_TOOLS,
  LOAD_PMOL_EXTRA_WORK_TOOLS_FAIL,
  LOAD_PMOL_EXTRA_WORK_TOOLS_SUCCESS,
  LOAD_PMOL_HELTH_INSTRUCTION,
  LOAD_PMOL_HELTH_INSTRUCTION_FAIL,
  LOAD_PMOL_HELTH_INSTRUCTION_SUCCESS,
  LOAD_PMOL_JOURNAL,
  LOAD_PMOL_JOURNAL_FAIL,
  LOAD_PMOL_JOURNAL_SUCCESS,
  LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS,
  LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_FAIL,
  LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_SUCCESS,
  LOAD_PMOL_LABOUR_TEAM_MEMBERS,
  LOAD_PMOL_LABOUR_TEAM_MEMBERS_FAIL,
  LOAD_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS,
  LOAD_PMOL_LABOURS,
  LOAD_PMOL_LABOURS_FAIL,
  LOAD_PMOL_LABOURS_SUCCESS,
  LOAD_PMOL_LSITPANE,
  LOAD_PMOL_LSITPANE_FAIL,
  LOAD_PMOL_LSITPANE_SUCCESS,
  LOAD_PMOL_MATERIALS,
  LOAD_PMOL_MATERIALS_FAIL,
  LOAD_PMOL_MATERIALS_SUCCESS,
  LOAD_PMOL_PDF_DETAILS,
  LOAD_PMOL_PDF_DETAILS_FAIL,
  LOAD_PMOL_PDF_DETAILS_SUCCESS,
  LOAD_PMOL_PLANNED_WORK_RESOURCES,
  LOAD_PMOL_PLANNED_WORK_RESOURCES_FAIL,
  LOAD_PMOL_PLANNED_WORK_RESOURCES_SUCCESS,
  LOAD_PMOL_QUALITY,
  LOAD_PMOL_QUALITY_FAIL,
  LOAD_PMOL_QUALITY_SUCCESS,
  LOAD_PMOL_SAFTY_INSTRUCTION,
  LOAD_PMOL_SAFTY_INSTRUCTION_FAIL,
  LOAD_PMOL_SAFTY_INSTRUCTION_SUCCESS,
  LOAD_PMOL_SHORTCUTPANE,
  LOAD_PMOL_SHORTCUTPANE_FAIL,
  LOAD_PMOL_SHORTCUTPANE_SUCCESS,
  LOAD_PMOL_TECH_INSTRUCTION,
  LOAD_PMOL_TECH_INSTRUCTION_FAIL,
  LOAD_PMOL_TECH_INSTRUCTION_SUCCESS,
  LOAD_PMOL_TOOLS,
  LOAD_PMOL_TOOLS_FAIL,
  LOAD_PMOL_TOOLS_SUCCESS,
  LOAD_RESOURCE_BY_BOR_ID,
  LOAD_RESOURCE_BY_BOR_ID_FAIL,
  LOAD_RESOURCE_BY_BOR_ID_SUCCESS,
  READ_PMOL_EXTRA_WORK,
  READ_PMOL_EXTRA_WORK_FAIL,
  READ_PMOL_EXTRA_WORK_SUCCESS,
  READ_PMOL_LOCATION,
  READ_PMOL_LOCATION_FAIL,
  READ_PMOL_LOCATION_SUCCESS,
  READ_PMOL_RISK,
  READ_PMOL_RISK_FAIL,
  READ_PMOL_RISK_SUCCESS,
  READ_PMOL_SERVICE,
  READ_PMOL_SERVICE_FAIL,
  READ_PMOL_SERVICE_SUCCESS,
  READ_STOP_HANDSHAKE,
  READ_STOP_HANDSHAKE_FAIL,
  READ_STOP_HANDSHAKE_SUCCESS,
  SAVE_CONSUMABLE,
  SAVE_CONSUMABLE_FAIL,
  SAVE_CONSUMABLE_SUCCESS,
  SAVE_EXTRA_WORK_CONSUMABLE,
  SAVE_EXTRA_WORK_CONSUMABLE_FAIL,
  SAVE_EXTRA_WORK_CONSUMABLE_SUCCESS,
  SAVE_EXTRA_WORK_LABOURS,
  SAVE_EXTRA_WORK_LABOURS_FAIL,
  SAVE_EXTRA_WORK_LABOURS_SUCCESS,
  SAVE_EXTRA_WORK_MATERIALS,
  SAVE_EXTRA_WORK_MATERIALS_FAIL,
  SAVE_EXTRA_WORK_MATERIALS_SUCCESS,
  SAVE_EXTRA_WORK_TOOLS,
  SAVE_EXTRA_WORK_TOOLS_FAIL,
  SAVE_EXTRA_WORK_TOOLS_SUCCESS,
  SAVE_HANDSHAKE_DOCS,
  SAVE_HANDSHAKE_DOCS_FAIL,
  SAVE_HANDSHAKE_DOCS_SUCCESS,
  SAVE_JOURNAL,
  SAVE_JOURNAL_FAIL,
  SAVE_JOURNAL_SUCCESS,
  SAVE_LABOURS,
  SAVE_LABOURS_FAIL,
  SAVE_LABOURS_SUCCESS,
  SAVE_MATERIALS,
  SAVE_MATERIALS_FAIL,
  SAVE_MATERIALS_SUCCESS,
  SAVE_PMOL,
  SAVE_PMOL_EXTRA_WORK,
  SAVE_PMOL_EXTRA_WORK_FAIL,
  SAVE_PMOL_EXTRA_WORK_SUCCESS,
  SAVE_PMOL_FAIL,
  SAVE_PMOL_STATE_ATTR,
  SAVE_PMOL_SUCCESS,
  SAVE_TOOLS,
  SAVE_TOOLS_FAIL,
  SAVE_TOOLS_SUCCESS,
  SET_EXTRA_WORK_FETCHED,
  SET_PLANNED_WORK_FETCHED,
  SET_PMOL_UID,
  SHOW_PROGRESSBAR_PMOL,
  START_HANDSHAKE,
  START_HANDSHAKE_FAIL,
  START_HANDSHAKE_SUCCESS,
  STOP_HANDSHAKE,
  STOP_HANDSHAKE_FAIL,
  STOP_HANDSHAKE_SUCCESS,
  UPDATE_PMOL_LABOUR_END_TIME,
  UPDATE_PMOL_LABOUR_END_TIME_FAIL,
  UPDATE_PMOL_LABOUR_END_TIME_SUCCESS,
  UPLOAD_SIGNATURE,
  UPLOAD_SIGNATURE_FAIL,
  UPLOAD_SIGNATURE_SUCCESS,
} from '../actionTypes/projectMoleculeActionTypes';
import {EndDateAndTime, ExtraWork, Journal, PMOLListPaneFilter, ProjectMolecule} from '../types/projectMolecule';
import {RESET_IS_CHANGE} from '../actionTypes/projectBreakdownType';
import {v4 as uuidv4} from 'uuid';
import {ListPaneItem} from '../../../src/app/types/projectMolecule';
import {LocationAddress} from '../types/projectDefinition';
import {Resource} from '../types/projectBreakdownStructure';

const defaultState: any = {
  pmolShortCutdata: [],
  listPaneItems: [],
  isPMOLListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  pmolDropDowns: {},
  formData: {},
  borResources: [],
  uid: null,
  readMaterialsStatus: false,
  createMaterialsStatus: false,
  materials: [],
  readToolsStatus: false,
  createToolsStatus: false,
  tools: [],
  readConsumableStatus: false,
  createConsumableStatus: false,
  consumables: [],
  readLabourStatus: false,
  createLabourStatus: false,
  labours: [],
  loadMsg: '',
  pmolLocation: null,
  pmolLocationId: null,
  signatureLink: '',
  stopHandshakeStatus: false,
  stopHandShakes: [],
  readExtraWorkMaterialsStatus: false,
  createExtraWorkMaterialsStatus: false,
  materialsExtraWork: [],
  readExtraWorkToolsStatus: false,
  createExtraWorkToolsStatus: false,
  toolsExtraWork: [],
  readExtraWorkConsumableStatus: false,
  createExtraWorkConsumableStatus: false,
  consumablesExtraWork: [],
  readExtraWorkLabourStatus: false,
  createExtraWorkLabourStatus: false,
  laboursExtraWork: [],
  readHandShakeStatus: false,
  journal: null,
  handshakeDocs: [],
  extraWork: {},
  readTechInstructionStatus: false,
  techInstructions: [],
  readSaftyInstructionStatus: false,
  saftyInstructions: [],
  readEnvInstructionStatus: false,
  envInstructions: [],
  readHelthInstructionStatus: false,
  helthInstructions: [],
  teamMembers: [],
  extraTeamMembers: [],
  locationFromPMOL: null,
  riskList: [],
  readRiskStatus: false,
  qualityList: [],
  readQualityStatus: false,
  pmolDropdownList: {},
  pmolType: [],
  pmolStatus: [],

  newPMOLCreated: false,
  instructionDropDowns: [],
  riskDropdowns: [],
  locationTaxonomy: [],
  utilityTaxonomy: [],
  userRoles: [],
  borStatus: [],
  handshakeDataFetched: false,
  plannedWorkFetched: false,
  extraWorkFetched: false,
  formDataPdf: {},
  service:null,
  readPMOLServiceStatus:false,
  loadApproveMsg:'',
  isEndTimeUpdated: false,
  createdVPPMOL:null,
  pmolCreatedVP: false,
  pmolCreatedFromVP:false,
  pmolUpdateFromVP:false,
  updatedVPPMOL:null,
  isPMOLOpenFromVP: true,
  deleteTeamMembersStatus: false,
  clonePmolId: null,
  selectedLabourResourceItem: {},
};

export default function PMOLReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_PMOL_SHORTCUTPANE:
        //UprinceLogger.log('LOAD_PMOL_SHORTCUTPANE', action);
        return { ...state };
      case LOAD_PMOL_SHORTCUTPANE_SUCCESS:
        //UprinceLogger.log('LOAD_PMOL_SHORTCUTPANE_SUCCESS', action);
        return {
          ...state,
          pmolShortCutdata: action.payload.data.result,
        };
      case LOAD_PMOL_SHORTCUTPANE_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PMOL_SHORTCUTPANE_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
        };

      case LOAD_PMOL_LSITPANE:
        // UprinceLogger.log('LOAD_PMOL_LSITPANE', action);
        return { ...state, isPMOLListLoaded: false };
      case LOAD_PMOL_LSITPANE_SUCCESS:
        // UprinceLogger.log('LOAD_PMOL_LSITPANE_SUCCESS', action);
        return {
          ...state,
          isChange: false,
          isPMOLListLoaded: true,
          listPaneItems: formattedPMOLList(action.payload.data.result),
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_PMOL_LSITPANE_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PMOL_LSITPANE_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          isPMOLListLoaded: true,
          listPaneItems: [],
          loadMsg: action.error.response.data.message,
        };

      case SAVE_JOURNAL:
        // UprinceLogger.log('SAVE_JOURNAL', action);
        return { ...state, isPMOLSaveJournal: false };
      case SAVE_JOURNAL_SUCCESS:
        // UprinceLogger.log('SAVE_JOURNAL_SUCCESS', action);
        return {
          ...state,
          isPMOLSaveJournal: true,
          saveJournalItems: action.payload.data.result,
        };
      case SAVE_JOURNAL_FAIL:
        // UprinceLogger.log(
        //   'SAVE_JOURNAL_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          isPMOLSaveJournal: true,
        };

      case RESET_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_PMOL_DROPDOWN:
        //UprinceLogger.log('LOAD_PMOL_DROPDOWN', action);
        return { ...state, pmolDropDowns: {} };
      case LOAD_PMOL_DROPDOWN_SUCCESS:
        // UprinceLogger.log('LOAD_PMOL_DROPDOWN_SUCCESS', action);
        return {
          ...state,
          pmolDropDowns: action.payload.data.result,
          pmolDropdownList: formattedPayloadToDropdownList(action.payload.data.result),
          pmolType: action.payload.data.result.type,
          pmolStatus: action.payload.data.result.status,
        };
      case LOAD_PMOL_DROPDOWN_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PMOL_DROPDOWN_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          pmolDropDowns: {},
        };

      case LOAD_PMOL_DATA:
        //UprinceLogger.log('LOAD_PMOL_DROPDOWN', action);
        return {
          ...state,
          instructionDropDowns: [],
          riskDropdowns: [],
          locationTaxonomy: [],
          utilityTaxonomy: [],
          userRoles: [],
          borStatus: [],
        };
      case LOAD_PMOL_DATA_SUCCESS:
        return {
          ...state,
          instructionDropDowns: action.payload.data.result ? action.payload.data.result.pbsInstructionsDropdown.pbsInstructionFamily : [],
          riskDropdowns: action.payload.data.result ? action.payload.data.result.riskDropdown : [],
          locationTaxonomy: action.payload.data.result ? action.payload.data.result.pbsTreeStructureDto.locationTaxonomy : [],
          utilityTaxonomy: action.payload.data.result ? action.payload.data.result.pbsTreeStructureDto.utilityTaxonomy : [],
          userRoles: action.payload.data.result ? formatUserRoleResponse(action.payload.data.result.roles) : [],
          borStatus: formattedPayloadToDropdown(
            action.payload.data.result ? action.payload.data.result.borDropdown.borStatus : [],
          ),
        };
      case LOAD_PMOL_DATA_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PMOL_DROPDOWN_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          instructionDropDowns: [],
          riskDropdowns: [],
          locationTaxonomy: [],
          utilityTaxonomy: [],
          userRoles: [],
          borStatus: [],
        };

      case LOAD_PMOL_BY_ID:
        //UprinceLogger.log('LOAD_PMOL_BY_ID', action);
        return { ...state, showProgressBar: true };
      case LOAD_PMOL_BY_ID_SUCCESS:
        //UprinceLogger.log('LOAD_PMOL_BY_ID_SUCCESS', action);
        return {
          ...state,
          isEdit: true,
          handshakeDataFetched: false,
          plannedWorkFetched: false,
          extraWorkFetched: false,
          formData: action.payload.data.result,
          showProgressBar: false,
          updatedVPPMOL: action.payload.data.result,
        };
      case LOAD_PMOL_BY_ID_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PMOL_BY_ID_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          formData: {},
          showProgressBar: false,
        };

      case SAVE_PMOL:
        return { ...state, showProgressBar: true, newPMOLCreated: false };
      case SAVE_PMOL_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          newPMOLCreated: true,

        };
      case SAVE_PMOL_FAIL:
        return {
          ...state,
          showProgressBar: false,
          newPMOLCreated: false,

        };

      case SET_PMOL_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_PMOL_UID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_PMOL_FORM_DATA:
        return {
          ...state,
          uid: uuidv4(),
          isEdit: false,
          isNew: true,
          isChange: false,
          formData: {},
          materials: [],
          tools: [],
          consumables: [],
          labours: [],
          techInstructions: [],
          saftyInstructions: [],
          envInstructions: [],
          helthInstructions: [],
          extraWork: {
            id: null,
            description: null,
            pmolId: null,
            imageList: [],
            extraWorkFiles: [],
            audioList: [],
          },
          stopHandShakes: [],
          handshakeDocs: [],
          journal: {
            doneWork: null,
            encounteredProblem: null,
            lessonsLearned: null,
            reportedThings: null,
            id: null,
            pmolId: null,
            pictureList: [],
          },
          riskList: [],
          qualityList: [],
          service: null,
          locationFromPMOL: null,
          location: null,
        };

      case LOAD_RESOURCE_BY_BOR_ID:
        return { ...state, borResources: [], showProgressBar: true };
      case LOAD_RESOURCE_BY_BOR_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          borResources: action.payload.data.result,
          showProgressBar: false,
        };
      case LOAD_RESOURCE_BY_BOR_ID_FAIL:
        return {
          ...state,
          borResources: [],
          showProgressBar: false,
        };

      case LOAD_PMOL_MATERIALS:
        return { ...state, readMaterialsStatus: true, materials: [] };
      case LOAD_PMOL_MATERIALS_SUCCESS:
        return {
          ...state,
          readMaterialsStatus: false,
          createMaterialsStatus: false,
          materials: action.payload.data.result,
        };
      case LOAD_PMOL_MATERIALS_FAIL:
        return {
          ...state,
          readMaterialsStatus: false,
        };

      case LOAD_PMOL_TOOLS:
        return { ...state, readToolsStatus: true, tools: [] };
      case LOAD_PMOL_TOOLS_SUCCESS:
        return {
          ...state,
          readToolsStatus: false,
          createToolsStatus: false,
          tools: action.payload.data.result,
        };
      case LOAD_PMOL_TOOLS_FAIL:
        return {
          ...state,
          readToolsStatus: false,
        };

      case LOAD_PMOL_CONSUMABLE:
        return { ...state, readConsumableStatus: true, consumables: [] };
      case LOAD_PMOL_CONSUMABLE_SUCCESS:
        return {
          ...state,
          readConsumableStatus: false,
          createConsumableStatus: false,
          consumables: action.payload.data.result,
        };
      case LOAD_PMOL_CONSUMABLE_FAIL:
        return {
          ...state,
          readConsumableStatus: false,
        };

      case LOAD_PMOL_LABOURS:
        return { ...state, readLabourStatus: true, labours: [] };
      case LOAD_PMOL_LABOURS_SUCCESS:
        return {
          ...state,
          readLabourStatus: false,
          createLabourStatus: false,
          labours: action.payload.data.result,
        };
      case LOAD_PMOL_LABOURS_FAIL:
        return {
          ...state,
          readLabourStatus: false,
        };
      case LOAD_LOCATION_BY_PROJECT:
        return { ...state, pmolLocation: null };
      case LOAD_LOCATION_BY_PROJECT_SUCCESS:
        return {
          ...state,
          pmolLocation: action.payload.data.result,
        };
      case LOAD_LOCATION_BY_PROJECT_FAIL:
        return {
          ...state,
          pmolLocation: null,
        };
      case CREATE_LOCATION:
        return { ...state, pmolLocationId: null };
      case CREATE_LOCATION_SUCCESS:
        return {
          ...state,
          pmolLocationId: action.payload.data.result,
        };
      case CREATE_LOCATION_FAIL:
        return {
          ...state,
          pmolLocationId: null,
        };
      case START_HANDSHAKE:
        return { ...state };
      case START_HANDSHAKE_SUCCESS:
        return {
          ...state,
        };
      case START_HANDSHAKE_FAIL:
        return {
          ...state,
        };

      case SAVE_MATERIALS:
        return { ...state, createMaterialsStatus: false };
      case SAVE_MATERIALS_SUCCESS:
        return {
          ...state,
          createMaterialsStatus: true,
        };
      case SAVE_MATERIALS_FAIL:
        return {
          ...state,
          createMaterialsStatus: false,
        };

      case SAVE_TOOLS:
        return { ...state, createToolsStatus: false };
      case SAVE_TOOLS_SUCCESS:
        return {
          ...state,
          createToolsStatus: true,
        };
      case SAVE_TOOLS_FAIL:
        return {
          ...state,
          createToolsStatus: false,
        };

      case SAVE_CONSUMABLE:
        return { ...state, createConsumableStatus: false };
      case SAVE_CONSUMABLE_SUCCESS:
        return {
          ...state,
          createConsumableStatus: true,
        };
      case SAVE_CONSUMABLE_FAIL:
        return {
          ...state,
          createConsumableStatus: false,
        };

      case SAVE_LABOURS:
        return { ...state, createLabourStatus: false };
      case SAVE_LABOURS_SUCCESS:
        return {
          ...state,
          createLabourStatus: true,
        };
      case SAVE_LABOURS_FAIL:
        return {
          ...state,
          createLabourStatus: false,
        };

      case UPLOAD_SIGNATURE:
        return { ...state, signatureLink: '' };
      case UPLOAD_SIGNATURE_SUCCESS:
        return {
          ...state,
          signatureLink: action.payload.data.result,
        };
      case UPLOAD_SIGNATURE_FAIL:
        return {
          ...state,
          signatureLink: '',
        };
      case STOP_HANDSHAKE:
        return { ...state, stopHandshakeStatus: false };
      case STOP_HANDSHAKE_SUCCESS:
        return {
          ...state,
          stopHandshakeStatus: true,
        };
      case STOP_HANDSHAKE_FAIL:
        return {
          ...state,
          stopHandshakeStatus: true,
        };
      case READ_STOP_HANDSHAKE:
        return { ...state, readHandShakeStatus: false };
      case READ_STOP_HANDSHAKE_SUCCESS:
        return {
          ...state,
          stopHandShakes: action.payload.data.result.stopHandshakes,
          handshakeDocs: formattedHanshakeDocs(action.payload.data.result.docLinks),
          readHandShakeStatus: true,
          plannedWorkFetched: true,

        };
      case READ_STOP_HANDSHAKE_FAIL:
        return {
          ...state, readHandShakeStatus: true,
        };
      case DELETE_PMOL_MATERIALS:
        return {
          ...state,
          createMaterialsStatus: false,
          createExtraWorkMaterialsStatus: false,
        };
      case DELETE_PMOL_MATERIALS_SUCCESS:
        return {
          ...state,
          createMaterialsStatus: true,
          createExtraWorkMaterialsStatus: true,
        };
      case DELETE_PMOL_MATERIALS_FAIL:
        return {
          ...state,
          createMaterialsStatus: false,
          createExtraWorkMaterialsStatus: false,
        };

      case DELETE_PMOL_TOOLS:
        return {
          ...state,
          createToolsStatus: false,
          createExtraWorkToolsStatus: false,
        };
      case DELETE_PMOL_TOOLS_SUCCESS:
        return {
          ...state,
          createToolsStatus: true,
          createExtraWorkToolsStatus: true,
        };
      case DELETE_PMOL_TOOLS_FAIL:
        return {
          ...state,
          createToolsStatus: false,
          createExtraWorkToolsStatus: false,
        };

      case DELETE_PMOL_CONSUMABLE:
        return {
          ...state,
          createConsumableStatus: false,
          createExtraWorkConsumableStatus: false,
        };
      case DELETE_PMOL_CONSUMABLE_SUCCESS:
        return {
          ...state,
          createConsumableStatus: true,
          createExtraWorkConsumableStatus: true,
        };
      case DELETE_PMOL_CONSUMABLE_FAIL:
        return {
          ...state,
          createConsumableStatus: false,
          createExtraWorkConsumableStatus: false,
        };

      case DELETE_PMOL_LABOURS:
        return {
          ...state,
          createLabourStatus: false,
          createExtraWorkLabourStatus: false,
          deleteTeamMembersStatus: false,
        };
      case DELETE_PMOL_LABOURS_SUCCESS:
        return {
          ...state,
          createLabourStatus: true,
          createExtraWorkLabourStatus: true,
          deleteTeamMembersStatus: true,
        };
      case DELETE_PMOL_LABOURS_FAIL:
        return {
          ...state,
          createLabourStatus: false,
          createExtraWorkLabourStatus: false,
          deleteTeamMembersStatus: false,
        };

      //EXTRA_WORK
      case LOAD_PMOL_EXTRA_WORK_MATERIALS:
        return {
          ...state,
          readExtraWorkMaterialsStatus: true,
          materialsExtraWork: [],
        };
      case LOAD_PMOL_EXTRA_WORK_MATERIALS_SUCCESS:
        return {
          ...state,
          readExtraWorkMaterialsStatus: false,
          createExtraWorkMaterialsStatus: false,
          materialsExtraWork: action.payload.data.result,
        };
      case LOAD_PMOL_EXTRA_WORK_MATERIALS_FAIL:
        return {
          ...state,
          readExtraWorkMaterialsStatus: false,
        };

      case LOAD_PMOL_EXTRA_WORK_TOOLS:
        return { ...state, readExtraWorkToolsStatus: true, toolsExtraWork: [] };
      case LOAD_PMOL_EXTRA_WORK_TOOLS_SUCCESS:
        return {
          ...state,
          readExtraWorkToolsStatus: false,
          createExtraWorkToolsStatus: false,
          toolsExtraWork: action.payload.data.result,
        };
      case LOAD_PMOL_EXTRA_WORK_TOOLS_FAIL:
        return {
          ...state,
          readExtraWorkToolsStatus: false,
        };

      case LOAD_PMOL_EXTRA_WORK_CONSUMABLE:
        return {
          ...state,
          readExtraWorkConsumableStatus: true,
          consumablesExtraWork: [],
        };
      case LOAD_PMOL_EXTRA_WORK_CONSUMABLE_SUCCESS:
        return {
          ...state,
          readExtraWorkConsumableStatus: false,
          createExtraWorkConsumableStatus: false,
          consumablesExtraWork: action.payload.data.result,
        };
      case LOAD_PMOL_EXTRA_WORK_CONSUMABLE_FAIL:
        return {
          ...state,
          readExtraWorkConsumableStatus: false,
        };

      case LOAD_PMOL_EXTRA_WORK_LABOURS:
        return {
          ...state,
          readExtraWorkLabourStatus: true,
          laboursExtraWork: [],
        };
      case LOAD_PMOL_EXTRA_WORK_LABOURS_SUCCESS:
        return {
          ...state,
          readExtraWorkLabourStatus: false,
          createExtraWorkLabourStatus: false,
          laboursExtraWork: action.payload.data.result,
        };
      case LOAD_PMOL_EXTRA_WORK_LABOURS_FAIL:
        return {
          ...state,
          readExtraWorkLabourStatus: false,
        };

      case SAVE_EXTRA_WORK_MATERIALS:
        return { ...state, createExtraWorkMaterialsStatus: false };
      case SAVE_EXTRA_WORK_MATERIALS_SUCCESS:
        return {
          ...state,
          createExtraWorkMaterialsStatus: true,
        };
      case SAVE_EXTRA_WORK_MATERIALS_FAIL:
        return {
          ...state,
          createExtraWorkMaterialsStatus: false,
        };

      case SAVE_EXTRA_WORK_TOOLS:
        return { ...state, createExtraWorkToolsStatus: false };
      case SAVE_EXTRA_WORK_TOOLS_SUCCESS:
        return {
          ...state,
          createExtraWorkToolsStatus: true,
        };
      case SAVE_EXTRA_WORK_TOOLS_FAIL:
        return {
          ...state,
          createExtraWorkToolsStatus: false,
        };

      case SAVE_EXTRA_WORK_CONSUMABLE:
        return { ...state, createExtraWorkConsumableStatus: false };
      case SAVE_EXTRA_WORK_CONSUMABLE_SUCCESS:
        return {
          ...state,
          createExtraWorkConsumableStatus: true,
        };
      case SAVE_EXTRA_WORK_CONSUMABLE_FAIL:
        return {
          ...state,
          createExtraWorkConsumableStatus: false,
        };

      case SAVE_EXTRA_WORK_LABOURS:
        return { ...state, createExtraWorkLabourStatus: false };
      case SAVE_EXTRA_WORK_LABOURS_SUCCESS:
        return {
          ...state,
          createExtraWorkLabourStatus: true,
        };
      case SAVE_EXTRA_WORK_LABOURS_FAIL:
        return {
          ...state,
          createExtraWorkLabourStatus: false,
        };

      case READ_PMOL_EXTRA_WORK:
        return { ...state, extraWork: {} };
      case READ_PMOL_EXTRA_WORK_SUCCESS:
        return {
          ...state,
          extraWork: action.payload.data.result,
        };
      case READ_PMOL_EXTRA_WORK_FAIL:
        return {
          ...state,
          extraWork: {},
        };

      case LOAD_PMOL_JOURNAL:
        return { ...state, isPMOLLoadJournal: false };
      case LOAD_PMOL_JOURNAL_SUCCESS:
        return {
          ...state,
          isPMOLLoadJournal: true,
          journal: action.payload.data.result,
        };
      case LOAD_PMOL_JOURNAL_FAIL:
        return {
          ...state,
          isPMOLLoadJournal: true,
        };
      case SAVE_HANDSHAKE_DOCS:
        return { ...state };
      case SAVE_HANDSHAKE_DOCS_SUCCESS:
        return {
          ...state,
        };
      case SAVE_HANDSHAKE_DOCS_FAIL:
        return {
          ...state,
        };
      case LOAD_PMOL_TECH_INSTRUCTION:
        return { ...state, readTechInstructionStatus: true };
      case LOAD_PMOL_TECH_INSTRUCTION_SUCCESS:
        return {
          ...state,
          readTechInstructionStatus: false,
          techInstructions: action.payload.data.result,
        };
      case LOAD_PMOL_TECH_INSTRUCTION_FAIL:
        return {
          ...state,
          readTechInstructionStatus: false,
        };
      case LOAD_PMOL_SAFTY_INSTRUCTION:
        return { ...state, readSaftyInstructionStatus: true };
      case LOAD_PMOL_SAFTY_INSTRUCTION_SUCCESS:
        return {
          ...state,
          readSaftyInstructionStatus: false,
          saftyInstructions: action.payload.data.result,
        };
      case LOAD_PMOL_SAFTY_INSTRUCTION_FAIL:
        return {
          ...state,
          readSaftyInstructionStatus: false,
        };
      case LOAD_PMOL_ENV_INSTRUCTION:
        return { ...state, readEnvInstructionStatus: true };
      case LOAD_PMOL_ENV_INSTRUCTION_SUCCESS:
        return {
          ...state,
          readEnvInstructionStatus: false,
          envInstructions: action.payload.data.result,
        };
      case LOAD_PMOL_ENV_INSTRUCTION_FAIL:
        return {
          ...state,
          readEnvInstructionStatus: false,
        };
      case LOAD_PMOL_HELTH_INSTRUCTION:
        return { ...state, readHelthInstructionStatus: true };
      case LOAD_PMOL_HELTH_INSTRUCTION_SUCCESS:
        return {
          ...state,
          readHelthInstructionStatus: false,
          helthInstructions: action.payload.data.result,
        };
      case LOAD_PMOL_HELTH_INSTRUCTION_FAIL:
        return {
          ...state,
          readHelthInstructionStatus: false,
        };

      case SAVE_PMOL_EXTRA_WORK:
        return { ...state };
      case SAVE_PMOL_EXTRA_WORK_SUCCESS:
        return {
          ...state,
        };
      case SAVE_PMOL_EXTRA_WORK_FAIL:
        return {
          ...state,
        };


      case CLONE_PMOL:
        return { ...state, showProgressBar: true };
      case CLONE_PMOL_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          isChange: true,
        };
      case CLONE_PMOL_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };


      case LOAD_PMOL_LABOUR_TEAM_MEMBERS:
        return { ...state, teamMembers: [] };
      case LOAD_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS:
        return {
          ...state,
          teamMembers: action.payload.data.result,
        };
      case LOAD_PMOL_LABOUR_TEAM_MEMBERS_FAIL:
        return {
          ...state,
          teamMembers: [],
        };

      case LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS:
        return { ...state, extraTeamMembers: [] };
      case LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_SUCCESS:
        return {
          ...state,
          extraTeamMembers: action.payload.data.result,
        };
      case LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_FAIL:
        return {
          ...state,
          extraTeamMembers: [],
        };

      case DELETE_PMOL_LABOUR_TEAM_MEMBERS:
        return { ...state,
          deleteTeamMembersStatus: false,
          showProgressBar: true,
        };
      case DELETE_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS:
        return {
          ...state,
          deleteTeamMembersStatus: true,
          showProgressBar: false,
        };
      case DELETE_PMOL_LABOUR_TEAM_MEMBERS_FAIL:
        return {
          ...state,
          deleteTeamMembersStatus: false,
          showProgressBar: false,
        };
      case READ_PMOL_LOCATION:
        return { ...state };
      case READ_PMOL_LOCATION_SUCCESS:
        return {
          ...state,
          locationFromPMOL: action.payload.data.result,
        };
      case READ_PMOL_LOCATION_FAIL:
        return {
          ...state,
        };
      case READ_PMOL_RISK:
        return { ...state, readRiskStatus: true };
      case READ_PMOL_RISK_SUCCESS:
        return {
          ...state,
          riskList: action.payload.data.result,
          readRiskStatus: false,
        };
      case READ_PMOL_RISK_FAIL:
        return {
          ...state,
          readRiskStatus: false,
        };
      case LOAD_PMOL_QUALITY:
        return { ...state, readQualityStatus: true, handshakeDataFetched: true };
      case LOAD_PMOL_QUALITY_SUCCESS:
        return {
          ...state,
          readQualityStatus: false,
          qualityList: action.payload.data.result,
        };
      case LOAD_PMOL_QUALITY_FAIL:
        return {
          ...state,
          readQualityStatus: false,
        };
    case APPROVED_PMOL:
      return { ...state, showProgressBar: true };
    case APPROVED_PMOL_SUCCESS:
      return {
        ...state,
        isChange: true,
        showProgressBar: false,
        loadApproveMsg: !action.payload.data?.status ? action.payload.data?.message : '',
      };
    case APPROVED_PMOL_FAIL:
      return {
        ...state,
        showProgressBar: false,
      };

      case FINISHED_PMOL:
        return { ...state, showProgressBar: true };
      case FINISHED_PMOL_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case FINISHED_PMOL_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case LOAD_PMOL_EXTRA_WORK_RESOURCES:
        return {
          ...state,
          showProgressBar: true,
          readExtraWorkMaterialsStatus: true,
          materialsExtraWork: [],
          readExtraWorkToolsStatus: true,
          toolsExtraWork: [],
          readExtraWorkConsumableStatus: true,
          consumablesExtraWork: [],
          readExtraWorkLabourStatus: true,
          laboursExtraWork: [],
        };
      case LOAD_PMOL_EXTRA_WORK_RESOURCES_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          readExtraWorkMaterialsStatus: false,
          materialsExtraWork: action.payload.data.result ? action.payload.data.result.material : [],
          readExtraWorkToolsStatus: false,
          toolsExtraWork: action.payload.data.result ? action.payload.data.result.tools : [],
          readExtraWorkConsumableStatus: false,
          consumablesExtraWork: action.payload.data.result ? action.payload.data.result.consumable : [],
          readExtraWorkLabourStatus: false,
          laboursExtraWork: action.payload.data.result ? action.payload.data.result.labour : [],
        };
      case LOAD_PMOL_EXTRA_WORK_RESOURCES_FAIL:
        return {
          ...state,
          showProgressBar: false,
          readExtraWorkMaterialsStatus: false,
          materialsExtraWork: [],
          readExtraWorkToolsStatus: false,
          toolsExtraWork: [],
          readExtraWorkConsumableStatus: false,
          consumablesExtraWork: [],
          readExtraWorkLabourStatus: false,
          laboursExtraWork: [],
        };

      case LOAD_PMOL_PLANNED_WORK_RESOURCES:
        return {
          ...state,
          showProgressBar: true,
          readMaterialsStatus: true,
          materials: [],
          readToolsStatus: true,
          tools: [],
          readConsumableStatus: true,
          consumables: [],
          readLabourStatus: true,
          labours: [],
        };
      case LOAD_PMOL_PLANNED_WORK_RESOURCES_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          readMaterialsStatus: false,
          materials: action.payload.data.result ? action.payload.data.result.material : [],
          readToolsStatus: false,
          tools: action.payload.data.result ? action.payload.data.result.tools : [],
          readConsumableStatus: false,
          consumables: action.payload.data.result ? action.payload.data.result.consumable : [],
          readLabourStatus: false,
          labours: action.payload.data.result ? action.payload.data.result.labour : [],
        };
      case LOAD_PMOL_PLANNED_WORK_RESOURCES_FAIL:
        return {
          ...state,
          showProgressBar: false,
          readMaterialsStatus: false,
          materials: [],
          readToolsStatus: false,
          tools: [],
          readConsumableStatus: false,
          consumables: [],
          readLabourStatus: false,
          labours: [],
        };
      case SHOW_PROGRESSBAR_PMOL:
        return {
          ...state,
          showProgressBar: true,
        };
      case HIDE_PROGRESSBAR_PMOL:
        return {
          ...state,
          showProgressBar: false,
        };
      case SET_PLANNED_WORK_FETCHED:
        return {
          ...state,
          plannedWorkFetched: true,
        };
      case SET_EXTRA_WORK_FETCHED:
        return {
          ...state,
          extraWorkFetched: true,
        };
      case LOAD_PMOL_ALL_INSTRUCTION:
        return {
          ...state,
          readTechInstructionStatus: true,
          readSaftyInstructionStatus: true,
          readEnvInstructionStatus: true,
          readHelthInstructionStatus: true,
        };
      case LOAD_PMOL_ALL_INSTRUCTION_SUCCESS:
        return {
          ...state,
          readTechInstructionStatus: false,
          readSaftyInstructionStatus: false,
          readEnvInstructionStatus: false,
          readHelthInstructionStatus: false,
          techInstructions: action.payload.data.result.technical,
          saftyInstructions: action.payload.data.result.safety,
          envInstructions: action.payload.data.result.environmental,
          helthInstructions: action.payload.data.result.health,
        };
      case LOAD_PMOL_ALL_INSTRUCTION_FAIL:
        return {
          ...state,
          readTechInstructionStatus: false,
          readSaftyInstructionStatus: false,
          readEnvInstructionStatus: false,
          readHelthInstructionStatus: false,
        };
      case LOAD_PMOL_DETAILS:
        return {
          ...state,
          readHandShakeStatus: false,
          readTechInstructionStatus: true,
          readSaftyInstructionStatus: true,
          readEnvInstructionStatus: true,
          readHelthInstructionStatus: true,
          readExtraWorkConsumableStatus: true,
          readExtraWorkToolsStatus: true,
          readExtraWorkMaterialsStatus: true,
          readExtraWorkLabourStatus: true,
          readPMOLServiceStatus: true,
          readRiskStatus: true,
          readQualityStatus: true,
          formData: {},
          techInstructions: [],
          saftyInstructions: [],
          envInstructions: [],
          helthInstructions: [],
          materials: [],
          tools: [],
          consumables: [],
          labours: [],
          extraWork: {},
          riskList: [],
          qualityList: [],
          competenceList: [],
          locationFromPMOL: null,
          stopHandShakes: [],
          handshakeDocs: [],
          journal: null,
          isEdit: false,
          service: null,
          showProgressBar: true,
        };
      case LOAD_PMOL_DETAILS_SUCCESS:
        return {
          ...state,
          readHandShakeStatus: true,
          readTechInstructionStatus: false,
          readSaftyInstructionStatus: false,
          readEnvInstructionStatus: false,
          readHelthInstructionStatus: false,
          readPMOLServiceStatus: false,
          readRiskStatus: false,
          readQualityStatus: false,
          readExtraWorkConsumableStatus: false,
          readExtraWorkToolsStatus: false,
          readExtraWorkMaterialsStatus: false,
          readExtraWorkLabourStatus: false,
          formData: action.payload.data.result.header,
          techInstructions: action.payload.data.result.instruction?.technical,
          saftyInstructions: action.payload.data.result.instruction?.safety,
          envInstructions: action.payload.data.result.instruction?.environmental,
          helthInstructions: action.payload.data.result.instruction?.health,
          materials: action.payload.data.result ? action.payload.data.result.plannedResource?.material : [],
          tools: action.payload.data.result ? action.payload.data.result.plannedResource?.tools : [],
          consumables: action.payload.data.result ? action.payload.data.result.plannedResource?.consumable : [],
          labours: action.payload.data.result ? action.payload.data.result.plannedResource?.labour : [],
          extraWork: action.payload.data.result ? action.payload.data.result?.extraWork : {},
          consumablesExtraWork: action.payload.data.result ? action.payload.data.result?.extraResource?.consumable : [],
          laboursExtraWork: action.payload.data.result ? action.payload.data.result?.extraResource?.labour : [],
          toolsExtraWork: action.payload.data.result ? action.payload.data.result?.extraResource?.tools : [],
          materialsExtraWork: action.payload.data.result ? action.payload.data.result?.extraResource?.material : [],
          riskList: action.payload.data.result.risk,
          qualityList: action.payload.data.result.quality,
          competenceList: action.payload.data.result.competencies,
          locationFromPMOL: action.payload.data.result.mapLocation,
          stopHandShakes: action.payload.data.result.stopHandshake.stopHandshakes,
          handshakeDocs: formattedHanshakeDocs(action.payload.data.result.stopHandshake.docLinks),
          journal: action.payload.data.result.journal,
          isEdit: true,
          service: action.payload.data.result.pmolService,
          showProgressBar: false,
          isEndTimeUpdated: true,
        };
      case LOAD_PMOL_DETAILS_FAIL:
        return {
          ...state,
          readTechInstructionStatus: false,
          readSaftyInstructionStatus: false,
          readEnvInstructionStatus: false,
          readHelthInstructionStatus: false,
          readRiskStatus: false,
          readQualityStatus: false,
          readExtraWorkConsumableStatus: false,
          readExtraWorkToolsStatus: false,
          readExtraWorkMaterialsStatus: false,
          readExtraWorkLabourStatus: false,
          readPMOLServiceStatus: false,
          showProgressBar: false,
        };

      case LOAD_PMOL_PDF_DETAILS:
        return { ...state, showProgressBar: true, formDataPdf: {} };
      case LOAD_PMOL_PDF_DETAILS_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          formDataPdf: action.payload.data.result,
        };
      case LOAD_PMOL_PDF_DETAILS_FAIL:
        return {
          ...state,
          showProgressBar: false,
          formDataPdf: {},
        };


      case READ_PMOL_SERVICE:
        return { ...state, showProgressBar: true, service: null };
      case READ_PMOL_SERVICE_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          service: action.payload.data.result,
        };
      case READ_PMOL_SERVICE_FAIL:
        return {
          ...state,
          showProgressBar: false,
          service: null,
        };
      case SAVE_PMOL_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };
        case UPDATE_PMOL_LABOUR_END_TIME:
          return {
            ...state,
            showProgressBar: true,
            isEndTimeUpdated: false,
          };
        case UPDATE_PMOL_LABOUR_END_TIME_SUCCESS:
          return {
            ...state,
            showProgressBar: false,
            isEndTimeUpdated: true,
          };
        case UPDATE_PMOL_LABOUR_END_TIME_FAIL:
          return {
            ...state,
            showProgressBar: false,
            isEndTimeUpdated: false,
          };

        case CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS:
          return {
            ...state,
            showProgressBar: true,
          };
        case CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_SUCCESS:
          return {
            ...state,
            clonePmolId: action.payload.data.result,
            showProgressBar: false,
          };
        case CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_FAIL:
          return {
            ...state,
            showProgressBar: false,
          };

      default:
        return state;
    }
  }
}

export function savePMOLStateAttr(attr: string, value: any) {
  return {
    type: SAVE_PMOL_STATE_ATTR,
    [attr]: value,
  };
}

export const readPMOLShortCutPaneData = () => {
  return {
    types: [
      LOAD_PMOL_SHORTCUTPANE,
      LOAD_PMOL_SHORTCUTPANE_SUCCESS,
      LOAD_PMOL_SHORTCUTPANE_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_SHORTCUT_PANE_EP,
      },
    },
  };
};

export const readPMOLDropDownData = () => {
  return {
    types: [
      LOAD_PMOL_DROPDOWN,
      LOAD_PMOL_DROPDOWN_SUCCESS,
      LOAD_PMOL_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_DROPDOWN_EP,
      },
    },
  };
};

export const readPmolData = () => {
  return {
    types: [
      LOAD_PMOL_DATA,
      LOAD_PMOL_DATA_SUCCESS,
      LOAD_PMOL_DATA_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_DATA_EP,
      },
    },
  };
};

export const filterPMOLListPaneData = (filter: PMOLListPaneFilter) => {
  return {
    types: [
      LOAD_PMOL_LSITPANE,
      LOAD_PMOL_LSITPANE_SUCCESS,
      LOAD_PMOL_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_PMOL_LIST_EP,
        data: filter,
      },
    },
  };
};

export const saveJournal = (journal: Journal) => {
  return {
    types: [SAVE_JOURNAL, SAVE_JOURNAL_SUCCESS, SAVE_JOURNAL_FAIL],
    payload: {
      request: {
        method: 'POST',
        url: PMOL_SAVE_JOURNAL,
        data: journal,
      },
    },
  };
};

export const getPmolJournal = (id: string) => {
  return {
    types: [
      LOAD_PMOL_JOURNAL,
      LOAD_PMOL_JOURNAL_SUCCESS,
      LOAD_PMOL_JOURNAL_FAIL,
    ],
    payload: {
      request: {
        url: PMOL_GET_PMOL_JOURNAL + id,
        method: 'GET',
      },
    },
  };
};

export const readByPMOLId = (id: string) => {
  return {
    types: [LOAD_PMOL_BY_ID, LOAD_PMOL_BY_ID_SUCCESS, LOAD_PMOL_BY_ID_FAIL],
    payload: {
      request: {
        url: READ_PMOL_BY_ID_EP + id,
      },
    },
  };
};

export const resetIsChange = () => {
  return {
    type: RESET_IS_CHANGE,
  };
};

export const saveFormData = (formData: ProjectMolecule) => {
  return {
    types: [SAVE_PMOL, SAVE_PMOL_SUCCESS, SAVE_PMOL_FAIL],
    payload: {
      request: {
        url: PMOL_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return {
    type: SET_PMOL_UID,
  };
};

export const clearFormData = () => {
  return {
    type: CLEAR_PMOL_FORM_DATA,
  };
};

export const setPlannedWorkFetched = () => {
  return {
    type: SET_PLANNED_WORK_FETCHED,
  };
};

export const setExtraWorkFetched = () => {
  return {
    type: SET_EXTRA_WORK_FETCHED,
  };
};

export const readResourcesByBorId = (id: string) => {
  return {
    types: [
      LOAD_RESOURCE_BY_BOR_ID,
      LOAD_RESOURCE_BY_BOR_ID_SUCCESS,
      LOAD_RESOURCE_BY_BOR_ID_FAIL,
    ],
    payload: {
      request: {
        url: READ_RESOURCE_BY_BOR_ID_EP + id,
      },
    },
  };
};

export const readPmolMaterialsData = (id: string, canceltoken: any) => {
  return {
    types: [
      LOAD_PMOL_MATERIALS,
      LOAD_PMOL_MATERIALS_SUCCESS,
      LOAD_PMOL_MATERIALS_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_MATERIALS_EP + id,
      },
      cancelToken: canceltoken,
    },
  };
};

export const readPmolToolsData = (id: string) => {
  return {
    types: [LOAD_PMOL_TOOLS, LOAD_PMOL_TOOLS_SUCCESS, LOAD_PMOL_TOOLS_FAIL],
    payload: {
      request: {
        url: READ_PMOL_TOOLS_EP + id,
      },
    },
  };
};

export const readPmolConsumableData = (id: string) => {
  return {
    types: [
      LOAD_PMOL_CONSUMABLE,
      LOAD_PMOL_CONSUMABLE_SUCCESS,
      LOAD_PMOL_CONSUMABLE_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_CONSUMABLE_EP + id,
      },
    },
  };
};

export const readPmolLaboursData = (id: string) => {
  return {
    types: [
      LOAD_PMOL_LABOURS,
      LOAD_PMOL_LABOURS_SUCCESS,
      LOAD_PMOL_LABOURS_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_LABOURS_EP + id,
      },
    },
  };
};

export const readPmolPlannedWorkResources = (id: string) => {
  return {
    types: [
      LOAD_PMOL_PLANNED_WORK_RESOURCES,
      LOAD_PMOL_PLANNED_WORK_RESOURCES_SUCCESS,
      LOAD_PMOL_PLANNED_WORK_RESOURCES_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_PLANNED_WORK_RESOURCES_EP + id,
      },
    },
  };
};


export const readLocationsByProjectId = (id: string | null) => {
  if (id) {
    return {
      types: [
        LOAD_LOCATION_BY_PROJECT,
        LOAD_LOCATION_BY_PROJECT_SUCCESS,
        LOAD_LOCATION_BY_PROJECT_FAIL,
      ],
      payload: {
        request: {
          url: PMOL_LOCATION_EP + id,
        },
      },
    };
  }
};

export const createPMOLLocation = (data: LocationAddress) => {
  return {
    types: [CREATE_LOCATION, CREATE_LOCATION_SUCCESS, CREATE_LOCATION_FAIL],
    payload: {
      request: {
        url: CREATE_LOCATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const startHandshake = (data: {
  locationId: string;
  pmolId: string;
}) => {
  return {
    types: [START_HANDSHAKE, START_HANDSHAKE_SUCCESS, START_HANDSHAKE_FAIL],
    payload: {
      request: {
        url: START_HANDSHAKE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

const formattedPMOLList = (result: any) => {
  if (result) {
    let data = result.map((item: ListPaneItem) => {
      return {
        ...item,
        status: item.status ? item.status : '-',
        foreman: item.foreman ? item.foreman : '-',
        type: item.type ? item.type : '-',
        executionDate: format24hDateTime(item.executionDate),
      };
    });
    return data;
  } else {
    return [];
  }
};

export const createMaterials = (data: Resource) => {
  return {
    types: [SAVE_MATERIALS, SAVE_MATERIALS_SUCCESS, SAVE_MATERIALS_FAIL],
    payload: {
      request: {
        url: SAVE_MATERIALS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createTools = (data: Resource) => {
  return {
    types: [SAVE_TOOLS, SAVE_TOOLS_SUCCESS, SAVE_TOOLS_FAIL],
    payload: {
      request: {
        url: SAVE_TOOLS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createConsumable = (data: Resource) => {
  return {
    types: [SAVE_CONSUMABLE, SAVE_CONSUMABLE_SUCCESS, SAVE_CONSUMABLE_FAIL],
    payload: {
      request: {
        url: SAVE_CONSUMABLE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createLabour = (data: any) => {
  return {
    types: [SAVE_LABOURS, SAVE_LABOURS_SUCCESS, SAVE_LABOURS_FAIL],
    payload: {
      request: {
        url: SAVE_LABOURS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const uploadSignature = (formData: any) => {
  return {
    types: [UPLOAD_SIGNATURE, UPLOAD_SIGNATURE_SUCCESS, UPLOAD_SIGNATURE_FAIL],
    payload: {
      request: {
        url: UPLOAD_SIGNATURE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const stopHandshake = (signature: any) => {
  return {
    types: [STOP_HANDSHAKE, STOP_HANDSHAKE_SUCCESS, STOP_HANDSHAKE_FAIL],
    payload: {
      request: {
        url: STOP_HANDSHAKE_EP,
        method: 'POST',
        data: signature,
      },
    },
  };
};

export const readStopHandshakes = (id: string | null) => {
  return {
    types: [
      READ_STOP_HANDSHAKE,
      READ_STOP_HANDSHAKE_SUCCESS,
      READ_STOP_HANDSHAKE_FAIL,
    ],
    payload: {
      request: {
        url: READ_STOP_HANDSHAKE_EP + id,
      },
    },
  };
};
export const deleteMaterials = (data: string[]) => {
  return {
    types: [
      DELETE_PMOL_MATERIALS,
      DELETE_PMOL_MATERIALS_SUCCESS,
      DELETE_PMOL_MATERIALS_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_PMOL_MATERIALS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteTools = (data: string[]) => {
  return {
    types: [
      DELETE_PMOL_TOOLS,
      DELETE_PMOL_TOOLS_SUCCESS,
      DELETE_PMOL_TOOLS_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_PMOL_TOOLS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteConsumable = (data: string[]) => {
  return {
    types: [
      DELETE_PMOL_CONSUMABLE,
      DELETE_PMOL_CONSUMABLE_SUCCESS,
      DELETE_PMOL_CONSUMABLE_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_PMOL_CONSUMABLE_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteLabours = (data: string[]) => {
  return {
    types: [
      DELETE_PMOL_LABOURS,
      DELETE_PMOL_LABOURS_SUCCESS,
      DELETE_PMOL_LABOURS_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_PMOL_LABOURS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readPmolExtraWorkMaterialsData = (id: string) => {
  return {
    types: [
      LOAD_PMOL_EXTRA_WORK_MATERIALS,
      LOAD_PMOL_EXTRA_WORK_MATERIALS_SUCCESS,
      LOAD_PMOL_EXTRA_WORK_MATERIALS_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_EXTRA_WORK_MATERIALS_EP + id,
      },
    },
  };
};

export const readPmolExtraWorkToolsData = (id: string) => {
  return {
    types: [
      LOAD_PMOL_EXTRA_WORK_TOOLS,
      LOAD_PMOL_EXTRA_WORK_TOOLS_SUCCESS,
      LOAD_PMOL_EXTRA_WORK_TOOLS_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_EXTRA_WORK_TOOLS_EP + id,
      },
    },
  };
};

export const readPmolExtraWorkConsumableData = (id: string) => {
  return {
    types: [
      LOAD_PMOL_EXTRA_WORK_CONSUMABLE,
      LOAD_PMOL_EXTRA_WORK_CONSUMABLE_SUCCESS,
      LOAD_PMOL_EXTRA_WORK_CONSUMABLE_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_EXTRA_WORK_CONSUMABLE_EP + id,
      },
    },
  };
};

export const readPmolExtraWorkLaboursData = (id: string) => {
  return {
    types: [
      LOAD_PMOL_EXTRA_WORK_LABOURS,
      LOAD_PMOL_EXTRA_WORK_LABOURS_SUCCESS,
      LOAD_PMOL_EXTRA_WORK_LABOURS_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_EXTRA_WORK_LABOURS_EP + id,
      },
    },
  };
};

export const readPmolExtraWorkResources = (id: string) => {
  return {
    types: [
      LOAD_PMOL_EXTRA_WORK_RESOURCES,
      LOAD_PMOL_EXTRA_WORK_RESOURCES_SUCCESS,
      LOAD_PMOL_EXTRA_WORK_RESOURCES_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_EXTRA_WORK_RESOURCES_EP + id,
      },
    },
  };
};

export const createExtraWorkMaterials = (data: Resource) => {
  return {
    types: [
      SAVE_EXTRA_WORK_MATERIALS,
      SAVE_EXTRA_WORK_MATERIALS_SUCCESS,
      SAVE_EXTRA_WORK_MATERIALS_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_MATERIALS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createExtraWorkTools = (data: Resource) => {
  return {
    types: [
      SAVE_EXTRA_WORK_TOOLS,
      SAVE_EXTRA_WORK_TOOLS_SUCCESS,
      SAVE_EXTRA_WORK_TOOLS_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_TOOLS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createExtraWorkConsumable = (data: Resource) => {
  return {
    types: [
      SAVE_EXTRA_WORK_CONSUMABLE,
      SAVE_EXTRA_WORK_CONSUMABLE_SUCCESS,
      SAVE_EXTRA_WORK_CONSUMABLE_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_CONSUMABLE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createExtraWorkLabour = (data: Resource) => {
  return {
    types: [
      SAVE_EXTRA_WORK_LABOURS,
      SAVE_EXTRA_WORK_LABOURS_SUCCESS,
      SAVE_EXTRA_WORK_LABOURS_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_LABOURS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const readPmolExtraWorkData = (id: string) => {
  return {
    types: [
      READ_PMOL_EXTRA_WORK,
      READ_PMOL_EXTRA_WORK_SUCCESS,
      READ_PMOL_EXTRA_WORK_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_EXTRA_WORK_EP + id,

      },
    },
  };
};

export const saveExtraWorkData = (data: ExtraWork) => {
  return {
    types: [
      SAVE_PMOL_EXTRA_WORK,
      SAVE_PMOL_EXTRA_WORK_SUCCESS,
      SAVE_PMOL_EXTRA_WORK_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_EXTRA_WORK_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const readPMOLLocationById = (id: String) => {
  return {
    types: [
      READ_PMOL_LOCATION,
      READ_PMOL_LOCATION_SUCCESS,
      READ_PMOL_LOCATION_FAIL,
    ],
    payload: {
      request: {
        url: READ_PMOL_LOCATION_EP + id,
      },
    },
  };
};

export const saveHandshakeDocuments = (data: {
  pmolId: string,
  docLinks: string[]
}) => {
  return {
    types: [
      SAVE_HANDSHAKE_DOCS,
      SAVE_HANDSHAKE_DOCS_SUCCESS,
      SAVE_HANDSHAKE_DOCS_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_HANDSHAKE_DOCUMENTS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

const formattedHanshakeDocs = (result: any) => {
  if (result) {
    let data = result.map((item: string) => {
      return {
        image: item,
        id: item,
        isLast: true,
      };
    });
    return data;
  } else {
    return [];
  }
};

export const readPMOLAllInstruction = (pbsId: string) => {
  return {
    types: [LOAD_PMOL_ALL_INSTRUCTION, LOAD_PMOL_ALL_INSTRUCTION_SUCCESS, LOAD_PMOL_ALL_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: READ_ALL_INSTRUCTIONS + pbsId,
      },
    },
  };
};

export const readPMOLTechInstruction = (pbsId: string, type: string) => {
  return {
    types: [LOAD_PMOL_TECH_INSTRUCTION, LOAD_PMOL_TECH_INSTRUCTION_SUCCESS, LOAD_PMOL_TECH_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: getPMOLInstructionByType(pbsId, type),
      },
    },
  };
};

export const readPMOLSaftyInstruction = (pbsId: string, type: string) => {
  return {
    types: [LOAD_PMOL_SAFTY_INSTRUCTION, LOAD_PMOL_SAFTY_INSTRUCTION_SUCCESS, LOAD_PMOL_SAFTY_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: getPMOLInstructionByType(pbsId, type),
      },
    },
  };
};

export const readPMOLEnvInstruction = (pbsId: string, type: string) => {
  return {
    types: [LOAD_PMOL_ENV_INSTRUCTION, LOAD_PMOL_ENV_INSTRUCTION_SUCCESS, LOAD_PMOL_ENV_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: getPMOLInstructionByType(pbsId, type),
      },
    },
  };
};

export const readPMOLHelthInstruction = (pbsId: string, type: string) => {
  return {
    types: [LOAD_PMOL_HELTH_INSTRUCTION, LOAD_PMOL_HELTH_INSTRUCTION_SUCCESS, LOAD_PMOL_HELTH_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: getPMOLInstructionByType(pbsId, type),
      },
    },
  };
};

export const readPMOLByID = (pmolId: string, project?: string,cu?: string) => {
  return {
    types: [LOAD_PMOL_DETAILS, LOAD_PMOL_DETAILS_SUCCESS, LOAD_PMOL_DETAILS_FAIL],
    payload: {
      request: {
        url: READ_PMOL_BY_ID + pmolId,
        headers: { project: project,cu:cu },
      },
    },
  };
};

export const readPMOLPDFByID = (pmolId: string, lan: string) => {
  return {
    types: [LOAD_PMOL_PDF_DETAILS, LOAD_PMOL_PDF_DETAILS_SUCCESS, LOAD_PMOL_PDF_DETAILS_FAIL],
    payload: {
      request: {
        url: READ_PMOL_PDF_BY_ID + pmolId + '/' + lan,
      },
    },
  };
};

export const clonePmol = (id: string) => {
  return {
    types: [CLONE_PMOL, CLONE_PMOL_SUCCESS, CLONE_PMOL_FAIL],
    payload: {
      request: {
        url: CLONE_PMOL_EP + id,
      },
    },
  };
};

export const readTeamMembers = (id: string) => {
  return {
    types: [LOAD_PMOL_LABOUR_TEAM_MEMBERS, LOAD_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS, LOAD_PMOL_LABOUR_TEAM_MEMBERS_FAIL],
    payload: {
      request: {
        url: LOAD_PMOL_LABOUR_TEAM_MEMBERS_EP + id,
      },
    },
  };
};

export const readExtraTeamMembers = (id: string) => {
  return {
    types: [LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS, LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_SUCCESS, LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_FAIL],
    payload: {
      request: {
        url: LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_EP + id,
      },
    },
  };
};

export const deleteTeamMembers = (data: string[]) => {
  return {
    types: [DELETE_PMOL_LABOUR_TEAM_MEMBERS, DELETE_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS, DELETE_PMOL_LABOUR_TEAM_MEMBERS_FAIL],
    payload: {
      request: {
        url: DELETE_PMOL_LABOUR_TEAM_MEMBERS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readRiskList = (id: string) => {
  return {
    types: [READ_PMOL_RISK, READ_PMOL_RISK_SUCCESS, READ_PMOL_RISK_FAIL],
    payload: {
      request: {
        url: getPMOLRiskesByPBSId(id),
      },
    },
  };
};

export const readQualityList = (id: string) => {
  return {
    types: [LOAD_PMOL_QUALITY, LOAD_PMOL_QUALITY_SUCCESS, LOAD_PMOL_QUALITY_FAIL],
    payload: {
      request: {
        url: 'PbsQuality/GetAllQualityByPbsProductId/' + id,
      },
    },
  };
};

export const approvedPmol = (id: string) => {
  return {
    types: [APPROVED_PMOL, APPROVED_PMOL_SUCCESS, APPROVED_PMOL_FAIL],
    payload: {
      request: {
        url: APPROVED_PMOL_EP + id,
        method: 'PUT',
      },
    },
  };
};

export const finishedPmol = (id: string) => {
  return {
    types: [FINISHED_PMOL, FINISHED_PMOL_SUCCESS, FINISHED_PMOL_FAIL],
    payload: {
      request: {
        url: FINISHED_PMOL_EP + id,
        method: 'PUT',
      },
    },
  };
};
export const createPMOLService = (data: any) => {
  return {
    types: [CREATE_PMOL_SERVICE, CREATE_PMOL_SERVICE_SUCCESS, CREATE_PMOL_SERVICE_FAIL],
    payload: {
      request: {
        url: PMOL_CREATE_SERVICE,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updatePmolLabourEndTime = (data: EndDateAndTime) => {
  return {
    types: [UPDATE_PMOL_LABOUR_END_TIME, UPDATE_PMOL_LABOUR_END_TIME_SUCCESS, UPDATE_PMOL_LABOUR_END_TIME_FAIL],
    payload: {
      request: {
        url: UPDATE_PMOL_LABOUR_END_TIME_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const readPBSService = (id: string) => {
  return {
    types: [READ_PMOL_SERVICE, READ_PMOL_SERVICE_SUCCESS, READ_PMOL_SERVICE_FAIL],
    payload: {
      request: {
        url: PBS_READ_SERVICE + id,
        method: 'GET',
      },
    },
  };
};

export const createPmolCloneForMultipleDays = (data: any) => {
  return {
    types: [
      CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS,
      CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_SUCCESS,
      CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_FAIL
    ],
    payload: {
      request: {
        url: CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_EP,
        method: 'POST',
          data: data,
      },
    },
  };
};

const formattedPayloadToDropdownList = (response: any) => {
  if (response) {
    let keys = Object.keys(response);
    return keys.map((item: any) => {
      return {
        key: item,
        text: item,

      };
    });
  } else {
    return [];
  }
};

const formattedPayloadToDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    return data;
  } else {
    return [];
  }
};

export const showProgressbarPmol = (status: boolean) => {

  if (status) {
    return {
      type: SHOW_PROGRESSBAR_PMOL,
    };
  } else {
    return {
      type: HIDE_PROGRESSBAR_PMOL,
    };
  }
};

// const formattedPayloadToDropdown = (response: any) => {
//   if (response) {
//     let data = response.map((item: any) => {
//       return {
//         key: item.id,
//         text: item.name,
//         isDefault: item.isDefault,
//       };
//     });
//     return data;
//   } else {
//     return [];
//   }
// };

const formatUserRoleResponse = (data: any[]) => {
  if (data && _.isArray(data)) {
    const formattedData = data.map((item: any) => {
      return {
        key: item.id,
        text: item.roleName,
      };
    });
    // console.log("map_data",data);
    return formattedData;
  }
  return [];
};
