import _ from 'lodash';
import {CommandBar} from 'office-ui-fabric-react/lib/CommandBar';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {getFlatDataFromTree} from '@nosferatu500/react-sortable-tree';
import * as XLSX from 'xlsx';
import {
    contractorLotExcelUpload,
    saveContractStateAttr,
    sendContractorInvitation,
    uploadCBCExcelFile,
    uploadCBCPdfFile,
} from '../../../reducers/contractReducer';
import {messageService} from '../../../services/messageService';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import {CONTRACT, CONTRACTOR, ContractorStatus, Status} from '../../../types/contract';
import {removeCurrencyAndThousandSeparators} from '../../../shared/util';
import SortedDataComponent from "./recursiveSort";

const CommandBarRight = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const _excelFile = useRef(null);
    const [isdialogVisible, setIsDialogVisible]: any = useState(false);
    const [cbc, setCBC]: any = useState<any>([]);
    // const [excel, setExcel]: any = useState<any>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const pdfFileRef = useRef<HTMLInputElement>(null);
    const {selectedLotType, constructorFormData, formData}: any = useSelector((state: any) => state.contract);

    const newClick = () => {
        messageService.sendMessage({isNewCONTRACT: true, resetListPaneSelection: true});
        dispatch(saveContractStateAttr('isClear', true));
        dispatch(saveContractStateAttr('selectedLotType', CONTRACT));
        // props.createNew(true);
    };
    const saveFormData = () => {
        if (selectedLotType === CONTRACT) {
            messageService.sendMessage({saveCONTRACTData: true});
        } else if (selectedLotType === CONTRACTOR) {
            messageService.sendMessage({saveCONTRACTORData: true});
        }
    };
    const handleTemporaryDelivery = () => {
        // props.handleTemporaryDelivery();
    };
    const handleDefinitiveDelivery = () => {
        // props.handleTemporaryDelivery();
    };

    /* Upload Contractor PDF */
    const handlePdf = (e: any) => {
        const formData = new FormData();
        if (e?.target?.files[0]) {
            formData.append('pdf', e.target.files[0]);
            formData.append('lotId', constructorFormData?.contractTitle.substring(0, constructorFormData?.contractTitle.indexOf(' ')));
            formData.append('contractorId', constructorFormData?.cabCompanyId);
            dispatch(uploadCBCPdfFile(formData));
            // form.mutators.setDocumentLink(uploadedInstructionLink);
        }
        e.target.value = null;
    }

    /* Upload Contractor Excel */
    const handleCwfFile = (e: any) => {
        const files: any = e.target.files;
        const f = files[0];
        const reader = new FileReader();
        const result: any = {};
        setCBC([]);
        // const regex = /[$€£¥]/g;  // inside [] define the currency symbols  $€£


        reader.onload = (d: any) => {
            const data = new Uint8Array(d.target.result);
            const workbook = XLSX.read(data, {type: 'array'});

            workbook.SheetNames.forEach((sheetName: any, index: number) => {
                const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                    header: 1,
                });
                if (roa.length) {
                    result[index] = roa;
                }
            });

            const result2 = result['0']?.filter((item: any) => {
                // remove empty arrays
                return Array.isArray(item) && !_.isEmpty(item);
            });

            // result2.splice(0, 10);

            const result3 = result2?.filter((item: any) => {
                // remove last '.'
                if (
                    item[2] &&
                    // item[2].includes('.') &&
                    !isNaN(item[2].split('.')?.[0])
                ) {
                    item[2] = item[2].slice(item[2].length - 1) === '.'
                        ? item[2].slice(0, -1)
                        : item[2];

                    return item;
                }
            });

            const uniqueObjects: any = [];
            result3.forEach((item: any) => {
                // let isDuplicate = uniqueObjects.find((obj: any) => {
                //     return obj[2] === item[2]
                // });
                uniqueObjects.push(item)
                // if (isDuplicate) {
                //     if (item && item[10]) {
                //         let objIndex = uniqueObjects.findIndex(((obj: any) => obj[2] == isDuplicate[2]));
                //
                //         uniqueObjects[objIndex] = item
                //     }
                // } else {
                //     uniqueObjects.push(item)
                // }
            });

            // console.log('uniqueObjects > ',uniqueObjects);
            let children = uniqueObjects.reduce((r: any, s: any) => {
                r.children.push({
                    pageRowColumn: s[0],
                    lotRowNumber: s[1],
                    articleNo: s[2],
                    title: s[3],
                    key1: s[4] ? s[4] : null,
                    value1: s[5] ? s[5] : null,
                    key2: s[6] ? s[6] : null,
                    value2: s[7] ? s[7] : null,
                    key3: s[8] ? s[8] : null,
                    value3: s[9] ? s[9] : null,
                    key4: s[10] ? s[10] : null,
                    value4: s[11] ? s[11] : null,
                    key5: s[12] ? s[12] : null,
                    value5: s[13] ? s[13] : null,
                    measurementCode: s[14] ? s[14] : null,
                    quantity: s[15],
                    unit: s[16],
                    unitPrice: s[18] ? removeCurrencyAndThousandSeparators(s[18]) : null,
                    totalPrice: s[19] ? removeCurrencyAndThousandSeparators(s[19]) : null,
                });

                return r;
            }, {children: []}).children;

            // console.log('children >', children)
            setIsDialogVisible(true);
            setCBC(children);
            _excelFile.current = f;
        };
        reader.readAsArrayBuffer(f);
        e.target.value = null;
    };

    /* Upload Measuring State */
    const handleFile = (e: any) => {
        const files: any = e.target.files;
        const f = files[0];
        const reader = new FileReader();
        const result: any = {};
        setCBC([]);
        reader.onload = (d: any) => {
            const data = new Uint8Array(d.target.result);
            const workbook = XLSX.read(data, {type: 'array'});
            workbook.SheetNames.forEach((sheetName: any, index: number) => {
                const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                    header: 1,
                });
                if (roa.length) {
                    result[index] = roa;
                }
            });
            let lot =
                Array.isArray(result[0][3]) && result[0][3][0].includes('Lot')
                    ? result[0][3][1]
                    : null;

            const result2 = result['0']?.filter((item: any) => {
                return Array.isArray(item) && !_.isEmpty(item);
            });
            const excelData: any = [];
            let dynamicDataKeys: any = {
                key1: "",
                key2: "",
                key3: "",
                key4: "",
                key5: ""
            };

            result2?.forEach((data: any, index: number) => {
                if (data?.[0] === "Artikelnr") {
                    dynamicDataKeys = {
                        key1: data?.[2] ? data?.[2] : "key1",
                        key2: data?.[3] ? data?.[3] : "key2",
                        key3: data?.[4] ? data?.[4] : "key3",
                        key4: data?.[5] ? data?.[5] : "key4",
                        key5: data?.[6] ? data?.[6] : "key5"
                    };
                }
                let dynamicData = {
                    key1: data?.[2] ? dynamicDataKeys?.key1 : undefined,
                    key2: data?.[3] ? dynamicDataKeys?.key2 : undefined,
                    key3: data?.[4] ? dynamicDataKeys?.key3 : undefined,
                    key4: data?.[5] ? dynamicDataKeys?.key4 : undefined,
                    key5: data?.[6] ? dynamicDataKeys?.key5 : undefined,
                    value1: data?.[2],
                    value2: data?.[3],
                    value3: data?.[4],
                    value4: data?.[5],
                    value5: data?.[6]
                };
                excelData.push({ ...data, dynamicData });
            });

            const result4 = excelData?.filter((item: any) => {
                // remove last .
                if (
                    item[0] &&
                    !isNaN(item[0].split('.')?.[0])
                ) {
                    if (item[0].slice(item[0].length - 1) === '.') {
                        item[0] = item[0].substring(0, item[0].length - 1);
                    }
                    return item;
                }
            });

            result4.map((item:any, index:number) => {
                if (index >0 && result4[index-1]?.[0] === item?.[0]) {
                    let prevItem:any=result4[index-1];
                    if(!prevItem?.[7] && !prevItem?.[8] && !prevItem?.[9] && !prevItem?.[10] && !prevItem?.[11]){
                        // if(!prevItem?.[7] || !prevItem?.[8] || !prevItem?.[9] || !prevItem?.[10] || !prevItem?.[11]){
                        result4.splice(index-1, 1);
                    }
                }
            });

            console.log({result4})

            // console.log('result4>>>', result4)

            const result5 = result4?.map((item: any) => {
                return item[0];
            });

            const sortedArr = SortedDataComponent(result4)

            // console.log('children>>>', children)
            // console.log('children>>>', JSON.stringify(children))
            // console.log('sortedArr>>>', sortedArr)
            // console.log('sortedArr>>>', JSON.stringify(sortedArr))
            const fData = getFlatDataFromTree({
                treeData: [...sortedArr],
                getNodeKey: (node: any) => node.id,
                ignoreCollapsed: false,
            });
            // console.log('fData>>>', fData)

            let flatData = fData.map((item: any) => {
                return {
                    id: item?.node?.id,
                    title: item?.node?.title,
                    quantity: result5.includes(item?.node.id) ? item?.node.quantity : '0',
                    parentId: item?.parentNode?.id ? item?.parentNode?.id : 0,
                    unitPrice: item?.node?.unitPrice ? removeCurrencyAndThousandSeparators(item?.node?.unitPrice) : null,
                    totalPrice: item?.node?.totalPrice ? removeCurrencyAndThousandSeparators(item?.node?.totalPrice) : null,
                    contractor: lot,
                    measurementCode: item?.node?.measurementCode,
                    key1: item?.node?.key1,
                    value1: item?.node?.value1,
                    key2: item?.node?.key2,
                    value2: item?.node?.value2,
                    key3: item?.node?.key3,
                    value3: item?.node?.value3,
                    key4: item?.node?.key4,
                    value4: item?.node?.value4,
                    key5: item?.node?.key5,
                    value5: item?.node?.value5,
                    mou: item?.node?.mou,
                };
            });
            // console.log('flatData>>>', flatData)
            setIsDialogVisible(true);
            setCBC(flatData);
            _excelFile.current = f;
        };
        reader.readAsArrayBuffer(f);
        e.target.value = null;
    };

    const onUploadConfirm = () => {
        setIsDialogVisible(false);
        const formDatax: any = new FormData();
        if (Array.isArray(cbc) && !_.isEmpty(cbc)) {
            // let contractId = location.pathname.split('/').pop()
            if (selectedLotType === 'Contract') {
                formDatax.append('excel', _excelFile.current);
                formDatax.append('uploadExcelDto', JSON.stringify({ExcelData: cbc, contractId: formData?.sequenceId}));
                dispatch(uploadCBCExcelFile(formDatax));
            } else {
                formDatax.append('excel', _excelFile.current);
                formDatax.append('uploadExcelDto', JSON.stringify({
                    ExcelData: cbc,
                    contractId: constructorFormData?.lotSequenceId,
                    contractorId: constructorFormData.cabCompanyId
                }));
                dispatch(contractorLotExcelUpload(formDatax));
            }
        }
    };

    const onUploadCancel = () => {
        setIsDialogVisible(false);
    };

    const handleErrorMsg = () => {
        messageService.sendMessage({resetErrorMsg: true});
    };

    const getDisable = () => {
        if (selectedLotType === 'Contract') {
            return !!(formData?.id && formData?.status?.key == Status.In_Preparation);
        } else {
            return !!constructorFormData?.id;
        }
    }

    const getInviteBtnDisable = () => {
        return !(constructorFormData && constructorFormData.status?.key === ContractorStatus.Added_to_Contract && !constructorFormData?.isInviteSend && constructorFormData.lotStatusId != Status.In_Preparation && constructorFormData.lotStatusId != Status.In_Execution);
    };

    const getItems = () => {
        const items = [];

        const newBtn = {
            key: 'new',
            text: t('new'),
            iconProps: {iconName: 'Add'},
            onClick: () => {
                newClick();
            },
            disabled: false,
        };

        const saveBtn = {
            key: 'save',
            text: t('save'),
            iconProps: {iconName: 'Save'},
            onClick: () => {
                saveFormData();
            },
            disabled: false,
        };

        const lotUpload = {
            key: 'lotUpload',
            onClick: () => {
                inputFileRef.current && inputFileRef.current.click();
                handleErrorMsg();
            },
            name: t('uploadMeasuringState'),
            iconProps: {iconName: 'Upload'},
            disabled: !getDisable(),
        };

        const upload = {
            key: 'upload',
            onClick: () => {
                inputFileRef.current && inputFileRef.current.click();
                handleErrorMsg();
            },
            name: t('upload'),
            iconProps: {iconName: 'Upload'},
            disabled: !getDisable(),
            split: true,
            subMenuProps: {
                items: [
                    {
                        key: 'excelUpload',
                        text: t('excelUpload'),
                        onClick: () => {
                            inputFileRef.current && inputFileRef.current.click();
                            handleErrorMsg();
                        },
                    },
                    {
                        key: 'pdfUpload',
                        text: t('pdfUpload'),
                        onClick: () => {
                            pdfFileRef.current && pdfFileRef.current.click();
                            handleErrorMsg();
                        },
                    },
                ],
            },
        };

        const download = {
            key: 'download',
            onClick: () => {
                () => {
                };
            },
            name: t('download'),
            iconProps: {iconName: 'Download'},
            disabled: true,
        };

        const tempDelivery = {
            key: 'temporaryDelivery',
            onClick: () => {
                handleTemporaryDelivery();
            },
            name: t('temporaryDelivery'),
            iconProps: {iconName: 'Accept'},
            disabled: formData?.status?.key != Status.In_Execution,
        };

        const defDelivery = {
            key: 'definitiveDelivery',
            onClick: () => {
                handleDefinitiveDelivery();
            },
            name: t('definitiveDelivery'),
            iconProps: {iconName: 'Accept'},
            disabled: formData?.status?.key != Status.In_Execution,
        };
        const inviteBtn = {
            key: 'invite',
            onClick: () => {
                dispatch(sendContractorInvitation({
                    lotId: constructorFormData.lot,
                    sequenceId: constructorFormData.lotSequenceId,
                    contractorTeamList: [
                        {
                            cabPersonId: constructorFormData.cabPersonId,
                            companyId: constructorFormData.cabCompanyId,
                            lotContractorId: constructorFormData.lot,
                        },
                    ]
                }))
            },
            name: t('invite'),
            //name: t('invite'),
            iconProps: {iconName: 'Send'},
            //disabled:true
            disabled: getInviteBtnDisable(),
        };

        items.push(newBtn);
        items.push(saveBtn);
        if (selectedLotType === 'Contract') {
            items.push(lotUpload);
        } else {
            items.push(upload);
        }
        items.push(download);
        items.push(tempDelivery);
        items.push(defDelivery);
        if (selectedLotType === CONTRACTOR) {
            items.push(inviteBtn);
        }
        return items;
    };

    return (
        <div>
            <input
                style={{display: 'none'}}
                ref={inputFileRef}
                type="file"
                accept=".xlsx" // ".png,.xlsx" multiple inputs
                multiple={false}
                onChange={(e: any) => {
                    if (selectedLotType === 'Contract') {
                        handleFile(e);
                    } else {
                        handleCwfFile(e);
                    }
                }}
            />
            <input
                style={{display: 'none'}}
                ref={pdfFileRef}
                type="file"
                accept=".pdf" // ".png,.xlsx" multiple inputs
                multiple={false}
                onChange={(e: any) => {
                    handlePdf(e);
                }}
            />
            <CommandBar
                styles={{root: {height: 26}}}
                items={getItems()}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />

            <ConfirmationDialog
                hidden={!isdialogVisible}
                title={t('areyousure')}
                subText={
                    cbc.length +
                    ' ' +
                    t('cbcExelUploadConfirm')
                }
                onClickConfirmDialog={onUploadConfirm}
                onClickCancelDialog={onUploadCancel}
                cancelButtonText={t('cancel')}
                confirmButtonText={t('yesUpload')}
            />
        </div>
    );
};


export default withRouter(CommandBarRight);
