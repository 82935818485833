import {
    ActionButton,
    ChoiceGroup,
    getTheme,
    IChoiceGroupOption,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    Link,
    mergeStyleSets,
    Separator,
    Stack,
    TextField
} from '@fluentui/react';
import React, {FormEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {uPrinceTheme} from '../../../../theme';
import {messageService} from '../../../services/messageService';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import {formatDateTimeToISOString} from '../../../shared/util';
import {PBS_TYPE, ProjectBreakdown, ProjectBreakdownValidationMessage} from '../../../types/projectBreakdownStructure';
import MachineTaxonomyField from '../../mileStones/documentPane/taxonomy/taxonomy';
import ProductTaxonomyField from '../taxonomy/productTaxonomy';
import TaxonomyField from '../taxonomy/taxonomy';

const theme = getTheme();

const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark }
};


const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 3,
    padding: 10
};

// Styles definition
const stackStyles: IStackStyles = {
    root: {
        padding: 0,
        marginBottom: 10
    }
};
const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50,
        width: 0,
        flexGrow: 1
    }
};

// Tokens definition
const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10
};

const stackItemStylesColThree: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50
    }
};

const classNames = mergeStyleSets({
    fullWidth: { width: '100%' },
    labelRightWrapper: {
        textAlign: 'end',
        selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } }
    },
    actionButton: {
        color: uPrinceTheme.palette.themePrimary,
        cursor: 'pointer'
    },
    bigScreenTree: {
        marginBottom: 30,
        height: '30vh'
    },
    smallScreenTree: {
        marginBottom: 30,
        height: '56vh'
    },
    textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' }
});

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const ProductHeader = (props: {
  isCollapsed: boolean;
  isEdit?:boolean;
  dropDownOptions:any;
  projectBreakdown:ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (
    projectBreakdown: ProjectBreakdown,
    save: boolean
  ) => void;
  validationMessages: ProjectBreakdownValidationMessage;
  onFilterTaxonomyNodeChanged:any;
  pbsType?:PBS_TYPE;
  hasBorCreatedForThisProduct:boolean;
}) => {
    const { t } = useTranslation();
    const options: IChoiceGroupOption[] = [
        { key: '0', text: t('inScope') },
        { key: '1', text: t('outofScope'), styles: { field: { marginLeft: '15px' } } }
    ];
    // const [contractIsRequired, setcontractIsRequired] = useState(false);
    const _onChange = (ev?:FormEvent<HTMLElement|HTMLInputElement>|undefined, option?:IChoiceGroupOption|undefined): void => {
        // console.dir(option);
        if (option) {
            props.projectBreakdown.scope = option.key;
            if(option.key === '0') {
                // setcontractIsRequired(true);
            }else{
                // setcontractIsRequired(false);
            }
        } else {
            props.projectBreakdown.scope = null;
            // setcontractIsRequired(false);
        }
        props.handleFormChange(
            props.projectBreakdown,
            true
        );
    };
    return(
        <div className={ 'document-pane-card' }>

            <div className={ 'card-header' }>
                <Link href="#" id="pbs-product-header">
                    <Label>1. { t('product') } </Label>
                </Link>
            </div>

            <div className={ 'card-body' }>
                <div className="proj-detail-content inner">
                    <div className="marginTop">
                        { props.projectBreakdown.productId && <Stack horizontal wrap styles={ stackStyles } tokens={ stackTokens }>
                            <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                <div className={ `${classNames.fullWidth}` }>
                                    <TextField
                                        label={ t('productTitle') }
                                        value={ (props.projectBreakdown.title) ? props.projectBreakdown.title : '' }
                                        disabled={ true }
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                <div className={ `${classNames.fullWidth}` }>
                                    <TextField
                                        label={ t('productId') }
                                        value={ (props.projectBreakdown.productId) ? props.projectBreakdown.productId : '' }
                                        disabled={ true }
                                    />
                                </div>
                            </Stack.Item>
                        </Stack> }
                    </div>

                    <div className="marginTop">
                        <Stack horizontal wrap styles={ stackStyles } tokens={ stackTokens }>
                            <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                <div className={ `${classNames.fullWidth}` }>
                                    <TextField
                                        label={ t('name') }
                                        value={
                                            props.projectBreakdown.name
                                                ? props.projectBreakdown.name
                                                : ''
                                        }
                                        errorMessage={
                                            props.validationMessages.nameErrorMsg
                                                ? props.validationMessages.nameErrorMsg
                                                : ''
                                        }
                                        required={ true }
                                        onChange={ (event, value) => {
                                            if (value) {
                                                props.projectBreakdown.name = value;
                                                props.validationMessages.nameErrorMsg = '';
                                            } else {
                                                props.projectBreakdown.name = null;
                                            }
                                            props.handleFormChange(
                                                props.projectBreakdown,
                                                true
                                            );
                                        } }
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                <div className={ `${classNames.fullWidth}` }>
                                    <ClearableDropdown
                                        placeholder={ t('productItemType') }
                                        label={ t('productItemType') }
                                        options={ props.dropDownOptions.itemTypes }
                                        errorMessage={
                                            props.validationMessages.ItemTypeErrorMsg
                                                ? props.validationMessages.ItemTypeErrorMsg
                                                : ''
                                        }
                                        required={ true }
                                        onChange={ (event, value) => {
                                            if (value) {
                                                props.projectBreakdown.pbsProductItemTypeId = value.key.toString();
                                                messageService.sendMessage({ pbsTypeFromRead: value.text === t('subcontractor') ? PBS_TYPE.SUB : PBS_TYPE.REGULAR });
                                            } else {
                                                props.projectBreakdown.pbsProductItemTypeId = null;
                                            }
                                            props.validationMessages.ItemTypeErrorMsg = '';
                                            props.handleFormChange(
                                                props.projectBreakdown,
                                                true
                                            );
                                        } }
                                        disabled={ props.hasBorCreatedForThisProduct }
                                        selectedKey={
                                            props.projectBreakdown.pbsProductItemTypeId
                                                ? props.projectBreakdown.pbsProductItemTypeId
                                                : ''
                                        }
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>
                    </div>


                    <div className="marginTop">
                        <Stack horizontal wrap styles={ stackStyles } tokens={ stackTokens }>
                            <Stack.Item grow={ 2 } styles={ stackItemStylesColThree }>
                                <div className={ `${classNames.fullWidth}` } style={ { paddingTop: 26 } }>
                                    <ChoiceGroup
                                        styles={ { flexContainer: { display: 'flex' } } }
                                        selectedKey={ (props.projectBreakdown.scope) ? props.projectBreakdown.scope : '0' }
                                        options={ options }
                                        onChange={ _onChange }
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={ 1 } styles={ stackItemStylesColThree }>
                                <div style={ { paddingTop: 30 } }>
                                    <Label>{ t('of') }</Label>
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={ 2 } align="end" styles={ stackItemStylesColThree }>
                                <div className={ `${classNames.fullWidth}` }>
                                    <TextField
                                        label={ t('contract') }
                                        value={
                                            props.projectBreakdown.contract
                                                ? props.projectBreakdown.contract
                                                : ''
                                        }
                                        errorMessage={
                                            props.validationMessages.contractErrorMsg
                                                ? props.validationMessages.contractErrorMsg
                                                : ''
                                        }
                                        required={ true }
                                        onChange={ (event, value) => {
                                            if (value) {
                                                props.projectBreakdown.contract = value;
                                                props.validationMessages.contractErrorMsg = '';
                                            } else {
                                                props.projectBreakdown.contract = null;
                                            }
                                            props.handleFormChange(
                                                props.projectBreakdown,
                                                true
                                            );
                                        } }
                                    />
                                </div>
                            </Stack.Item>
                            { props.isCollapsed &&
                                <Stack.Item grow={ 13 } styles={ stackItemStylesColThree }>
                                    <div className={ `${classNames.fullWidth}` }>
                                        <Label>{ ' ' }</Label>
                                    </div>
                                </Stack.Item>
                            }
                        </Stack>
                    </div>


                    <div className="marginTop">
                        <Stack horizontal wrap styles={ stackStyles } tokens={ stackTokens }>
                            <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                <div className={ `${classNames.fullWidth}` }>
                                    <ClearableDropdown
                                        placeholder={ t('productstate') }
                                        label={ t('productstate') }
                                        options={ props.dropDownOptions.productStates }
                                        required={ true }
                                        errorMessage={
                                            props.validationMessages.statusErrorMsg
                                                ? props.validationMessages.statusErrorMsg
                                                : ''
                                        }
                                        onChange={ (event, value) => {
                                            if (value) {
                                                props.projectBreakdown.pbsProductStatusId = value.key.toString();
                                            } else {
                                                props.projectBreakdown.pbsProductStatusId = null;
                                            }
                                            props.validationMessages.statusErrorMsg = '';
                                            props.handleFormChange(
                                                props.projectBreakdown,
                                                true
                                            );
                                        } }
                                        selectedKey={
                                            props.projectBreakdown.pbsProductStatusId
                                                ? props.projectBreakdown.pbsProductStatusId
                                                : ''
                                        }
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                <div className={ `${classNames.fullWidth}` }>
                                    <ClearableDropdown
                                        placeholder={ t('productToleranceStates') }
                                        label={ t('productToleranceStates') }
                                        options={ props.dropDownOptions.toleranceStates }
                                        required={ true }
                                        errorMessage={
                                            props.validationMessages.toleranceStateErrorMsg
                                                ? props.validationMessages.toleranceStateErrorMsg
                                                : ''
                                        }
                                        onChange={ (event, value) => {
                                            if (value) {
                                                props.projectBreakdown.pbsToleranceStateId = value.key.toString();
                                            } else {
                                                props.projectBreakdown.pbsToleranceStateId = null;
                                            }
                                            props.validationMessages.toleranceStateErrorMsg = '';
                                            props.handleFormChange(
                                                props.projectBreakdown,
                                                true
                                            );
                                        } }
                                        selectedKey={
                                            props.projectBreakdown.pbsToleranceStateId
                                                ? props.projectBreakdown.pbsToleranceStateId
                                                : ''
                                        }
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>
                    </div>

                    <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                    >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                                <CustomDatePicker
                                    setMinDate={ null }
                                    setMaxDate={ (props.projectBreakdown.endDate) ? new Date(props.projectBreakdown.endDate) : null }
                                    label={ t('startDate') }
                                    setValue={ (props.projectBreakdown.startDate) ? new Date(props.projectBreakdown.startDate) : null }
                                    getValue={ (date: Date) => {
                                        props.projectBreakdown.startDate = formatDateTimeToISOString(date);
                                        if (!props.projectBreakdown.endDate) {
                                            props.projectBreakdown.endDate = formatDateTimeToISOString(date);
                                        }
                                        props.handleFormChange(
                                            props.projectBreakdown,
                                            true
                                        );
                                    } }
                                    disabled={ false }
                                />
                            </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                                <CustomDatePicker
                                    setMinDate={ (props.projectBreakdown.startDate) ? new Date(props.projectBreakdown.startDate) : null }
                                    setMaxDate={ null }
                                    label={ t('endDate') }
                                    setValue={ (props.projectBreakdown.endDate) ? new Date(props.projectBreakdown.endDate) : (props.projectBreakdown.startDate) ? new Date(props.projectBreakdown.startDate) : null  }
                                    getValue={ (date: Date) => {
                                        props.projectBreakdown.endDate = formatDateTimeToISOString(date);
                                        props.handleFormChange(
                                            props.projectBreakdown,
                                            true
                                        );
                                    } }
                                    disabled={ false }
                                />
                            </div>
                        </Stack.Item>
                    </Stack>

                    <div className="marginTop" style={ { paddingLeft: 10, paddingRight: 10 } }>
                        <TaxonomyField
                            label={ t('utilityTaxonomy') }
                            treeData={ props.dropDownOptions.utilityTaxonomy }
                            selectItemId={ props.projectBreakdown.utilityTaxonomyParentId
                                ? props.projectBreakdown.utilityTaxonomyParentId
                                : '' }
                            onSelectItem={ (id: string) => {
                                if (id) {
                                    if(id === props.projectBreakdown.utilityTaxonomyParentId ) {
                                        props.projectBreakdown.utilityTaxonomyParentId = null;
                                    }else{
                                        props.projectBreakdown.utilityTaxonomyParentId = id;
                                    }
                                } else {
                                    props.projectBreakdown.utilityTaxonomyParentId = null;
                                }
                                props.handleFormChange(
                                    props.projectBreakdown,
                                    true
                                );
                            } }
                            treeLevel={ props.dropDownOptions.utilityTaxonomyLevel }
                            onFilterTaxonomyNodeChanged={ props.onFilterTaxonomyNodeChanged }
                        />
                    </div>

                    <div className="marginTop" style={ { paddingLeft: 10, paddingRight: 10 } }>
                        <TaxonomyField
                            label={ t('locationTaxonomy') }
                            treeData={ props.dropDownOptions.locationTaxonomy }
                            selectItemId={ props.projectBreakdown.locationTaxonomyParentId
                                ? props.projectBreakdown.locationTaxonomyParentId
                                : '' }
                            onSelectItem={ (id: string) => {
                                if (id) {
                                    if(id === props.projectBreakdown.locationTaxonomyParentId ) {
                                        props.projectBreakdown.locationTaxonomyParentId = null;
                                    }else{
                                        props.projectBreakdown.locationTaxonomyParentId = id;
                                    }
                                } else {
                                    props.projectBreakdown.locationTaxonomyParentId = null;
                                }
                                props.handleFormChange(
                                    props.projectBreakdown,
                                    true
                                );
                            } }
                            treeLevel={ props.dropDownOptions.locationTaxonomyLevel }
                            onFilterTaxonomyNodeChanged={ props.onFilterTaxonomyNodeChanged }
                        />
                    </div>

                    <div className="marginTop" style={ { paddingLeft: 10, paddingRight: 10 } }>
                        <ProductTaxonomyField
                            label={ t('productTaxonomy') }
                            treeData={ props.dropDownOptions.productTaxonomy }
                            selectItemId={ props.projectBreakdown.parentId
                                ? props.projectBreakdown.parentId
                                : '' }
                            onSelectItem={ (id: string) => {
                                if (id) {
                                    if(id === props.projectBreakdown.parentId ) {
                                        props.projectBreakdown.parentId = null;
                                    } else {
                                        props.projectBreakdown.parentId = id;
                                    }
                                } else {
                                    props.projectBreakdown.parentId = null;
                                }
                                props.handleFormChange(
                                    props.projectBreakdown,
                                    true
                                );
                            } }
                            treeLevel={[]}
                            onFilterTaxonomyNodeChanged={ null }
                            projectBreakdown={props.projectBreakdown}
                        />
                    </div>

                    <div className="marginTop" style={ { paddingLeft: 10, paddingRight: 10 } }>
                        <MachineTaxonomyField
                            label={ t('machineTaxonomy') }
                            treeData={ props.dropDownOptions.machineTaxonomy }
                            selectItemId={ props.projectBreakdown.machineTaxonomyParentId
                                ? props.projectBreakdown.machineTaxonomyParentId
                                : '' }
                            onSelectItem={ (id: string) => {
                                if (id) {
                                    if(id === props.projectBreakdown.machineTaxonomyParentId ) {
                                        props.projectBreakdown.machineTaxonomyParentId = null;
                                    } else {
                                        props.projectBreakdown.machineTaxonomyParentId = id;
                                    }
                                } else {
                                    props.projectBreakdown.machineTaxonomyParentId = null;
                                }
                                props.handleFormChange(
                                    props.projectBreakdown,
                                    true
                                );
                            } }
                            treeLevel={props.dropDownOptions.machineTaxonomyLevel}
                            onFilterTaxonomyNodeChanged={ null }
                        />
                    </div>
                </div>
            </div>

            <div className={ 'card-footer' }>
                <Separator />
                <Stack
                    styles={ stackStyles }
                    tokens={ itemAlignmentsStackTokens }>
                    <Stack.Item align="end" styles={ stackFooterBarItemStyles }>
                        <ActionButton
                            className={ classNames.actionButton }
                            iconProps={ saveIcon }
                            allowDisabledFocus
                            onClick={ () => {
                                props.saveFormData();
                            } }
                        >
                            <Label className={ classNames.actionButton }>{ t('save') } </Label>
                        </ActionButton>
                    </Stack.Item>
                </Stack>
            </div>

        </div>
    );
};


export default ProductHeader;
