import React, {useEffect, useRef, useState} from 'react';
import {Task} from '../../types/public-types';
import {BarTask} from '../../types/bar-task';
import styles from './tooltip.module.css';
import {getCPCTeams} from '../../../../components/visualPlan/utils';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useDispatch, useSelector, useStore} from "react-redux";
import {getPBSLabourForVP} from "../../../../reducers/projectBreakdownReducer";
import _ from "lodash";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {readAllPmolLabour} from "../../../../reducers/visualPlanReducer";

export type TooltipProps = {
  task: BarTask;
  arrowIndent: number;
  rtl: boolean;
  svgContainerHeight: number;
  svgContainerWidth: number;
  svgWidth: number;
  headerHeight: number;
  taskListWidth: number;
  scrollX: number;
  scrollY: number;
  rowHeight: number;
  fontSize: string;
  fontFamily: string;
  TooltipContent: React.FC<{
    task: Task;
    fontSize: string;
    fontFamily: string;
    y:number
  }>;
};
export const Tooltip: React.FC<TooltipProps> = ({
    task,
    rowHeight,
    rtl,
    svgContainerHeight,
    svgContainerWidth,
    scrollX,
    scrollY,
    arrowIndent,
    fontSize,
    fontFamily,
    headerHeight,
    taskListWidth,
    TooltipContent
}) => {
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [ relatedY, setRelatedY ] = useState(0);
    const [ relatedX, setRelatedX ] = useState(0);
    useEffect(() => {
        if (tooltipRef.current) {
            const tooltipHeight = tooltipRef.current.offsetHeight * 1.1;
            const tooltipWidth = tooltipRef.current.offsetWidth * 1.1;

            let newRelatedY = task.index * rowHeight - scrollY + headerHeight;
            let newRelatedX: number;
            if (rtl) {
                newRelatedX = task.x1 - arrowIndent * 1.5 - tooltipWidth - scrollX;
                if (newRelatedX < 0) {
                    newRelatedX = task.x2 + arrowIndent * 1.5 - scrollX;
                }
                const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
                if (tooltipLeftmostPoint > svgContainerWidth) {
                    newRelatedX = svgContainerWidth - tooltipWidth;
                    newRelatedY += rowHeight;
                }
            } else {
                newRelatedX = task.x2 + arrowIndent * 1.5 + taskListWidth - scrollX;
                const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
                const fullChartWidth = taskListWidth + svgContainerWidth;
                if (tooltipLeftmostPoint > fullChartWidth) {
                    newRelatedX =
            task.x1 +
            taskListWidth -
            arrowIndent * 1.5 -
            scrollX -
            tooltipWidth;
                }
                if (newRelatedX < taskListWidth) {
                    newRelatedX = svgContainerWidth + taskListWidth - tooltipWidth;
                    newRelatedY += rowHeight;
                }
            }

            const tooltipLowerPoint = tooltipHeight + newRelatedY - scrollY;
            if (tooltipLowerPoint > svgContainerHeight - scrollY) {
                newRelatedY = svgContainerHeight - tooltipHeight;
            }
        
            setRelatedY(newRelatedY);
            setRelatedX(newRelatedX);
        }
    }, [
        tooltipRef.current,
        task,
        arrowIndent,
        scrollX,
        scrollY,
        headerHeight,
        taskListWidth,
        rowHeight,
        svgContainerHeight,
        svgContainerWidth
    ]);

    return (
        <div
            ref={ tooltipRef }
            className={
                relatedX
                    ? styles.tooltipDetailsContainer
                    : styles.tooltipDetailsContainerHidden
            }
            style={ { left: relatedX, top: relatedY } }
        >
            <TooltipContent task={ task } y={(task.index * rowHeight - scrollY + headerHeight)} fontSize={ fontSize } fontFamily={ fontFamily } />
        </div>
    );
};

export const StandardTooltipContent: React.FC<{
  task: any;
  fontSize: string;
  fontFamily: string;
  y:number
}> = ({ task, fontSize, fontFamily, y }) => {
    const store = useStore();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [labourResource, setLabourResources] = useState<any>(null);
    const [pmolLaboursList, setPmolLaboursList] = useState<any>(null);

    const {pbsLabourResourcesLoading, pbsLabourResources}:any = useSelector(
      (state: any) =>  state.projectBreakdown,
    );

    const {allPmolLaboursListLoading, allPmolLaboursList}:any = useSelector(
      (state: any) =>  state.vp,
    );

    const style = {
        fontSize,
        fontFamily,
        background:' #fff',
        padding: '12px',
        marginTop: y < 450 ?'30px':'0px',
        marginBottom: y > 450 ?'100px':'0px',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
    };

    useEffect(() => {
      if(store.getState().vp.selectedProjectItemType === 6) {
        if(!_.isNull(task.cpcId)) {
          dispatch(readAllPmolLabour({pmolSequenceId: task.pmolSequenceId, cpcId: task.cpcId}));
        } else {
          dispatch(getPBSLabourForVP(task.pbsSequenceId));
        }
      }
    }, []);

    useEffect(() => {
      setLabourResources(pbsLabourResources);
    }, [pbsLabourResources]);

    useEffect(() => {
        setPmolLaboursList(allPmolLaboursList);
    }, [allPmolLaboursList]);

  const renderOptionList = () => {
    let isLoading: boolean = pbsLabourResourcesLoading || allPmolLaboursListLoading;

    if (isLoading) {
      return <Spinner size={SpinnerSize.xSmall}/>
    } else {
      if(_.isNull(task.pmolSequenceId)) { //for PBS
        return labourResource && labourResource.length > 1 && labourResource.map((item: any) => {
            return <p
              className={styles.tooltipDefaultContainerParagraph}>{`${item.cpcTitle}: ${~~(item.quantity)} ${t('hours')}`}
            </p>
          }
        );
      } else if (!_.isNull(task.cpcId) && pmolLaboursList && pmolLaboursList?.pmolLabour?.length > 1) {  //for CPC
        return pmolLaboursList.pmolLabour.map((item: any) => {
            return <p
              className={styles.tooltipDefaultContainerParagraph}>{`${item.cabPersonName}`}
            </p>
          }
        );
      }
    }
  };

    return (
        <div id={'tip'} style={ style }>
            <b style={ { fontSize: fontSize + 6 } }>{ `${
                task.name
            }: ${task.start.getDate()}-${
                task.start.getMonth() + 1
            }-${task.start.getFullYear()} - ${moment(task.end).subtract(1, "days").format('DD-MM-YYYY')}` }</b>
            { task.end.getTime() - task.start.getTime() !== 0 && (
              <div>
                <p className={styles.tooltipDefaultContainerParagraph}>{`${t('duration')}: ${~~(
                  (task.end.getTime() - task.start.getTime()) /
                  (1000 * 60 * 60 * 24)
                )} ${t('days')}`}</p>
                {store.getState().vp.selectedProjectItemType === 6 && renderOptionList()}
              </div>
            ) }

            <p className={ styles.tooltipDefaultContainerParagraph }>
                { !!task.progress && `${t('progress')}: ${task.progress} %` }
            </p>
           
            { /* {JSON.stringify(task.teams)} */ }

            { (task.type === 'resourceitem' && task.teams.length > 0 && task.resourceType === 'Labour') && 

          <div>
             <p className={ styles.tooltipDefaultContainerParagraph }>
                { t('teamAssigned:') }
            </p>
              { getCPCTeams(task.teams, task.cpcId).map((member:any) =>
                  <li key={ member.id }>{ member.personName }</li>
              ) }
          </div>
            }

        </div>
    );
};
