import React, { useEffect, useState } from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import './shortcutPane/wbs-create-shortcut-pane-styles.css';
import WbsCreateShortcutPane from './shortcutPane/component';
import { useDispatch, useSelector } from 'react-redux';
import WBSCreateCmdBarLeft from './commandBar/wbsCreateCmdBarLeft/component';
import TitlePane from '../../shared/titlePane/titlePane';
import './wbs-create-style-sheet.css';
import { messageService } from '../../services/messageService';
import { useLocation } from 'react-router-dom';
import {
  createWbsTemplate,
  getWbsCreateLevelData,
  getWbsProductById,
  readWbsCreateListData,
  readWbsTaxonomyByTemId,
  saveWbsCreateStateAttr,
} from '../../reducers/wbsCreateReducer';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ParamTypes } from '../../types/uPrince';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import { WBS_TAXONOMY_LEVEL, WbsCreateListPaneFilter } from '../../types/wbs';
import DocumentPaneWrapper from './documentPane/WbsCreateDocumentPaneWrapper';
import RenameTemplateModal from './documentPane/component/RenameTemplateModal';
import ListPaneWrapper from './listPane/ListPaneWrapper';
import i18n from 'i18next';
import _ from 'lodash';
import { getWbsTaskById } from '../../reducers/wbsTaskReducer';

/**
 * Wbs Create MainLayout
 * @isTemplate means inside of the template. (It's opens on title click)
 */
const WbsCreateMainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { featureId, templateId }: ParamTypes = useParams();
  const paths = window.location.pathname.split('/');

  //  Redux state variables
  // const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const isTemplateCreated = useSelector((state: any) => state.wbsCreate.isTemplateCreated);
  const isTemplate = useSelector((state: any) => state.wbsCreate.isTemplate);
  const wbsListData = useSelector((state: any) => state.wbsCreate.wbsListData);

  //  State variables
  const [reSizer, setReSizer] = useState<number>(screen.width);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [showDetails, setShowDetails]: any = useState(false);
  const [template, setTemplate] = useState(null);
  const [wbsTaxonomyLevel, setWbsTaxonomyLevel] = React.useState<any>(null);

  //  Wbs Create MainLayout didMount
  useEffect(() => {
    dispatch(readWbsCreateListData(new WbsCreateListPaneFilter()));
    dispatch(getWbsCreateLevelData());

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewWbs) {
          setOpenConfirmationDialog((prevState: boolean) => !prevState);
        }
        if (data.data.showWbsTaxonomyCreateDocumentPane) {
          hideDocumentPane(false);
        }
        if (data.data.hideDocumentPane) {
          hideDocumentPane(true);
        }
        if (data.data.wbsTaxonomyLevel) {
          setWbsTaxonomyLevel(data.data.wbsTaxonomyLevel);
        }
      }
    });

    return () => {
      //  Wbs Create MainLayout unMount
      subscription.unsubscribe();

      setTemplate(null);
    };
  }, []);

  useEffect(() => {
    handleUrlChange();
  }, [location, featureId, templateId]);

  useEffect(() => {
    //New WBS Template created
    if (isTemplateCreated && openConfirmationDialog) {
      setOpenConfirmationDialog((prevState: boolean) => !prevState);
    }
  }, [isTemplateCreated]);

  /**
   *  This useEffect is use for set the state for the template.
   *  @handleTemplate function set the state.
   *  each url change required to run this function so the template and its title set correctly
   *  @wbsListData comes from WbsFilter API
   */
  useEffect(() => {
    const loadTemplate = async () => {
      if (wbsListData && !_.isEmpty(wbsListData)) {
        handleTemplate();
      }
    };

    loadTemplate().then();
  }, [wbsListData]);
  const handleTemplate = () => {
    setTemplate(wbsListData.find((item: any) => item.id === featureId));
  };

  const handleUrlChange = () => {
    if (wbsTaxonomyLevel === WBS_TAXONOMY_LEVEL.TASK && templateId) {
      dispatch(getWbsTaskById({ id: templateId }));
    } else if (paths[5] && paths[5] === 'template') {                                    //is inside the template
      if (templateId) {
        hideDocumentPane(false);
        dispatch(getWbsProductById(templateId));
      } else {
        hideDocumentPane(true);
        dispatch(readWbsTaxonomyByTemId(featureId));
        handleTemplate();
      }
      dispatch(saveWbsCreateStateAttr('isTemplate', true));
    } else {                                                                      //is on the template
      if (featureId) {
        hideDocumentPane(false);
        dispatch(readWbsTaxonomyByTemId(featureId));
        handleTemplate();
      } else {
        hideDocumentPane(true);
      }
      dispatch(saveWbsCreateStateAttr('isTemplate', false));
    }
  };

  const hideDocumentPane = (isHide: boolean) => {
    if (isHide) {
      setShowDetails(false);
      setReSizer(screen.width);
    } else {
      setShowDetails(true);
      setReSizer(screen.width / 2);
    }
  };

  const renderShortCutPane = () => {
    return <WbsCreateShortcutPane />;
  };

  const getListPaneView = () => {
    return (
      <div className={'grid-righter column-filter-activated'}>
        <TitlePane
          headerTitle={'All'}
          type={1}
        />
        <div style={{
          height: 'calc(100vh - 155px)',
          overflow: 'hidden',
        }}>
          <div style={{ display: 'flex' }}>
            {renderShortCutPane()}
            <div className={'grid-righter'}>
              <div className='project-list-header new-design'>
                <WBSCreateCmdBarLeft isTemplate={isTemplate} />
                {/*<ProgressBar show={showWbsProgressBar} />*/}
              </div>

              <div style={{ height: 'calc(100vh - 184px)', overflow: 'auto' }}>
                <ListPaneWrapper isTemplate={isTemplate} showDocumentPane={showDetails}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getDocumentPaneView = () => {
    return <>
      <DocumentPaneWrapper isTemplate={isTemplate} template={template} wbsTaxonomyLevel={wbsTaxonomyLevel} />
    </>;
  };

  return (
    <div>
      <SplitPane
        className={'SplitPane'}
        minSize={(screen.width / 3)}
        maxSize={(screen.width / 3) * 2}
        defaultSize={screen.width}
        split='vertical'
        onChange={(size: any) => {
          setReSizer(size);
        }}
        size={reSizer}
        style={{ height: 'calc(100vh - 129px)' }}
      >
        <Pane className={''}
              style={{
                width: reSizer,
                height: 'calc(100vh - 129px)',
              }}>{getListPaneView()} </Pane>
        <Pane className={''}
              style={{
                marginLeft: 6,
                width: screen.width - reSizer,
                height: '100%',
              }}> {showDetails && getDocumentPaneView()}</Pane>
      </SplitPane>

      <ConfirmationDialog
        hidden={!openConfirmationDialog}
        title={i18n.t('areyousure')}
        subText={i18n.t('createTemMsg')}
        onClickConfirmDialog={() => {
          hideDocumentPane(false);
          dispatch(createWbsTemplate({ id: uuidv4(), name: 'New Template' }));
          dispatch(saveWbsCreateStateAttr('isEditWbsTemp', false));
        }}
        onClickCancelDialog={() => {
          setOpenConfirmationDialog((prevState: boolean) => !prevState);
        }}
        cancelButtonText={i18n.t('no')}
        confirmButtonText={i18n.t('yesCreateIt')}
      />

      <RenameTemplateModal template={template} />
    </div>
  );
};

export default WbsCreateMainLayout;
