import React from 'react';
import {Pivot, Stack} from "office-ui-fabric-react";
import {saveDayPlanningStateAttr} from "../../../../reducers/dayPlanningReducer";
import {SelectedGridType} from "../../../../types/dayPlanning";
import {Calendar, DateRangeType, DayOfWeek, defaultCalendarStrings, FirstWeekOfYear, PivotItem} from "@fluentui/react";
import moment from "moment";
import {getCalenderString} from "../../../../shared/util";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

interface Props {
    divClass: string;
    horizontalReSizer: any;
}

export const MyEnvShortcutPane = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const dayButtonId = 'day';
    const weekButtonId = 'week';


    const {dayPlanningDate, dayPlanningWeek, selectedGridType} = useSelector(
      (state: any) => state.dplan
    );

    const checkDateIsValid = (date: any) => {
        if (typeof date === "string") {
            return new Date(date);
        } else {
            return date;
        }
    };


    return (
      <div className={`dayPlanning ${props.divClass} wrapper`}>
          <Stack
            horizontal
            wrap
            styles={{
                root: {
                    width: '100%',
                    verticalAlign: 'center'
                }
            }}
          >
              <Stack.Item>
                  <Pivot selectedKey={selectedGridType} onLinkClick={(item: any) => {
                      if (item) {
                          if (item.props.itemKey! === dayButtonId) {
                              dispatch(saveDayPlanningStateAttr('selectedGridType', SelectedGridType.DAY));
                          } else if (item.props.itemKey! === weekButtonId) {
                              dispatch(saveDayPlanningStateAttr('selectedGridType', SelectedGridType.WEEK));
                          }
                      }
                  }}>
                      <PivotItem headerText={t('day')} itemKey={dayButtonId}>
                          < Calendar
                            onSelectDate={(date: Date) => {
                                let selectedWeek: {};
                                dispatch(saveDayPlanningStateAttr('dayPlanningDate', date));
                                selectedWeek = {
                                    startDate: moment(date).clone().startOf('isoWeek').format('YYYY-MM-DD'),
                                    endDate: moment(date).clone().endOf('isoWeek').format('YYYY-MM-DD'),
                                    toDate: date
                                }
                                dispatch(saveDayPlanningStateAttr('dayPlanningWeek', selectedWeek));
                            }}
                            value={checkDateIsValid(dayPlanningDate)}
                            firstDayOfWeek={DayOfWeek.Monday}
                            // Calendar uses English strings by default. For localized apps, you must override this prop.
                            strings={getCalenderString()}
                            showWeekNumbers
                            showMonthPickerAsOverlay
                            highlightSelectedMonth
                            firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                            showGoToToday={false}
                          />
                      </PivotItem>
                      <PivotItem headerText={t('week')} itemKey={weekButtonId}>
                          <Calendar
                            value={checkDateIsValid(dayPlanningWeek.toDate)}
                            dateRangeType={DateRangeType.Week}
                            onSelectDate={(date: Date, selectedDateRangeArray: Date[] | undefined) => {
                                let selectedWeek = {};
                                if (selectedDateRangeArray) {
                                    selectedWeek = {
                                        startDate: selectedDateRangeArray[0],
                                        endDate: selectedDateRangeArray[selectedDateRangeArray.length - 1],
                                        toDate: date
                                    }
                                }
                                dispatch(saveDayPlanningStateAttr('dayPlanningWeek', selectedWeek));
                            }}
                            firstDayOfWeek={DayOfWeek.Monday}
                            // Calendar uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultCalendarStrings}
                            showWeekNumbers
                            showMonthPickerAsOverlay
                            highlightSelectedMonth
                            showGoToToday={false}
                          />
                      </PivotItem>
                  </Pivot>
              </Stack.Item>
          </Stack>
      </div>
    );
}